/* eslint-disable no-debugger */
import React, { useState, useEffect } from "react";
import blueLocationIcon from "../../assets/img/marker-location-blue.svg";
import withStyles from "@mui/styles/withStyles";
import { compose } from "redux";
import { connect } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { MapContainer, TileLayer, Marker, LayersControl } from "react-leaflet";
import L from "leaflet";
import "./arrow.css";

const useStyles = (theme) => ({
  leafletContainer: {
    width: "100%",
    height: "100%",
    zIndex: 0,
  },
});

const SiteInfoMapBox = ({ classes, siteInfos, siteCenter, actualParams }) => {
  const [azimuts, setAzimuts] = useState(null);
  const [customMarkerIcon, setCustomMarkerIcon] = useState(null);
  const [markerIcon, setMarkerIcon] = useState(null);

  useEffect(() => {
    if (actualParams) {
      let azimutArray = [];
      actualParams.sectors.map((sector) => {
        azimutArray = [...azimutArray, parseInt(sector.azimuth)];
      });
      setAzimuts(azimutArray);
    }
  }, [actualParams]);

  useEffect(() => {
    if (azimuts) {
      let div = "";
      azimuts.map((a) => {
        div += `<div class='arrow' style="transform: rotate(${a}deg);"></div>`;
      });
      setCustomMarkerIcon(div);
    }
  }, [azimuts]);

  useEffect(() => {
    if (customMarkerIcon) {
      const icon = new L.divIcon({
        iconAnchor: [27, 82],
        className: "rect",
        html: `${customMarkerIcon}`,
      });
      setMarkerIcon(icon);
    } else {
      const icon = L.icon({
        iconUrl: blueLocationIcon,
        iconSize: [40, 40],
        iconAnchor: [19, 40],
      });
      setMarkerIcon(icon);
    }
  }, [customMarkerIcon]);

  return (
    <>
      {siteCenter !== undefined && (
        <MapContainer
          className={classes.leafletContainer}
          center={siteCenter}
          zoom={18}
        >
          <LayersControl position='bottomleft'>
            <LayersControl.BaseLayer checked name='Street View'>
              <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
              />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer name='Geo View'>
              <TileLayer
                attribution='Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
                url='https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
              />
            </LayersControl.BaseLayer>
          </LayersControl>
          <Marker
            key={uuidv4()}
            icon={markerIcon}
            style={{
              backgroundColor: "transparent",
            }}
            position={[
              siteInfos.geometry.coordinates[1],
              siteInfos.geometry.coordinates[0],
            ]}
          ></Marker>
        </MapContainer>
      )}
      {siteCenter === undefined && (
        <div
          style={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontWeight: "bold",
          }}
        >
          non ci sono le coordinate
        </div>
      )}
    </>
  );
};

const mapState = ({ site }) => ({
  actualParams: site.actualParams,
});

const mapDispatch = ({ site: { setSiteBounds } }) => ({
  setSiteBounds: (coords) => setSiteBounds(coords),
});

export default compose(
  withStyles(useStyles),
  connect(mapState, mapDispatch)
)(SiteInfoMapBox);
