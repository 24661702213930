import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import withStyles from "@mui/styles/withStyles";
import { v4 as uuidv4 } from "uuid";

import {
  TextField,
  Button,
  Dialog,
  Divider,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import DeleteIcon from "@mui/icons-material/Delete";
import { useConfirm } from "material-ui-confirm";

const useStyles = (theme) => ({
  mainContainer: {
    width: "min(80%, 1000px)",
    margin: "0 auto",
    padding: "0.5rem",
    fontFamily: "TIM Sans, sans-serif",
  },
  mainHeader: {
    margin: "2rem 0",
    "& > h3": {
      color: "#018ABE",
      margin: "0.5rem 0",
    },
    "& > p": {
      margin: "0",
    },
  },
  inputsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },
  inputCategory: {
    display: "flex",
    justifyContent: "space-between",
    gap: "1rem",
  },
  inputHeader: {
    color: "#018ABE",
    marginBottom: "0.5rem",
  },
  categorySplit: {
    width: "100%",
    "& > *:not(:last-child)": {
      marginBottom: "0.5rem",
    },
  },
  inputSplit: {
    display: "flex",
    justifyContent: "space-between",
  },
  inputRow: {
    display: "flex",
    justifyContent: "space-between",
    gap: "0.5rem",
    "& > *": {
      flex: "0.25",
    },
  },
  buttonGroup: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    gap: "2rem",
    margin: "2rem 0",
    "& > *": {
      color: "#fff",
    },
  },
});

const SimulateTechnologyModal = ({
  classes,
  openSimulateTech,
  setOpenSimulateTech,
  setSimulationStarted,
  setIsSimulatingTechnology,
  resetModelAndTenant,
  getTenantsList,
  getManufacturersList,
  getModelsList,
  setSimulateTechInputs,
  setModelsList,
  resetSimulateTechInputs,
  getAvailableTechnologiesList,
  setAvailableTechnologiesList,
  setSimulateTechParameter,
  setSimulateTechConfiguration,
  putSimulateTechConfiguration,
  tenantsList,
  manufacturersList,
  availableTechnologiesList,
  simulateTechInputs,
  modelsList,
}) => {
  const [modelSearchString, setModelSearchString] = useState("");
  const [canBeAdded, setCanBeAdded] = useState(false);
  const [canStart, setCanStart] = useState(false);
  const [isInputLocked, setIsInputLocked] = useState(false);
  const [lockedTenant, setLockedTenant] = useState(null);
  const [lockedTechnology, setLockedTechnology] = useState(null);
  const [technologiesAdded, setTechnologiesAdded] = useState([]);
  const [autocompleteInputValues, setAutocompleteInputValues] = useState({
    tenant: "",
    technology: "",
    manufacturer: "",
    model: "",
  });

  const confirm = useConfirm();

  const {
    freqRangeMin,
    freqRangeMax,
    manufacturer,
    azimuth,
    hba,
    model,
    poleid,
    technology,
    tenant,
    tilt,
    etilt,
  } = simulateTechInputs;

  const handleAddTech = () => {
    const newAddedTechnology = {
      azimuth: parseInt(azimuth),
      model,
      poleid,
      hba: parseFloat(hba),
      tilt: parseInt(tilt),
      etilt: parseInt(etilt),
      tenant,
      technology,
      brand: manufacturer,
      id: uuidv4(),
    };
    setTechnologiesAdded((previous) => [...previous, newAddedTechnology]);
    setLockedTenant(tenant);
    setLockedTechnology(technology);
    setIsInputLocked(true);
    resetSimulateTechInputs({
      tenant,
      technology,
      freqRangeMin,
      freqRangeMax,
      manufacturer: null,
      model: null,
      polid:null,
      azimuth: null,
      hba: null,
      tilt: null,
      etilt: null,
    });
    setModelSearchString("");
  };
  const handleCloseModal = (_event, _reason) => {
    confirm({
      title: "You're about to close this configuration panel.",
      description:
        "All the configurations done will be lost. Do you want to proceed?",
      confirmationText: "Yes",
      cancellationText: "No",
    })
      .then(() => {
        setOpenSimulateTech(!openSimulateTech);
        resetModelAndTenant({});
        resetSimulateTechInputs({
          tenant: null,
          technology: null,
          manufacturer: null,
          freqRangeMin: null,
          freqRangeMax: null,
          model: null,
          azimuth: null,
          hba: null,
          tilt: null,
          etilt: null,
        });
      })
      .catch(() => {});
  };
  const handleDeleteRow = (row) => {
    const filteredTechnologies = technologiesAdded.filter(
      (tech) => tech.id !== row.id
    );
    setTechnologiesAdded(filteredTechnologies);
    if (filteredTechnologies.length === 0) {
      setLockedTenant(null);
      setLockedTechnology(null);
      setIsInputLocked(false);
      resetSimulateTechInputs({
        tenant: null,
        technology: null,
        manufacturer: null,
        freqRangeMin: null,
        freqRangeMax: null,
        model: null,
        azimuth: null,
        hba: null,
        tilt: null,
        etilt: null,
      });
      setAutocompleteInputValues({
        tenant: "",
        technology: "",
        manufacturer: "",
        model: "",
      });
    }
  };
  const handleStartSimulation = () => {
    setSimulateTechParameter({
      tenant: lockedTenant,
      technology: lockedTechnology,
    });
    setSimulateTechConfiguration(technologiesAdded);
    confirm({
      title: "You're about to start a simulate technology evaluation.",
      description: "Do you want to go on with this configuration?",
      confirmationText: "Yes",
      cancellationText: "No",
    })
      .then(() => {
        startSimulation();
      })
      .catch(() => {});
  };

  const startSimulation = async () => {
    await putSimulateTechConfiguration().then(async () => {
      await setIsSimulatingTechnology(true);
      await setSimulationStarted(true);
      await setOpenSimulateTech(!openSimulateTech);
    });
  };
  const fetchTenantsAndManufacturers = async () => {
    await getTenantsList();
    await getManufacturersList();
  };
  const fetchModelsList = async () => {
    setModelsList(null);
    if (manufacturer && modelSearchString && freqRangeMin && freqRangeMax) {
      await getModelsList(
        manufacturer,
        modelSearchString,
        freqRangeMin,
        freqRangeMax
      );
    }
  };

  const inputHandlers = {
    addTenant: (_event, newValue) => {
      setSimulateTechInputs("tenant", newValue);
      getAvailableTechnologiesList(newValue);
    },
    addTechnology: (_event, newValue) => {
      setSimulateTechInputs("technology", newValue.technology);
      setAutocompleteInputValues((previous) => {
        return {
          ...previous,
          technology: newValue.technology,
        };
      });
      setSimulateTechInputs("freqRangeMin", newValue.base_frequency);
      setSimulateTechInputs("freqRangeMax", newValue.base_frequency);
    },
    addManufacturer: (_event, newValue) => {
      setSimulateTechInputs("manufacturer", newValue);
    },
    addModelSearchString: (event) => {
      const { value } = event.target;
      setModelSearchString(value);
    },
    addMinRangeFreq: (event) => {
      const { value } = event.target;
      setSimulateTechInputs("freqRangeMin", Number(value));
    },
    addMaxRangeFreq: (event) => {
      const { value } = event.target;
      setSimulateTechInputs("freqRangeMax", Number(value));
    },
    addModel: (_event, newValue) => {
      setSimulateTechInputs("model", newValue);
    },
    addAzimuth: (event) => {
      const { value } = event.target;
      setSimulateTechInputs("azimuth", Number(value));
    },
    addHba: (event) => {
      const { value } = event.target;
      setSimulateTechInputs("hba", Number(value));
    },
    addMechanicalDowntilt: (event) => {
      const { value } = event.target;
      setSimulateTechInputs("tilt", Number(value));
    },
    addElectricalTilt: (event) => {
      const { value } = event.target;
      setSimulateTechInputs("etilt", Number(value));
    },
  };
  const autocompleteInputHandlers = {
    onTenantChange: (_event, newInputValue) => {
      setAutocompleteInputValues((previous) => {
        return { ...previous, tenant: newInputValue };
      });
    },
    onTechnologyChange: (_event, newInputValue) => {
      setAutocompleteInputValues((previous) => {
        return {
          ...previous,
          technology: newInputValue,
        };
      });
    },
    onModelChange: (_event, newInputValue) => {
      setAutocompleteInputValues((previous) => {
        return { ...previous, model: newInputValue };
      });
    },
    onManufacturerChange: (_event, newInputValue) => {
      setAutocompleteInputValues((previous) => {
        return { ...previous, manufacturer: newInputValue };
      });
    },
  };

  useEffect(() => {
    fetchTenantsAndManufacturers();
    setSimulateTechParameter({});
    setSimulateTechConfiguration([]);
    return () => {
      resetModelAndTenant({});
      setAvailableTechnologiesList(null);
      resetSimulateTechInputs({
        tenant: null,
        technology: null,
        manufacturer: null,
        freqRangeMin: null,
        freqRangeMax: null,
        model: null,
        azimuth: null,
        hba: null,
        tilt: null,
        etilt: null,
      });
    };
  }, []);

  useEffect(() => {
    const azimuthIsOk =
      ("azimuth" in simulateTechInputs && azimuth) || azimuth === 0;
    const modelIsOk = "model" in simulateTechInputs && model;
    const technologyIsOk = "technology" in simulateTechInputs && technology;
    const tenantIsOk = "tenant" in simulateTechInputs && tenant;
    const tiltIsOk = ("tilt" in simulateTechInputs && tilt) || tilt === 0;
    const etiltIsOk = ("etilt" in simulateTechInputs && etilt) || etilt === 0;

    const isOk = Boolean(
      azimuthIsOk &&
        modelIsOk &&
        technologyIsOk &&
        tenantIsOk &&
        tiltIsOk &&
        etiltIsOk
    );

    setCanBeAdded(isOk);
  }, [simulateTechInputs]);

  useEffect(() => {
    if (technologiesAdded && technologiesAdded.length > 0) {
      setCanStart(true);
    }
  }, [technologiesAdded]);

  useEffect(() => {
    fetchModelsList();
  }, [manufacturer, modelSearchString, freqRangeMin, freqRangeMax]);

  return (
    <Dialog
      onClose={handleCloseModal}
      open={openSimulateTech}
      fullWidth={true}
      fullScreen
      sx={{ padding: "1rem" }}
    >
      <div className={classes.mainContainer}>
        <div className={classes.mainHeader}>
          <h3>Simulate Technology Configuration</h3>
          <p>
            Fill the required inputs and add the technologies you want to
            simulate.
          </p>
        </div>
        <div className={classes.inputsContainer}>
          <div className={classes.inputCategory}>
            {/* TENANT INPUT GROUP */}
            <div className={classes.categorySplit}>
              <div className={classes.inputHeader}>TENANTS</div>
              <Autocomplete
                options={tenantsList || []}
                value={tenant}
                inputValue={autocompleteInputValues.tenant}
                onChange={inputHandlers.addTenant}
                onInputChange={autocompleteInputHandlers.onTenantChange}
                disabled={isInputLocked}
                renderInput={(params) => (
                  <TextField
                    style={{ width: "100%", display: "block" }}
                    {...params}
                    label='Tenant'
                    variant='outlined'
                  />
                )}
              />
              <Autocomplete
                options={availableTechnologiesList || []}
                value={technology}
                onChange={inputHandlers.addTechnology}
                inputValue={autocompleteInputValues.technology}
                getOptionLabel={(option) =>
                  option && option.technology ? option.technology : ""
                }
                isOptionEqualToValue={(option, value) => {
                  return option.technology === value;
                }}
                disabled={!availableTechnologiesList || isInputLocked}
                renderInput={(params) => (
                  <TextField
                    style={{ width: "100%", display: "block" }}
                    {...params}
                    label='Technology'
                    variant='outlined'
                  />
                )}
              />
            </div>
            {/* MODEL INPUT GROUP */}
            <div className={classes.categorySplit}>
              <div className={classes.inputHeader}>MODEL</div>
              <div className={classes.inputSplit}>
                <Autocomplete
                  options={manufacturersList || []}
                  value={manufacturer}
                  inputValue={autocompleteInputValues.manufacturer}
                  onChange={inputHandlers.addManufacturer}
                  onInputChange={autocompleteInputHandlers.onManufacturerChange}
                  isOptionEqualToValue={(option, value) => {
                    if (value === "") return true;
                    else return option === value;
                  }}
                  sx={{ width: "49%" }}
                  renderInput={(params) => (
                    <TextField
                      style={{ width: "100%", display: "block" }}
                      {...params}
                      label='Manufacturer'
                      variant='outlined'
                    />
                  )}
                />
                <TextField
                  autoComplete='off'
                  InputLabelProps={{
                    style: { padding: 0 },
                  }}
                  name='antennaModel'
                  label='Antenna Model'
                  variant='outlined'
                  value={modelSearchString}
                  onChange={inputHandlers.addModelSearchString}
                  sx={{ width: "49%" }}
                />
              </div>
              <div className={classes.inputSplit}>
                <TextField
                  type='number'
                  autoComplete='off'
                  InputLabelProps={{
                    style: { padding: 0 },
                  }}
                  name='freqRangeMin'
                  label='Frequency Range min'
                  variant='outlined'
                  value={freqRangeMin || ""}
                  onChange={inputHandlers.addMinRangeFreq}
                  sx={{ width: "49%" }}
                />
                <TextField
                  type='number'
                  autoComplete='off'
                  InputLabelProps={{
                    style: { padding: 0 },
                  }}
                  name='freqRangeMax'
                  label='Frequency Range max'
                  variant='outlined'
                  value={freqRangeMax || ""}
                  onChange={inputHandlers.addMaxRangeFreq}
                  sx={{ width: "49%" }}
                />
              </div>
              <Autocomplete
                options={modelsList || []}
                value={model || ""}
                inputValue={autocompleteInputValues.model}
                onChange={inputHandlers.addModel}
                onInputChange={autocompleteInputHandlers.onModelChange}
                renderInput={(params) => (
                  <TextField
                    style={{ width: "100%", display: "block" }}
                    {...params}
                    label='Model'
                    variant='outlined'
                  />
                )}
              />
            </div>
          </div>
          {/* PHYSICAL PARAMS INPUT GROUP */}
          <div>
            <div className={classes.inputHeader}> PHYSICAL PARAMETERS</div>
            <div className={classes.inputRow}>
              <TextField
                type='number'
                autoComplete='off'
                value={azimuth === 0 || azimuth ? azimuth : ""}
                InputLabelProps={{
                  style: { padding: 0 },
                }}
                name='azimuth'
                label='Azimuth'
                variant='outlined'
                onChange={inputHandlers.addAzimuth}
              />
              <TextField
                type='number'
                autoComplete='off'
                InputLabelProps={{
                  style: { padding: 0 },
                }}
                name='hba'
                label='HBA'
                variant='outlined'
                value={hba === 0 || hba ? hba : ""}
                onChange={inputHandlers.addHba}
              />
              <TextField
                type='number'
                autoComplete='off'
                value={tilt === 0 || tilt ? tilt : ""}
                InputLabelProps={{
                  style: { padding: 0 },
                }}
                name='tilt'
                label='Mechanical Downtilt'
                variant='outlined'
                onChange={inputHandlers.addMechanicalDowntilt}
              />
              <TextField
                type='number'
                autoComplete='off'
                value={etilt === 0 || etilt ? etilt : ""}
                InputLabelProps={{
                  style: { padding: 0 },
                }}
                name='etilt'
                label='Electrical Tilt'
                variant='outlined'
                onChange={inputHandlers.addElectricalTilt}
              />
            </div>
          </div>
        </div>
        <div className={classes.buttonGroup}>
          <Button
            variant='contained'
            disabled={!canBeAdded}
            onClick={handleAddTech}
          >
            Add Technology
          </Button>
          <Button
            variant='contained'
            disabled={!canStart}
            onClick={handleStartSimulation}
          >
            Start Simulation
          </Button>
          <Button variant='contained' onClick={handleCloseModal}>
            Close
          </Button>
        </div>
        <Divider />
        <div>
          <OverviewTable
            data={technologiesAdded}
            handleDeleteRow={handleDeleteRow}
          />
        </div>
      </div>
    </Dialog>
  );
};

const OverviewTable = (props) => {
  const { data, handleDeleteRow } = props;
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Azimuth</TableCell>
            <TableCell>Model</TableCell>
            <TableCell>HBA</TableCell>
            <TableCell>Mechanical Downtilt</TableCell>
            <TableCell>Electrical Tilt</TableCell>
            <TableCell>Delete Row</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data &&
            data.length > 0 &&
            data.map((row) => (
              <OverviewTableRow
                row={row}
                handleDeleteRow={handleDeleteRow}
                key={uuidv4()}
              />
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const OverviewTableRow = ({ row, handleDeleteRow }) => {
  const { azimuth, model, hba, tilt, etilt } = row;
  return (
    <TableRow>
      <TableCell>{azimuth}</TableCell>
      <TableCell>{model}</TableCell>
      <TableCell>{hba}</TableCell>
      <TableCell>{tilt}</TableCell>
      <TableCell>{etilt}</TableCell>
      <TableCell>
        <DeleteIcon
          style={{ color: "#018ABE", cursor: "pointer" }}
          onClick={() => handleDeleteRow(row)}
        />
      </TableCell>
    </TableRow>
  );
};

const mapState = ({ simulationsStepper }) => ({
  tenantsList: simulationsStepper.tenantsList,
  manufacturersList: simulationsStepper.manufacturersList,
  availableTechnologiesList: simulationsStepper.availableTechnologiesList,
  simulateTechInputs: simulationsStepper.simulateTechInputs,
  modelsList: simulationsStepper.modelsList,
});

const mapDispatch = ({
  site: { resetModelAndTenant },
  simulationsStepper: {
    getTenantsList,
    getManufacturersList,
    getModelsList,
    setSimulateTechInputs,
    setModelsList,
    resetSimulateTechInputs,
    getAvailableTechnologiesList,
    setAvailableTechnologiesList,
    setSimulateTechParameter,
    setSimulateTechConfiguration,
    putSimulateTechConfiguration,
  },
}) => ({
  resetModelAndTenant: (value) => resetModelAndTenant(value),
  getTenantsList: () => getTenantsList(),
  getManufacturersList: () => getManufacturersList(),
  getModelsList: (manufacturer, antennaModel, freqRangeMin, freqRangeMax) =>
    getModelsList({ manufacturer, antennaModel, freqRangeMin, freqRangeMax }),
  setSimulateTechInputs: (name, value) =>
    setSimulateTechInputs({ name, value }),
  setModelsList: (value) => setModelsList(value),
  resetSimulateTechInputs: (value) => resetSimulateTechInputs(value),
  getAvailableTechnologiesList: (tenant) =>
    getAvailableTechnologiesList({ tenant }),
  setAvailableTechnologiesList: (value) => setAvailableTechnologiesList(value),
  setSimulateTechParameter: (value) => setSimulateTechParameter(value),
  setSimulateTechConfiguration: (value) => setSimulateTechConfiguration(value),
  putSimulateTechConfiguration: () => putSimulateTechConfiguration(),
});

export default compose(
  withStyles(useStyles),
  connect(mapState, mapDispatch)
)(SimulateTechnologyModal);
