import React, { useEffect, useState } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import withStyles from "@mui/styles/withStyles";
import { Dialog, IconButton, TextField } from "@mui/material";
import { Autocomplete } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

const useStyles = (theme) => ({
  modalContainer: {
    padding: "25px 40px",
  },
  modalHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  modalTitle: {
    color: "#018ABE",
    fontWeight: "bold",
    fontSize: "22px",
    marginBottom: "25px",
  },
  modalCloseIcon: {
    color: "#000000",
  },
  modalButtonsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "100px",
  },
  modalCancelButton: {
    background: "#C9CAC9 0% 0% no-repeat padding-box",
    borderRadius: "26px",
    opacity: 1,
    outline: "none",
    padding: "11px 30px",
    textDecoration: "none",
    font: "700 14px/19px",
    letterSpacing: 0,
    color: "#FFFFFF",
    border: "none",
    "&:hover": {
      outline: "none",
      cursor: "pointer",
      background: "#A5A5A6 0% 0% no-repeat padding-box",
    },
  },
  modalAddButton: {
    marginLeft: "20px",
    background: "#03B8FF 0% 0% no-repeat padding-box",
    borderRadius: "26px",
    opacity: 1,
    outline: "none",
    padding: "11px 30px",
    textDecoration: "none",
    font: "700 14px/19px",
    letterSpacing: 0,
    color: "#FFFFFF",
    border: "none",
    "&:hover": {
      outline: "none",
      cursor: "pointer",
      background: "#018ABE 0% 0% no-repeat padding-box",
    },
  },
  modalAddButtonDisabled: {
    marginLeft: "20px",
    background: "#A5A5A6 0% 0% no-repeat padding-box",
    borderRadius: "26px",
    opacity: 1,
    outline: "none",
    padding: "11px 30px",
    textDecoration: "none",
    font: "700 14px/19px",
    letterSpacing: 0,
    color: "#FFFFFF",
    border: "none",
    "&:hover": {
      outline: "none",
      background: "#A5A5A6 0% 0% no-repeat padding-box",
    },
  },
  modalInputsContainer: {
    display: "flex",
  },
  modalInputsLeft: {
    width: "48%",
    marginRight: "2%",
  },
  modalInputsRight: {
    width: "48%",
    marginLeft: "2%",
  },
  modalInputsCategory: {
    display: "flex",
    flexDirection: "column",
  },
  modalInputsCategoryTitle: {
    color: "#018ABE",
    fontWeight: "bold",
    fontSize: "13px",
    margin: "25px 0 15px 0",
  },
  modalInputsCategoryInput: {
    margin: "10px 0",
  },
  inputSplit: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
});

const SimulationsStepperAddTenantModal = ({
  classes,
  openModal,
  setOpenModal,
  tenantsList,
  getTenantsList,
  manufacturersList,
  getManufacturersList,
  modelsList,
  getModelsList,
  getParameters,
  setAddTenantInputs,
  addTenantInputs,
  setModelsList,
  resetAddTenantInputs,
  getTenantTechnologiesList,
  tenantTechnologiesList,
  putAddTenant,
  resetModelAndTenant,
  setSiteParameters,
}) => {
  const [canBeAdded, setCanBeAdded] = useState(false);

  const {
    antennaModel,
    freqRangeMin,
    freqRangeMax,
    manufacturer,
    azimuth,
    hba,
    model,
    technology,
    tenant,
    tenantSector,
    tilt,
  } = addTenantInputs;

  const handleExitAddTenantModal = async () => {
    await setOpenModal(false);
    await resetModelAndTenant({});
    await setSiteParameters(null);
    await getParameters("isSimulationsStepper");
  };

  const handleAddTenant = async () => {
    await putAddTenant();
    handleExitAddTenantModal();
  };

  useEffect(() => {
    async function fetchTenantAndManufacturersLists() {
      resetAddTenantInputs({});
      await getTenantsList();
      await getManufacturersList();
    }
    fetchTenantAndManufacturersLists();
  }, []);

  useEffect(() => {
    async function fetchModelsList() {
      setModelsList(null);
      if (manufacturer && antennaModel && freqRangeMin && freqRangeMax) {
        await getModelsList(
          manufacturer,
          antennaModel,
          freqRangeMin,
          freqRangeMax
        );
      }
    }
    fetchModelsList();
  }, [manufacturer, antennaModel, freqRangeMin, freqRangeMax]);

  useEffect(() => {
    const azimuthIsOk = "azimuth" in addTenantInputs && azimuth !== "";
    const modelIsOk = "model" in addTenantInputs && model;
    const technologyIsOk = "technology" in addTenantInputs && technology;
    const tenantIsOk = "tenant" in addTenantInputs && tenant;
    const tiltIsOk = "tilt" in addTenantInputs && tilt !== "";

    const isOk = Boolean(
      azimuthIsOk && modelIsOk && technologyIsOk && tenantIsOk && tiltIsOk
    );

    setCanBeAdded(isOk);
  }, [addTenantInputs]);

  return (
    <Dialog
      onClose={() => handleExitAddTenantModal()}
      open={openModal}
      fullWidth={true}
      maxWidth={"md"}
    >
      <div className={classes.modalContainer}>
        <div className={classes.modalHeader}>
          <div className={classes.modalTitle}>Add Tenant</div>
          <IconButton
            className={classes.modalCloseIcon}
            onClick={() => handleExitAddTenantModal()}
            size='large'
          >
            <ClearIcon />
          </IconButton>
        </div>
        <div className={classes.modalInputsContainer}>
          <div className={classes.modalInputsLeft}>
            <div className={classes.modalInputsCategory}>
              <div className={classes.modalInputsCategoryTitle}>TENANT</div>
              <Autocomplete
                className={classes.modalInputsCategoryInput}
                options={tenantsList || []}
                onChange={(event, newValue) => {
                  setAddTenantInputs("tenant", newValue);
                  getTenantTechnologiesList(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    style={{ width: "100%", display: "block" }}
                    {...params}
                    label='Tenant'
                    variant='outlined'
                  />
                )}
              />
              <Autocomplete
                className={classes.modalInputsCategoryInput}
                options={tenantTechnologiesList || []}
                getOptionLabel={(option) => {
                  return option.technology || "";
                }}
                isOptionEqualToValue={(option, value) => {
                  return option.technology === value.technology;
                }}
                onChange={(event, newValue) => {
                  setAddTenantInputs("technology", newValue.technology);
                  setAddTenantInputs("freqRangeMin", newValue.base_frequency);
                  setAddTenantInputs("freqRangeMax", newValue.base_frequency);
                }}
                disabled={!tenantTechnologiesList}
                renderInput={(params) => (
                  <TextField
                    style={{ width: "100%", display: "block" }}
                    {...params}
                    label='Technology'
                    variant='outlined'
                  />
                )}
              />
            </div>
            <div className={classes.modalInputsCategory}>
              <div className={classes.modalInputsCategoryTitle}>MODEL</div>
              <div className={classes.inputSplit}>
                <Autocomplete
                  className={classes.modalInputsCategoryInput}
                  style={{ width: "49%" }}
                  options={manufacturersList || []}
                  onChange={(event, newValue) =>
                    setAddTenantInputs("manufacturer", newValue)
                  }
                  renderInput={(params) => (
                    <TextField
                      style={{ width: "100%", display: "block" }}
                      {...params}
                      label='Manufacturer'
                      variant='outlined'
                    />
                  )}
                />
                <TextField
                  className={classes.modalInputsCategoryInput}
                  style={{ width: "49%" }}
                  autoComplete='off'
                  InputLabelProps={{
                    style: { padding: 0 },
                  }}
                  name='antennaModel'
                  label='Antenna Model'
                  variant='outlined'
                  onChange={(e) =>
                    setAddTenantInputs("antennaModel", e.target.value)
                  }
                />
              </div>
              <div className={classes.inputSplit}>
                <TextField
                  className={classes.modalInputsCategoryInput}
                  style={{ width: "49%" }}
                  type='number'
                  autoComplete='off'
                  InputLabelProps={{
                    style: { padding: 0 },
                  }}
                  name='freqRangeMin'
                  label='Frequency Range min'
                  variant='outlined'
                  value={freqRangeMin || ""}
                  onChange={(e) =>
                    setAddTenantInputs("freqRangeMin", Number(e.target.value))
                  }
                />
                <TextField
                  className={classes.modalInputsCategoryInput}
                  style={{ width: "49%" }}
                  type='number'
                  autoComplete='off'
                  InputLabelProps={{
                    style: { padding: 0 },
                  }}
                  name='freqRangeMax'
                  label='Frequency Range max'
                  variant='outlined'
                  value={freqRangeMax || ""}
                  onChange={(e) =>
                    setAddTenantInputs("freqRangeMax", Number(e.target.value))
                  }
                />
              </div>
              <Autocomplete
                className={classes.modalInputsCategoryInput}
                options={modelsList || []}
                onChange={(event, newValue) =>
                  setAddTenantInputs("model", newValue)
                }
                renderInput={(params) => (
                  <TextField
                    style={{ width: "100%", display: "block" }}
                    {...params}
                    label='Model'
                    variant='outlined'
                  />
                )}
              />
            </div>
          </div>
          <div className={classes.modalInputsRight}>
            <div className={classes.modalInputsCategory}>
              <div className={classes.modalInputsCategoryTitle}>
                PHYSICAL PARAMETERS
              </div>
              <div className={classes.inputSplit}>
                <TextField
                  className={classes.modalInputsCategoryInput}
                  style={{ width: "49%" }}
                  type='number'
                  autoComplete='off'
                  InputLabelProps={{
                    style: { padding: 0 },
                  }}
                  name='tenantSector'
                  label='Tenant Sector'
                  variant='outlined'
                  value={tenantSector || 1}
                  onChange={(e) =>
                    setAddTenantInputs("tenantSector", Number(e.target.value))
                  }
                />
                <TextField
                  className={classes.modalInputsCategoryInput}
                  style={{ width: "49%" }}
                  type='number'
                  autoComplete='off'
                  InputLabelProps={{
                    style: { padding: 0 },
                  }}
                  name='azimuth'
                  label='Azimuth'
                  variant='outlined'
                  onChange={(e) =>
                    setAddTenantInputs("azimuth", Number(e.target.value))
                  }
                />
              </div>
              <div className={classes.inputSplit}>
                <TextField
                  className={classes.modalInputsCategoryInput}
                  style={{ width: "49%" }}
                  type='number'
                  autoComplete='off'
                  InputLabelProps={{
                    style: { padding: 0 },
                  }}
                  name='hba'
                  label='HBA'
                  variant='outlined'
                  value={hba || 1}
                  onChange={(e) =>
                    setAddTenantInputs("hba", Number(e.target.value))
                  }
                />
                <TextField
                  className={classes.modalInputsCategoryInput}
                  style={{ width: "49%" }}
                  type='number'
                  autoComplete='off'
                  InputLabelProps={{
                    style: { padding: 0 },
                  }}
                  name='tilt'
                  label='Mechanical Downtilt'
                  variant='outlined'
                  onChange={(e) =>
                    setAddTenantInputs("tilt", Number(e.target.value))
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className={classes.modalButtonsContainer}>
          <button
            className={classes.modalCancelButton}
            onClick={handleExitAddTenantModal}
          >
            Close
          </button>
          <button
            className={
              !canBeAdded
                ? classes.modalAddButtonDisabled
                : classes.modalAddButton
            }
            disabled={!canBeAdded}
            onClick={handleAddTenant}
          >
            Add
          </button>
        </div>
      </div>
    </Dialog>
  );
};

const mapState = ({ simulationsStepper }) => ({
  tenantsList: simulationsStepper.tenantsList,
  manufacturersList: simulationsStepper.manufacturersList,
  modelsList: simulationsStepper.modelsList,
  addTenantInputs: simulationsStepper.addTenantInputs,
  tenantTechnologiesList: simulationsStepper.tenantTechnologiesList,
});

const mapDispatch = ({
  site: { getSiteParameters, resetModelAndTenant, setSiteParameters },
  simulationsStepper: {
    getTenantsList,
    getManufacturersList,
    getModelsList,
    setAddTenantInputs,
    setModelsList,
    resetAddTenantInputs,
    getTenantTechnologiesList,
    putAddTenant,
  },
}) => ({
  getParameters: (type) => getSiteParameters({ type }),
  getTenantsList: () => getTenantsList(),
  getManufacturersList: () => getManufacturersList(),
  getModelsList: (manufacturer, antennaModel, freqRangeMin, freqRangeMax) =>
    getModelsList({ manufacturer, antennaModel, freqRangeMin, freqRangeMax }),
  setAddTenantInputs: (name, value) => setAddTenantInputs({ name, value }),
  setModelsList: (value) => setModelsList(value),
  resetAddTenantInputs: (value) => resetAddTenantInputs(value),
  getTenantTechnologiesList: (tenant) => getTenantTechnologiesList({ tenant }),
  putAddTenant: () => putAddTenant(),
  resetModelAndTenant: (value) => resetModelAndTenant(value),
  setSiteParameters: (value) => setSiteParameters(value),
});

export default compose(
  withStyles(useStyles),
  connect(mapState, mapDispatch)
)(SimulationsStepperAddTenantModal);
