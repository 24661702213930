// export const parseTenant = (parameters) => {
//   const Tenants = []
//   const listTest = {}

//   parameters.map((sector) => {
//     return sector.antennas.map((antenna) => {
//       return antenna.technologies.map((technology) => {
//         const result = Tenants.find((obj) => {
//           return obj.tenant_name === technology.tenant
//         })

//         if (!result) {
//           listTest[technology.tenant] = {
//             tenant_name: technology.tenant,
//             sec_num: 0,
//             ant_num: 0,
//             systems: '',
//             sectors: []
//           }
//           Tenants.push({ tenant_name: technology.tenant })
//         }
//         return true
//       })
//     })
//   })

//   Object.keys(listTest).map((tenant) => {
//     return parameters.map((sector) => {
//       let finded = false

//       sector.antennas.map((antenna) => {
//         antenna.technologies.map((technology) => {
//           if (tenant === technology.tenant) {
//             finded = true
//           }
//           if (listTest[tenant].systems.indexOf(technology.band) === -1) {
//             if (listTest[tenant].systems.length > 0) {
//               listTest[tenant].systems += ', '
//             }
//             listTest[tenant].systems += technology.band
//           }
//           return true
//         })
//         if (finded) {
//           listTest[tenant].ant_num += 1
//         }
//         return true
//       })
//       if (finded) {
//         listTest[tenant].sec_num += 1
//         listTest[tenant].sectors.push({
//           sector: sector.sector,
//           power: sector.antennas[0].technologies[0].arpaParameters.potenzaTotaleApparatoW,
//           azimuth: sector.azimuth
//         })
//       }
//       return true
//     })
//   })

//   return Object.values(listTest)
// }
export const parseTenant = (parameters) => {
  const Tenants = [];
  const listTest = {};
  parameters.map((sector) => {
    return sector.antennas.map((antenna) => {
      return antenna.technologies.map((technology) => {
        const result = Tenants.find((obj) => {
          return obj.tenant_name === technology.tenant;
        });
        if (!result) {
          listTest[technology.tenant] = {
            tenant_name: technology.tenant,
            sec_num: 0,
            ant_num: 0,
            systems: "",
            sectors: [],
          };
          Tenants.push({
            tenant_name: technology.tenant,
          });
        }
        return true;
      });
    });
  });

  Object.keys(listTest).map((tenant, index) => {
    return parameters.map((sector) => {
      let finded = false;
      sector.antennas.map((antenna) => {
        antenna.technologies.map((technology) => {
          if (tenant === technology.tenant) {
            finded = true;
          }

          return true;
        });
        if (finded) {
          listTest[tenant].ant_num += 1;
        }
        return true;
      });
      if (finded) {
        let temp = filterTechFromParam(parameters);
        listTest[tenant].sec_num += 1;
        listTest[tenant].sectors.push({
          sector: sector.sector,
          power:
            sector.antennas[0].technologies[0].arpaParameters
              .potenzaTotaleApparatoW,
          azimuth: sector.azimuth,
        });
        listTest[tenant].systems = temp.map((el) => {
          if (el.name === listTest[tenant].tenant_name) {
            return el.sys;
          }
        });
      }
      return true;
    });
  });
  return Object.values(listTest);
};

const filterTechFromParam = (parameters) => {
  let temp = [];
  parameters.map((sector) => {
    sector.antennas.map((antenna) => {
      antenna.technologies.map((tech, index) => {
        temp.push({ name: tech.tenant, sys: tech.band });
      });
    });
  });
  // remove duplicates
  let systemsArray = temp.filter(
    (v, i, a) =>
      a.findIndex((value) =>
        ["name", "sys"].every((k) => value[k] === v[k])
      ) === i
  );
  return systemsArray;
};

export default { parseTenant };
