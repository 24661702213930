import React, { useState, useEffect } from "react";
import withStyles from "@mui/styles/withStyles";
import { connect } from "react-redux";
import { compose } from "redux";
import SiteParamsTableBox from "../../components/SiteParamsTableBox";
import { siteParamsTableHeaders } from "../../utils/tableHeaders";

const useStyles = (theme) => ({
  tabParametersContainer: {
    padding: "20px 0",
  },
  paramsTable: {
    height: "calc(100vh - 250px)",
  },
});

const Parameters = ({
  classes,
  tableParameters,
  technologiesList,
  finalConfigParams,
}) => {
  const [siteParamsTableInfos, setSiteParamsTableInfos] = useState({
    tableHeaders: [],
    tableRows: [],
  });

  useEffect(() => {
    if (tableParameters && tableParameters.length > 0) {
      setSiteParamsTableInfos({
        tableHeaders: [...siteParamsTableHeaders, ...technologiesList],
        tableRows: tableParameters,
      });
    }
    if (finalConfigParams && finalConfigParams.length > 0) {
      setSiteParamsTableInfos({
        tableHeaders: [...siteParamsTableHeaders, ...technologiesList],
        tableRows: finalConfigParams,
      });
    }
  }, [tableParameters, finalConfigParams, technologiesList]);
  // const tableHeaders = [...siteParamsTableHeaders, ...technologiesList];

  // const siteParamsTableInfos = {
  //   tableHeaders: tableHeaders,
  //   tableRows: tableParameters || finalConfigParams,
  // };

  return (
    <div className={classes.tabParametersContainer}>
      <div className={classes.paramsTable}>
        {siteParamsTableInfos.tableHeaders.length > 0 &&
        siteParamsTableInfos.tableRows.length > 0 ? (
          <>
            <SiteParamsTableBox
              tableInfos={siteParamsTableInfos}
              isFinalConfig={true}
            />
          </>
        ) : (
          <div>No actual parameters found...</div>
        )}
      </div>
    </div>
  );
};

const mapState = ({ site, simulation }) => ({
  tableParameters: site.parameters,
  technologiesList: site.technologiesList,
  finalConfigParams: simulation.finalConfigParams,
});

const mapDispatch = () => ({});

export default compose(
  withStyles(useStyles),
  connect(mapState, mapDispatch)
)(Parameters);
