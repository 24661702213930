import React, { useState, useContext, useEffect } from "react";
import withStyles from "@mui/styles/withStyles";
import { connect } from "react-redux";
import { compose } from "redux";
import { Button, Popper } from "@mui/material";
import SiteParamsTableBox from "../SiteParamsTableBox";
import { siteParamsTableHeaders } from "../../utils/tableHeaders";
import SimulationsStepperAddTenantModal from "../SimulationsStepperAddTenantModal";
import SiteAddOnMapPage from "../SiteAddOnMapPage";
import SimulateTechnologyModal from "../SimulateTechnology/SimulateTechnology";
import ArrowDropDownIcon from "../../assets/img/arrow-drop-down.svg";
import axios from "axios";
import { POST_EVAL_MEASURE_POINTS, tokenExpiredDoLogin } from "../../utils/api";
import { UserRoleContext } from "../../contexts/user-role.context";

const useStyles = (theme) => ({
  buttonsContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginBottom: "30px",
  },
  button: {
    fontWeight: "bold",
    height: "32px",
    padding: "3px 24px",
    background: "white",
    border: "0.5px solid #018ABE",
    borderRadius: "100px",
    font: "700 13px/20px",
    color: "#018ABE",
    marginLeft: "13px",
    textTransform: "none",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    minWidth: "64px",
    "&:hover": {
      backgroundColor: "white",
      cursor: "pointer",
      outline: "none",
    },
    "&:focus": {
      outline: "none",
    },
    "&:active": {
      outline: "none",
    },
    "&:disabled": {
      color: "rgba(0, 0, 0, 0.26)",
      background: "white",
      cursor: "unset",
    },
  },
  dropButton: {
    height: "32px",
    width: "100%",
    padding: "10px 20px",
    background: "white",
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: "0",
    color: "#000000",
    textTransform: "none",
    display: "flex",
    flexDirection: "row",
    alignItems: "left",
    justifyContent: "left",
    "&:hover": {
      backgroundColor: "#E8EFF9",
      cursor: "pointer",
      outline: "none",
    },
    "&:focus": {
      outline: "none",
    },
    "&:active": {
      outline: "none",
    },
  },
  paramsTable: {
    height: "calc(100vh - 250px)",
  },
});

const SimulationsStepperStep2 = ({
  classes,
  tableParameters,
  technologiesList,
  exportEval,
  siteInfos,
  downloadEmptyMeasurePoints,
  downloadEvalMeasurePoints,
  loader,
  selectedUUID,
  evalSelected,
  simulateTechIsOpen,
  simulateTechHandler,
  setSimulationStarted,
  setIsSimulatingTechnology,
  setApiMessages,
}) => {
  const [openAddTenantModal, setOpenAddTenantModal] = useState(false);
  const [openAddEvalMeasurePoints, setOpenAddEvalMeasurePoints] =
    useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [tableInfos, setTableInfos] = useState({
    tableHeaders: [],
    tableRows: [],
  });

  const { hasWritePermission } = useContext(UserRoleContext);

  useEffect(() => {
    if (tableParameters) {
      setTableInfos({
        tableHeaders: [
          ...siteParamsTableHeaders,
          ...technologiesList,
          { name: "", key: "modifica" },
          { name: "", key: "elimina" },
        ],
        tableRows: tableParameters,
      });
    }
  }, [tableParameters, technologiesList]);

  // const tableHeaders = [
  //   ...siteParamsTableHeaders,
  //   ...technologiesList,
  //   { name: "", key: "modifica" },
  //   { name: "", key: "elimina" },
  // ];

  // const tableInfos = {
  //   tableHeaders: tableHeaders,
  //   tableRows: tableParameters || [],
  // };

  const handleDropdown = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setOpenDropdown(!openDropdown);
  };

  const uploadExcelFile = async ({ target }) => {
    loader(true);
    setApiMessages({});

    const file = target.files[0];
    const formData = new FormData();
    formData.append("formFile", file);

    try {
      const response = await axios({
        method: "POST",
        url: `${POST_EVAL_MEASURE_POINTS}?uuid=${selectedUUID}`,
        headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        data: formData,
      });
      if (response?.data.result === 0) {
        setApiMessages({
          status: "warning",
          message: response.data.message,
        });
      } else {
        setApiMessages({
          status: "success",
          message: "Excel file imported successfully",
        });
      }
    } catch (err) {
      console.log(`Error ${POST_EVAL_MEASURE_POINTS}`, err);
      tokenExpiredDoLogin(err);
    } finally {
      document.getElementById("button_upload_excel_file").value = null;
      loader(false);
    }
  };

  return (
    <>
      <div className={classes.step2Container}>
        <div className={classes.buttonsContainer}>
          <Button className={classes.button} onClick={() => exportEval()}>
            Download file
          </Button>
          <Button
            className={classes.button}
            onClick={handleDropdown}
            aria-describedby={"measure_points_dropdown"}
            style={{ paddingRight: "18px" }}
          >
            Add eval measure points
            <img src={ArrowDropDownIcon} alt='arrow dropdown icon' />
          </Button>
          <Popper
            id={"measure_points_dropdown"}
            open={openDropdown}
            anchorEl={anchorEl}
            style={{ zIndex: "1000", marginTop: "0.3rem" }}
          >
            <Button
              className={classes.dropButton}
              onClick={() => {
                setOpenAddEvalMeasurePoints(true);
                setOpenDropdown(!openDropdown);
                setAnchorEl(null);
              }}
            >
              Add manually
            </Button>
            <input
              hidden
              id='button_upload_excel_file'
              type='file'
              onChange={uploadExcelFile}
              disabled={!hasWritePermission}
            />
            <label htmlFor='button_upload_excel_file'>
              <Button
                className={classes.dropButton}
                component='span'
                disabled={!hasWritePermission}
              >
                Import excel file
              </Button>
            </label>
            <Button
              className={classes.dropButton}
              onClick={() => {
                downloadEvalMeasurePoints();
                setOpenDropdown(!openDropdown);
                setAnchorEl(null);
              }}
            >
              Download eval measure points
            </Button>
            <Button
              className={classes.dropButton}
              onClick={() => {
                downloadEmptyMeasurePoints();
                setOpenDropdown(!openDropdown);
                setAnchorEl(null);
              }}
            >
              Download empty file
            </Button>
          </Popper>

          <Button
            className={classes.button}
            onClick={() => setOpenAddTenantModal(true)}
            disabled={!hasWritePermission}
          >
            Add Tenant
          </Button>
        </div>

        <div className={classes.paramsTable}>
          <SiteParamsTableBox
            tableInfos={tableInfos}
            isSimulationStepper
            isFinalConfig={false}
          />
        </div>

        {openAddTenantModal && (
          <SimulationsStepperAddTenantModal
            openModal={openAddTenantModal}
            setOpenModal={setOpenAddTenantModal}
          />
        )}

        {openAddEvalMeasurePoints && (
          <SiteAddOnMapPage
            addType='eval_measure_points'
            setOpenAddOnMapPage={setOpenAddEvalMeasurePoints}
            siteInfos={siteInfos}
            evalUUID={selectedUUID}
            selectedEval={evalSelected}
          />
        )}

        {simulateTechIsOpen && (
          <SimulateTechnologyModal
            openSimulateTech={simulateTechIsOpen}
            setOpenSimulateTech={simulateTechHandler}
            setSimulationStarted={setSimulationStarted}
            setIsSimulatingTechnology={setIsSimulatingTechnology}
          />
        )}
      </div>
    </>
  );
};

const mapState = ({ site, simulationsStepper }) => ({
  tableParameters: simulationsStepper.parameters,
  technologiesList: site.technologiesList,
  siteInfos: site.infos,
  selectedUUID: site.selectedUUID,
  measurePoints: site.measurePoints,
  evalSelected: site.evalSelected,
});

const mapDispatch = ({
  simulationsStepper: { exportEval },
  site: {
    downloadEmptyMeasurePoints,
    downloadEvalMeasurePoints,
    setEvalMeasurePoints,
    setApiMessages,
  },
  loader: { loader },
}) => ({
  exportEval: () => exportEval(),
  downloadEmptyMeasurePoints: () => downloadEmptyMeasurePoints(),
  downloadEvalMeasurePoints: () => downloadEvalMeasurePoints(),
  setEvalMeasurePoints: (value) => setEvalMeasurePoints(value),
  setApiMessages: (value) => setApiMessages(value),
  loader: (value) => loader(value),
});

export default compose(
  withStyles(useStyles),
  connect(mapState, mapDispatch)
)(SimulationsStepperStep2);
