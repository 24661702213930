/* eslint-disable no-debugger */
import React, { useEffect, useRef } from "react";
import withStyles from "@mui/styles/withStyles";
import { compose } from "redux";
import { connect } from "react-redux";
import { MapContainer, TileLayer, LayersControl } from "react-leaflet";
import ClusterGroup from "./ClusterGroup";
import MapInstance from "./MapInstance";
import LeafletRuler from "../LeafletRuler/LeafletRuler";

const useStyles = (theme) => ({
  leafletContainer: {
    width: "100%",
    height: "100%",
    zIndex: 0,
  },
});

const MainMapBox = ({
  classes,
  map,
  setSelectedOffice,
  loadOfficesFromBounds,
  setGpsPosition,
  resetAll,
  resetOffices,
  resetNeighbourhood,
  resetMassives,
  resetMassiveState,
  setMassives,
  resetMapParams,
  resetMapOffices,
  setNeighbourhood,
}) => {
  const {
    center,
    zoom,
    offices,
    selectedOffice,
    canLoadNeighbourhood,
    neighbourhood,
    gpsPosition,
    massives,
  } = map;

  const clusterReference = useRef(null);
  const officeClicked = useRef(false);
  const clusterAlreadyShowed = useRef(false);

  const handleMarkerClick = (office) => {
    officeClicked.current = true;
    setSelectedOffice(office);
  };

  const handleMassiveMarkerClick = (massive) => {
    console.log(massive);
  };

  useEffect(() => {
    clusterAlreadyShowed.current = false;
  }, [offices]);

  useEffect(() => {
    if (resetAll) {
      console.log("resetAll");
      resetMassiveState();
      setMassives([]);
      resetMapOffices([]);
      setNeighbourhood([]);
      setSelectedOffice(null);
    }
    if (resetOffices) {
      console.log("resetOffices");
      resetMapOffices([]);
      setNeighbourhood([]);
      setSelectedOffice(null);
    }
    if (resetNeighbourhood) {
      console.log("resetNeighbourhood");
      setNeighbourhood([]);
    }
    if (resetMassives) {
      console.log("resetMassives");
      resetMassiveState();
      setMassives([]);
    }
    resetMapParams();
  }, []);

  return (
    <MapContainer
      className={classes.leafletContainer}
      center={center}
      zoom={zoom}
    >
      <LayersControl position='bottomleft'>
        <LayersControl.BaseLayer checked name='Street View'>
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
          />
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer name='Geo View'>
          <TileLayer
            attribution='Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
            url='https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
          />
        </LayersControl.BaseLayer>
        {neighbourhood?.length > 0 && (
          <ClusterGroup
            neighbourhood={neighbourhood}
            offices={offices}
            type='neighbourhood'
            handleMarkerClick={handleMarkerClick}
          />
        )}
        {offices?.length > 0 && (
          <ClusterGroup
            offices={offices}
            cluster={clusterReference}
            type='offices'
            handleMarkerClick={handleMarkerClick}
          />
        )}
        {massives?.length > 0 && (
          <ClusterGroup
            massives={massives}
            cluster={clusterReference}
            type='massives'
            handleMarkerClick={handleMassiveMarkerClick}
          />
        )}
      </LayersControl>

      <MapInstance
        cluster={clusterReference.current}
        clusterAlreadyShowed={clusterAlreadyShowed}
        selectedOffice={selectedOffice}
        loadOfficesFromBounds={loadOfficesFromBounds}
        canLoadNeighbourhood={canLoadNeighbourhood}
        officeClicked={officeClicked}
        gpsPosition={gpsPosition}
        setGpsPosition={setGpsPosition}
      />
      <LeafletRuler />
    </MapContainer>
  );
};

const mapState = ({ map }) => ({
  map: map,
});

const mapDispatch = ({
  map: {
    loadOfficesFromBounds,
    setSelectedOffice,
    setGpsPosition,
    resetMapParams,
    resetOffices,
    setNeighbourhood,
    setMassives,
  },
  site: { getSimulationsLastInfos },
  massive: { resetState },
}) => ({
  loadOfficesFromBounds: (value) => loadOfficesFromBounds(value),
  setSelectedOffice: (value) => setSelectedOffice(value),
  setGpsPosition: (value) => setGpsPosition(value),
  resetMapParams: () => resetMapParams(),
  resetMapOffices: () => resetOffices(),
  setNeighbourhood: (value) => setNeighbourhood(value),
  setMassives: (value) => setMassives(value),
  getSimulationsLastInfos: (siteId) => getSimulationsLastInfos({ siteId }),
  resetMassiveState: () => resetState(),
});

export default compose(
  withStyles(useStyles),
  connect(mapState, mapDispatch)
)(MainMapBox);
