import React from 'react'
import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux'
import { compose } from 'redux'
import TableBox from '../../components/TableBox'
import { siteDocsTableHeaders } from '../../utils/tableHeaders'
import dayjs from 'dayjs'

const useStyles = theme => ({
  tabDocsContainer: {
    padding: '20px 0'
  },
  docsTable: {
    height: 'calc(100vh - 250px)'
  }
})

const Docs = ({ classes, docs }) => {
  const siteDocsTableInfos = {
    tableHeaders: siteDocsTableHeaders,
    tableRows: docs
      ? docs.map(file => ([file.name, dayjs(file.lastWrite).format('DD/MM/YYYY h:mm A'), 'download']))
      : []
  }

  return (
    <div className={classes.tabDocsContainer}>
      <div className={classes.docsTable}>
        <TableBox tableInfos={siteDocsTableInfos} />
      </div>
    </div>
  )
}

const mapState = ({ site }) => ({
  docs: site.docs
})

const mapDispatch = () => ({})

export default compose(
  withStyles(useStyles),
  connect(mapState, mapDispatch)
)(Docs)
