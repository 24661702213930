import React from 'react'
import { v4 as uuidv4 } from "uuid";

import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'

import { Grid } from '@mui/material'

import List from '@mui/material/List'
import ListItemText from '@mui/material/ListItemText'
import ListItemButton from '@mui/material/ListItemButton'
import { Icon } from '@mui/material'
import EnhancedIconButton from '../../components/PlaygroundComponents/EnhancedIconButton'

import Slider from '@mui/material/Slider'

import gear_animated from './gear_animated.gif'

import useHttpService from "../../hooks/useHttpService";
import {
    QUEUE_TASKS,
    POST_ERROR_LOG,
    GET_SITE_DOCS_DOWNLOAD
} from "../../utils/api";
import { json } from 'react-router-dom';



const ManageTasks = () => {
    const [tasks, setTasks] = React.useState([])
    const [logs, setLogs] = React.useState([])
    const [disk, setDisk] = React.useState(0)
    const [valueTab, setValueTab] = React.useState()
    const [idTab, setIdTab] = React.useState()
    const [filterMessages, setFilterMessages] = React.useState()
    const [timeRange, setTimeRange] = React.useState(8)
    const [selectedTask, setSelectedTask] = React.useState()
    const [totalQueued, setTotalQueued] = React.useState(0)
    const [totalRunning, setTotalRunning] = React.useState(0)
    const [totalFinished, setTotalFinished] = React.useState(0)
    const [totalFailed, setTotalFailed] = React.useState(0)


    const httpService = useHttpService()

    const handleChangeTab = (event, newValue) => {
        setLogs([])
        setValueTab(newValue)
        setIdTab(event.target.id)
        setFilterMessages(event.target.id)
    }

    //tasks polling
    function slideChange(e, value) {
        setTotalQueued(0);
        setTotalRunning(0);
        setTotalFinished(0);
        setTotalFailed(0);
        setTimeRange(value);
    }

    function pythonJsonParse(input) {
        return JSON.parse(input.replaceAll("'", '"').replaceAll('None', 'null').replaceAll('True', 'true').replaceAll('False', 'false').replaceAll("...", ''))
    }

    function pollTasks(tr) {
        let d = new Date()
        d.setHours(d.getHours() - tr)
        httpService.getAPI(
            `${QUEUE_TASKS}?received_start=${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()} ${('0' + d.getHours()).substr(-2)}:${d.getMinutes()}`,
            (data) => {
                var totQueued = 0;
                var totRunning = 0;
                var totFinished = 0;
                var totFailed = 0;
                Object.entries(data).forEach((task) => {

                    try {
                        switch (task[1].state) {
                            case 'PENDING':
                            case 'RECEIVED':
                                totQueued++;
                                break;
                            case 'STARTED':
                            case 'RETRY':
                                totRunning++;
                                break;
                            case 'SUCCESS':
                                totFinished++;
                                break;
                            case 'FAILURE':
                                totFailed++;
                                break;
                            default:
                                break;
                        }

                        if (task[1].kwargs) {
                            var pj = pythonJsonParse(task[1].kwargs);
                            //console.log(JSON.stringify( pj));
                            data[task[0]].kwargs = pj;
                        }



                    } catch (e) {
                        console.error(e);
                    }
                })
                setTotalQueued(totQueued);
                setTotalRunning(totRunning);
                setTotalFinished(totFinished);
                setTotalFailed(totFailed);
                setTasks(data);
                console.log(data);


            }
        );

        getDiskCheck();
    }

    function getLogs(taskId) {
        setSelectedTask(taskId);
        httpService.getAPI(
            `${POST_ERROR_LOG}?task_id=${taskId}`,
            (data) => {
                setLogs(data)
                console.log(data)
            }
        )
    }

    function getDiskCheck() {
        httpService.getAPI(
            `${GET_SITE_DOCS_DOWNLOAD}diskcheck`,
            (diskdata) => {
                setDisk(diskdata.FreePerc)
                console.log('diskdata',diskdata)
            }
        )
    }

    function getTaskDetails(name, queueMsg) {
        switch (name) {
            case 'EMstart':
                return `simulating site ${queueMsg.roomCode}`;
            case 'BIMstart':
                return `rendering ${queueMsg.BIM_DESC_PREFIX}`;
            case 'EXCELstart':
            case 'WORDstart':
                return `report ${queueMsg.REPORT_TYPE}`;
            default:
                return `${queueMsg.roomCode}`;
        }
    }

    function tasksToArray(tasks) {
        let a = []
        Object.entries(tasks).forEach((task) => {
            a.push(task[1])
        })
        return a
    }

    function translateTask(taskName) {
        switch (taskName) {
            case "EMstart":
                return "EM";
            case "BIMstart":
                return "BIM";
            case "WORDstart":
                return "Word";
            case "EXCELstart":
                return "Excel";
            case "GEOstart":
                return "GEO";
            case "MSIstart":
                return "MSI";
            case "MASSIVEstart":
                return "Massive elaboration";
            default:
                return taskName;
        }
    }

    function TreeList(props) {
        if (props.data.length === 0) return <ListItemText key={uuidv4()} />
        return props.data.map((element) => {
            // console.log(element)
            if (!props.parent && props.firstParent !== element.uuid) return <></>
            if (element.parent === props.parent) {
                return (
                    <>
                        <ListItemButton key={element.uuid} onClick={(e) => {
                            getLogs(element.uuid);
                        }}
                        >
                            <ListItemText
                                sx={{
                                    color: element.uuid === selectedTask ? 'white' : 'black',
                                    bgcolor: element.uuid === selectedTask ? 'primary.main' : 'white',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    paddingLeft: props.level,
                                }}
                                primary={`${element.uuid === props.firstParent ? '' : '-'}
                                ${new Date(element.started * 1000)
                                        .toTimeString()
                                        .substring(0, 5)}
                                            ${translateTask(element.name)} `
                                }
                                secondary={
                                    `${element.kwargs.queueMsg ? getTaskDetails(element.name, element.kwargs.queueMsg) : ''}`
                                }
                            />

                            {element.state && element.state === 'STARTED' && (
                                <img
                                    src={gear_animated}
                                    style={{ width: '30%' }}
                                    alt='Running'
                                />
                            )}
                            {element.state && element.state === 'SUCCESS' && (
                                <Icon
                                    fontSize='large'
                                    sx={{ color: 'green', marginLeft: '10px' }}
                                >
                                    done
                                </Icon>
                            )}
                            {element.state && element.state === 'FAILURE' && (
                                <EnhancedIconButton
                                    icon='error'
                                    size='large'
                                    sx={{ color: 'red', marginLeft: '10px' }}
                                    tooltip={element.exception}
                                />
                            )}
                        </ListItemButton>
                        <List>
                            <TreeList
                                key={element.uuid}
                                data={props.data}
                                parent={element.uuid}
                                level={props.level + 1}
                            />
                        </List>
                    </>
                )
            } else return <></>
        })
    }

    // React.useEffect(() => {
    //   props.taskId && setIdTab(props.taskId)
    // }, [props, props.taskId])

    React.useEffect(() => {
        pollTasks(timeRange)
        const timer = setInterval(function () {
            pollTasks(timeRange)
        }, 3000)
        return () => clearInterval(timer)
    }, [timeRange])



    return (
        <div>
            <Grid container spacing={3} padding={3} value={0} index={0}>
                <Grid item md={2}>
                    <span>Time range hours:</span>
                </Grid>
                <Grid item md={10}>
                    <Slider
                        size='small'
                        defaultValue={8}
                        valueLabelDisplay='on'
                        step={1}
                        marks
                        min={1}
                        max={24}
                        onChange={slideChange}
                    />
                </Grid>
                <Grid item md={12}>Total tasks: &nbsp;&nbsp;&nbsp;&nbsp; queued: {totalQueued} &nbsp;&nbsp;&nbsp;&nbsp; running: {totalRunning} &nbsp;&nbsp;&nbsp;&nbsp; finished: {totalFinished} &nbsp;&nbsp;&nbsp;&nbsp; failed: {totalFailed} &nbsp;&nbsp;&nbsp;&nbsp; finished: {totalFinished}</Grid>
                <Grid item md={12}>Free storage space: {disk} %</Grid>

                <Grid item md={12}>
                    <Tabs
                        value={valueTab}
                        onChange={handleChangeTab}
                        variant='scrollable'
                        scrollButtons='auto'
                        aria-label='scrollable auto tabs example'
                    >
                        {tasks &&
                            Object.entries(tasks)
                                .sort(function (a, b) {
                                    return b[1].started - a[1].started
                                })
                                .map((task) => {
                                    return (
                                        task[1].started &&
                                        task[1].kwargs &&
                                        !task[1].parent && (
                                            <Tab
                                                key={task[1].uuid}
                                                id={task[1].uuid}
                                                sx={{
                                                    backgroundColor:
                                                        idTab && idTab === task[1].uuid
                                                            ? 'white'
                                                            : 'primary.main',
                                                    color: 'white',
                                                }}
                                                label={`${new Date(task[1].started * 1000)
                                                    .toTimeString()
                                                    .substring(0, 5)
                                                    }
                                                ${task[1].name.replace('start', '')}
                                                ${task[1].kwargs.queueMsg ? task[1].kwargs.queueMsg.roomCode ? task[1].kwargs.queueMsg.roomCode : '' : ''}
                                                `}
                                            />
                                        )
                                    )
                                })}
                    </Tabs>
                    <hr></hr>
                </Grid>
                <Grid item md={4}>
                    {tasks && (
                        <TreeList
                            data={tasksToArray(tasks)}
                            firstParent={idTab}
                            parent={null}
                            level={0}
                        />
                    )}
                </Grid>
                <Grid item md={8}>
                    <List>
                        {logs &&
                            logs.map((row) => {
                                return (row.message && row.message.length > 0 &&
                                    <ListItemText
                                        key={uuidv4()}
                                    // hidden={filterMessages && filterMessages !== row.user}
                                    //secondary={}
                                    ><b>{row.logtime.substring(11, 19)}:</b> {row.message} </ListItemText>
                                )
                            })}
                    </List>
                </Grid>
            </Grid>
        </div>
    );
};

export default ManageTasks;
