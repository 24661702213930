import React, { useState, useContext, useEffect } from "react";
import withStyles from "@mui/styles/withStyles";
import { connect } from "react-redux";
import { compose } from "redux";
import { useNavigate } from "react-router-dom";
import {
  Button,
  IconButton,
  Paper,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import { Visibility, Edit, Check, PictureAsPdf, OpenInBrowser } from "@mui/icons-material";
import { Autocomplete } from "@mui/material";
import TransitionsModal from "./modal";
import Dialog from '@mui/material/Dialog';
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import JsonSchemaBox from "../../components/PlaygroundComponents/JsonSchemaBox";



import useHttpService from "../../hooks/useHttpService";
import { PUT_ANTENNA_PORT,GET_ANTENNA_WITH_DATASHEET,GET_FILES_FROM_GOOGLE_STORAGE} from "../../utils/api"
import { UserRoleContext } from "../../contexts/user-role.context";


const useStyles = (theme) => ({
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: "1em",
    width: "99%",
  },
  form: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  textField: {
    margin: "10px 0",
    width: "20%",
  },
  searchButton: {
    background: "#03B8FF 0 0 no-repeat padding-box",
    borderRadius: "26px",
    opacity: 1,
    font: "700 14px/22px",
    letterSpacing: 0,
    height: "40px",
    color: "#fff",
    border: "none",
    textTransform: "none",
    "&:hover": {
      background: "#018ABE 0 0 no-repeat padding-box",
    },
    padding: "0 22px",
  },
  tableContainer: {
    paddingLeft: "4em",
    width: "93%",
    height: "700px",
    zIndex: 0,
    marginTop: "2em",
    boxShadow: "none",
  },
  tableHeaderRow: {
    height: "80px",
  },
  tableHeaderCell: {
    backgroundColor: "#FFF",
    fontWeight: "bold",
  },
  tableBodyRow: {
    height: "80px",
    "&:hover": {
      background: "rgba(236, 236, 237, 0.5) !important",
    },
  },
  tableBodyCell: {
    borderTop: "1px solid #ccc",
    borderBottom: "none",
  },
});

const Antenne = ({
  classes,
  manufacturersList,
  getManufacturersList,
  antennasList,
  setAntennasParamSearch,
  antennasParamSearch,
  getAntennasList,
  resetAntennasList,
  getAntennaMsiInfo,
  antennaMsiInfo,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [openModalPorts, setOpenModalPorts] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [editSchema, setEditSchema] = React.useState({});
  const [formData, setFormData] = React.useState({});
  const [editedFormData, setEditedFormData] = React.useState({});
  const [tabs, setTabs] = useState([])
  const [datasheetList, setDatasheetList] = React.useState(false);

  
  const { hasRestrictedRouteAccess } = useContext(UserRoleContext);
  
  const navigate = useNavigate();
  const httpService = useHttpService();

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleOpenModalPorts = (row) => {  
    setOpenModalPorts(true);
    setFormData(row);
    setTabs(['General Properties','Port Properties'])
    setEditSchema({
      manufacturer: {
        tab: 'General Properties',
        title: "Manufacturer",
        type: "text",
        md: 3,
        disabled: true
      },
      model_code: {
        tab: 'General Properties',
        title: "Model Code",
        type: "text",
        md: 3,
        disabled: true
      },
      port_num: {
        tab: 'Port Properties',
        title: "Port Number",
        type: "number",
        md: 3,
      },
      frequency_from: {
        tab: 'Port Properties',
        title: "Minimum frequency",
        type: "number",
        md: 3,
      },
      frequency_to: {
        tab: 'Port Properties',
        title: "Maximum frequency",
        type: "number",
        md: 3,
      },
      gain: {
        tab: 'Port Properties',
        title: "Gain (dBi)",
        type: "real",
        md: 3,
      },
      hpbw: {
        tab: 'Port Properties',
        title: "HPBW (°)",
        type: "text",
        md: 3,
      },
      tilt_from: {
        tab: 'Port Properties',
        title: "Minimum tilt",
        type: "number",
        md: 3,
      },
      tilt_to: {
        tab: 'Port Properties',
        title: "Maximum tilt",
        type: "number",
        md: 3,
      },
      polarization: {
        tab: 'General Properties',
        title: "Polarization",
        type: "text",
        md: 3,

      },
      height: {
        tab: 'General Properties',
        title: "Height (mm)",
        type: "number",
        md: 3,
      },
      connector_type: {
        tab: 'General Properties',
        title: "Connector",
        type: "text",
        md: 3,
      },
      azimuth_beam_direction: {
        tab: 'Port Properties',
        title: "Azimuth Beam Direction (°)",
        type: "number",
        md: 3,
      },  
    });  
  };


  const downloadDatasheet = async(e) => {
    httpService.downloadAPI(
      GET_FILES_FROM_GOOGLE_STORAGE+'efesto-msi/'+ e.datasheet_filename,
      notfound,
      "application/zip",
      e.model_code.toUpperCase()+"_Datasheet.zip")
  };

  function notfound(fileName)
  {
    alert("Datasheet is not found");
  }

  // const getDatasheetList = async(e) =>{
  //   //httpService.getAPI(GET_ANTENNA_WITH_DATASHEET+"/ANDREW/sg",checkDatasheetPresence)
  //   await axios({
  //     method: "GET",
  //     url: GET_ANTENNA_WITH_DATASHEET+"/"+e.manufacturer.toUpperCase(),
  //     headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
  //   })
  //     .then((res) => {
  //       setDatasheetList(true)
  //       data = res.data;
  //     })
  //     .catch((err) => {
  //       setDatasheetList(false);
  //     });
  // };

  //const checkDatasheetPresence = (data) =>{

  //   const manufacturers = [];
  //   for (var i=0;i<data.folders.length; i++){
  //     const manufacturer = data.folders[i].name
  //     httpService.getAPI(GET_ANTENNA_WITH_DATASHEET+"/"+manufacturer.toUpperCase(),modelsWithDatasheet)
  //   }
  // }

  // const modelsWithDatasheet = (data) =>{
  //   const models= []
  //   for (var i=0;i<data.files.length; i++){
  //     const model = data.files[i].name
  //     models[i] = model
  //   }
  //   const list = datasheetList
  //   list[data.name]= models;
  //   setDatasheetList(list)
  // }



  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleCloseModalPorts = () => {
    setOpenModalPorts(false);
  };

  const onDataChange = async (e) => {    
    const data = {
      'id': e['port_id'],
      'model_code': e['model_code'],
      'freq_from': e['frequency_from'],
      'freq_to': e['frequency_to'],
      'port_num': e['port_num'],
      'gain': e['gain'],
      'hpbw': e['hpbw'],
      'tilt_from': e['tilt_from'], 
      'tilt_to': e['tilt_to'],
      'azimuth_beam_direction': e['azimuth_beam_direction'],
      'height': e['height'],
      'polarization': e['polarization'], 
      'connector': e['connector'],
      'datasheet_filename' : e['datasheet_filename']
    }
    setEditedFormData(data);
  }

  const handleCloseSave = () => {
    httpService.putAPI(PUT_ANTENNA_PORT, editedFormData,handleCloseModalPorts);
  }

  useEffect(() => {
    const fetchManufacturersList = async () => {
      await getManufacturersList();
    };
    fetchManufacturersList();
    resetAntennasList(); // resetting antennasList: null on first render
  }, []);

  const mapJsonToHeader = (name) => {
    switch (name) {
      case "manufacturer": {
        name = "Manufacturer";
        break;
      }
      case "model_code": {
        name = "Model Code";
        break;
      }
      case "port_num": {
        name = "Port Number";
        break;
      }
      case "frequency_from":
      case "frequency_to": {
        name = "Frequency";
        break;
      }
      case "gain": {
        name = "Gain (dBi)";
        break;
      }
      case "hpbw": {
        name = "HPBW (°)";
        break;
      }
      case "tilt_from":
      case "tilt_to": {
        name = "Downtilt (°)";
        break;
      }
      case "polarization": {
        name = "Polarization";
        break;
      }
      case "height": {
        name = "Height (mm)";
        break;
      }
      case "connector_type": {
        name = "Connector";
        break;
      }
      case "azimuth_beam_direction": {
        name = "Azimuth Beam Direction (°)";
        break;
      }
      case "msi_legacy": {
        name = "MSI Legacy";
        break;
      }
      case "msi_stat": {
        name = "MSI";
        break;
      }
      case "datasheet_filename": {
        name = "Datasheet";
        break;
      }
      case "port_id": {
        name = "";
        break;
      }

    }



    return (
      <TableCell
        className={classes.tableHeaderCell}
        key={`initial-table-header-cell-${name}`}
        style={{
          fontWeight: "bold",
          visibility: "unset",
        }}
        align='center'
      >
        {name}
      </TableCell>
    );
  };

  const mapJsonToCells = (row) => {             
    //getDatasheetList(row);
    const shortedValues = Object.values(row)
      .map((el, i, array) => {
        if (Object.keys(row)[i].endsWith("_from")) {
          return `${el} - ${array[i + 1]}`;
        }
        if (Object.keys(row)[i].endsWith("_to")) {
          return "to_remove";
        }
        if (Object.keys(row)[i] === "msi_stat" && el)
          return (
            <IconButton
              aria-label='Visualizza dettagli'
              onClick={async () => {
                navigate(`/antenne/${row.manufacturer}/${row.model_code}/${row.frequency_from}/${row.frequency_to}`);
                // const msiInfo = await getAntennaMsiInfo({
                //   model_code: row.model_code,
                //   freq_max: row.frequency_to,
                //   freq_min: row.frequency_from,
                // });
                // setModalTitle(
                //   `${row.manufacturer}, ${row.frequency_from} - ${row.frequency_to}`
                // );
                // handleOpenModal();
                // return msiInfo;
              }}
              size='large'
            >
              <OpenInBrowser />
            </IconButton>           
            
          );
        if (Object.keys(row)[i] === "msi_legacy" && el)
          return (
            <IconButton aria-label='Visualizza dettagli' size='large'>
              <Check style={{ color: "green" }} />
            </IconButton>
          );    
          
        if (Object.keys(row)[i] === "datasheet_filename" && el){
          if (hasRestrictedRouteAccess)
            return (
              <IconButton
                aria-label='Download datasheet' 
                size='large'
                onClick= {() =>downloadDatasheet(row)}>
                <PictureAsPdf/>
              </IconButton>);
          else 
            return "";
        } 

        if (Object.keys(row)[i] === "port_id" && el){
          if (hasRestrictedRouteAccess)
            return (
              <IconButton
                aria-label='Edit Port' 
                size='large'
                onClick= {() =>handleOpenModalPorts(row)}>
                <Edit/>
              </IconButton>);
          else 
            return "";
        }           

        if (el === "" || !el) return "--";
        return el;
      })
      .filter((el) => el !== "to_remove");

    return shortedValues.map((cell, j) => {
      return (
        <TableCell
          className={classes.tableBodyCell}
          key={`initial-table-body-cell-${j}`}
          align='center'
        >
          {cell}
        </TableCell>
      );
    });
  };


  return (
    <>
      <div className={classes.mainContainer}>
        <h2>Antennas Catalog</h2>
        <div className={classes.form}>
          <Autocomplete
            options={manufacturersList || []}
            style={{ width: "20%", display: "block" }}
            onChange={(e, value) => {
              setAntennasParamSearch("manufacturer", value);
            }}
            renderInput={(params) => (
              <TextField
                style={{ width: "100%", display: "block" }}
                {...params}
                label='Choose manufacturer name'
                variant='outlined'
              />
            )}
          />
          <TextField
            className={classes.textField}
            autoComplete='off'
            InputLabelProps={{
              style: { padding: 0 },
            }}
            name='modelCode'
            label='Model Code'
            variant='outlined'
            onChange={(e) => {
              setAntennasParamSearch("model_code", e.target.value);
            }}
          />
          <TextField
            type='number'
            className={classes.textField}
            autoComplete='off'
            InputLabelProps={{
              style: { padding: 0 },
            }}
            name='frequencyRangeMinimum'
            label='Frequency Range Minimum'
            variant='outlined'
            onChange={(e) => {
              setAntennasParamSearch("freq_max", Number(e.target.value));
            }}
          />
          <TextField
            type='number'
            className={classes.textField}
            autoComplete='off'
            InputLabelProps={{
              style: { padding: 0 },
            }}
            name='frequencyRangeMaximum'
            label='Frequency Range Maximum'
            variant='outlined'
            onChange={(e) => {
              setAntennasParamSearch("freq_min", Number(e.target.value));
            }}
          />
          <Button
            className={classes.searchButton}
            onClick={() => {    
              getAntennasList(antennasParamSearch);
            }}
          >
            Search
          </Button>
        </div>
      </div>
      {openModal && (
        <TransitionsModal
          handleCloseModal={handleCloseModal}
          isOpen={openModal}
          msi={antennaMsiInfo}
          title={modalTitle}
        />
      )}
      {openModalPorts && (
        <Dialog
        fullWidth
        maxWidth='lg'
        open={openModalPorts}
        onClose={handleCloseModalPorts}
        >
          <DialogTitle>Edit Antenna</DialogTitle>
          <DialogContent>            
            <JsonSchemaBox
             formData={formData}
             schema={editSchema}
             onChange={onDataChange}
             tabs={tabs}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseModalPorts}>Cancel</Button>
            <Button onClick={handleCloseSave}>Save</Button>
          </DialogActions>        
        </Dialog>      
      )}
      {antennasList && (
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table stickyHeader>
            <TableHead>
              <TableRow className={classes.tableHeaderRow}>
                {Object.keys(antennasList[0]).map((key) => {
                  if (!key.endsWith("from")) return mapJsonToHeader(key);
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {antennasList.map((row, i) => {
                return (
                  <TableRow
                    className={classes.tableBodyRow}
                    key={`initial-table-body-row-${i}`}
                  >
                    {mapJsonToCells(row)}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

const mapState = ({ antennas }) => ({
  manufacturersList: antennas.manufacturersList,
  antennasList: antennas.antennasList,
  antennasParamSearch: antennas.antennasParamSearch,
  antennaMsiInfo: antennas.antennaMsiInfo,
  antennaMsiParams: antennas.antennaMsiParams,
});

const mapDispatch = ({
  antennas: {
    getManufacturersList,
    setAntennasParamSearch,
    getAntennasList,
    resetAntennasList,
    getAntennaMsiInfo,
  },
}) => ({
  getManufacturersList: () => getManufacturersList(),
  setAntennasParamSearch: (key, value) =>
    setAntennasParamSearch({ key, value }),
  getAntennasList: (param) => getAntennasList(param),
  resetAntennasList: () => resetAntennasList(),
  getAntennaMsiInfo: (param) => getAntennaMsiInfo(param),
});

export default compose(
  withStyles(useStyles),
  connect(mapState, mapDispatch)
)(Antenne);
