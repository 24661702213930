/* eslint-disable no-debugger */
import React from "react";
import { v4 as uuidv4 } from "uuid";
import MarkerClusterGroup from "react-leaflet-markercluster";
import { useMap, Marker, Tooltip } from "react-leaflet";
import L from "leaflet";

const ClusterGroup = (props) => {
  const mapInstance = useMap();
  let offices = props.offices;

  let neighbourhood = null;
  if (props.type === "neighbourhood") {
    neighbourhood = props.neighbourhood
      .map((n) => {
        if (offices.find((o) => o.id === n.id)) return null;
        return n;
      })
      .filter((n) => n);
  }

  let cluster = null;
  if (props.type === "offices") {
    cluster = props.cluster;
  }

  if (props.type === "massives") {
    cluster = props.massives;
  }

  const defaultIcon = new L.Icon({
    iconUrl:
      props.type === "neighbourhood"
        ? "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-grey.png"
        : "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png",
    shadowUrl:
      "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  });

  const competitorIcon = new L.Icon({
    iconUrl:
      props.type === "neighbourhood"
        ? "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-grey.png"
        : "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png",
    shadowUrl:
      "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  });

  const prototypeIcon = new L.Icon({
    iconUrl:
      props.type === "neighbourhood"
        ? "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-grey.png"
        : "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png",
    shadowUrl:
      "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  });

  const markers =
    neighbourhood && mapInstance.getZoom() >= 10
      ? neighbourhood
      : offices?.length > 0
      ? offices
      : props.massives;
  return (
    <>
      {markers?.length > 0 && (
        <MarkerClusterGroup key={uuidv4()} ref={cluster}>
          {markers.map((el) => {
            // if (el.hasOwnProperty('hasDtm')) {
            //   if (!el.hasDtm || !el.hasShapefile) {
            //     icon.options.iconUrl =
            //       'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png'
            //   } else {
            //     icon.options.iconUrl =
            //       'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png'
            //   }
            // }
            return (
              <Marker
                icon={
                  el?.site_type === "prototype"
                    ? prototypeIcon
                    : el?.site_type === "competitor"
                    ? competitorIcon
                    : defaultIcon
                }
                style={{
                  backgroundColor: "gray",
                }}
                key={uuidv4()}
                position={
                  props.type === "offices" || props.type === "neighbourhood"
                    ? [el.geometry.coordinates[1], el.geometry.coordinates[0]]
                    : [el.latwgs84, el.longwgs84]
                }
                eventHandlers={{
                  click: () => {
                    props.handleMarkerClick(el);
                  },
                }}
              >
                <Tooltip
                  permanent
                >{`${el.site_code} - ${el.site_name}`}</Tooltip>
              </Marker>
            );
          })}
        </MarkerClusterGroup>
      )}

      {/* {offices?.length > 0 && (
        <MarkerClusterGroup>
          <GeoJSON
            ref={geoJsonLayer}
            data={offices}
            onEachFeature={handleGeoJsonFeatures}
            pointToLayer={handleMarkers}
          />
        </MarkerClusterGroup>
      )} */}
    </>
  );
};

export default ClusterGroup;
