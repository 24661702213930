import { useEffect, useState } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { compose } from "redux";
import { IconButton, CircularProgress, Button } from "@mui/material";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import BlurOnIcon from "@mui/icons-material/BlurOn";
import BlurOffIcon from "@mui/icons-material/BlurOff";

import { config } from "../../utils/config";

import "./preview.scss";

const { BASE_API } = config;
const STORAGE_BASE_URL = `${BASE_API}storage/`;

const Preview = ({
  siteInfos,
  loadedModels,
  setLoadedModels,
  loadingModels,
  setLoadingModels,
  modelIsLoading,
  setModelIsLoading,
  modelsVisibilityList,
  setModelsVisibilityList,
  modelsGhostedList,
  setModelsGhostedList,
}) => {
  const [siteFiles, setSiteFiles] = useState([]);
  const [orderedFiles, setOrderedFiles] = useState([]);
  const [mainScene, setMainScene] = useState(null);

  // EXTRACT base_folder from props and save it
  const baseFolder = siteInfos?.base_folder;

  // GET list of files from the base_folder
  // THEN filter them to store only .gltf
  const fetchData = async () => {
    try {
      const response = await axios({
        method: "GET",
        url: `${STORAGE_BASE_URL}${baseFolder}`,
        headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      setSiteFiles(
        response.data.files.filter((file) => file.name.includes(".gltf"))
      );
    } catch (error) {
      console.log(error);
    }
  };

  // GET gltf files
  const downloadGLTF = async (fileName) => {
    let file;
    await axios({
      method: "GET",
      url: `${STORAGE_BASE_URL}${baseFolder}`,
      headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
      params: { file: fileName },
    })
      .then((res) => {
        file = res.data;
      })
      .catch((err) => {
        setLoadingModels({});
        setModelIsLoading(false);
        console.log("Error downloadGLTF", err);
      });
    return file;
  };

  // MAIN gltf entry point
  const downloadFile = async (fileName, i) => {
    setModelIsLoading(true);
    await setLoadingModels({ ...loadingModels, [i]: true });
    const file = await downloadGLTF(fileName);
    loadGLTF(fileName, file, i);
  };

  // CREATE scene if there isn't already one
  // CREATE new xeogl model
  function loadGLTF(fileName, file, i) {
    const scene = mainScene || createMainScene();

    let model = null;

    if (fileName === "terrain.SceneJS.json") {
      model = new xeogl.SceneJSModel(scene);
      model.data = file;
    } else {
      model = new xeogl.Model(scene);
      xeogl.GLTFModel.parse(model, file, { lambertMaterials: true });
    }

    model.on("loaded", () => {
      setModelIsLoading(false);
      setLoadedModels(fileName, model);
      setLoadingModels({ ...loadingModels, [i]: false });
      setModelsVisibilityList({ ...modelsVisibilityList, [i]: true });
      setModelsGhostedList({ ...modelsGhostedList, [i]: true });
    });

    model.on("error", (error) => {
      console.log(error);
    });
  }

  // TOGGLE visibility
  function modelsVisibilityToggle(fileName, i) {
    function toggle(node, visible) {
      Object.keys(node.meshes).forEach(function (key) {
        node.meshes[key].visible = visible;
      });
    }

    toggle(loadedModels[fileName], !modelsVisibilityList[i]);
    setModelsVisibilityList({
      ...modelsVisibilityList,
      [i]: !modelsVisibilityList[i],
    });
  }

  // TOGGLE ghost-mode
  function modelsGhostifyToggle(fileName, i) {
    function toggle(node, ghosted) {
      Object.keys(node.meshes).forEach(function (key) {
        node.meshes[key].ghosted = !ghosted;
      });
    }

    toggle(loadedModels[fileName], !modelsGhostedList[i]);
    setModelsGhostedList({
      ...modelsGhostedList,
      [i]: !modelsGhostedList[i],
    });
  }

  // CREATE scene
  function createMainScene() {
    const newMainScene = new xeogl.Scene({
      canvas: "xeoglCanvas",
      transparent: true,
    });

    const camera = newMainScene.camera;

    camera.eye = [-100.0, 200.0, 200.0];
    camera.look = [0.0, 0.0, 0.0];

    new xeogl.AmbientLight(newMainScene, {
      color: [1, 1, 1],
      intensity: 0.6,
    });

    new xeogl.DirLight(newMainScene, {
      dir: [1, 1, 1], // Direction the light is shining in
      color: [1, 1, 1],
      intensity: 0.6,
      space: "view", // Other option is "world", for World-space
      shadow: false, // Default
    });

    new xeogl.DirLight(newMainScene, {
      dir: [1, 0, 0],
      color: [1, 1, 1],
      intensity: 0.6,
      space: "view",
      shadow: false,
    });

    new xeogl.CameraControl(newMainScene);

    setMainScene(newMainScene);
  }

  // ENTER full screen mode
  const goFullScreen = () => {
    const el = document.getElementById("xeoglCanvas");
    if (el.webkitRequestFullScreen) {
      el.webkitRequestFullScreen();
    } else {
      el.mozRequestFullScreen();
    }
  };

  // on first render
  useEffect(() => {
    fetchData();
    setLoadedModels({});
    setModelsVisibilityList({});
    setModelsGhostedList({});
    if (Object.keys(loadedModels).length > 0) {
      Object.values(loadedModels).map((model) => model?.destroy());
    }
    if (mainScene === null) createMainScene();
  }, []);

  useEffect(() => {
    siteFiles.length > 0 && orderFiles();
  }, [siteFiles]);

  const orderFiles = () => {
    let tmp = [];
    tmp = [
      ...tmp,
      ...siteFiles.filter((item) => item.name.includes("terreno")),
      ...siteFiles.filter((item) => item.name.includes("palazzo")),
    ];
    setOrderedFiles(tmp);
  };

  // child component
  const LeftSideMenu = () => {
    return (
      <>
        {orderedFiles &&
          orderedFiles.map((file, i) => {
            return (
              <div className='side-pannel' key={`file${file.name}-${i}`}>
                <span>
                  {file.name === "terreno.ifc.gltf"
                    ? "DTM"
                    : file.name === "palazzo.ifc.gltf"
                    ? "shapefile"
                    : file.name}
                </span>
                {modelsVisibilityList[i] === undefined && (
                  <IconButton
                    color='primary'
                    style={{ marginLeft: "10px" }}
                    onClick={() => downloadFile(file.name, i)}
                    disabled={modelIsLoading}
                    size='large'
                  >
                    {loadingModels[i] ? (
                      <CircularProgress size={24} />
                    ) : (
                      <DoubleArrowIcon />
                    )}
                  </IconButton>
                )}
                {modelsVisibilityList[i] !== undefined && (
                  <>
                    {modelsVisibilityList[i] ? (
                      <IconButton
                        color='primary'
                        style={{ marginLeft: "10px" }}
                        onClick={() => modelsVisibilityToggle(file.name, i)}
                        size='large'
                      >
                        <VisibilityIcon />
                      </IconButton>
                    ) : (
                      <IconButton
                        color='primary'
                        style={{ marginLeft: "10px" }}
                        onClick={() => modelsVisibilityToggle(file.name, i)}
                        size='large'
                      >
                        <VisibilityOffIcon />
                      </IconButton>
                    )}
                  </>
                )}
                {modelsGhostedList[i] !== undefined && (
                  <>
                    {modelsGhostedList[i] ? (
                      <IconButton
                        color='primary'
                        onClick={() => modelsGhostifyToggle(file.name, i)}
                        size='large'
                      >
                        <BlurOnIcon />
                      </IconButton>
                    ) : (
                      <IconButton
                        color='primary'
                        onClick={() => modelsGhostifyToggle(file.name, i)}
                        size='large'
                      >
                        <BlurOffIcon />
                      </IconButton>
                    )}
                  </>
                )}
              </div>
            );
          })}
      </>
    );
  };

  return (
    <div className='preview__container'>
      <div className='preview__left'>
        <LeftSideMenu />
        <Button
          color='primary'
          className='preview__btn'
          onClick={() => goFullScreen()}
        >
          Enter Full Screen
        </Button>
      </div>
      <div className='xeoglBox'>
        <canvas id='xeoglCanvas'></canvas>
      </div>
    </div>
  );
};

const mapState = ({ gltfFiles }) => ({
  loadedModels: gltfFiles.loadedModels,
  loadingModels: gltfFiles.loadingModels,
  modelIsLoading: gltfFiles.modelIsLoading,
  modelsVisibilityList: gltfFiles.modelsVisibilityList,
  modelsGhostedList: gltfFiles.modelsGhostedList,
});

const mapDispatch = ({
  gltfFiles: {
    setLoadedModels,
    setLoadingModels,
    setModelIsLoading,
    setModelsVisibilityList,
    setModelsGhostedList,
  },
}) => ({
  setLoadedModels: (modelName, model) => setLoadedModels({ modelName, model }),
  setLoadingModels: (value) => setLoadingModels(value),
  setModelIsLoading: (value) => setModelIsLoading(value),
  setModelsVisibilityList: (value) => setModelsVisibilityList(value),
  setModelsGhostedList: (value) => setModelsGhostedList(value),
});

export default compose(connect(mapState, mapDispatch))(Preview);
