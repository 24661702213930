
const loader = {
  state: {
    loading: false
  },
  reducers: {
    loader (state, value) {
      return {
        ...state,
        loading: value
      }
    }
  }
}

export default loader
