import { createContext, useState, useEffect } from "react";

const parseJwt = (token) => {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
  return JSON.parse(jsonPayload);
};

const determineUserWritePermission = (userRole) => {
  switch (userRole) {
    case "ROOT":
      return true;
    case "ADMIN":
      return true;
    case "USER":
      return true;
    case "EXTERNAL":
      return false;
    default:
      return false;
  }
};

const determineRestrictedRouteAccess = (userRole) => {
  switch (userRole) {
    case "ROOT":
      return true;
    case "ADMIN":
      return true;
    case "USER":
      return false;
    case "EXTERNAL":
      return false;
    default:
      return false;
  }
};

export const UserRoleContext = createContext({
  userRole: "",
  setUserRole: () => {},
  hasWritePermission: null,
  setHasWritePermission: () => {},
  hasRestrictedRouteAccess: null,
  setHasRestrictedRouteAccess: () => {},
});

export const UserRoleProvider = ({ children }) => {
  const [userRole, setUserRole] = useState("");
  const [hasWritePermission, setHasWritePermission] = useState(null);
  const [hasRestrictedRouteAccess, setHasRestrictedRouteAccess] =
    useState(null);
  const value = {
    userRole,
    setUserRole,
    hasWritePermission,
    hasRestrictedRouteAccess,
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    let parsedToken = null;
    if (token) {
      parsedToken = parseJwt(token);
      setUserRole(parsedToken.UserProfile);
    }
  }, []);
  useEffect(() => {
    setHasWritePermission(determineUserWritePermission(userRole));
    setHasRestrictedRouteAccess(determineRestrictedRouteAccess(userRole));
  }, [userRole]);

  return (
    <UserRoleContext.Provider value={value}>
      {children}
    </UserRoleContext.Provider>
  );
};
