import React from "react";
import withStyles from "@mui/styles/withStyles";
import { connect } from "react-redux";
import { compose } from "redux";
import TableBox from "../../components/TableBox";
import { siteTenantsTableHeaders } from "../../utils/tableHeaders";

const useStyles = (theme) => ({
  tabTenantsContainer: {
    padding: "20px 0",
  },
  tenantsTable: {
    height: "calc(100vh - 250px)",
  },
});

const Tenants = ({ classes, tenants }) => {
  const siteTenantsTableInfos = {
    tableHeaders: siteTenantsTableHeaders,
    tableRows: tenants?.map((tenant) => [
      tenant.tenant_name,
      tenant.sec_num,
      tenant.systems,
      tenant.sectors,
    ]),
  };

  return (
    <div className={classes.tabTenantsContainer}>
      <div className={classes.tenantsTable}>
        <TableBox tableInfos={siteTenantsTableInfos} isExpandable />
      </div>
    </div>
  );
};

const mapState = ({ site }) => ({
  tenants: site.tenants,
});

const mapDispatch = () => ({});

export default compose(
  withStyles(useStyles),
  connect(mapState, mapDispatch)
)(Tenants);
