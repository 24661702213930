import React, { useEffect, useState, useCallback } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import {
  Grid,
  IconButton,
  InputBase,
  InputLabel,
  InputAdornment,
  FormControl,
  TextField,
} from "@mui/material";
import { Autocomplete } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import SearchIcon from "@mui/icons-material/Search";
import CancelIcon from "@mui/icons-material/Cancel";
import axios from "axios";
import {
  GET_SB_LIST_ZONE,
  GET_SB_LIST_REGIONI,
  GET_SB_LIST_PROVINCE,
  GET_SB_LIST_COMUNI,
  tokenExpiredDoLogin,
} from "../../utils/api";

const useStyles = (theme) => ({
  searchBox: {
    padding: "20px 25px",
    display: "flex",
    height: "40px",
    alignItems: "center",
    position: "relative",
  },
  formControl: {
    borderRadius: "2px",
    height: "100%",
    borderBottom: "none",
    width: "40vw",
  },
  inputBase: {
    height: "100%",
    background: "#F5F5F6",
  },
  inputAdornmentStart: {
    marginTop: "0 !important",
    marginRight: "0",
    padding: "0px 6px",
  },
  inputAdornmentEnd: {
    padding: "0px 6px",
  },
  iconsBlack: {
    color: "#000",
  },
  openedSearchBoxContainer: {
    position: "absolute",
    top: "60px",
    zIndex: 9,
    width: "40vw",
    background: "#F5F5F6",
    left: "25px",
  },
  searchBoxOptions: {
    display: "flex",
    flexDirection: "column",
    padding: "37px 60px",
  },
  optionItem: {
    width: "100%",
    padding: "2px 4px",
  },
  optionInput: {
    background: "#fff",
    marginBottom: "10px",
    font: "400 13px/18px",
    letterSpacing: "0",
    color: "#000000",
    opacity: "0.7",
  },
  searchBoxOptionsButtons: {
    paddingTop: "30px",
    display: "flex",
    justifyContent: "flex-end",
  },
  cancelButton: {
    background: "#C9CAC9 0% 0% no-repeat padding-box",
    borderRadius: "26px",
    opacity: 1,
    outline: "none",
    padding: "11px 30px",
    textDecoration: "none",
    font: "700 14px/19px",
    letterSpacing: 0,
    color: "#FFFFFF",
    border: "none",
    "&:hover": {
      outline: "none",
      cursor: "pointer",
      background: "#A5A5A6 0% 0% no-repeat padding-box",
    },
  },
  searchButton: {
    marginLeft: "20px",
    background: "#03B8FF 0% 0% no-repeat padding-box",
    borderRadius: "26px",
    opacity: 1,
    outline: "none",
    padding: "11px 30px",
    textDecoration: "none",
    font: "700 14px/19px",
    letterSpacing: 0,
    color: "#FFFFFF",
    border: "none",
    "&:hover": {
      outline: "none",
      cursor: "pointer",
      background: "#018ABE 0% 0% no-repeat padding-box",
    },
  },
});

const SearchBox = ({
  classes,
  options,
  loadOffices,
  globalFilterValue,
  filtersValues,
  filtersLists,
  setGlobalFilterValue,
  setFiltersValues,
  setFiltersLists,
  setBounds,
}) => {
  const [token] = useState(localStorage.getItem("token"));
  const [openSearchBox, setOpenSearchBox] = useState(false);
  const [filtersOptions] = useState(options);

  const handleGlobalSearch = async () => {
    await loadOffices({ freetext: globalFilterValue });
    setOpenSearchBox(false);
  };

  const handleFiltersValues = (event, type, el, v) => {
    const name = type === "input" ? event.target.name : el.name;
    const inputValue = event.target.value;
    const autoCompleteValue = v;
    const key = type === "input" ? el.key : el.key;

    setFiltersValues(key, type === "input" ? inputValue : autoCompleteValue);

    if (type === "autocomplete") {
      if (v !== null) {
        if (name === "Zone") {
          getOptions(
            "Region",
            type === "input" ? inputValue : autoCompleteValue.value
          );
        } else if (name === "Region") {
          const coords = filtersLists[name].filter((el) =>
            (el.cod_reg === type) === "input"
              ? inputValue
              : autoCompleteValue.value
          )[0].boundary.coordinates[0];
          setBounds(coords);
          getOptions(
            "Province",
            type === "input" ? inputValue : autoCompleteValue.value
          );
        } else if (name === "Province") {
          const coords = filtersLists[name].filter((el) =>
            (el.cod_prov === type) === "input"
              ? inputValue
              : autoCompleteValue.value
          )[0].boundary.coordinates[0];
          setBounds(coords);
          getOptions(
            "Municipality",
            type === "input" ? inputValue : autoCompleteValue.value
          );
        } else if (name === "Municipality") {
          const coords = filtersLists[name].filter((el) =>
            (el.gid === type) === "input" ? inputValue : autoCompleteValue.value
          )[0].boundary.coordinates[0];
          setBounds(coords);
        }
      } else {
        const indexOfRegione = filtersOptions.findIndex(
          (el) => el.name === "Region"
        );
        const indexOfProvincia = filtersOptions.findIndex(
          (el) => el.name === "Province"
        );
        const indexOfComune = filtersOptions.findIndex(
          (el) => el.name === "Municipality"
        );

        if (name === "Zone") {
          filtersOptions[indexOfRegione].disabled = true;
          filtersOptions[indexOfProvincia].disabled = true;
          filtersOptions[indexOfComune].disabled = true;

          setFiltersValues("regione", null);
          setFiltersLists("Region", []);
          setFiltersValues("provincia", null);
          setFiltersLists("Province", []);
          setFiltersValues("comune", null);
          setFiltersLists("Municipality", []);
        } else if (name === "Region") {
          filtersOptions[indexOfProvincia].disabled = true;
          filtersOptions[indexOfComune].disabled = true;

          setFiltersValues("provincia", null);
          setFiltersLists("Province", []);
          setFiltersValues("comune", null);
          setFiltersLists("Municipality", []);
        } else if (name === "Province") {
          filtersOptions[indexOfComune].disabled = true;

          setFiltersValues("comune", null);
          setFiltersLists("Municipality", []);
        }
      }
    }
  };

  const getOffices = () => {
    loadOffices({
      id_municipality: filtersValues?.comune || null,
      site_name: filtersValues?.nomeSito || null,
      site_code: filtersValues?.idInwit || null,
      address: filtersValues?.indirizzo || null,
      original_site_code: filtersValues?.codiceOrigine || null,
    });
    setOpenSearchBox(false);
  };

  const getOptions = useCallback(
    async (selectName, selectedOptionId) => {
      const api =
        selectName === "Zone"
          ? GET_SB_LIST_ZONE
          : selectName === "Region"
          ? `${GET_SB_LIST_REGIONI}?zone_id=${selectedOptionId}`
          : selectName === "Province"
          ? `${GET_SB_LIST_PROVINCE}?id=${selectedOptionId}`
          : selectName === "Municipality"
          ? `${GET_SB_LIST_COMUNI}?id=${selectedOptionId}`
          : "";

      function callbackAfterGettingList(selectName) {
        const indexOfZona = filtersOptions.findIndex(
          (el) => el.name === "Zone"
        );
        const indexOfRegione = filtersOptions.findIndex(
          (el) => el.name === "Region"
        );
        const indexOfProvincia = filtersOptions.findIndex(
          (el) => el.name === "Province"
        );
        const indexOfComune = filtersOptions.findIndex(
          (el) => el.name === "Municipality"
        );

        if (selectName === "Zone") {
          filtersOptions[indexOfZona].disabled = false;
          filtersOptions[indexOfRegione].disabled = true;
          filtersOptions[indexOfProvincia].disabled = true;
          filtersOptions[indexOfComune].disabled = true;
        } else if (selectName === "Region") {
          filtersOptions[indexOfRegione].disabled = false;
          filtersOptions[indexOfProvincia].disabled = true;
          filtersOptions[indexOfComune].disabled = true;
        } else if (selectName === "Province") {
          filtersOptions[indexOfProvincia].disabled = false;
          filtersOptions[indexOfComune].disabled = true;
        } else if (selectName === "Municipality") {
          filtersOptions[indexOfComune].disabled = false;
        }
      }

      await axios({
        method: "GET",
        url: api,
        headers: { authorization: `Bearer ${token}` },
      })
        .then((res) => setFiltersLists(selectName, res.data.data))
        .then(callbackAfterGettingList(selectName))
        .catch((err) => {
          console.log(`Error ${api}`, err);
          tokenExpiredDoLogin(err);
        });
    },
    [filtersOptions, setFiltersLists, token]
  );

  useEffect(() => {
    const getAsyncOptions = async () => {
      await getOptions("Zone");
    };
    getAsyncOptions();
  }, [getOptions]);

  const getAutocompleteOptions = (el) => {
    if (filtersLists[el.name]?.length > 0) {
      return filtersLists[el.name].map((option, i) => {
        const value =
          el.name === "Zone"
            ? option.zone_id
            : el.name === "Region"
            ? option.cod_reg
            : el.name === "Province"
            ? option.cod_prov
            : el.name === "Municipality"
            ? option.gid
            : "";

        const shownValue =
          el.name === "Zone"
            ? option.zone_name
            : el.name === "Region"
            ? option.den_reg
            : el.name === "Province"
            ? option.den_pcm
            : el.name === "Municipality"
            ? option.comune
            : "";

        return { value: value, shownValue: shownValue };
      });
    } else {
      return [];
    }
  };

  return (
    <div className={classes.searchBox}>
      <FormControl className={classes.formControl} fullWidth variant='filled'>
        <InputLabel htmlFor='filled-adornment-amount'></InputLabel>
        <InputBase
          className={classes.inputBase}
          autoComplete='off'
          placeholder='Search...'
          id='filled-adornment-amount'
          startAdornment={
            <InputAdornment
              className={classes.inputAdornmentStart}
              position='start'
            >
              <IconButton
                size='small'
                aria-label='search'
                onClick={() =>
                  globalFilterValue?.length >= 3 && handleGlobalSearch()
                }
              >
                <SearchIcon className={classes.iconsBlack} />
              </IconButton>
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment
              className={classes.inputAdornmentEnd}
              position='end'
            >
              <IconButton
                size='small'
                onClick={() => setOpenSearchBox(!openSearchBox)}
              >
                {openSearchBox ? (
                  <CancelIcon />
                ) : (
                  <MoreHorizIcon className={classes.iconsBlack} />
                )}
              </IconButton>
            </InputAdornment>
          }
          onChange={(e) => setGlobalFilterValue(e.target.value)}
          onKeyPress={(e) =>
            globalFilterValue?.length >= 3 &&
            e.charCode === 13 &&
            handleGlobalSearch()
          }
          style={{
            color:
              globalFilterValue?.length >= 3 ? "unset" : "rgba(0, 0, 0, 0.38)",
          }}
        />
      </FormControl>

      {openSearchBox && (
        <div className={classes.openedSearchBoxContainer}>
          <div className={classes.searchBoxOptions}>
            <Grid container spacing={1}>
              {filtersOptions.map((el, i) => (
                <Grid
                  className={classes.optionItem}
                  item
                  key={`key-${i}`}
                  md={el.grid}
                  lg={el.grid}
                >
                  {el.type === "input" && (
                    <TextField
                      style={{ width: "100%" }}
                      InputProps={{ className: classes.optionInput }}
                      autoComplete='off'
                      InputLabelProps={{
                        style: { padding: 0 },
                      }}
                      id='outlined-search'
                      name={el.name}
                      label={el.name}
                      type='search'
                      variant='outlined'
                      value={filtersValues[el.key] || ""}
                      onChange={(e) => handleFiltersValues(e, "input", el)}
                    />
                  )}
                  {el.type === "select" && (
                    <Autocomplete
                      options={getAutocompleteOptions(el)}
                      style={{ width: "100%", display: "block" }}
                      onChange={(event, newValue) =>
                        handleFiltersValues(event, "autocomplete", el, newValue)
                      }
                      value={filtersValues[el.key] || null}
                      getOptionLabel={(option) => {
                        return option.shownValue || "";
                      }}
                      isOptionEqualToValue={(option, value) => {
                        return option.value === value.value;
                      }}
                      className={classes.optionInput}
                      disabled={el.disabled}
                      renderInput={(params) => (
                        <TextField
                          style={{ width: "100%", display: "block" }}
                          {...params}
                          label={el.name}
                          variant='outlined'
                        />
                      )}
                    />
                  )}
                </Grid>
              ))}
            </Grid>

            <div className={classes.searchBoxOptionsButtons}>
              <button
                className={classes.cancelButton}
                onClick={() => setFiltersValues({})}
              >
                Close
              </button>
              <button
                className={classes.searchButton}
                onClick={() => getOffices()}
              >
                Search
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const mapState = ({ searchBox }) => ({
  globalFilterValue: searchBox.globalFilterValue,
  filtersValues: searchBox.filtersValues,
  filtersLists: searchBox.filtersLists,
});

const mapDispatch = ({
  map: { loadOffices, setBounds },
  searchBox: { setGlobalFilterValue, setFiltersValues, setFiltersLists },
}) => ({
  loadOffices: (query) => loadOffices(query),
  setBounds: (coords) => {
    return setBounds(coords);
  },
  setGlobalFilterValue: (value) => setGlobalFilterValue(value),
  setFiltersValues: (key, value) => setFiltersValues({ key, value }),
  setFiltersLists: (name, data) => setFiltersLists({ name, data }),
});

export default compose(
  withStyles(useStyles),
  connect(mapState, mapDispatch)
)(SearchBox);
