import { useState, useEffect } from "react";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";

import { Grid, MenuItem } from "@mui/material";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import ComboBox from "../../../components/PlaygroundComponents/ComboBox";
import EnhancedTableBox from "../../../components/PlaygroundComponents/EnhancedTableBox";

import {
  POST_SITE_INFOS,
  GET_OFFICES,
  GET_SITE_INFOS,
  GET_SB_LIST_ZONE,
  GET_SB_LIST_REGIONI,
  GET_SB_LIST_PROVINCE,
  GET_SB_LIST_COMUNI,
  GET_SITE_TYPE,
} from "../../../utils/api";
import useHttpService from "../../../hooks/useHttpService";

const INITIAL_INPUTS_STATE = {
  site_code: "",
  original_site_code: "",
  site_name: "",
  site_type: "",
  address: "",
  latitude: 41.898264387534425,
  longitude: 12.49647815901863,
};

const editSchema = {
  site_code: {
    title: "Site Code",
    type: "text",
    md: 6,
  },
  original_site_code: {
    title: "Original site Code",
    type: "text",
    md: 6,
  },
  site_name: {
    title: "Site Name",
    type: "text",
    md: 6,
  },
  address: {
    title: "Address",
    type: "text",
    md: 6,
  },
  site_type: {
    title: "Site Type",
    type: "combo",
    url: GET_SITE_TYPE,
    valueMember: "site_type",
    displayMember: "site_type",
    md: 6,
  },
};
const visibleColumns = [
  {
    id: "site_code",
    alignRight: false,
    disablePadding: false,
    label: "Site Code",
  },
  {
    id: "original_site_code",
    alignRight: false,
    disablePadding: false,
    label: "Original Site Code",
  },
  {
    id: "site_name",
    alignRight: false,
    disablePadding: false,
    label: "Site Name",
  },
  {
    id: "address",
    alignRight: false,
    disablePadding: false,
    label: "Address",
  },
  {
    id: "site_type",
    alignRight: false,
    disablePadding: false,
    label: "Site Type",
    textTransform: "uppercase",
  },
];
const actions = [
  {
    action: "edit",
    icon: "edit",
    tooltip: "edit current row",
  },
  {
    action: "delete",
    icon: "delete",
    tooltip: "delete current row",
  },
];

const ManageSites = () => {
  const [openPost, setOpenPost] = useState(false);
  const [selectedZone, setSelectedZone] = useState();
  const [selectedRegion, setSelectedRegion] = useState("");
  const [selectedProvince, setSelectedProvince] = useState("");
  const [selectedMunicipality, setSelectedMunicipality] = useState("");
  const [isAddNewSiteDisabled, setIsAddNewSiteDisabled] = useState(true);
  const [inputFields, setInputFields] = useState(INITIAL_INPUTS_STATE);
  const [disableActions, setDisableActions] = useState(true);
  const [inputKey, setInputKey] = useState(uuidv4());
  const [siteTypes, setSiteTypes] = useState([]);

  const { fireWarningMessage, fireSuccessMessage, fireErrorMessage } =
    useHttpService();

  const resetToInitialState = () => {
    setSelectedZone("");
    setSelectedRegion("");
    setSelectedProvince("");
    setSelectedMunicipality("");
  };

  const resetInputs = () => {
    setInputFields(INITIAL_INPUTS_STATE);
  };

  const onChangeZone = (e) => {
    setSelectedZone(e.id);
    setSelectedRegion("");
    setSelectedProvince("");
    setDisableActions(true);
  };

  const onChangeRegion = (e) => {
    setSelectedRegion(e.cod_reg);
    setSelectedProvince("");
    setDisableActions(true);
  };

  const onChangeProvince = (e) => {
    setSelectedProvince(e.cod_prov);
    setDisableActions(true);
  };

  const onChangeMunicipality = (e) => {
    setSelectedMunicipality(e.gid);
    setIsAddNewSiteDisabled(false);
    setDisableActions(false);
  };

  const onChangeSiteCode = (e) => {
    const { value } = e.target;
    setInputFields({ ...inputFields, site_code: value });
  };

  const onChangeOriginalSiteCode = (e) => {
    const { value } = e.target;
    setInputFields({ ...inputFields, original_site_code: value });
  };

  const onChangeSiteName = (e) => {
    const { value } = e.target;
    setInputFields({ ...inputFields, site_name: value });
  };

  const onChangeAddress = (e) => {
    const { value } = e.target;
    setInputFields({ ...inputFields, address: value });
  };

  const onChangeLatitude = (e) => {
    const { value } = e.target;
    const newLatitude = Number(value);
    setInputFields({ ...inputFields, latitude: newLatitude });
  };

  const onChangeLongitude = (e) => {
    const { value } = e.target;
    const newLongitude = Number(value);
    setInputFields({ ...inputFields, longitude: newLongitude });
  };

  const onChangeSiteType = (e) => {
    const { value } = e.target;
    setInputFields({ ...inputFields, site_type: value });
  };

  const toggleAddNewSiteModal = () => {
    setOpenPost(!openPost);
  };

  const handleCloseCancel = () => {
    resetInputs();
    setOpenPost(false);
  };

  const clearSearchResults = () => {
    resetToInitialState();
    setIsAddNewSiteDisabled(true);
    // forcing ComboBox re-render
    const newKey = uuidv4();
    setInputKey(newKey);
  };

  const isFormReadyToBeSubmitted = () => {
    const {
      site_code,
      original_site_code,
      site_name,
      address,
      latitude,
      longitude,
      site_type,
    } = inputFields;
    if (
      site_code &&
      original_site_code &&
      site_name &&
      address &&
      latitude &&
      longitude &&
      site_type
    ) {
      return false;
    } else {
      return true;
    }
  };

  const handleCloseSavePost = async () => {
    const {
      site_code,
      original_site_code,
      site_name,
      site_type,
      address,
      latitude,
      longitude,
    } = inputFields;

    const params = {
      site_code,
      original_site_code,
      site_name,
      site_type,
      address,
      id_municipality: selectedMunicipality,
      geometry: {
        type: "Point",
        coordinates: [longitude, latitude],
      },
    };
    try {
      const response = await axios({
        method: "POST",
        url: POST_SITE_INFOS,
        headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        data: params,
      });
      const { result, data } = response.data;
      if (result === 1 && data !== null) {
        fireWarningMessage(data);
      } else {
        fireSuccessMessage();
      }
    } catch (error) {
      fireErrorMessage(error.response.status, error.response.data?.message);
    } finally {
      resetInputs();
      toggleAddNewSiteModal();
    }
  };

  useEffect(() => {
    const fetchSiteTypes = async () => {
      try {
        const response = await axios({
          method: "get",
          url: GET_SITE_TYPE,
          headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        });
        const { data } = response.data;
        setSiteTypes(data);
      } catch (error) {
        fireErrorMessage(error.response.status, error.response.data?.message);
      }
    };

    fetchSiteTypes();
  }, []);

  return (
    <div>
      <Grid container spacing={5} padding={5}>
        <Grid item md={3}>
          <ComboBox
            label='Zone'
            apiURL={GET_SB_LIST_ZONE}
            valueMember='id'
            displayMember='zone_name'
            onSelectChange={onChangeZone}
            selectedValue={selectedZone}
            key={inputKey}
          />
        </Grid>

        <Grid item md={3}>
          <ComboBox
            label='Region'
            apiURL={`${GET_SB_LIST_REGIONI}?zone_id=${selectedZone}`}
            valueMember='cod_reg'
            displayMember='den_reg'
            onSelectChange={onChangeRegion}
            selectedValue={selectedRegion}
            key={inputKey}
          />
        </Grid>

        <Grid item md={3}>
          <ComboBox
            label='Province'
            apiURL={`${GET_SB_LIST_PROVINCE}?id=${selectedRegion}`}
            valueMember='cod_prov'
            displayMember='den_pcm'
            onSelectChange={onChangeProvince}
            selectedValue={selectedProvince}
            key={inputKey}
          />
        </Grid>

        <Grid item md={3}>
          <ComboBox
            label='Municipality'
            apiURL={`${GET_SB_LIST_COMUNI}?id=${selectedProvince}`}
            valueMember='gid'
            displayMember='comune'
            onSelectChange={onChangeMunicipality}
            selectedValue={selectedMunicipality}
            key={inputKey}
          />
        </Grid>
      </Grid>
      <Grid container spacing={5} padding={5}>
        <Grid container justifyContent='center'>
          <Grid item sx={{ boxSizing: "border-box" }}>
            <Button
              variant='contained'
              onClick={toggleAddNewSiteModal}
              disabled={isAddNewSiteDisabled}
              sx={{ color: "#fff", marginRight: "1rem" }}
            >
              add a new site
            </Button>
            <Button
              variant='contained'
              sx={{ color: "#fff" }}
              onClick={clearSearchResults}
            >
              clear search results
            </Button>
          </Grid>
        </Grid>

        <Grid item md={12}>
          <EnhancedTableBox
            heightPercentage='0.5'
            title='Sites'
            onSelectChange={onChangeMunicipality}
            apiURL={`${GET_OFFICES}?id_municipality=${selectedMunicipality}`}
            deleteURL={GET_SITE_INFOS}
            editURL={GET_SITE_INFOS}
            valueMember='id'
            visibleColumns={visibleColumns}
            editJsonSchema={editSchema}
            actions={actions}
            disableDefaultActions={disableActions}
          />
        </Grid>
      </Grid>

      <Dialog
        fullWidth
        maxWidth='lg'
        open={openPost}
        onClose={handleCloseCancel}
      >
        <DialogTitle>ADD A NEW SITE</DialogTitle>
        <DialogContent>
          <Grid container spacing={5} padding={5}>
            <Grid item md={4}>
              <TextField
                autoFocus
                fullWidth
                margin='dense'
                id='site_code'
                label='Site Code'
                variant='standard'
                value={inputFields.site_code}
                onChange={onChangeSiteCode}
              />
            </Grid>

            <Grid item md={4}>
              <TextField
                fullWidth
                margin='dense'
                id='og_site_code'
                label='Original Site Code'
                variant='standard'
                value={inputFields.original_site_code}
                onChange={onChangeOriginalSiteCode}
              />
            </Grid>
          </Grid>
          <Grid container spacing={5} padding={5}>
            <Grid item md={4}>
              <TextField
                fullWidth
                margin='dense'
                id='site_name'
                label='Site Name'
                variant='standard'
                value={inputFields.site_name}
                onChange={onChangeSiteName}
              />
            </Grid>

            <Grid item md={4}>
              <TextField
                fullWidth
                margin='dense'
                id='address'
                label='Address'
                variant='standard'
                value={inputFields.address}
                onChange={onChangeAddress}
              />
            </Grid>
          </Grid>
          <Grid container spacing={5} padding={5}>
            <Grid item md={4}>
              <TextField
                fullWidth
                type='number'
                margin='dense'
                id='latitude'
                label='Latitude'
                variant='standard'
                value={inputFields.latitude === 0 ? "" : inputFields.latitude}
                onChange={onChangeLatitude}
              />
            </Grid>

            <Grid item md={4}>
              <TextField
                fullWidth
                type='number'
                margin='dense'
                id='longitude'
                label='Longitude'
                variant='standard'
                value={inputFields.longitude === 0 ? "" : inputFields.longitude}
                onChange={onChangeLongitude}
              />
            </Grid>
          </Grid>
          <Grid container spacing={5} padding={5}>
            <Grid item md={4}>
              <TextField
                select
                fullWidth
                label='Site Type'
                value={inputFields.site_type}
                onChange={onChangeSiteType}
              >
                {siteTypes &&
                  siteTypes.length > 0 &&
                  siteTypes.map((type) => (
                    <MenuItem key={type.id} value={type.site_type}>
                      {type.site_type}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCancel}>Cancel</Button>
          <Button
            onClick={handleCloseSavePost}
            disabled={isFormReadyToBeSubmitted()}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ManageSites;
