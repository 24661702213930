import React, { useEffect, useState, useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";
import withStyles from "@mui/styles/withStyles";
import { compose } from "redux";
import { connect } from "react-redux";
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableSortLabel,
  IconButton,
  Collapse,
  Popper,
  Button,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import DownloadIcon from "@mui/icons-material/GetApp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DeleteIcon from "@mui/icons-material/Delete";
import { ClickAwayListener } from "@mui/material";
import { UserRoleContext } from "../../contexts/user-role.context";

const useStyles = (theme) => ({
  tableContainer: {
    width: "100%",
    height: "100%",
    zIndex: 0,
  },
  tableHeaderRow: {
    height: "80px",
  },
  tableHeaderCell: {
    backgroundColor: "#FFF",
  },
  tableHeaderCellLabel: {
    fontWeight: "bold",
    color: "unset",
    "&:hover": {
      color: "#018ABE",
    },
  },
  tableHeaderCellLabelActive: {
    fontWeight: "bold",
    color: "#018ABE",
  },
  tableHeaderCellIcon: {
    opacity: 1,
  },
  tableHeaderCellIconActive: {
    color: "#018ABE !important",
    opacity: 1,
  },
  tableBody: {},
  tableBodyRow: {
    height: "80px",
    "&:hover": {
      background: "rgba(236, 236, 237, 0.5) !important",
    },
    "& td:nth-of-type(4)": { "& > .sys-span": { display: "none" } },
  },
  tableBodyCell: {},
  collapseButtonCell: {},
  collapseBox: {
    width: "100%",
    padding: "24px 15px",
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
  },
  dropButton: {
    height: "32px",
    width: "100%",
    padding: "10px 20px",
    background: "white",
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: "0",
    color: "#000000",
    textTransform: "none",
    display: "flex",
    flexDirection: "row",
    alignItems: "left",
    justifyContent: "left",
    "&:hover": {
      backgroundColor: "#E8EFF9",
      cursor: "pointer",
      outline: "none",
    },
    "&:focus": {
      outline: "none",
    },
    "&:active": {
      outline: "none",
    },
  },
});

const TableBox = ({
  classes,
  downloadFileFromList,
  isMainTable,
  isExpandable,
  tableInfos: { tableHeaders, tableRows },
  deleteSiteSimulationsHistoryRow,
  duplicateSiteSimulationsHistoryRow,
  promoteSiteSimulationsHistoryRow,
  draftSiteSimulationsHistoryRow,
  isSimulationsHistory,
  handleSelectedRowUUID,
  resetSimulationsStepper,
  deleteFileFromList,
}) => {
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState(0);
  const [isExpanded, setIsExpanded] = useState([]);
  const [openedDots, setOpenedDots] = useState([]);
  const [anchorEl, setAnchorEl] = useState([]);

  const { hasWritePermission } = useContext(UserRoleContext);
  let navigate = useNavigate();

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const sortTable = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const handleExpandedRow = (i) => {
    const newIsExpanded = [...isExpanded];
    newIsExpanded[i] = !newIsExpanded[i];
    setIsExpanded(newIsExpanded);
  };

  const handleClickDots = useCallback((event, i) => {
    const { currentTarget } = event;
    setAnchorEl({ ...anchorEl, [i]: currentTarget });
    setOpenedDots({ ...openedDots, [i]: !openedDots[i] });
  });

  const clickAwayHandler = useCallback(() => {
    if (anchorEl && openedDots) {
      setOpenedDots([]);
      setAnchorEl([]);
    } else {
      return;
    }
  }, []);

  useEffect(() => {
    if (isExpandable) {
      const rows = [];
      tableRows.map((row) => rows.push(false));
      setIsExpanded(rows);
    }
  }, []);

  return (
    <TableContainer component={Paper} className={classes.tableContainer}>
      <Table stickyHeader>
        <TableHead>
          <TableRow className={classes.tableHeaderRow}>
            {tableHeaders.map((el, i) => (
              <TableCell
                className={classes.tableHeaderCell}
                key={`table-header-${el.key}`}
              >
                {el.key !== "download" && el.key !== "dots" ? (
                  <TableSortLabel
                    IconComponent={ArrowDropDownIcon}
                    direction={orderBy === i ? order : "asc"}
                    onClick={() => {
                      setOrder(
                        orderBy !== i
                          ? "desc"
                          : order === "asc"
                          ? "desc"
                          : "asc"
                      );
                      setOrderBy(i);
                    }}
                    classes={{
                      icon:
                        orderBy === i
                          ? classes.tableHeaderCellIconActive
                          : classes.tableHeaderCellIcon,
                      active: classes.tableHeaderSortLabel,
                    }}
                  >
                    <span
                      className={
                        orderBy === i
                          ? classes.tableHeaderCellLabelActive
                          : classes.tableHeaderCellLabel
                      }
                    >
                      {el.name}
                    </span>
                  </TableSortLabel>
                ) : (
                  <span></span>
                )}
              </TableCell>
            ))}
            {isExpandable && (
              <TableCell
                style={{ backgroundColor: "transparent", width: "26px" }}
              />
            )}
          </TableRow>
        </TableHead>

        <TableBody className={classes.tableBody}>
          {tableRows && tableRows.length > 0 ? (
            sortTable(tableRows, getComparator(order, orderBy)).map(
              (row, i) => (
                <React.Fragment key={`table-body-row-${i}`}>
                  <TableRow className={classes.tableBodyRow}>
                    {row.map((cell, j) => (
                      <TableCell
                        className={classes.tableBodyCell}
                        key={`table-body-cell-${i}-${j}`}
                        align={
                          cell !== "download" && cell !== "dots"
                            ? "left"
                            : "right"
                        }
                        onClick={() => {
                          if (isMainTable) navigate(`/cem/site/${row[0]}/info`);
                          if (isSimulationsHistory && cell !== "dots") {
                            if (
                              row[5] === "Terminata" ||
                              row[5] === "Attuale" ||
                              row[5] === "Storicizzata" ||
                              row[5] === "In Errore"
                            ) {
                              setOpenedDots([]);
                              handleSelectedRowUUID(row[0], "results");
                            }

                            if (row[5] === "Creata") {
                              setOpenedDots([]);
                              resetSimulationsStepper();
                              handleSelectedRowUUID(row[0], "params");
                            }

                            if (row[5] === "In Corso" || row[5] === "Inviata") {
                              setOpenedDots([]);
                              resetSimulationsStepper();
                              handleSelectedRowUUID(row[0], "simulation");
                            }
                          }
                        }}
                      >
                        {cell !== "download" &&
                          cell !== "dots" &&
                          typeof cell == "object" &&
                          Array.isArray(row[row.length - 2]) &&
                          row[row.length - 2].map((el, i) => {
                            if (el !== undefined)
                              return (
                                <React.Fragment key={i}>
                                  <span className='sys-span'>{`${el} `}</span>
                                </React.Fragment>
                              );
                          })}
                        {cell !== "download" &&
                          cell !== "dots" &&
                          typeof cell !== "object" && <span>{cell}</span>}
                        {cell === "download" && (
                          <>
                            <IconButton
                              color='primary'
                              style={{ marginRight: "5px" }}
                              onClick={() => deleteFileFromList(row[0])}
                              size='large'
                              disabled={!hasWritePermission}
                            >
                              <DeleteIcon />
                            </IconButton>
                            <IconButton
                              color='primary'
                              onClick={() => downloadFileFromList(row[0])}
                              size='large'
                            >
                              <DownloadIcon />
                            </IconButton>
                          </>
                        )}
                        {cell === "dots" && (
                          <>
                            <IconButton
                              color='primary'
                              onClick={(event) => handleClickDots(event, i)}
                              size='large'
                            >
                              <MoreHorizIcon />
                            </IconButton>
                            {openedDots[i] && (
                              <ClickAwayListener onClickAway={clickAwayHandler}>
                                <Popper
                                  id={"row-dots-actions"}
                                  open={openedDots[i] || false}
                                  anchorEl={anchorEl[i] || null}
                                >
                                  {row[5] !== "Attuale" && (
                                    <Button
                                      className={classes.dropButton}
                                      onClick={async () => {
                                        if (
                                          row[5] === "Terminata" ||
                                          row[5] === "Storicizzata" ||
                                          row[5] === "In Errore"
                                        ) {
                                          await handleSelectedRowUUID(
                                            row[0],
                                            "row-delete"
                                          );
                                        }
                                        await deleteSiteSimulationsHistoryRow(
                                          row[0]
                                        );
                                        setOpenedDots([]);
                                        setAnchorEl(null);
                                      }}
                                      disabled={!hasWritePermission}
                                    >
                                      Delete
                                    </Button>
                                  )}
                                  <Button
                                    className={classes.dropButton}
                                    onClick={() => {
                                      duplicateSiteSimulationsHistoryRow(
                                        row[0]
                                      );
                                      setOpenedDots([]);
                                      setAnchorEl(null);
                                    }}
                                    disabled={!hasWritePermission}
                                  >
                                    Duplicate
                                  </Button>
                                  <Button
                                    className={classes.dropButton}
                                    onClick={async () => {
                                      await promoteSiteSimulationsHistoryRow(
                                        row[0]
                                      ).then(() => {
                                        setOpenedDots([]);
                                        setAnchorEl(null);
                                        window.location.reload();
                                      });
                                    }}
                                    disabled={!hasWritePermission}
                                  >
                                    Promote to "On Air"
                                  </Button>
                                  {row[5] === "Terminata" && (
                                    <Button
                                      className={classes.dropButton}
                                      onClick={() => {
                                        draftSiteSimulationsHistoryRow(row[0]);
                                        setOpenedDots([]);
                                        setAnchorEl(null);
                                      }}
                                      disabled={!hasWritePermission}
                                    >
                                      Revert to draft
                                    </Button>
                                  )}
                                </Popper>
                              </ClickAwayListener>
                            )}
                          </>
                        )}
                        {}
                      </TableCell>
                    ))}

                    {isExpandable && (
                      <TableCell className={classes.collapseButtonCell}>
                        <IconButton
                          size='small'
                          onClick={() => handleExpandedRow(i)}
                          style={{ color: "#018ABE" }}
                        >
                          {isExpanded[i] ? (
                            <KeyboardArrowUpIcon />
                          ) : (
                            <KeyboardArrowDownIcon />
                          )}
                        </IconButton>
                      </TableCell>
                    )}
                  </TableRow>
                  {isExpandable && (
                    <Collapse
                      className={classes.collapseBox}
                      in={isExpanded[i]}
                      timeout='auto'
                      unmountOnExit
                    >
                      <table cellPadding='5'>
                        <thead>
                          <tr>
                            <td>Sector</td>
                            <td>Power</td>
                            <td>Azimuth</td>
                          </tr>
                        </thead>
                        <tbody>
                          {Array.isArray(row[row.length - 1]) &&
                            row[row.length - 1].map((e) => {
                              return (
                                <tr key={e.sector}>
                                  <td>{e.sector}</td>
                                  <td>{e.power}W</td>
                                  <td>{e.azimuth}&deg;</td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </Collapse>
                  )}
                </React.Fragment>
              )
            )
          ) : (
            <TableRow className={classes.tableBodyRow}>
              <TableCell
                className={classes.tableBodyCell}
                style={{ border: "none" }}
              >
                No results available...
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const mapState = () => ({});

const mapDispatch = ({
  site: {
    downloadFileFromList,
    deleteSiteSimulationsHistoryRow,
    duplicateSiteSimulationsHistoryRow,
    promoteSiteSimulationsHistoryRow,
    draftSiteSimulationsHistoryRow,
    deleteFileFromList,
  },
  simulationsStepper: { resetSimulationsStepper },
}) => ({
  downloadFileFromList: (fileName) => downloadFileFromList({ fileName }),
  deleteSiteSimulationsHistoryRow: (evalId) =>
    deleteSiteSimulationsHistoryRow({ evalId }),
  duplicateSiteSimulationsHistoryRow: (evalId) =>
    duplicateSiteSimulationsHistoryRow({ evalId }),
  promoteSiteSimulationsHistoryRow: (evalId) =>
    promoteSiteSimulationsHistoryRow({ evalId }),
  draftSiteSimulationsHistoryRow: (evalId) =>
    draftSiteSimulationsHistoryRow({ evalId }),
  resetSimulationsStepper: () => resetSimulationsStepper(),
  deleteFileFromList: (fileName) => deleteFileFromList({ fileName }),
});

export default compose(
  withStyles(useStyles),
  connect(mapState, mapDispatch)
)(TableBox);
