import React, { useState, useContext } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { compose } from "redux";

import withStyles from "@mui/styles/withStyles";
import { Button, Popper } from "@mui/material";
import ArrowDropDownIcon from "../../assets/img/arrow-drop-down.svg";
import SiteInfoModifyModal from "../../components/SiteInfoModifyModal";
import SiteAddOnMapPage from "../../components/SiteAddOnMapPage";
import SiteInformationBox from "../../components/SiteInformationBox/SiteInformationBox";

import { UserRoleContext } from "../../contexts/user-role.context";

import { POST_SITE_EXCEL_FILE, tokenExpiredDoLogin } from "../../utils/api";

const useStyles = (theme) => ({
  buttonsContainer: {
    display: "flex",
    padding: "20px 0",
  },
  button: {
    padding: "0.3em 1.7em",
    background: "white",
    border: "0.5px solid #018ABE",
    borderRadius: "100px",
    font: "700 13px/20px",
    color: "#018ABE",
    marginLeft: "13px",
    textTransform: "none",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    minWidth: "64px",
    "&:hover": {
      backgroundColor: "white",
      cursor: "pointer",
      outline: "none",
    },
    "&:focus": {
      outline: "none",
    },
    "&:active": {
      outline: "none",
    },
  },
  dropButton: {
    height: "32px",
    width: "100%",
    padding: "10px 20px",
    background: "white",
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: "0",
    color: "#000000",
    textTransform: "none",
    display: "flex",
    flexDirection: "row",
    alignItems: "left",
    justifyContent: "left",
    "&:hover": {
      backgroundColor: "#E8EFF9",
      cursor: "pointer",
      outline: "none",
    },
    "&:focus": {
      outline: "none",
    },
    "&:active": {
      outline: "none",
    },
  },
  infosContainer: {
    display: "flex",
    width: "100%",
    height: "calc(100vh - 330px)",
  },
  infosBox: {
    backgroundColor: "#ECECED",
    marginRight: "7px",
    width: "calc(30% - 7px)",
    height: "100%",
    padding: "17px 25px",
    overflow: "auto",
  },
  infosRow: {
    display: "flex",
    width: "100%",
    marginBottom: "15px",
  },
  infosLocationTitle: {
    color: "#018ABE",
    fontSize: "17px",
    fontWeight: "bold",
    marginBottom: "24px",
  },
  infosSimulationInfoTitle: {
    color: "#018ABE",
    fontSize: "17px",
    fontWeight: "bold",
    margin: "40px 0 24px 0",
  },
  infosKey: {
    color: "#9CA1A9",
    fontSize: "14px",
    fontWeight: "bold",
    width: "35%",
  },
  infosValue: {
    color: "#000000",
    fontSize: "14px",
    fontWeight: "bold",
    width: "65%",
  },
  mapBox: {
    border: "1px solid #ECECED",
    marginLeft: "7px",
    width: "calc(70% - 7px)",
    height: "105%",
  },
});

const Info = ({
  classes,
  siteInfos,
  siteCenter,
  loadingSiteInfos,
  lastSimulationDate,
  lastSimulationStatus,
  simulationsCount,
  downloadMeasurePoints,
  downloadEmptyMeasurePoints,
  loader,
  setApiMessages,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDropdown, setOpenDropDown] = useState(false);
  const [openModifyModal, setOpenModifyModal] = useState(false);
  const [openAddOnMapPage, setOpenAddOnMapPage] = useState(false);
  const [addType, setAddType] = useState("");

  const { hasWritePermission } = useContext(UserRoleContext);

  const handleDropdown = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setOpenDropDown(!openDropdown);
  };

  // const uploadShapeFile = async ({ target }) => {
  //   loader(true)

  //   const siteBaseFolder = siteInfos.base_folder
  //   const file = target.files[0]
  //   const formData = new FormData()
  //   formData.append('file', file)

  //   await axios({
  //     method: 'POST',
  //     url: POST_SITE_SHAPEFILE + siteBaseFolder,
  //     headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
  //     data: formData
  //   })
  //     .then(res => {
  //       document.getElementById('button-upload-shapefile').value = null
  //       loader(false)
  //     })
  //     .catch(err => {
  //       console.log(`Error ${POST_SITE_SHAPEFILE}`, err)
  //       tokenExpiredDoLogin(err)
  //       document.getElementById('button-upload-shapefile').value = null
  //       loader(false)
  //     })

  //   loader(true)

  //   await axios({
  //     method: 'PUT',
  //     url: PUT_SITE_SHAPEFILE,
  //     headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
  //     data: {
  //       id: siteInfos.id,
  //       shape_filename: file.name
  //     }
  //   })
  //     .then((res) => loader(false))
  //     .catch((err) => {
  //       console.log(`Error ${PUT_SITE_SHAPEFILE}`, err)
  //       tokenExpiredDoLogin(err)
  //       loader(false)
  //     })
  // }

  const uploadExcelFile = async ({ target }) => {
    loader(true);
    setApiMessages({});

    const file = target.files[0];
    const formData = new FormData();
    formData.append("formFile", file);

    try {
      const response = await axios({
        method: "POST",
        url: `${POST_SITE_EXCEL_FILE}?id_site=${siteInfos.id}`,
        headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        data: formData,
      });
      if (response?.data.result === 0) {
        setApiMessages({
          status: "warning",
          message: response.data.message,
        });
      } else {
        setApiMessages({
          status: "success",
          message: "Excel file imported successfully",
        });
      }
    } catch (err) {
      console.log(`Error ${POST_SITE_EXCEL_FILE}`, err);
      tokenExpiredDoLogin(err);
    } finally {
      document.getElementById("button-upload-excel-file").value = null;
      loader(false);
    }
  };

  return (
    <div className={classes.tabInfoContainer}>
      <div className={classes.buttonsContainer}>
        <div
          style={{ display: "flex", justifyContent: "flex-end", width: "32%" }}
        >
          <Button
            className={classes.button}
            onClick={() => setOpenModifyModal(true)}
            disabled={!hasWritePermission}
          >
            Modify data
          </Button>
          <Button
            className={classes.button}
            onClick={() =>
              window.open(
                `https://www.google.it/maps/search/?api=1&query=${siteCenter[0]},${siteCenter[1]}`,
                "_blank"
              )
            }
          >
            View on Google Maps
          </Button>
        </div>
        <div
          style={{ display: "flex", justifyContent: "flex-end", width: "70%" }}
        >
          {/* <input
            hidden
            id="button-upload-shapefile"
            onChange={uploadShapeFile}
            type="file"
          />
          <label htmlFor="button-upload-shapefile">
            <Button className={classes.button} component="span">
              Import shape file
            </Button>
          </label> */}
          <Button
            className={classes.button}
            onClick={() => {
              setOpenAddOnMapPage(true);
              setAddType("shapefile");
            }}
          >
            Shapefile and DTM
          </Button>
          <Button
            className={classes.button}
            onClick={() => {
              setOpenAddOnMapPage(true);
              setAddType("poles");
            }}
          >
            Poles
          </Button>
          <Button
            className={classes.button}
            style={{ paddingRight: "18px" }}
            aria-describedby={"imports-dropdown"}
            onClick={handleDropdown}
          >
            Measure points
            <img src={ArrowDropDownIcon} alt='arrow drop down icon' />
          </Button>
          <Popper
            id={"imports-dropdown"}
            open={openDropdown}
            anchorEl={anchorEl}
          >
            <Button
              className={classes.dropButton}
              onClick={() => {
                setOpenAddOnMapPage(true);
                setAddType("measure_points");
              }}
            >
              Add manually
            </Button>
            <input
              hidden
              id='button-upload-excel-file'
              onChange={uploadExcelFile}
              type='file'
            />
            <label htmlFor='button-upload-excel-file'>
              <Button
                className={classes.dropButton}
                component='span'
                disabled={!hasWritePermission}
              >
                Import excel file
              </Button>
            </label>
            <Button
              className={classes.dropButton}
              onClick={() => downloadMeasurePoints()}
            >
              Download measure points
            </Button>
            <Button
              className={classes.dropButton}
              onClick={() => downloadEmptyMeasurePoints()}
            >
              Download empty file
            </Button>
          </Popper>
        </div>
      </div>
      <SiteInformationBox
        classes={classes}
        siteInfos={siteInfos}
        loadingSiteInfos={loadingSiteInfos}
        lastSimulationDate={lastSimulationDate}
        lastSimulationStatus={lastSimulationStatus}
        simulationsCount={simulationsCount}
        siteCenter={siteCenter}
      />

      {siteInfos && openModifyModal && (
        <SiteInfoModifyModal
          openModifyModal={openModifyModal}
          setOpenModifyModal={setOpenModifyModal}
        />
      )}
      {siteInfos && openAddOnMapPage && (
        <SiteAddOnMapPage
          setOpenAddOnMapPage={setOpenAddOnMapPage}
          addType={addType}
          siteInfos={siteInfos}
        />
      )}
    </div>
  );
};

const mapState = ({ site }) => ({
  siteInfos: site.infos,
  siteCenter: site.center,
  loadingSiteInfos: site.loadingInfos,
  lastSimulationDate: site.lastSimulationDate,
  lastSimulationStatus: site.lastSimulationStatus,
  simulationsCount: site.simulationsCount,
});

const mapDispatch = ({
  site: {
    downloadMeasurePoints,
    downloadEmptyMeasurePoints,
    manualImportMeasurePoints,
    setApiMessages,
  },
  loader: { loader },
}) => ({
  downloadMeasurePoints: () => downloadMeasurePoints(),
  downloadEmptyMeasurePoints: () => downloadEmptyMeasurePoints(),
  manualImportMeasurePoints: () => manualImportMeasurePoints(),
  setApiMessages: (value) => setApiMessages(value),
  loader: (value) => loader(value),
});

export default compose(
  withStyles(useStyles),
  connect(mapState, mapDispatch)
)(Info);
