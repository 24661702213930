import React, { useState, useContext } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import withStyles from "@mui/styles/withStyles";
import { IconButton, TextField, Button } from "@mui/material";
import ArrowIcon from "../../assets/img/arrow-right.svg";
import CreateIcon from "@mui/icons-material/Create";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import docs from "../../assets/img/google-docs.svg";
import pie from "../../assets/img/pie-chart.svg";
import rotation from "../../assets/img/rotation.svg";
import file from "../../assets/img/file.svg";

import EM2D from "./simulationResume.EM2D";
import EM3D from "./simulationResume.EM3D";
import FinalConfiguration from "./simulationResume.finalConfiguration";
import DownloadFile from "./simulationResume.downloadFile";
import { UserRoleContext } from "../../contexts/user-role.context";

const useStyles = (theme) => ({
  simulationResumePageContainer: {
    height: "100vh",
    width: "100vw",
    zIndex: 998,
    backgroundColor: "#ffffff",
    position: "fixed",
    top: 0,
    left: 0,
  },
  simulationResumePageHeader: {
    padding: "30px 40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#FAFAFA",
  },
  titleBox: {
    display: "flex",
    flexDirection: "column",
    fontWeight: "bold",
  },
  mainTitle: {
    color: "#000000",
    fontSize: "22px",
  },
  subTitle: {
    marginTop: "5px",
    color: "#A5A5A6",
    fontSize: "13px",
  },
  backwardButton: {
    marginLeft: "20px",
    background: "#03B8FF 0% 0% no-repeat padding-box",
    borderRadius: "26px",
    opacity: 1,
    outline: "none",
    padding: "11px 30px",
    textDecoration: "none",
    font: "700 14px/19px",
    letterSpacing: 0,
    color: "#FFFFFF",
    border: "none",
    "&:hover": {
      outline: "none",
      cursor: "pointer",
      background: "#018ABE 0% 0% no-repeat padding-box",
    },
  },
  resumePageBody: {
    padding: "15px 40px",
  },
  result: {
    fontWeight: "bold",
    marginLeft: "5px",
  },
  resumePageBoxesWrapper: {
    height: "calc(100vh - 180px)",
    marginTop: "15px",
    display: "flex",
  },
  arrowIcon: {
    color: "#018ABE",
    border: "1px solid #018ABE",
    height: "18px",
    width: "22px",
    borderRadius: "50%",
    padding: "18px",
    position: "absolute",
    top: "calc(50% - 9px)",
    right: "30px",
    cursor: "pointer",
  },
  siteInfos: {
    width: "calc(33% - 5px)",
    marginRight: "5px",
    backgroundColor: "#ECECED",
    padding: "30px 25px",
    position: "relative",
  },
  infosTitle: {
    fontSize: "14px",
    fontWeight: "bold",
    color: "#018ABE",
  },
  infosBox: {
    margin: "15px 0",
  },
  infosRow: {
    display: "flex",
    width: "100%",
    marginBottom: "15px",
  },
  infosKey: {
    color: "#9CA1A9",
    fontSize: "14px",
    fontWeight: "bold",
    width: "45%",
  },
  infosValue: {
    color: "#000000",
    fontSize: "14px",
    fontWeight: "bold",
    width: "55%",
  },
  siteButtons: {
    height: "100%",
    width: "calc(67% - 5px)",
    marginLeft: "5px",
  },
  siteButtonsRow: {
    display: "flex",
    flexDirection: "row",
    height: "calc(50% - 2px)",
  },
  pageButton: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: "#ECECED",
    width: "calc(50% - 2px)",
    padding: "40px 30px",
    position: "relative",
  },
  buttonImg: {
    marginBottom: "-60px",
    width: "22px",
  },
  buttonTitle: {
    fontSize: "21px",
    fontWeight: "bold",
    color: "#018ABE",
    marginTop: "65px",
  },
  buttonSubTitle: {
    maxWidth: "70%",
  },
  descriptionTitleBox: {
    display: "flex",
    alignItems: "center",
    height: "24px",
    marginTop: "30px",
  },
  resumePageTypeContainer: {
    height: "100vh",
    width: "100vw",
    zIndex: 999,
    backgroundColor: "#ffffff",
    position: "fixed",
    top: 0,
    left: 0,
  },
  resumePageTypeHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "30px",
  },
  resumePageTypeTitle: {
    fontWeight: "bold",
    fontSize: "18px",
    marginLeft: "10px",
  },
  resumePageTypeSubtitle: {
    color: "#A5A5A6",
    fontSize: "13px",
    marginTop: "-25px",
    marginLeft: "65px",
    fontWeight: "bold",
  },
  errorLogBtn: {
    position: "absolute",
    width: "250px",
    bottom: "20px",
    left: "50%",
    transform: "translateX(-50%)",
  },
});

const SimulationResumePage = ({
  classes,
  selectedRow,
  setSimulationResumePageActive,
  siteInfos,
  putDescription,
  getSiteSimulationsHistory,
  downloadEvalSimulationLog,
}) => {
  const { id, result, actual, uuid, eval_type } = selectedRow;
  const [descriptionIsChanging, setDescriptionIsChanging] = useState(false);
  const [descriptionChanged, setDescriptionChanged] = useState(false);
  const [changingDescription, setChanginDescription] = useState(null);
  const [newDescription, setNewDescription] = useState(null);
  const [resumePageType, setResumePageType] = useState(null);
  const [resumePageTypeTitle, setResumePageTypeTitle] = useState("");

  const { hasWritePermission } = useContext(UserRoleContext);

  const handleNewDescriptionSave = async () => {
    if (changingDescription !== newDescription) {
      await putDescription(id, changingDescription);
      setNewDescription(changingDescription);
      setDescriptionChanged(true);
    }
    setDescriptionIsChanging(!descriptionIsChanging);
  };

  const handleResumePageType = (type) => {
    setResumePageType(type);

    if (type === "2dEM") setResumePageTypeTitle("2D EM ANALYSIS");
    if (type === "3dEM") setResumePageTypeTitle("3D EM ANALYSIS");
    if (type === "finalConfiguration")
      setResumePageTypeTitle("Final Configuration");
    if (type === "downloadFile") setResumePageTypeTitle("Download File");
    if (type === null) setResumePageTypeTitle("");
  };

  const normalizeEvalTypeString = (type) => {
    switch (type) {
      case "new_tech":
        return "Simulated Technology";
      case "massive":
        return "Massive";
      default:
        return "Standard";
    }
  };
  const isSimulatedTechnology = () => {
    if (eval_type === "new_tech") {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className={classes.simulationResumePageContainer}>
      <div className={classes.simulationResumePageHeader}>
        <div className={classes.titleBox}>
          <div className={classes.mainTitle}>Simulation C.E.M. {id}</div>
          <div className={classes.subTitle}>
            {`${siteInfos?.site_name} ${siteInfos?.site_code}`}
          </div>
        </div>

        <div className={classes.buttonsBox}>
          <button
            className={classes.backwardButton}
            onClick={() => {
              setSimulationResumePageActive(false);
              getSiteSimulationsHistory();
            }}
          >
            Go to simulations history
          </button>
        </div>
      </div>

      <div className={classes.resumePageBody}>
        <div>
          Result:{" "}
          <span
            className={classes.result}
            style={{ color: result === "Conforme" ? "#2F861B" : "unset" }}
          >
            {result}
          </span>
        </div>

        <div className={classes.resumePageBoxesWrapper}>
          <div className={classes.siteInfos}>
            <div
              className={classes.infosTitle}
              style={{ marginBottom: "10px" }}
            >
              LOCATION
            </div>
            <div className={classes.infosBox}>
              <div className={classes.infosRow}>
                <div className={classes.infosKey}>ID INWIT</div>
                <div className={classes.infosValue}>
                  {siteInfos?.site_code || "-"}
                </div>
              </div>
              <div className={classes.infosRow}>
                <div className={classes.infosKey}>Original Site Code</div>
                <div className={classes.infosValue}>
                  {siteInfos?.original_site_code || "-"}
                </div>
              </div>
              <div className={classes.infosRow}>
                <div className={classes.infosKey}>Site Name</div>
                <div className={classes.infosValue}>
                  {siteInfos?.site_name || "-"}
                </div>
              </div>
              <div className={classes.infosRow}>
                <div className={classes.infosKey}>Zone</div>
                <div className={classes.infosValue}>
                  {siteInfos?.zona || "-"}
                </div>
              </div>
              <div className={classes.infosRow}>
                <div className={classes.infosKey}>Province</div>
                <div className={classes.infosValue}>
                  {siteInfos?.provincia || "-"}
                </div>
              </div>
              <div className={classes.infosRow}>
                <div className={classes.infosKey}>Municipality</div>
                <div className={classes.infosValue}>
                  {siteInfos?.comune || "-"}
                </div>
              </div>
              <div className={classes.infosRow}>
                <div className={classes.infosKey}>Address</div>
                <div className={classes.infosValue}>
                  {siteInfos?.address || "-"}
                </div>
              </div>
              <div className={classes.infosRow}>
                <div className={classes.infosKey}>State</div>
                <div className={classes.infosValue}>{actual || "-"}</div>
              </div>
              <div className={classes.infosRow}>
                <div className={classes.infosKey}>Type</div>
                <div className={classes.infosValue}>
                  {normalizeEvalTypeString(eval_type)}
                </div>
              </div>
            </div>

            <div className={classes.descriptionTitleBox}>
              <div
                className={classes.infosTitle}
                style={{ marginRight: "20px" }}
              >
                DESCRIPTION
              </div>
              <IconButton
                style={{ padding: "0px" }}
                onClick={() => handleNewDescriptionSave()}
                size='large'
                disabled={!hasWritePermission}
              >
                <CreateIcon
                  style={hasWritePermission ? { color: "#018ABE" } : undefined}
                />
              </IconButton>
            </div>

            {descriptionIsChanging ? (
              <TextField
                multiline
                style={{ marginTop: "10px", width: "100%" }}
                onChange={(event) => setChanginDescription(event.target.value)}
                onKeyPress={(event) =>
                  event.charCode === 13 && handleNewDescriptionSave()
                }
                value={changingDescription || selectedRow.project_description}
              />
            ) : (
              <div style={{ marginTop: "15px" }}>
                {descriptionChanged
                  ? newDescription
                  : selectedRow.project_description}
              </div>
            )}
            {selectedRow && (
              <Button
                color='primary'
                variant='outlined'
                className={classes.errorLogBtn}
                onClick={() => downloadEvalSimulationLog(selectedRow.uuid)}
              >
                Download Simulation Log
              </Button>
            )}
          </div>

          <div className={classes.siteButtons}>
            <div
              className={classes.siteButtonsRow}
              style={{ marginBottom: "4px" }}
            >
              <div
                className={classes.pageButton}
                style={{ marginRight: "2px" }}
              >
                <img
                  className={classes.buttonImg}
                  src={pie}
                  alt='efesto logo'
                  width='25px'
                  height='25px'
                />
                <div className={classes.buttonTitle}>2D EM ANALYSIS</div>
                <div className={classes.buttonSubTitle}>
                  Initial and final top and side projection of antennas, EM
                  lobes, buildings and terrain.
                </div>
                <img
                  className={classes.arrowIcon}
                  src={ArrowIcon}
                  alt='arrow icon'
                  onClick={() => handleResumePageType("2dEM")}
                />
              </div>
              {isSimulatedTechnology() ? (
                <div
                  className={classes.pageButton}
                  style={{ marginLeft: "2px" }}
                >
                  <img
                    className={classes.buttonImg}
                    src={rotation}
                    alt='efesto logo'
                  />
                  <div className={classes.buttonTitle}>3D EM ANALYSIS</div>
                  <div className={classes.buttonSubTitle}>
                    No 3D EM Analysis available for this evaluation type.
                  </div>
                </div>
              ) : (
                <div
                  className={classes.pageButton}
                  style={{ marginLeft: "2px" }}
                >
                  <img
                    className={classes.buttonImg}
                    src={rotation}
                    alt='efesto logo'
                  />
                  <div className={classes.buttonTitle}>3D EM ANALYSIS</div>
                  <div className={classes.buttonSubTitle}>
                    Initial and final 3D rendering of antennas, EM lobes,
                    buildings and terrain.
                  </div>
                  <img
                    className={classes.arrowIcon}
                    src={ArrowIcon}
                    alt='arrow icon'
                    onClick={() => handleResumePageType("3dEM")}
                  />
                </div>
              )}
            </div>
            <div
              className={classes.siteButtonsRow}
              style={{ marginTop: "4px" }}
            >
              <div
                className={classes.pageButton}
                style={{ marginRight: "2px" }}
              >
                <img
                  className={classes.buttonImg}
                  src={docs}
                  alt='efesto logo'
                  width='22px'
                  height='22px'
                />
                <div className={classes.buttonTitle}>FINAL CONFIGURATION</div>
                <div className={classes.buttonSubTitle}>
                  Table of final site configuration.
                </div>
                <img
                  className={classes.arrowIcon}
                  src={ArrowIcon}
                  alt='arrow icon'
                  onClick={() => handleResumePageType("finalConfiguration")}
                />
              </div>
              <div className={classes.pageButton} style={{ marginLeft: "2px" }}>
                <img
                  className={classes.buttonImg}
                  src={file}
                  alt='efesto logo'
                  width='22px'
                  height='22px'
                />
                <div className={classes.buttonTitle}>DOWNLOAD FILE</div>
                <div className={classes.buttonSubTitle}>
                  Reports, 2D renderings, 3D renderings.
                </div>
                <img
                  className={classes.arrowIcon}
                  src={ArrowIcon}
                  alt='arrow icon'
                  onClick={() => handleResumePageType("downloadFile")}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {resumePageType && (
        <div className={classes.resumePageTypeContainer}>
          <div>
            <div className={classes.resumePageTypeHeader}>
              <IconButton
                onClick={() => handleResumePageType(null)}
                style={{ padding: 0 }}
                size='large'
              >
                <ArrowBackIcon />
              </IconButton>
              <div className={classes.resumePageTypeTitle}>
                {resumePageTypeTitle}
              </div>
            </div>

            <div
              className={classes.resumePageTypeSubtitle}
            >{`${siteInfos?.site_name} ${siteInfos?.site_code}`}</div>
          </div>

          {resumePageType === "2dEM" && (
            <EM2D uuid={uuid} evalType={eval_type} />
          )}
          {resumePageType === "3dEM" && <EM3D uuid={uuid} />}
          {resumePageType === "finalConfiguration" && (
            <FinalConfiguration uuid={uuid} />
          )}
          {resumePageType === "downloadFile" && <DownloadFile uuid={uuid} />}
        </div>
      )}
    </div>
  );
};

const mapState = ({ site }) => ({
  siteInfos: site.infos,
});

const mapDispatch = ({
  simulationsStepper: { putDescription },
  site: { getSiteSimulationsHistory },
  simulation: { downloadEvalSimulationLog },
}) => ({
  putDescription: (id, newDescription) =>
    putDescription({ id, newDescription }),
  getSiteSimulationsHistory: () => getSiteSimulationsHistory(),
  downloadEvalSimulationLog: (uuid) =>
    downloadEvalSimulationLog({ selectedRowUUID: uuid }),
});

export default compose(
  withStyles(useStyles),
  connect(mapState, mapDispatch)
)(SimulationResumePage);
