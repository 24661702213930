import React, { useState, useEffect } from "react";
import withStyles from "@mui/styles/withStyles";
import { connect } from "react-redux";
import { compose } from "redux";
import { Button, ButtonGroup } from "@mui/material";
import { Replay } from "@mui/icons-material";
import { mainSearchBoxOptionsList } from "../../utils/searchBoxOptions";
import { mainTableHeaders } from "../../utils/tableHeaders";
import SearchBox from "../../components/SearchBox";
import MainMapBox from "../../components/MainMapBox";
import MapOfficeDetail from "../../components/MapOfficeDetail";
import TableBox from "../../components/TableBox";
import { v4 as uuidv4 } from "uuid";

const useStyles = (theme) => ({
  landingPage: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  actionsContainer: {
    position: "relative",
    display: "flex",
    alignItems: "center",
  },
  buttonGroup: {
    marginRight: "20vw",
  },
  mapButton: {
    height: "32px",
    padding: "3px 14px 3px 30px",
    background: "white",
    border: "0.5px solid #018ABE",
    borderRadius: "100px",
    font: "700 13px/20px",
    color: "#018ABE",
    marginLeft: "13px",
    textTransform: "none",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    minWidth: "64px",
    "&:hover": {
      backgroundColor: "white",
      cursor: "pointer",
      outline: "none",
    },
    "&:focus": {
      outline: "none",
    },
    "&:active": {
      outline: "none",
    },
  },
  listButton: {
    height: "32px",
    padding: "3px 30px 3px 14px",
    background: "white",
    border: "0.5px solid #018ABE",
    borderRadius: "100px",
    font: "700 13px/20px",
    color: "#018ABE",
    marginLeft: "13px",
    textTransform: "none",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      backgroundColor: "white",
      cursor: "pointer",
      outline: "none",
    },
    "&:focus": {
      outline: "none",
    },
    "&:active": {
      outline: "none",
    },
  },
  openNewButtonContainer: {
    padding: "10px 25px",
    position: "absolute",
    right: 0,
    marginTop: "10px",
    top: 0,
  },
  openNewButton: {
    right: 0,
    background: "#03B8FF 0 0 no-repeat padding-box",
    borderRadius: "26px",
    opacity: 1,
    font: "700 14px/22px",
    letterSpacing: 0,
    height: "40px",
    color: "#fff",
    border: "none",
    textTransform: "none",
    "&:hover": {
      background: "#018ABE 0 0 no-repeat padding-box",
    },
    padding: "0 30px",
  },
  tabContainer: {
    padding: "10px 25px",
  },
  mapContainer: {
    height: "calc(100vh - 170px)",
    position: "relative",
  },
  listContainer: {
    height: "calc(100vh - 170px)",
  },
  resetBtn: {
    color: "#fff",
    boxShadow: "none",
    textTransform: "none",
    fontSize: "1rem",
    borderRadius: "26px",
  },
});

const CEMLadingPage = ({
  classes,
  offices,
  selectedOffice,
  resetMapState,
  setGlobalFilterValue,
}) => {
  const [showTab, setShowTab] = useState("map");
  const [mapKey, setMapKey] = useState(uuidv4());

  const mainTableInfos = {
    tableHeaders: mainTableHeaders,
    tableRows: offices?.map((office) => [
      office.site_code,
      office.original_site_code,
      office.site_name,
      office.zona,
      office.regione,
      office.provincia,
      office.comune,
      office.address,
      office.id_status,
    ]),
  };

  // handler to force re renders of MainMapBox with a reset state
  const handleMapReset = () => {
    const newKey = uuidv4();
    setGlobalFilterValue(null);
    resetMapState();
    setMapKey(newKey);
  };

  useEffect(() => {
    console.log(selectedOffice);
  }, [selectedOffice]);

  return (
    <div className={classes.landingPage}>
      <div className={classes.actionsContainer}>
        <SearchBox options={mainSearchBoxOptionsList} />
        <Button
          variant='contained'
          color='primary'
          size='small'
          endIcon={<Replay />}
          className={classes.resetBtn}
          onClick={handleMapReset}
        >
          Clear results
        </Button>
        <ButtonGroup className={classes.buttonGroup}>
          <Button
            className={classes.mapButton}
            onClick={() => setShowTab("map")}
            style={{
              color: showTab === "map" ? "#fff" : "#03B8FF",
              backgroundColor: showTab === "map" ? "#03B8FF" : "#fff",
            }}
          >
            Map
          </Button>
          <Button
            className={classes.listButton}
            onClick={() => setShowTab("list")}
            style={{
              color: showTab === "list" ? "#fff" : "#03B8FF",
              backgroundColor: showTab === "list" ? "#03B8FF" : "#fff",
            }}
          >
            List
          </Button>
        </ButtonGroup>
      </div>
      <div className={classes.tabContainer}>
        {showTab === "map" && (
          <div className={classes.mapContainer}>
            <MainMapBox resetMassives={true} key={mapKey} />
            {selectedOffice && (
              <MapOfficeDetail selectedOffice={selectedOffice} />
            )}
          </div>
        )}

        {showTab === "list" && (
          <div className={classes.listContainer}>
            <TableBox tableInfos={mainTableInfos} isMainTable />
          </div>
        )}
      </div>
    </div>
  );
};

const mapState = ({ map }) => ({
  offices: map.offices,
  selectedOffice: map.selectedOffice,
});

const mapDispatch = ({
  map: { resetMapState },
  searchBox: { setGlobalFilterValue },
}) => ({
  resetMapState: () => resetMapState(),
  setGlobalFilterValue: (value) => setGlobalFilterValue(value),
});

export default compose(
  withStyles(useStyles),
  connect(mapState, mapDispatch)
)(CEMLadingPage);
