/* eslint-disable */
import { useEffect, useState, useContext } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import withStyles from "@mui/styles/withStyles";
import { Dialog, IconButton, TextField } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { Autocomplete } from "@mui/material";
import { UserRoleContext } from "../../contexts/user-role.context";

const useStyles = (theme) => ({
  modalContainer: {
    padding: "25px 40px",
  },
  modalHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  modalTitle: {
    color: "#018ABE",
    fontWeight: "bold",
    fontSize: "22px",
  },
  modalCloseIcon: {
    color: "#000000",
  },
  firstRow: {
    marginTop: "45px",
    display: "flex",
    backgroundColor: "#ECECED",
    padding: "15px",
    justifyContent: "space-between",
  },
  secondRow: {
    marginTop: "5px",
    marginBottom: "25px",
    display: "flex",
    backgroundColor: "#ECECED",
    justifyContent: "space-between",
    overflowX: "auto",
  },
  infoBox: {
    padding: "0 15px",
  },
  techBox: {
    padding: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    fontWeight: "bold",
  },
  techButton: {
    padding: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    fontWeight: "bold",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#FFFFFF",
    },
  },
  techButtonActive: {
    padding: "10px",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#ffffff",
    alignItems: "center",
    fontWeight: "bold",
  },
  bandsInfosContainer: {
    marginTop: "30px",
    display: "flex",
  },
  bandsInfosLeft: {
    width: "50%",
  },
  bandsInfosRight: {
    width: "50%",
  },
  optionInput: {
    background: "#fff",
    font: "400 13px/18px",
    letterSpacing: "0",
    color: "#000000",
    opacity: "0.7",
    width: "38%",
    marginRight: "20px",
  },
  modalButtonsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "50px",
  },
  modalCloseButton: {
    background: "#C9CAC9 0% 0% no-repeat padding-box",
    borderRadius: "26px",
    opacity: 1,
    outline: "none",
    padding: "11px 30px",
    textDecoration: "none",
    font: "700 14px/19px",
    letterSpacing: 0,
    color: "#FFFFFF",
    border: "none",
    "&:hover": {
      outline: "none",
      cursor: "pointer",
      background: "#A5A5A6 0% 0% no-repeat padding-box",
    },
  },
  modalSaveButton: {
    marginLeft: "20px",
    background: "#03B8FF 0% 0% no-repeat padding-box",
    borderRadius: "26px",
    opacity: 1,
    outline: "none",
    padding: "11px 30px",
    textDecoration: "none",
    font: "700 14px/19px",
    letterSpacing: 0,
    color: "#FFFFFF",
    border: "none",
    "&:hover": {
      outline: "none",
      cursor: "pointer",
      background: "#018ABE 0% 0% no-repeat padding-box",
    },
    "&:disabled": {
      color: "rgba(0, 0, 0, 0.26)",
      background: "white",
      border: "0.5px solid #018ABE",
      cursor: "unset",
    },
  },
});

const SiteParamsModal = ({
  classes,
  technologiesList,
  openParamsModal,
  setOpenParamsModal,
  activeTenantRow,
  setActiveTenantRow,
  paramsModalTypeIsModify,
  getPortsList,
  portsList,
  originalSectors,
  putModifySaveEval,
  evalSelected,
  resetModelAndTenant,
  setSiteParameters,
  getParameters,
  isFinalConfig,
  putConstraintsDefault,
}) => {
  const { hasWritePermission } = useContext(UserRoleContext);
  const { sector, model,poleID, azimuth, hbaSuolo, downtiltMeccanico, tenant } =
    activeTenantRow;
  const [activeBandIndex, setActiveBandIndex] = useState(
    tenant.values.findIndex((el) => el.values)
  );

  const [newTechnology, setNewTechnology] = useState(null);
  const [sectors, setSectors] = useState(originalSectors);
  const [rowInputs, setRowInputs] = useState({
    sector: null,
    poleid: null,
    azimuth: null,
    hba: null,
    mecdown: null,
  });
  const activeBandName = tenant.values[activeBandIndex].key;

  const headers = [
    { name: "Sector/cell", value: sector.name },
    { name: "Model", value: model.name },
    { name: "Pole ID", value: poleID.name},
    { name: "Azimuth", value: azimuth.name },
    { name: "HBA", value: hbaSuolo.name },
    { name: "Mechanical tilt", value: downtiltMeccanico.name },
    { name: "Tenant", value: tenant.name },
  ];

  const techInfos = technologiesList.map((tech, i) => {
    const infos = tenant.values[i].values || null;

    return {
      name: tech.name,
      value: tenant.values[i].name,
      infos: infos,
    };
  });

  const infosBox = techInfos.map((tech, i) => {
    let infosLeft = [];
    let infosRight = [];

    if (tech.value !== "") {
      const {
        nTrx,
        potenzaTRXApparato,
        potenzaTotaleApparatoW,
        potenzaTotaleApparatodBm,
        perdite,
        fattoreRiduzione,
        alfa24,
        potenzaTotAntennadBmNoriduzione,
        potenzaTotAntennaWNoriduzione,
        potenzaTotAntennaRiduzione,
        tiltElettricoMax,
        ktdd,
        kpr
      } = tech?.infos?.arpaParameters || 0;

      infosLeft = [
        {
          key: "port",
          name: "Port Number",
          value: "",
          isEditable: hasWritePermission,
          options: portsList,
        },
        {
          key: "A",
          name: "N.Trx",
          value: Number(nTrx) || "",
          isEditable: hasWritePermission,
          mandatory: !isFinalConfig,
        },
        {
          key: "B",
          name: "Power per TRX at equipment (W)",
          value: Number(potenzaTRXApparato).toFixed(2) || "",
          isEditable: hasWritePermission,
        },
        {
          key: "C",
          name: "Total power at equipment (W)",
          value: Number(potenzaTotaleApparatoW).toFixed(2) || "",
          isEditable: false,
        },
        {
          key: "D",
          name: "Total power at equipment (dBm)",
          value: Number(potenzaTotaleApparatodBm).toFixed(2) || "",
          isEditable: false,
        },
        {
          key: "E",
          name: "Losses (dB)",
          value: Number(perdite).toFixed(2) || "",
          isEditable: hasWritePermission,
        },
      ];

      infosRight = [
        {
          key: "H",
          name: "Total power at antenna (dBm) with losses",
          value: Number(potenzaTotAntennadBmNoriduzione).toFixed(2) || "",
          isEditable: false,
        },
        {
          key: "I",
          name: "Total power at antenna (W) with losses" || "",
          value: potenzaTotAntennaWNoriduzione,
          isEditable: false,
        },
        {
          key: "F",
          name: "Reduction factor",
          value: fattoreRiduzione || null,
          isEditable: hasWritePermission,
          options: tech.name.startsWith("GSM")
            ? ["NO", "DTX/PC"]
            : tech.name.startsWith("5G") || tech.name.startsWith("FWA")
              ?["NO", "Alfa 24", "K_TDD/K_PR"]
              :["NO", "Alfa 24"],
        },
        {
          key: "G",
          name: "Alfa 24",
          value:
            fattoreRiduzione === "NO" || fattoreRiduzione === "DTX/PC" || fattoreRiduzione === "K_TDD/K_PR" 
              ? 1
              : Number(alfa24).toFixed(2) || "",
          isEditable:
            fattoreRiduzione === "NO" || fattoreRiduzione === "DTX/PC" || fattoreRiduzione === "K_TDD/K_PR" 
              ? false
              : hasWritePermission,
        },

        {
          key: "J",
          name: "Total power at antenna (W) with losses and reduction",
          value: Number(potenzaTotAntennaRiduzione).toFixed(2) || "",
          isEditable: false,
        },
        {
          key: "K",
          name: "Electrical Tilt",
          value: Number(tiltElettricoMax).toFixed(0) || "",
          isEditable: hasWritePermission,
          mandatory: !isFinalConfig,
        },        {
          key: "L",
          name: "K_TDD",
          value:
            fattoreRiduzione === "NO" || fattoreRiduzione === "Alfa 24" 
              ? 1
              : Number(ktdd).toFixed(2) || "",
          isEditable:
            fattoreRiduzione === "NO" || fattoreRiduzione === "Alfa 24"
              ? false
              : hasWritePermission
        },
        {
          key: "M",
          name: "K_PR",
          value:
            fattoreRiduzione === "NO" || fattoreRiduzione === "Alfa 24" 
              ? 1
              : Number(kpr).toFixed(2) || "",
          isEditable:
            fattoreRiduzione === "NO" || fattoreRiduzione === "Alfa 24"
              ? false
              : hasWritePermission
        },

        // {
        //   key: 'L',
        //   name: 'Mechanical tilt Max',
        //   value:
        //     sectors
        //       .find(
        //         (el) => el.sector === sector.name && el.azimuth === azimuth.name
        //       )
        //       .antennas.find((el) => el.model === model.name).arpaParameters
        //       .tiltMeccanicoMax || '',
        //   isEditable: true,
        // },
      ];
    }

    return [infosLeft, infosRight];
  });

  const [info, setInfo] = useState(infosBox);

  const changeInfos = (activeBandIndex, side, index, value) => {
    //activeBandIndex -> posizione della tecnologia
    //side -> colonna sinistra = 0; colonna destra = 1
    //index -> campo i-esimo di un lato
    //value -> valore del campo
    const newInfo = [];
    info.map((band, indexBand) => {
      if (indexBand === activeBandIndex) {
        const newBand = [];
        band.map((sideBand, indexSide) => {
          if (indexSide === side) {
            const newSide = [];
            sideBand.map((field, indexField) => {
              if (index === indexField) {
                newSide.push({ ...field, value: value });
              } else {
                newSide.push(field);
              }
            });
            newBand.push(newSide);
          } else {
            newBand.push(sideBand);
          }
        });
        newInfo.push(newBand);
      } else {
        newInfo.push(band);
      }
    });

    if (index === 2) {
      const alfa = newInfo[activeBandIndex][side][index + 1];
      const kpr = newInfo[activeBandIndex][side][index + 5];
      const ktdd = newInfo[activeBandIndex][side][index + 4];
      
      alfa.value = value === "NO" || value === "K_TDD/K_PR"? 1 : alfa.value;
      alfa.isEditable = value === "NO" || value === "DTX/PC"|| value === "K_TDD/K_PR" ? false : true;

      if(ktdd){
        ktdd.value = value === "NO" || value === "Alfa 24"? 1 : ktdd.value;
        ktdd.isEditable = value === "NO" || value === "Alfa 24" ? false : true;}

      if(kpr){
        kpr.value = value === "NO" || value === "Alfa 24"? 1 : kpr.value;
        kpr.isEditable = value === "NO" || value === "Alfa 24" ? false : true;}
    }



    setInfo(newInfo);

    // PER IL Mechanical tilt
    // if (side === 1 && index === 6) {
    //   const sectorIndex = sectors.findIndex((el) => el.sector === sector.name)
    //   const antennaModelIndex = sectors
    //     .find((el) => el.sector === sector.name)
    //     .antennas.findIndex((el) => el.model === model.name)

    //   const newSectors = [...sectors]
    //   newSectors[sectorIndex].antennas[
    //     antennaModelIndex
    //   ].arpaParameters.tiltMeccanicoMax = value

    //   setSectors(newSectors)
    // }
  };

  const checkIsMandatoryField = (mandatory, value) => {
    if (!mandatory) return;
    if (mandatory && (value === "" || value === null || isNaN(value))) {
      return true;
    } else {
      return false;
    }
  };
  const limitInputRangeForTiltElettrico = (name) => {
    if (name !== "Electrical Tilt") {
      return;
    } else {
      return {
        min: 0,
      };
    }
  };

  const A = info[activeBandIndex][0][1]?.value || null; // side: 0, index : 1 - N.Trx
  const B = info[activeBandIndex][0][2]?.value || null; // side: 0, index : 2 - Power per TRX at equipment (W)
  const C = info[activeBandIndex][0][3]?.value || null; // side: 0, index : 3 - Total power at equipment (W)
  const D = info[activeBandIndex][0][4]?.value || null; // side: 0, index : 4 - Total power at equipment (dBm)
  const E = info[activeBandIndex][0][5]?.value || null; // side: 0, index : 5 - Perdite (dBm)
  const H = info[activeBandIndex][1][0]?.value || null; // side: 1, index : 0 - Total power at antenna (dBm) with losses
  const I = info[activeBandIndex][1][1]?.value || null; // side: 1, index : 1 - Potenza Tot. Antenna (W) con perdite
  const RID = info[activeBandIndex][1][2]?.value || null; // side: 1, index : 2 - Reduction factor
  const G = info[activeBandIndex][1][3]?.value || null; // side: 1, index : 3 - Alfa 24
  const J = info[activeBandIndex][1][4]?.value || null; // side: 1, index : 4 - Total power at antenna (W) with losses and reduction
  const K = info[activeBandIndex][1][5]?.value || null; // side: 1, index : 5 - Electrical Tilt
  const L = info[activeBandIndex][1][6]?.value || null; // side: 1, index : 6 - K_TDD
  const M = info[activeBandIndex][1][7]?.value || null; // side: 1, index : 7 - K_PR

  useEffect(() => {
    if (paramsModalTypeIsModify) {
      if (A && Number(A) !== 0 && B && Number(B) !== 0) {
        // B
        const newC = (Number(B) * Number(A)).toFixed(2);
        if (!C || newC !== C) changeInfos(activeBandIndex, 0, 3, newC);
      }
      if (C && Number(C) !== 0) {
        // D
        const newD = (10 * Math.log10(1000 * C)).toFixed(2);
        if (!D || newD !== D) changeInfos(activeBandIndex, 0, 4, newD);
      }
      if (D && Number(D) !== 0 && E) {
        // H
        const newH = (Number(D) - Number(E)).toFixed(2);
        if (!H || newH !== H) changeInfos(activeBandIndex, 1, 0, newH);
      }

      if (H && Number(H) !== 0) {
        // I
        const newI = (Math.pow(10, Number(H) / 10) / 1000).toFixed(2);
        if (!I || newI !== I) changeInfos(activeBandIndex, 1, 1, newI);
      }
      // if it is GSM
      // GSM activeBandIndex are -> 3 and 7
      // J = (I / A) * (1 + 0.49 * (A - 1))
      if (
        (activeBandName === "GSM900" || activeBandName === "GSM1800") &&
        I &&
        Number(I) !== 0 &&
        A &&
        Number(A) !== 0
      ) {
        const newJ = (
          (Number(I) / Number(A)) *
          (1 + 0.49 * (Number(A) - 1))
        ).toFixed(2);
        if (!J || newJ !== J) changeInfos(activeBandIndex, 1, 4, newJ);
      }
      // if it is not GSM
      // GSM activeBandIndex are -> 3 and 7
      // J = I * G
      if (activeBandName !== "GSM900" && activeBandName !== "GSM1800")
        if( RID !== "K_TDD/K_PR" &&
          I &&
          Number(I) !== 0 &&
          G &&
          Number(G) !== 0
        ) {
          const newJ = (Number(I) * Number(G)).toFixed(2);
          if (!J || newJ !== J) changeInfos(activeBandIndex, 1, 4, newJ);
        }
        else if (RID == "K_TDD/K_PR" &&
        I &&
        Number(I) !== 0 &&
        L &&
        Number(L) !== 0 &&
        M &&
        Number(M) !== 0)
      {
        const newJ = (Number(I) * Number(L) * Number(M)).toFixed(2);
        if (!J || newJ !== J) changeInfos(activeBandIndex, 1, 4, newJ);
      } 
    }
  }, [info, activeBandIndex]);

  useEffect(() => {
    const fetchPortsList = async () => {
      if (paramsModalTypeIsModify) {
        await getPortsList(model.name, tenant.name, activeBandName);
      }
    };
    fetchPortsList();
  }, [activeBandIndex]);

  const handleSave = async () => {
    const newSectors = [];
    let newTech = newTechnology ? true : false;
    sectors.map((sect) => {
      if (sect.sector === sector.name) {
        const antennasTemp = [];
        sect.antennas.map((a) => {
          if (a.model === model.name) {
            const techTemp = [];
            a.technologies.map((band) => {
              if (band.band === activeBandName) {
                const tempBand = {
                  ...band,
                  arpaParameters: setArpaParameters(),
                };
                techTemp.push(tempBand);
              } else if (newTech) {
                newTech = false;
                const tempBand = {
                  arpaParameters: setArpaParameters(),
                  band: newTechnology,
                  tenant: techInfos.find((el) => el.infos).infos.tenant,
                  port: null,
                  name: null,
                  rangeTiltElettric: null,
                  downtiltElettrico: null,
                  nTransmitters: null,
                };
                techTemp.push(tempBand);
                techTemp.push(band);
              } else {
                techTemp.push(band);
              }
            });

            antennasTemp.push({
              ...a,
              hbaSuolo: rowInputs.hba || a.hbaSuolo,
              hceSuolo: (parseFloat(rowInputs.hba || a.hbaSuolo) + (a.altezza /2000)).toString(),
              poleID: rowInputs.poleid || a.poleID,
              // arpaParameters: {
              //   tiltMeccanicoMax:
              //     rowInputs.mecdown ||
              //     parseInt(a.arpaParameters.tiltMeccanicoMax),
              // },
              technologies: techTemp,
            });
          } else {
            antennasTemp.push(a);
          }
        });
        const sectTemp = {
          ...sect,
          sector: rowInputs.sector || sect.sector,
          azimuth: rowInputs.azimuth || sect.azimuth,
          antennas: antennasTemp,
        };
        newSectors.push(sectTemp);
      } else {
        newSectors.push(sect);
      }
    });

    setNewTechnology(null);

    await putModifySaveEval(newSectors, evalSelected);
    await putConstraintsDefault();
    await resetModelAndTenant({});
    await setSiteParameters(null);
    await getParameters("isSimulationsStepper");
  };

  const setArpaParameters = () => {
    return {
      alfa24: G || "",
      ktdd: L || "",
      kpr: M || "",
      fattoreRiduzione: RID,
      nTrx: parseFloat(A),
      perdite: parseFloat(E),
      potenzaTRXApparato: parseFloat(B),
      potenzaTotAntennaRiduzione: parseFloat(J),
      potenzaTotAntennaWNoriduzione: parseFloat(I),
      potenzaTotAntennadBmNoriduzione: parseFloat(H),
      potenzaTotaleApparatoW: parseFloat(C),
      potenzaTotaleApparatodBm: parseFloat(D),
      tiltElettricoMax: parseFloat(K),
      potenzaTotAntennaRiduzionedBm: parseFloat(
        (10 * Math.log10(parseFloat(J)) + 30).toFixed(2)
      ),
    };
  };

  const handlOnChangeRowInput = (e, name) => {
    return name === "sector"
      ? { ...rowInputs, sector: e.target.value }
      : name === "azimuth"
      ? { ...rowInputs, azimuth: e.target.value }
      : name === "hba"
      ? { ...rowInputs, hba: e.target.value }
      : name === "pole id"
      ? { ...rowInputs, poleid: e.target.value }
      : name === "mechanical tilt"
      ? { ...rowInputs, mecdown: e.target.value }
      : "";
  };

  const getModalTextField = (el) => {
    let name = el.name.toLowerCase().replace("/cell", "");

    let value =
      name === "sector"
        ? rowInputs.sector ?? el.value
        : name === "azimuth"
        ? rowInputs.azimuth ?? el.value
        : name === "pole id"
        ? rowInputs.poleid ?? el.value
        : name === "hba"
        ? rowInputs.hba ?? el.value
        : name === "mechanical tilt"
        ? rowInputs.mecdown ?? el.value
        : "";

    return (
      <TextField
        type='number'
        variant='standard'
        style={{ marginRight: "20px", width: "100%" }}
        value={value > 0 ? value : 0}
        onChange={(e) => {
          setRowInputs(handlOnChangeRowInput(e, name));
        }}
        disabled={!hasWritePermission || isFinalConfig}
      />
    );
  };

  return (
    <Dialog
      onClose={() => {
        setOpenParamsModal(false);
        setActiveTenantRow(null);
      }}
      open={openParamsModal}
      fullWidth={true}
      maxWidth={"md"}
    >
      <div className={classes.modalContainer}>
        <div className={classes.modalHeader}>
          <div className={classes.modalTitle}>
            Tenant {tenant.name} - Sector {sector.name}
          </div>
          <IconButton
            className={classes.modalCloseIcon}
            onClick={() => {
              setOpenParamsModal(false);
              setActiveTenantRow(null);
            }}
            size='large'
          >
            <ClearIcon />
          </IconButton>
        </div>

        <div className={classes.rowInfosContainer}>
          <div className={classes.firstRow}>
            {headers.map((el, i) => {
              const field = getModalTextField(el);
              return (
                <div className={classes.infoBox} key={`first-row-cell-${i}`}>
                  <p>{el.name}</p>
                  {el.name === "Model" || el.name === "Tenant" ? (
                    <p>{el.value}</p>
                  ) : field !== null ? (
                    field
                  ) : (
                    ""
                  )}
                </div>
              );
            })}
          </div>
          <div className={classes.secondRow}>
            {techInfos.map((el, i) => (
              <div
                key={`second-row-cell-${i}`}
                className={
                  !el.value === "P"
                    ? classes.techBox
                    : activeBandIndex === i
                    ? classes.techButtonActive
                    : classes.techButton
                }
                onClick={() => {
                  // if (el.infos) {
                  setActiveBandIndex(i);
                  if (el.value === "-") setNewTechnology(el.name);
                  // }
                }}
              >
                <p>{el.name}</p>
                <p
                  style={{
                    marginTop: "10px",
                    color: el.value.includes("P") ? "#2F861B" : "#000000",
                  }}
                >
                  {el.value}
                </p>
              </div>
            ))}
          </div>
        </div>

        <div className={classes.bandsInfosContainer}>
          <div className={classes.bandsInfosLeft}>
            {info[activeBandIndex][0].map((el, i) => (
              <div
                key={`band-left-${i}`}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  paddingBottom: "18px",
                }}
              >
                <div style={{ width: "49%" }}>{el.name}</div>
                {paramsModalTypeIsModify ? (
                  el.name === "Port Number" ? (
                    <Autocomplete
                      className={classes.optionInput}
                      options={portsList}
                      onChange={(event, newValue) =>
                        changeInfos(activeBandIndex, 0, i, newValue)
                      }
                      getOptionLabel={(option) => {
                        return `${option}`;
                      }}
                      isOptionEqualToValue={(option, value) => {
                        return option.value == value.value;
                      }}
                      value={el.value}
                      disabled={isFinalConfig}
                      renderInput={(params) => (
                        <TextField
                          style={{ width: "100%", display: "block" }}
                          {...params}
                        />
                      )}
                    />
                  ) : (
                    <TextField
                      type='number'
                      style={{ marginRight: "20px", width: "38%" }}
                      disabled={!isFinalConfig && !el.isEditable}
                      onChange={(event) => {
                        const regexPositive =
                          /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;
                        const regexPositiveWithZero =
                          /^(0*[0-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;
                        const value = event.target.value;

                        if (
                          (i !== 1 && i !== 5) ||
                          (i === 1 && value.match(regexPositive)) ||
                          (i === 5 && value.match(regexPositiveWithZero))
                        )
                          changeInfos(activeBandIndex, 0, i, value);
                      }}
                      value={el.value}
                      error={checkIsMandatoryField(el.mandatory, el.value)}
                      required={checkIsMandatoryField(el.mandatory, el.value)}
                      helperText={
                        checkIsMandatoryField(el.mandatory, el.value)
                          ? "Field required"
                          : null
                      }
                    />
                  )
                ) : (
                  <div style={{ marginRight: "20px" }}>
                    {el.value && !isNaN(el.value) ? el.value : ""}
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className={classes.bandsInfosRight}>
            {info[activeBandIndex][1].map((el, i) => (
              <div
                key={`band-right-${i}`}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  paddingBottom: "18px",
                }}
              >
                <div style={{ width: "49%" }}>{el.name}</div>
                {paramsModalTypeIsModify ? (
                  el.name === "Reduction factor" ? (
                    <Autocomplete
                      className={classes.optionInput}
                      options={el.options}
                      onChange={(event, newValue) =>
                        changeInfos(activeBandIndex, 1, i, newValue)
                      }
                      getOptionLabel={(option) => {
                        return `${option}`;
                      }}
                      isOptionEqualToValue={(option, value) => {
                        return option.value === value.value;
                      }}
                      disabled={isFinalConfig}
                      value={el.value}
                      renderInput={(params) => (
                        <TextField
                          style={{ width: "100%", display: "block" }}
                          {...params}
                        />
                      )}
                    />
                  ) : el.name === "Alfa 24" ? (
                    <TextField
                      type='number'
                      inputProps={{ min: 0, max: 1, step: 0.01 }}
                      error={el.value > 1 || el.value < 0 ? true : false}
                      helperText={
                        el.value > 1 || el.value < 0
                          ? "Accepted range between 0 and 1"
                          : null
                      }
                      style={{ marginRight: "20px", width: "38%" }}
                      disabled={!isFinalConfig && !el.isEditable}
                      value={el.value}
                      onChange={(event) => {
                        const regexZeroOneAndBetween =
                          /^$|(0(\.\d+)?|1(\.0+)?)$/;
                        const value = event.target.value;
                        if (value.match(regexZeroOneAndBetween) !== null)
                          changeInfos(activeBandIndex, 1, i, value);
                      }}
                    />
                  ) : el.name === "K_TDD" ? (
                    <TextField
                      type='number'
                      inputProps={{ min: 0, max: 1, step: 0.01 }}
                      error={el.value > 1 || el.value < 0 ? true : false}
                      helperText={
                        el.value > 1 || el.value < 0
                          ? "Accepted range between 0 and 1"
                          : null
                      }
                      style={{ marginRight: "20px", width: "38%" }}
                      disabled={!isFinalConfig && !el.isEditable}
                      value={el.value}
                      onChange={(event) => {
                        const regexZeroOneAndBetween =
                          /^$|(0(\.\d+)?|1(\.0+)?)$/;
                        const value = event.target.value;
                        if (value.match(regexZeroOneAndBetween) !== null)
                          changeInfos(activeBandIndex, 1, i, value);
                      }}
                    />
                  ) :el.name === "K_PR" ? (
                    <TextField
                      type='number'
                      inputProps={{ min: 0, max: 1, step: 0.01 }}
                      error={el.value > 1 || el.value < 0 ? true : false}
                      helperText={
                        el.value > 1 || el.value < 0
                          ? "Accepted range between 0 and 1"
                          : null
                      }
                      style={{ marginRight: "20px", width: "38%" }}
                      disabled={!isFinalConfig && !el.isEditable}
                      value={el.value}
                      onChange={(event) => {
                        const regexZeroOneAndBetween =
                          /^$|(0(\.\d+)?|1(\.0+)?)$/;
                        const value = event.target.value;
                        if (value.match(regexZeroOneAndBetween) !== null)
                          changeInfos(activeBandIndex, 1, i, value);
                      }}
                    />
                  ) : el.name ===
                    "Total power at antenna (W) with losses and reduction" ? (
                    <TextField
                      type='number'
                      style={{ marginRight: "20px", width: "38%" }}
                      disabled={!isFinalConfig && !el.isEditable}
                      // here
                      value={RID === "NO" ? Number(I) : el.value}
                      onChange={(event) => {
                        const value = event.target.value;
                        changeInfos(activeBandIndex, 1, i, value);
                      }}
                    />
                  ) : (
                    <TextField
                      type='number'
                      style={{ marginRight: "20px", width: "38%" }}
                      disabled={!isFinalConfig && !el.isEditable}
                      value={el.value}
                      onChange={(event) => {
                        const value = event.target.value;
                        if (value < 0) {
                          return changeInfos(activeBandIndex, 1, i, "0");
                        } else {
                          changeInfos(activeBandIndex, 1, i, value);
                        }
                      }}
                      error={checkIsMandatoryField(el.mandatory, el.value)}
                      required={checkIsMandatoryField(el.mandatory, el.value)}
                      helperText={
                        checkIsMandatoryField(el.mandatory, el.value)
                          ? "Field required"
                          : null
                      }
                      inputProps={limitInputRangeForTiltElettrico(el.name)}
                    />
                  )
                ) : (
                  <div style={{ marginRight: "20px" }}>
                    {el.value && !isNaN(el.value) ? el.value : ""}
                    {el.name === "Reduction factor" ? el.value : null}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>

        {paramsModalTypeIsModify && (
          <div className={classes.modalButtonsContainer}>
            <button
              className={classes.modalCloseButton}
              onClick={() => {
                setOpenParamsModal(false);
                setActiveTenantRow(null);
              }}
            >
              Annulla
            </button>
            <button
              className={classes.modalSaveButton}
              onClick={() => {
                handleSave();
                setOpenParamsModal(false);
                setActiveTenantRow(null);
              }}
              disabled={!hasWritePermission}
            >
              Salva
            </button>
          </div>
        )}
      </div>
    </Dialog>
  );
};

const mapState = ({ site, simulationsStepper }) => ({
  technologiesList: site.technologiesList,
  originalSectors: site.sectors,
  evalSelected: site.evalSelected,
  portsList: simulationsStepper.portsList,
});

const mapDispatch = ({
  site: { getSiteParameters, resetModelAndTenant, setSiteParameters },
  simulationsStepper: {
    getPortsList,
    putModifySaveEval,
    putConstraintsDefault,
  },
}) => ({
  getPortsList: (model, tenant, technology) =>
    getPortsList({ model, tenant, technology }),
  putModifySaveEval: (sectors, evalSelected) =>
    putModifySaveEval({ sectors, evalSelected }),
  getParameters: (type) => getSiteParameters({ type }),
  resetModelAndTenant: (value) => resetModelAndTenant(value),
  setSiteParameters: (value) => setSiteParameters(value),
  putConstraintsDefault: () => putConstraintsDefault(),
});

export default compose(
  withStyles(useStyles),
  connect(mapState, mapDispatch)
)(SiteParamsModal);
