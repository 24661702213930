import React, { useState, useEffect } from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import useHttpService from "../../hooks/useHttpService";

export default function ComboBox(props) {
  const [myURL, setURL] = useState(props.apiURL);
  const [selectedValue, setSelected] = useState(props.selectedValue);
  const [myData, setData] = useState([]);

  const httpService = useHttpService();

  function queryAPI() {
    if (!myURL) {
      setData([]);
      return;
    }
    httpService.getAPI(myURL, onDataReceived);
  }

  function onDataReceived(data) {
    setData(data);
  }

  function SelectChange(e) {
    if (props.onSelectChange)
      props.onSelectChange(
        myData.find((row) => row[props.valueMember] === e.target.value)
      );
  }

  useEffect(() => {
    queryAPI();
    if (props.selectedValue) setSelected(props.selectedValue);
  }, [myURL]);

  useEffect(() => {
    if (props.apiURL) setURL(props.apiURL);
    if (props.data) {
      if (props.data.length > 0) setData(props.data);
      else setData([]);
    }
    if (props.selectedValue) setSelected(props.selectedValue);
  }, [props]);

  return (
    <FormControl fullWidth>
      <InputLabel id='demo-simple-select-label'>{props.label}</InputLabel>
      <Select
        labelId='demo-simple-select-label'
        id='demo-simple-select'
        value={selectedValue}
        label={props.label}
        onChange={SelectChange}
      >
        {myData && myData instanceof Array &&
          myData.map((option) => (
            <MenuItem
              value={option[props.valueMember]}
              name={option[props.displayMember]}
              key={uuidv4()}
            >
              {option[props.displayMember]}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
}
