import React, { useState } from "react";

import { Grid } from "@mui/material";
import EnhancedTableBox from "../../../components/PlaygroundComponents/EnhancedTableBox";

import { DELETE_TECHNOLOGIES, GET_TECH_TO_FREQ } from "../../../utils/api";

const actions = [
  {
    action: "edit",
    icon: "edit",
    tooltip: "edit current row",
  },

  {
    action: "delete",
    icon: "delete",
    tooltip: "delete current row",
  },
];

const visibleColumns = [
  {
    id: "technology",
    alignRight: false,
    disablePadding: false,
    label: "Technology",
  },
  {
    id: "nominal_frequency",
    alignRight: false,
    disablePadding: false,
    label: "Nominal Frequency",
  },
  {
    id: "tech_type",
    alignRight: false,
    disablePadding: false,
    label: "Type",
  },
  {
    id: "max_power_w",
    alignRight: false,
    disablePadding: false,
    label: "Max Power (W)",
  },
];

const schema = {
  technology: {
    title: "Technology",
    type: "text",
    md: 6,
  },
  nominal_frequency: {
    title: "Nominal Frequency",
    type: "number",
    md: 6,
  },
  tech_type: {
    title: "Technology Type",
    type: "text",
    md: 6,
  },
  max_power_w: {
    title: "Max Power",
    type: "number",
    md: 6,
  },
};

const ManageTechnologies = () => {
  const [selectedTechnology, setSelectedTechnology] = useState(null);
  function onChangeTechnology(e) {
    setSelectedTechnology(e.technology);
  }

  return (
    <div>
      <Grid container spacing={5} padding={5}>
        <Grid item md={12}>
          <EnhancedTableBox
            title='List of technologies'
            addButton
            onSelectChange={onChangeTechnology}
            apiURL={GET_TECH_TO_FREQ}
            deleteURL={DELETE_TECHNOLOGIES}
            valueMember='id'
            visibleColumns={visibleColumns}
            actions={actions}
            addJsonSchema={schema}
            editJsonSchema={schema}
            orderBy='nominal_frequency'
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default ManageTechnologies;
