import React from "react";
import axios from "axios";
import { useState, useEffect, useContext} from "react";
import { Grid } from "@mui/material";
import useHttpService from "../../../hooks/useHttpService";
import { TextField } from "@mui/material";
import { Button,Box} from "@mui/material";
import { UserRoleContext } from "../../../contexts/user-role.context";
import {POST_UPLOAD_MASSIVE_TENANT} from "../../../utils/api"

const ManageTenantMassive = () => {   
    const { hasWritePermission } = useContext(UserRoleContext);
    const httpService = useHttpService();  

    const [messageType, setMessageType] = useState("");
    const [messages, setMessages] = useState([]);

    const handleUpload =  async (e) => {  
        setMessageType("");
        setMessages([]);
        const file = e.target.files[0];
        const formData = new FormData();    
        formData.append("formFile", file, file.name);
        // await axios({
        //     method: "POST",
        //     url: `${POST_UPLOAD_MASSIVE}?note=test`,
        //     headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        //     data: formData,
        //   })
        //     .then((res) => {
        //       if (res.data?.result === 1 && res.data?.data.warnings) {
        //         this.setWarningMessages(res.data.data.warnings);
        //         this.setWarningMessagesAsString(res.data.data.warnings);
        //       }
        //       if (res.data?.result === 0) {
        //         if (res.data.errors) {
        //           this.setErrorMessages(res.data.errors);
        //           this.setErrorMessagesAsString(res.data.errors);
        //         }
        //         if (res.data.message) {
        //           if ((typeof res.data.message).toLowerCase() === "string") {
        //             this.setErrorMessages([res.data.message]);
        //             this.setErrorMessagesAsString([res.data.message]);
        //           } else {
        //             this.setErrorMessages(res.data.message);
        //             this.setErrorMessagesAsString(res.data.message);
        //           }
        //         }
        //       } 
        //     })
        //     .catch((err) => {
        //       console.log(`Error ${POST_UPLOAD_MASSIVE}`, err);
        //       dispatch.loader.loader(false);
        //     });
        httpService.postAPI(POST_UPLOAD_MASSIVE_TENANT, formData,showMessages) ;         
      }

    async function showMessages(data){
        if(data.data == null){
            setMessageType("Success")
        }
        if (data.result == 0) {
            setMessageType("Errors")
            setMessages(data.message)
        }
        if (data.warnings && data.warnings!==null){
            setMessageType("Warnings")
            setMessages(data.warnings)
        }
    }
    
    return (
        <div>
            <Grid>
                <Box paddingTop='2em' paddingLeft='3em' justifycontent='center' alignItems='center'>
                    <h2>Tenant Actual Site Configurations</h2>
                </Box>
            </Grid>
          <Grid container spacing={5} padding={5}>
            <Grid item md={3}> 
                <input
                hidden
                id='button-upload-massive'
                onChange={handleUpload}
                type='file'
                accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                
                />
                <label htmlFor='button-upload-massive'>
                <Button
                    variant='contained'
                    component='span'              
                    disabled={!hasWritePermission}
                    sx={{ color: "#fff", marginRight: "1rem" , marginLeft:"3em"}}
                >
                    Import 
                </Button>
                </label>
            </Grid>
        </Grid>
        <Grid container spacing={5} padding={5}>
            <Box paddingLeft='3em' justifycontent='center' alignItems='center'>   
                <h4>{messageType.toUpperCase()}</h4><br></br>
                {
                    messageType == "Warnings" && messages!=="" 
                        ? messages.map((message) =>(<h4>{message}</h4>))                    
                        : messageType == "Errors" && messages!=="" 
                            ? (<h4>{messages}</h4>)
                            : messageType == "Success" 
                                ?<h4>Uploaded all rows!</h4>
                                :<h4></h4>
                }
            </Box>
        </Grid>

        </div>
        );
 };

export default ManageTenantMassive;