import { useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { useMapFilters, actionTypes } from "../../contexts/map-filters.context";
import {
  MapContainer,
  TileLayer,
  Marker,
  LayersControl,
  Circle,
  Tooltip,
} from "react-leaflet";
import LeafletRuler from "../LeafletRuler/LeafletRuler";

import { GET_ADJACENT_SITES } from "../../utils/api";

import "./site-filter-map-box.css";

const SiteFilterMapBox = (props) => {
  const { state, dispatch } = useMapFilters();
  const { customMarkers, originalSites, error, showSiteCodes } = state;
  const { siteCenter, siteInfos } = props;

  useEffect(() => {
    if (siteInfos) {
      if (originalSites) {
        const fetchAdjecentSites = async (searchRadius) => {
          try {
            const { data } = await axios({
              method: "GET",
              url: GET_ADJACENT_SITES,
              headers: {
                authorization: `Bearer ${localStorage.getItem("token")}`,
              },
              params: { site_code: siteInfos.site_code, radius: searchRadius },
            });
            if (!data.data) {
              dispatch({
                type: actionTypes.API_FAILURE,
                payload: "No adjacent sites available",
              });
            } else {
              dispatch({
                type: actionTypes.API_SUCCESS,
                payload: data.data,
              });
            }
          } catch (error) {
            dispatch({
              type: actionTypes.API_FAILURE,
              payload: error,
            });
          }
        };
        fetchAdjecentSites(state.radius);
      }
    }
  }, [state.radius]);

  return (
    <>
      <MapContainer
        center={siteCenter}
        zoom={16}
        scrollWheelZoom={true}
        style={{ width: "100%", height: "100%", zIndex: 0 }}
      >
        <LayersControl position='bottomleft'>
          <LayersControl.BaseLayer checked name='Street View'>
            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
            />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name='Geo View'>
            <TileLayer
              attribution='Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
              url='https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
            />
          </LayersControl.BaseLayer>
        </LayersControl>
        <Circle
          center={siteCenter}
          radius={Number(state.radius)}
          pathOptions={{
            color: "#f64747",
            opacity: 1,
            fillColor: "#f87d7d",
            fillOpacity: 0.1,
          }}
        />
        {customMarkers &&
          customMarkers.length > 0 &&
          customMarkers.map((markers) => {
            return markers.map((marker) => (
              <Marker
                key={uuidv4()}
                position={marker.position}
                icon={marker.icon}
              >
                {showSiteCodes && (
                  <Tooltip permanent>{marker.site_code}</Tooltip>
                )}
              </Marker>
            ));
          })}
        {error && <Marker position={siteCenter}></Marker>}
        <LeafletRuler />
      </MapContainer>
    </>
  );
};

export default SiteFilterMapBox;
