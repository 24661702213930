import { useState } from "react";
import PropTypes from "prop-types";

import {
  MapFiltersProvider,
  useMapFilters,
  actionTypes,
} from "../../contexts/map-filters.context";

import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
// import Checkbox from "@mui/material/Checkbox";
// import Slider from "@mui/material/Slider";
import Collapse from "@mui/material/Collapse";
import Switch from "@mui/material/Switch";
import Slider from "@mui/material/Slider";
import IconButton from "@mui/material/IconButton";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import StringLoader from "../StringLoader";
import SiteInfoMapBox from "../SiteInfoMapBox";
import SiteFilterMapBox from "../SiteFilterMapBox/SiteFilterMapBox";

import dayjs from "dayjs";

const SiteInformationBox = ({
  classes,
  siteInfos,
  siteCenter,
  loadingSiteInfos,
  lastSimulationDate,
  lastSimulationStatus,
  simulationsCount,
}) => {
  const [activeTab, setActiveTab] = useState("info");

  const handleChangeTab = (_event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <MapFiltersProvider>
      <div className={classes.infosContainer}>
        <TabContext value={activeTab}>
          <div className={classes.infosBox}>
            <TabList
              onChange={handleChangeTab}
              textColor='secondary'
              indicatorColor='secondary'
              centered
              sx={{ borderBottom: 1, borderColor: "divider" }}
            >
              <Tab label='Informations' value={"info"} />
              <Tab label='Filters' value={"filter"} />
            </TabList>
            <TabPanel value={"info"}>
              <InfoPanel
                classes={classes}
                siteInfos={siteInfos}
                loadingSiteInfos={loadingSiteInfos}
                lastSimulationDate={lastSimulationDate}
                lastSimulationStatus={lastSimulationStatus}
                simulationsCount={simulationsCount}
              />
            </TabPanel>
            <TabPanel value={"filter"}>
              <FilterPanel />
            </TabPanel>
          </div>

          <div className={classes.mapBox}>
            {siteInfos && activeTab === "info" && (
              <SiteInfoMapBox siteInfos={siteInfos} siteCenter={siteCenter} />
            )}

            {siteInfos && activeTab === "filter" && (
              <SiteFilterMapBox siteInfos={siteInfos} siteCenter={siteCenter} />
            )}
          </div>
        </TabContext>
      </div>
    </MapFiltersProvider>
  );
};

SiteInformationBox.propTypes = {
  classes: PropTypes.object,
  siteInfos: PropTypes.object,
  siteCenter: PropTypes.arrayOf(PropTypes.number),
  loadingSiteInfos: PropTypes.bool,
  lastSimulationDate: PropTypes.string,
  lastSimulationStatus: PropTypes.string,
  simulationsCount: PropTypes.number,
};

const InfoPanel = (props) => {
  const {
    classes,
    siteInfos,
    loadingSiteInfos,
    lastSimulationDate,
    lastSimulationStatus,
    simulationsCount,
  } = props;
  return (
    <div>
      <div className={classes.infosLocationTitle}>LOCATION</div>

      <div className={classes.infosRow}>
        <div className={classes.infosKey}>ID INWIT</div>
        <div className={classes.infosValue}>
          <StringLoader
            string={siteInfos?.site_code || "-"}
            loadingString={loadingSiteInfos}
          />
        </div>
      </div>
      <div className={classes.infosRow}>
        <div className={classes.infosKey}>Original site code</div>
        <div className={classes.infosValue}>
          <StringLoader
            string={siteInfos?.original_site_code || "-"}
            loadingString={loadingSiteInfos}
          />
        </div>
      </div>
      <div className={classes.infosRow}>
        <div className={classes.infosKey}>Site name</div>
        <div className={classes.infosValue}>
          <StringLoader
            string={siteInfos?.site_name || "-"}
            loadingString={loadingSiteInfos}
          />
        </div>
      </div>
      <div className={classes.infosRow}>
        <div className={classes.infosKey}>Site type</div>
        <div className={classes.infosValue}>
          <StringLoader
            string={siteInfos?.site_type || "-"}
            loadingString={loadingSiteInfos}
          />
        </div>
      </div>
      <div className={classes.infosRow}>
        <div className={classes.infosKey}>Zone</div>
        <div className={classes.infosValue}>
          <StringLoader
            string={siteInfos?.zona || "-"}
            loadingString={loadingSiteInfos}
          />
        </div>
      </div>
      <div className={classes.infosRow}>
        <div className={classes.infosKey}>Province</div>
        <div className={classes.infosValue}>
          <StringLoader
            string={siteInfos?.provincia || "-"}
            loadingString={loadingSiteInfos}
          />
        </div>
      </div>
      <div className={classes.infosRow}>
        <div className={classes.infosKey}>Municipality</div>
        <div className={classes.infosValue}>
          <StringLoader
            string={siteInfos?.comune || "-"}
            loadingString={loadingSiteInfos}
          />
        </div>
      </div>
      <div className={classes.infosRow}>
        <div className={classes.infosKey}>Address</div>
        <div className={classes.infosValue}>
          <StringLoader
            string={siteInfos?.address || "-"}
            loadingString={loadingSiteInfos}
          />
        </div>
      </div>

      <div className={classes.infosSimulationInfoTitle}>SIMULATIONS</div>

      <div className={classes.infosRow}>
        <div className={classes.infosKey}>Actual state</div>
        <div className={classes.infosValue}>
          <StringLoader
            string={lastSimulationStatus || "-"}
            loadingString={loadingSiteInfos}
          />
        </div>
      </div>
      <div className={classes.infosRow}>
        <div className={classes.infosKey}>Last Simulation</div>
        <div className={classes.infosValue}>
          <StringLoader
            string={
              lastSimulationDate
                ? dayjs(lastSimulationDate).format("DD/MM/YYYY h:mm A")
                : "-"
            }
            loadingString={loadingSiteInfos}
          />
        </div>
      </div>
      <div className={classes.infosRow}>
        <div className={classes.infosKey}>Total Simulations</div>
        <div className={classes.infosValue}>
          <StringLoader
            string={simulationsCount || "-"}
            loadingString={loadingSiteInfos}
          />
        </div>
      </div>
    </div>
  );
};

const FilterPanel = () => {
  const { state, dispatch } = useMapFilters();
  const [tenantsToggle, setTenantsToggle] = useState(true);
  const [techToggle, setTechToggle] = useState(true);
  const [radiusValue, setRadiusValue] = useState(state.radius);
  const { filters, showSiteCodes } = state;

  const handleCheckboxChange = (id) => {
    dispatch({ type: actionTypes.TOGGLE_FILTER, payload: id });
    dispatch({ type: actionTypes.UPDATE_FILTERED_SITES });
  };
  const handleTenantCollapse = () => {
    setTenantsToggle(!tenantsToggle);
  };
  const handleTechCollapse = () => {
    setTechToggle(!techToggle);
  };
  const handleToggleShowSiteCodes = () => {
    dispatch({ type: actionTypes.TOGGLE_SHOW_SITE_CODES });
  };
  const handleRadiusValueChange = (event) => {
    const { value } = event.target;
    setRadiusValue(value);
  };
  const handleRadiusChangeCommitted = () => {
    dispatch({ type: actionTypes.SET_RADIUS, payload: radiusValue });
  };

  const getSliderAriaText = (value) => {
    return `${value}m`;
  };
  const getTenantFilters = () =>
    filters.filter((element) => element.type === "tenants");
  const getTechnologyFilters = () =>
    filters.filter((element) => element.type === "technologies");

  if (state.error) {
    return <div>No filtering options available...</div>;
  }

  let filteringOptions;
  if (filters && filters.length > 0) {
    filteringOptions = (
      <>
        <div>
          <div style={{ display: "flex" }}>
            <h4>Filter by tenants</h4>
            <IconButton onClick={handleTenantCollapse}>
              {tenantsToggle ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            </IconButton>
          </div>

          <div
            style={{
              background: "#ffffff",
              width: "min(15rem, 100%)",
              display: "grid",
              placeItems: "center",
              borderRadius: "15px",
            }}
          >
            <Collapse in={tenantsToggle}>
              <FormGroup>
                {filters &&
                  getTenantFilters().map((tenant) => (
                    <FormControlLabel
                      key={tenant.id}
                      control={
                        <Switch
                          checked={tenant.checked}
                          onChange={() => {
                            handleCheckboxChange(tenant.id);
                          }}
                        />
                      }
                      label={tenant.label}
                    />
                  ))}
              </FormGroup>
            </Collapse>
          </div>
        </div>

        <div>
          <div style={{ display: "flex" }}>
            <h4>Filter by technologies</h4>
            <IconButton onClick={handleTechCollapse}>
              {techToggle ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            </IconButton>
          </div>

          <div
            style={{
              background: "#ffffff",
              width: "min(15rem, 100%)",
              display: "grid",
              placeItems: "center",
              borderRadius: "15px",
            }}
          >
            <Collapse in={techToggle}>
              <FormGroup>
                {filters &&
                  getTechnologyFilters().map((tech) => (
                    <FormControlLabel
                      key={tech.id}
                      control={
                        <Switch
                          checked={tech.checked}
                          onChange={() => {
                            handleCheckboxChange(tech.id);
                          }}
                        />
                      }
                      label={tech.label}
                    />
                  ))}
              </FormGroup>
            </Collapse>
          </div>
        </div>
      </>
    );
  }

  return (
    <div>
      <div>
        <label style={{ fontWeight: "bold" }} id='search-radius'>
          Search radius in meters
        </label>
        <Slider
          min={500}
          max={6000}
          marks
          step={500}
          value={radiusValue}
          onChange={handleRadiusValueChange}
          onChangeCommitted={handleRadiusChangeCommitted}
          aria-label='Search radius'
          aria-labelledby='search-radius'
          getAriaValueText={getSliderAriaText}
          valueLabelDisplay='auto'
        />
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Switch checked={showSiteCodes} onChange={handleToggleShowSiteCodes} />
        <p style={{ fontWeight: "bold" }}>{`${
          showSiteCodes ? "Hide" : "Show"
        } site codes`}</p>
      </div>
      {filteringOptions}
    </div>
  );
};

export default SiteInformationBox;
