import React, { useEffect, useState, useRef, useContext } from "react";
import withStyles from "@mui/styles/withStyles";
import { connect } from "react-redux";
import { compose } from "redux";
import {
  Button,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockIcon from "@mui/icons-material/Lock";
import { UserRoleContext } from "../../contexts/user-role.context";

const useStyles = (theme) => ({
  topContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "20px",
  },
  locksInfos: {
    display: "flex",
  },
  lockImg: {
    marginBottom: "-2px",
  },
  lockText: {
    fontSize: "12px",
    fontWeight: "unset",
    marginRight: "10px",
    marginLeft: "5px",
  },
  buttonsContainer: {
    display: "flex",
    alignItems: "center",
  },
  button: {
    fontWeight: "bold",
    height: "32px",
    padding: "3px 24px",
    background: "white",
    border: "0.5px solid #018ABE",
    borderRadius: "100px",
    font: "700 13px/20px",
    color: "#018ABE",
    marginLeft: "13px",
    textTransform: "none",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    minWidth: "64px",
    "&:hover": {
      backgroundColor: "white",
      cursor: "pointer",
      outline: "none",
    },
    "&:focus": {
      outline: "none",
    },
    "&:active": {
      outline: "none",
    },
    "&:disabled": {
      color: "rgba(0, 0, 0, 0.26)",
      background: "white",
      cursor: "unset",
    },
  },
  tenantHead: {
    display: "flex",
    alignItems: "center",
    padding: "8px 10px",
    backgroundColor: "#ECECED",
    marginBottom: "2px",
  },
  tenantText: {
    fontSize: "12px",
    fontWeight: "bold",
    marginRight: "8px",
  },
  tableContainer: {
    borderRadius: 0,
    marginBottom: "25px",
  },
  tableHead: {
    height: "90px",
  },
  tableHeadersContainer: {
    padding: "0px",
    border: "none",
  },
  tableHeadersText: {
    fontSize: "12px",
    fontWeight: "bold",
    padding: "8px 10px",
    backgroundColor: "#ECECED",
    height: "45px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  tableHeadersSubText: {
    height: "45px",
    display: "flex",
    alignItems: "center",
  },
});

const SimulationsStepperStep4 = ({
  classes,
  constraints,
  putConstraintsDefault,
  putConstraintsSave,
}) => {
  const [constraintsTableInfos, setConstraintsTableInfos] = useState([]);
  const { hasWritePermission } = useContext(UserRoleContext);
  const howManyLockClick = useRef(0);

  const handleTenantLock = (tenantIndex) => {
    const newConstraintsTableInfos = [...constraintsTableInfos];
    newConstraintsTableInfos[tenantIndex].tenantIsLocked =
      !newConstraintsTableInfos[tenantIndex].tenantIsLocked;
    newConstraintsTableInfos[tenantIndex].headers = newConstraintsTableInfos[
      tenantIndex
    ].headers.map((header) => ({
      ...header,
      isLocked: newConstraintsTableInfos[tenantIndex].tenantIsLocked,
    }));
    newConstraintsTableInfos[tenantIndex].body = newConstraintsTableInfos[
      tenantIndex
    ].body.map((antennaModel) =>
      antennaModel.map((tech) =>
        tech.map((info) => ({
          ...info,
          isLocked: newConstraintsTableInfos[tenantIndex].tenantIsLocked,
          maxValue: newConstraintsTableInfos[tenantIndex].tenantIsLocked
            ? parseFloat(info.startingMaxValue)
            : parseFloat(info.startingMaxValue) + 1,
          minValue: newConstraintsTableInfos[tenantIndex].tenantIsLocked
            ? parseFloat(info.startingMinValue)
            : parseFloat(info.startingMinValue) - 1,
        }))
      )
    );
    setConstraintsTableInfos(newConstraintsTableInfos);
  };

  const handleTypeLock = (tenantIndex, clickedType, typeIsLocked) => {
    const newConstraintsTableInfos = [...constraintsTableInfos];
    newConstraintsTableInfos[tenantIndex].tenantIsLocked = false;
    newConstraintsTableInfos[tenantIndex].headers = newConstraintsTableInfos[
      tenantIndex
    ].headers.map((header) =>
      header.key !== clickedType
        ? header
        : { ...header, isLocked: !typeIsLocked }
    );
    newConstraintsTableInfos[tenantIndex].body = newConstraintsTableInfos[
      tenantIndex
    ].body.map((antennaModel) =>
      antennaModel.map((tech) =>
        tech.map((info) =>
          info.key !== clickedType ? info : { ...info, isLocked: !typeIsLocked }
        )
      )
    );
    setConstraintsTableInfos(newConstraintsTableInfos);
  };

  const handleSpecificLock = (
    tenantIndex,
    antennaModelIndex,
    techIndex,
    cellIndex,
    cellIsLocked
  ) => {
    const newConstraintsTableInfos = [...constraintsTableInfos];
    newConstraintsTableInfos[tenantIndex].tenantIsLocked = false;
    newConstraintsTableInfos[tenantIndex].headers = newConstraintsTableInfos[
      tenantIndex
    ].headers.map((header, headerIndex) =>
      headerIndex !== cellIndex ? header : { ...header, isLocked: false }
    );

    if (cellIndex >= 6) {
      newConstraintsTableInfos[tenantIndex].body[antennaModelIndex][techIndex][
        cellIndex
      ] = {
        ...newConstraintsTableInfos[tenantIndex].body[antennaModelIndex][
          techIndex
        ][cellIndex],
        isLocked: !cellIsLocked,
      };
    } else {
      newConstraintsTableInfos[tenantIndex].body[antennaModelIndex] =
        newConstraintsTableInfos[tenantIndex].body[antennaModelIndex].map(
          (tech) =>
            tech.map((info, infoIndex) =>
              infoIndex !== cellIndex
                ? info
                : { ...info, isLocked: !cellIsLocked }
            )
        );
    }
    setConstraintsTableInfos(newConstraintsTableInfos);
  };

  const handleMinMaxInputs = (
    tenantIndex,
    antennaModelIndex,
    techIndex,
    cellIndex,
    type,
    value
  ) => {
    const newConstraintsTableInfos = [...constraintsTableInfos];
    newConstraintsTableInfos[tenantIndex].body[antennaModelIndex][techIndex][
      cellIndex
    ] = {
      ...newConstraintsTableInfos[tenantIndex].body[antennaModelIndex][
        techIndex
      ][cellIndex],
      minValue:
        type === "min"
          ? value
          : newConstraintsTableInfos[tenantIndex].body[antennaModelIndex][
              techIndex
            ][cellIndex].minValue,
      maxValue:
        type === "max"
          ? value
          : newConstraintsTableInfos[tenantIndex].body[antennaModelIndex][
              techIndex
            ][cellIndex].maxValue,
    };
    setConstraintsTableInfos(newConstraintsTableInfos);
  };

  useEffect(() => {
    let howManyUnlocked = [];

    if (!constraintsTableInfos || constraintsTableInfos.length === 0) return;

    constraintsTableInfos.map((tenantTable) => {
      let count = 0;
      tenantTable.body.forEach((row) => {
        row.forEach((cell) => {
          count += cell.filter(
            (el) => el.key === "potTotal" && !el.isLocked
          ).length;
        });
      });
      howManyUnlocked.push(count);
    });

    const newConstraintsTableInfos = [...constraintsTableInfos];
    newConstraintsTableInfos.map((tenantTable, index) => {
      tenantTable.tenantIsLocked = howManyUnlocked[index] === 0 ? true : false;
    });
    // newConstraintsTableInfos[0].tenantIsLocked = howManyUnlocked === 0 ? true : false
    setConstraintsTableInfos(newConstraintsTableInfos);
  }, [howManyLockClick.current, constraintsTableInfos.length]);

  useEffect(() => {
    let newConstraintsTableInfos = [];

    constraints &&
      constraints.length > 0 &&
      constraints.map(({ antennas, tenant }) => {
        let tableBodyRows = [];

        antennas.map(({ sector, model, azimuth, tilt, hba, technologies }) => {
          let modelRows = [];

          technologies.map(({ band, etilt, potTotal }, technologyIndex) => {
            modelRows = [
              ...modelRows,
              [
                {
                  key: "azimuth",
                  value: azimuth.current,
                  minValue: azimuth.minValue,
                  maxValue: azimuth.maxValue,
                  startingMinValue: azimuth.minValue,
                  startingMaxValue: azimuth.maxValue,
                  isLockable: false,
                  isLocked:
                    azimuth.current === azimuth.minValue &&
                    azimuth.current === azimuth.maxValue,
                  isLocked: false,
                  hasValues: false,
                  isVisible: true,
                  isShown: technologyIndex === 0,
                },
                {
                  key: "antenna",
                  value: model,
                  isLockable: false,
                  isLocked: false,
                  hasValues: false,
                  isVisible: true,
                  isShown: technologyIndex === 0,
                },
                // {
                //   key: 'sector',
                //   value: sector,
                //   isLockable: true,
                //   isLocked: false,
                //   hasValues: false,
                //   isVisible: false,
                //   isShown: technologyIndex === 0
                // },
                {
                  key: "sector",
                  value: sector,
                  isLockable: false,
                  isLocked: false,
                  hasValues: true,
                  isVisible: false,
                  isShown: false,
                },
                {
                  key: "tilt",
                  value: tilt.current,
                  minValue: tilt.minValue,
                  maxValue: tilt.maxValue,
                  startingMinValue: tilt.minValue,
                  startingMaxValue: tilt.maxValue,
                  isLockable: true,
                  isLocked:
                    tilt.current === tilt.minValue &&
                    tilt.current === tilt.maxValue,
                  hasValues: true,
                  isVisible: false,
                  isShown: technologyIndex === 0,
                },
                {
                  key: "hba",
                  value: hba.current,
                  minValue: hba.minValue,
                  maxValue: hba.maxValue,
                  startingMinValue: hba.minValue,
                  startingMaxValue: hba.maxValue,
                  isLockable: true,
                  isLocked:
                    hba.current === hba.minValue &&
                    hba.current === hba.maxValue,
                  hasValues: true,
                  isVisible: false,
                  isShown: technologyIndex === 0,
                },
                {
                  key: "technology",
                  value: band,
                  isLockable: false,
                  isLocked: false,
                  hasValues: false,
                  isVisible: true,
                  isShown: true,
                },
                {
                  key: "etilt",
                  value: etilt.current,
                  minValue: etilt.minValue,
                  maxValue: etilt.maxValue,
                  startingMinValue: etilt.minValue,
                  startingMaxValue: etilt.maxValue,
                  isLockable: true,
                  isLocked:
                    etilt.current === etilt.minValue &&
                    etilt.current === etilt.maxValue,
                  hasValues: true,
                  isVisible: false,
                  isShown: true,
                },
                {
                  key: "potTotal",
                  value: potTotal.current,
                  minValue: potTotal.minValue,
                  maxValue: potTotal.maxValue,
                  startingMinValue: potTotal.minValue,
                  startingMaxValue: potTotal.maxValue,
                  isLockable: true,
                  isLocked:
                    potTotal.current === potTotal.minValue &&
                    potTotal.current === potTotal.maxValue,
                  hasValues: true,
                  isVisible: true,
                  isShown: true,
                },
              ],
            ];

            return true;
          });

          tableBodyRows = [...tableBodyRows, modelRows];

          return true;
        });

        // newConstraintsTableInfos = [...newConstraintsTableInfos, {
        //   tenant: tenant,
        //   tenantIsLocked: false,
        //   headers: [
        //     { name: 'SECTOR', key: 'sector', isLockable: false, isLocked: false, hasValues: false },
        //     { name: 'ANTENNA', key: 'antenna', isLockable: false, isLocked: false, hasValues: false },
        //     { name: 'AZIMUTH', key: 'azimuth', isLockable: true, isLocked: false, hasValues: true },
        //     { name: 'TILT MECCANICO RICHIESTO (°)', key: 'tilt', isLockable: true, isLocked: false, hasValues: true },
        //     { name: 'HBA (M)', key: 'hba', isLockable: true, isLocked: false, hasValues: true },
        //     { name: 'TECNOLOGIA', key: 'technology', isLockable: false, isLocked: false, hasValues: false },
        //     { name: 'TILT ELETTRICO RICHIESTO (°)', key: 'etilt', isLockable: true, isLocked: false, hasValues: true },
        //     { name: 'POTENZA TOTALE IN ANTENNA (°)', key: 'potTotal', isLockable: true, isLocked: false, hasValues: true }
        //   ],
        //   body: tableBodyRows
        // }]

        newConstraintsTableInfos = [
          ...newConstraintsTableInfos,
          {
            tenant: tenant,
            tenantIsLocked: false,
            headers: [
              // { name: 'AZIMUTH', key: 'azimuth', isLockable: true, isLocked: false, hasValues: true, isVisible: true },
              {
                name: "AZIMUTH",
                key: "azimuth",
                isLockable: false,
                isLocked: false,
                hasValues: false,
                isVisible: true,
              },
              {
                name: "ANTENNA",
                key: "antenna",
                isLockable: false,
                isLocked: false,
                hasValues: false,
                isVisible: true,
              },
              {
                name: "SECTOR",
                key: "sector",
                isLockable: false,
                isLocked: false,
                hasValues: false,
                isVisible: false,
              },
              {
                name: "REQUIRED MECHANICAL TILT (°)",
                key: "tilt",
                isLockable: true,
                isLocked: false,
                hasValues: true,
                isVisible: false,
              },
              {
                name: "HBA (M)",
                key: "hba",
                isLockable: true,
                isLocked: false,
                hasValues: true,
                isVisible: false,
              },
              {
                name: "TECHNOLOGY",
                key: "technology",
                isLockable: false,
                isLocked: false,
                hasValues: false,
                isVisible: true,
              },
              {
                name: "TILT ELETTRICO RICHIESTO (°)",
                key: "etilt",
                isLockable: true,
                isLocked: false,
                hasValues: true,
                isVisible: false,
              },
              {
                name: "ANTENNA TOTAL POWER (°)",
                key: "potTotal",
                isLockable: true,
                isLocked: false,
                hasValues: true,
                isVisible: false,
              },
              {
                name: "LOCK",
                key: "lock",
                isLockable: false,
                isLocked: false,
                hasValues: false,
                isVisible: true,
              },
            ],
            body: tableBodyRows,
          },
        ];

        return true;
      });

    setConstraintsTableInfos(newConstraintsTableInfos);
  }, [constraints]);

  return (
    <div className={classes.step4Container}>
      <div className={classes.topContainer}>
        <div className={classes.locksInfos}>
          <div className={classes.lock}>
            <LockOpenIcon
              className={classes.lockImg}
              style={{ color: "#018ABE" }}
            />
            <span className={classes.lockText}>Parameter can be modify</span>
          </div>
          <div className={classes.lock}>
            <LockIcon className={classes.lockImg} />
            <span className={classes.lockText}>Locked parameter</span>
          </div>
        </div>
        <div className={classes.buttonsContainer}>
          <Button
            className={classes.button}
            onClick={() => putConstraintsSave(constraintsTableInfos)}
            disabled={!hasWritePermission}
          >
            Save
          </Button>
          <Button
            className={classes.button}
            onClick={() => putConstraintsDefault()}
            disabled={!hasWritePermission}
          >
            Restore initial settings
          </Button>
        </div>
      </div>

      {constraintsTableInfos &&
        constraintsTableInfos.map(
          ({ tenant, tenantIsLocked, headers, body }, tenantIndex) => (
            <div
              className={classes.constraintsTable}
              key={`constraints-table-${tenant}`}
            >
              <div className={classes.tenantHead}>
                <div className={classes.tenantText}>{tenant}</div>
                <IconButton
                  style={{ padding: "0px" }}
                  onClick={() => handleTenantLock(tenantIndex)}
                  size='large'
                  disabled={!hasWritePermission}
                >
                  {tenantIsLocked ? (
                    <LockIcon />
                  ) : (
                    <LockOpenIcon style={{ color: "#018ABE" }} />
                  )}
                </IconButton>
              </div>

              <TableContainer className={classes.tableContainer}>
                <Table>
                  <TableHead className={classes.tableHead}>
                    <TableRow>
                      {headers.map(
                        (el, i) =>
                          el.isVisible && (
                            <TableCell
                              className={classes.tableHeadersContainer}
                              style={{
                                borderRight:
                                  i !== headers.length - 1
                                    ? "2px solid #ffffff"
                                    : "unset",
                              }}
                              key={`constraints-tenant-header-${el.name}`}
                            >
                              <div className={classes.tableHeadersText}>
                                {el.name}
                                {el.isLockable && (
                                  <IconButton
                                    style={{ padding: "0px" }}
                                    onClick={() =>
                                      handleTypeLock(
                                        tenantIndex,
                                        el.key,
                                        el.isLocked
                                      )
                                    }
                                    size='large'
                                    disabled={!hasWritePermission}
                                  >
                                    {el.isLocked ? (
                                      <LockIcon />
                                    ) : (
                                      <LockOpenIcon
                                        style={
                                          hasWritePermission && {
                                            color: "#018ABE",
                                          }
                                        }
                                      />
                                    )}
                                  </IconButton>
                                )}
                              </div>
                              <div className={classes.tableHeadersSubText}>
                                {el.hasValues && (
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      padding: "10px",
                                      width: "75%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontSize: "11px",
                                        lineHeight: "16px",
                                        color: "#A5A5A6",
                                        width: "26%",
                                        marginRight: "5%",
                                      }}
                                    >
                                      Current Value (°)
                                    </div>
                                    <div
                                      style={{
                                        fontSize: "11px",
                                        lineHeight: "16px",
                                        color: "#A5A5A6",
                                        width: "28.5%",
                                        marginRight: "5%",
                                      }}
                                    >
                                      Min Value (°)
                                    </div>
                                    <div
                                      style={{
                                        fontSize: "11px",
                                        lineHeight: "16px",
                                        color: "#A5A5A6",
                                        width: "30%",
                                      }}
                                    >
                                      Max Value (°)
                                    </div>
                                  </div>
                                )}
                              </div>
                            </TableCell>
                          )
                      )}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {body &&
                      body.length > 0 &&
                      body.map((antennaModel, antennaModelIndex) =>
                        antennaModel.map((tech, techIndex) => (
                          <TableRow
                            key={`constraints-table-body-row-${tenant}-${antennaModelIndex}-${techIndex}`}
                            style={{
                              borderTop:
                                techIndex === 0
                                  ? "1px solid rgba(88, 88, 89, 0.5)"
                                  : "unset",
                            }}
                          >
                            {tech.map((cell, cellIndex) => {
                              return (
                                <React.Fragment
                                  key={`constraints-table-body-cell-${tenant}-${antennaModelIndex}-${techIndex}-${cellIndex}`}
                                >
                                  {!cell.hasValues && (
                                    <TableCell
                                      style={{
                                        border: "none",
                                        visibility: cell.isShown
                                          ? "unset"
                                          : "hidden",
                                      }}
                                    >
                                      {cell.value}
                                    </TableCell>
                                  )}
                                  {cell.hasValues && cell.isVisible && (
                                    <TableCell
                                      style={{
                                        border: "none",
                                        padding: "10px",
                                        visibility: cell.isShown
                                          ? "unset"
                                          : "hidden",
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            width: "75%",
                                            visibility: "hidden",
                                          }}
                                        >
                                          <div
                                            style={{
                                              width: "27%",
                                              marginRight: "5%",
                                            }}
                                          >
                                            {cell.value}
                                          </div>
                                          <div
                                            style={{
                                              width: "27%",
                                              marginRight: "7%",
                                            }}
                                          >
                                            {cell.isLocked ? (
                                              cell.value
                                            ) : (
                                              <input
                                                type='number'
                                                value={cell.minValue}
                                                style={{
                                                  width: "100%",
                                                  border: "none",
                                                  outline: "none",
                                                  borderBottom:
                                                    cell.minValue !==
                                                    cell.startingMinValue
                                                      ? "1px solid rgb(3, 184, 255)"
                                                      : "1px solid lightgrey",
                                                  backgroundColor:
                                                    cell.minValue !==
                                                    cell.startingMinValue
                                                      ? "rgba(3, 184, 255, 0.5)"
                                                      : "transparent",
                                                }}
                                                onChange={(e) =>
                                                  handleMinMaxInputs(
                                                    tenantIndex,
                                                    antennaModelIndex,
                                                    techIndex,
                                                    cellIndex,
                                                    "min",
                                                    e.target.value
                                                  )
                                                }
                                                disabled={!hasWritePermission}
                                              />
                                            )}
                                          </div>
                                          <div style={{ width: "30%" }}>
                                            {cell.isLocked ? (
                                              cell.value
                                            ) : (
                                              <input
                                                type='number'
                                                value={cell.maxValue}
                                                style={{
                                                  width: "100%",
                                                  border: "none",
                                                  outline: "none",
                                                  borderBottom:
                                                    cell.maxValue !==
                                                    cell.startingMaxValue
                                                      ? "1px solid rgb(3, 184, 255)"
                                                      : "1px solid lightgrey",
                                                  backgroundColor:
                                                    cell.maxValue !==
                                                    cell.startingMaxValue
                                                      ? "rgba(3, 184, 255, 0.5)"
                                                      : "transparent",
                                                }}
                                                onChange={(e) =>
                                                  handleMinMaxInputs(
                                                    tenantIndex,
                                                    antennaModelIndex,
                                                    techIndex,
                                                    cellIndex,
                                                    "max",
                                                    e.target.value
                                                  )
                                                }
                                                disabled={!hasWritePermission}
                                              />
                                            )}
                                          </div>
                                        </div>

                                        {cell.isLockable && (
                                          <IconButton
                                            style={{ padding: "0px" }}
                                            disabled={!hasWritePermission}
                                            onClick={() => {
                                              howManyLockClick.current++;
                                              if (cell.isLocked) {
                                                handleMinMaxInputs(
                                                  tenantIndex,
                                                  antennaModelIndex,
                                                  techIndex,
                                                  cellIndex,
                                                  "max",
                                                  parseFloat(cell.value) + 1
                                                );
                                                handleMinMaxInputs(
                                                  tenantIndex,
                                                  antennaModelIndex,
                                                  techIndex,
                                                  cellIndex,
                                                  "min",
                                                  parseFloat(cell.value) - 1
                                                );
                                              } else {
                                                handleMinMaxInputs(
                                                  tenantIndex,
                                                  antennaModelIndex,
                                                  techIndex,
                                                  cellIndex,
                                                  "max",
                                                  parseFloat(cell.value)
                                                );
                                                handleMinMaxInputs(
                                                  tenantIndex,
                                                  antennaModelIndex,
                                                  techIndex,
                                                  cellIndex,
                                                  "min",
                                                  parseFloat(cell.value)
                                                );
                                              }
                                              handleSpecificLock(
                                                tenantIndex,
                                                antennaModelIndex,
                                                techIndex,
                                                cellIndex,
                                                cell.isLocked
                                              );
                                            }}
                                            size='large'
                                          >
                                            {cell.isLocked ? (
                                              <LockIcon />
                                            ) : (
                                              <LockOpenIcon
                                                style={
                                                  hasWritePermission
                                                    ? {
                                                        color: "#018ABE",
                                                      }
                                                    : {
                                                        color:
                                                          "rgba(0, 0, 0, 0.26)",
                                                      }
                                                }
                                              />
                                            )}
                                          </IconButton>
                                        )}
                                      </div>
                                    </TableCell>
                                  )}
                                </React.Fragment>
                              );
                            })}
                          </TableRow>
                        ))
                      )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          )
        )}
    </div>
  );
};

const mapState = ({ simulationsStepper }) => ({
  constraints: simulationsStepper.constraints,
});

const mapDispatch = ({
  simulationsStepper: { putConstraintsDefault, putConstraintsSave },
}) => ({
  putConstraintsDefault: () => putConstraintsDefault(),
  putConstraintsSave: (constraints) => putConstraintsSave({ constraints }),
});

export default compose(
  withStyles(useStyles),
  connect(mapState, mapDispatch)
)(SimulationsStepperStep4);
