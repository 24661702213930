import axios from "axios";
import {
  GET_MANUFACTURERS_LIST,
  GET_ANTENNA_BY_PARAMS,
  GET_ANTENNA_MSI_INFO,
  tokenExpiredDoLogin,
} from "../../utils/api";

const antennas = {
  state: {
    manufacturersList: [],
    antennasParamSearch: {},
    antennasList: null,
    antennaMsiParams: null,
    antennaMsiInfo: null,
  },
  reducers: {
    resetAntennas(state) {
      return {
        ...state,
        manufacturersList: [],
        antennasParamSearch: {},
        antennasList: null,
        antennaMsiParams: null,
        antennaMsiInfo: null,
      };
    },
    setManufacturersList(state, value) {
      return { ...state, manufacturersList: value };
    },
    setAntennasParamSearch(state, { key, value }) {
      if (Object.keys(state).length === 0) return {};
      return {
        ...state,
        antennasParamSearch: { ...state.antennasParamSearch, [key]: value },
      };
    },
    setAntennasList(state, value) {
      return { ...state, antennasList: value };
    },
    resetAntennasList(state, _value) {
      return { ...state, antennasList: null };
    },
    setAntennaMsiParams(state, value) {
      return {
        ...state,
        antennaMsiParams: value,
      };
    },
    setAntennaMsiInfo(state, value) {
      return { ...state, antennaMsiInfo: value };
    },
  },
  effects: (dispatch) => ({
    async getManufacturersList() {
      dispatch.loader.loader(true);
      this.setManufacturersList(null);

      await axios({
        method: "GET",
        url: GET_MANUFACTURERS_LIST,
        headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
      })
        .then((res) => {
          this.setManufacturersList(res.data.data);
          dispatch.loader.loader(false);
        })
        .catch((err) => {
          console.log(`Error ${GET_MANUFACTURERS_LIST}`, err);
          tokenExpiredDoLogin(err);
          dispatch.loader.loader(false);
        });
    },
    async getAntennasList(searchParams) {
      dispatch.loader.loader(true);

      this.setAntennasList(null);

      const model_code = searchParams.model_code || "";
      const manufacturer = searchParams.manufacturer || "";
      const freq_max = searchParams.freq_max || "";
      const freq_min = searchParams.freq_min || "";
      this.setAntennasParamSearch({});

      await axios({
        method: "GET",
        url: GET_ANTENNA_BY_PARAMS,
        headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        params: { model_code, manufacturer, freq_max, freq_min },
      })
        .then((res) => {
          this.setAntennasList(res.data.data);
          dispatch.loader.loader(false);
        })
        .catch((err) => {
          console.log(`Error ${GET_ANTENNA_BY_PARAMS}`, err);
          tokenExpiredDoLogin(err);
          dispatch.loader.loader(false);
        });
    },
    async getAntennaMsiInfo(searchParams) {
      dispatch.loader.loader(true);

      this.setAntennaMsiInfo(null);

      const model_code = searchParams.model_code || "";
      const freq_max = searchParams.freq_max || "";
      const freq_min = searchParams.freq_min || "";
      this.setAntennaMsiParams({});

      await axios({
        method: "GET",
        url: GET_ANTENNA_MSI_INFO,
        headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        params: { model_code, freq_max, freq_min },
      })
        .then((res) => {
          this.setAntennaMsiInfo(res.data.data);
          dispatch.loader.loader(false);
        })
        .catch((err) => {
          console.log(`Error ${GET_ANTENNA_BY_PARAMS}`, err);
          tokenExpiredDoLogin(err);
          dispatch.loader.loader(false);
        });
    },
  }),
};

export default antennas;
