import React from "react";
import { CircularProgress } from "@mui/material";
import { Check, Error, Report } from "@mui/icons-material";
import axios from "axios";
import { POST_ERROR_LOG } from "./api";

const classes = {
  container: {
    width: "100%",
    minHeight: "80vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "1.2rem",
  },
  text: {
    margin: "1rem 0",
    padding: "0",
  },
  button: {
    padding: "0.5em 1.3em",
    fontSize: "1.5rem",
    borderRadius: "15px",
    outline: "none",
    border: "none",
    background: "#03B8FF",
    color: "#fff",
    marginTop: "1rem",
    cursor: "pointer",
  },
  loadingContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
};

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      errorInfo: "",
      errorSent: false,
      logFailed: false,
      loading: true,
    };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    // window.location.reload();

    console.error(error, errorInfo);

    // LOG ERROR HERE
    const logError = async (error) => {
      if (error)
        await axios({
          method: "POST",
          url: POST_ERROR_LOG,
          headers: {
            authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          data: {
            logtype: "ERROR",
            module: "FE",
            message: `${error.toString()} \n ${errorInfo.componentStack}`,
          },
        })
          .then((res) => {
            this.setState({ ...this.state, errorSent: true, loading: false });
          })
          .catch((err) => {
            console.log(`Error - ${POST_ERROR_LOG}`, err);
            // tokenExpiredDoLogin();
            this.setState({ ...this.state, logFailed: true, loading: false });
          });
    };

    logError(error);
  }

  handleRefresh() {
    window.location.reload();
  }

  render() {
    const { errorSent, logFailed, loading } = this.state;
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div style={classes.container}>
          <h1>An error occurs...</h1>
          <Error color='primary' fontSize='large' />
          <p>
            Sorry for the inconvenience. We suggest to{" "}
            <strong>refresh the page</strong> to resolve the issue.
          </p>
          <div
            style={{
              display: "flex",
              gap: "1rem",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {loading && (
              <>
                <span>Sending the error to our engineers.</span>
                <CircularProgress />
              </>
            )}
            {errorSent && (
              <>
                <span>Error successfully reported.</span>
                <Check sx={{ color: "#06ab06" }} fontSize='large' />
              </>
            )}
            {logFailed && (
              <>
                <span>Failed to report this error.</span>
                <Report sx={{ color: "#d90b0b" }} fontSize='large' />
              </>
            )}
          </div>
          <button
            onClick={this.handleRefresh}
            style={classes.button}
            disabled={loading}
          >
            Refresh now
          </button>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
