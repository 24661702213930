import React, { useState, useEffect, useCallback, useContext } from "react";
import withStyles from "@mui/styles/withStyles";
import { connect } from "react-redux";
import { compose } from "redux";
import {
  Button,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  TextField,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ColorPicker from "../ColorPicker";
import { simulationsStepperThresholdsHeaders } from "../../utils/tableHeaders";
import { UserRoleContext } from "../../contexts/user-role.context";

const useStyles = (theme) => ({
  step3Container: {},
  buttonsContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  button: {
    fontWeight: "bold",
    height: "32px",
    padding: "3px 24px",
    background: "white",
    border: "0.5px solid #018ABE",
    borderRadius: "100px",
    font: "700 13px/20px",
    color: "#018ABE",
    marginLeft: "13px",
    textTransform: "none",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    minWidth: "64px",
    "&:hover": {
      backgroundColor: "white",
      cursor: "pointer",
      outline: "none",
    },
    "&:focus": {
      outline: "none",
    },
    "&:active": {
      outline: "none",
    },
  },
  thresholdsTable: {
    height: "calc(100vh - 250px)",
  },
  tableContainer: {
    width: "100%",
    height: "100%",
    zIndex: 0,
    marginTop: "25px",
  },
  tableHeaderRow: {
    height: "80px",
  },
  tableHeaderCell: {
    backgroundColor: "#FFF",
    fontWeight: "bold",
    color: "#000000",
    "&:hover": {
      color: "#000000",
    },
  },
  tableBodyRow: {
    height: "80px",
    "&:hover": {
      background: "rgba(236, 236, 237, 0.5) !important",
    },
  },
  tableBodyCell: {
    minWidth: "50px",
  },
  textField: {
    width: "40px",
  },
});

function isUnique(arr) {
  const seenValues = {};

  for (let i = 0; i < arr.length; i++) {
    if (seenValues[arr[i]]) {
      return false;
    } else {
      seenValues[arr[i]] = true;
    }
  }

  return true;
}

const SimulationsStepperStep3 = ({
  classes,
  thresholds,
  setThresholds,
  setThresholdsAreOk,
  setColorsAreOk,
}) => {
  const [pickerOpened, setPickerOpened] = useState([]);
  const { hasWritePermission } = useContext(UserRoleContext);

  const tableInfos = {
    tableHeaders: simulationsStepperThresholdsHeaders,
    tableRows:
      thresholds?.length > 0
        ? thresholds.map(({ threshold, color }, i) => [
            Number(threshold),
            color,
            "delete" ,
          ])
        : [],
  };

  const { tableHeaders, tableRows } = tableInfos;

  const chromePickerHandler = useCallback((i) => {
    setPickerOpened({ ...pickerOpened, [i]: !pickerOpened[i] });
  });
  const clickAwayHandler = useCallback(() => {
    setPickerOpened([]);
  });

  const handleThresholdChange = (value, i) => {
    const newThresholds = [...Object.values(thresholds)];
    newThresholds[i].threshold = Number(value);
    setThresholds(newThresholds);
  };

  const handleColorChange = (color, i) => {
    const newThresholds = [...Object.values(thresholds)];
    newThresholds[i].color = color.hex;
    setThresholds(newThresholds);
  };

  const handleAddThreshold = () => {
    const newThresholds = [...thresholds, { threshold: 1, color: "#000000" }];
    setThresholds([...newThresholds]);
  };

  const handleRemoveThreshold = (i) => {
    const newThresholds = [...Object.values(thresholds)];
    delete newThresholds[i];
    setThresholds(newThresholds.filter((el) => el));
  };

  useEffect(() => {
    const thresholdsAreUnique = isUnique(
      thresholds?.map((el) => el.threshold) || false
    );
    const colorsAreUnique = isUnique(
      thresholds?.map((el) => el.color) || false
    );
    setThresholdsAreOk(thresholdsAreUnique);
    setColorsAreOk(colorsAreUnique);
  }, [thresholds]);

  return (
    <div className={classes.step3Container}>
      <div className={classes.buttonsContainer}>
        <Button
          className={classes.button}
          onClick={() => handleAddThreshold()}
          disabled={!hasWritePermission}
        >
          Add Threshold
        </Button>
      </div>

      <div className={classes.thresholdsTable}>
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table stickyHeader>
            <TableHead>
              <TableRow className={classes.tableHeaderRow}>
                {tableHeaders &&
                  tableHeaders.map((el) => (
                    <TableCell
                      key={`table-header-${el.key}`}
                      className={classes.tableHeaderCell}
                      style={{ width: el.name === "delete" ? "50px" : "unset" }}
                    >
                      {el.name}
                    </TableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody className={classes.tableBody}>
              {tableRows &&
                tableRows.map((row, i) => (
                  <TableRow
                    className={classes.tableBodyRow}
                    key={`table-body-row-${i}`}
                  >
                    {row.map((cell, j) => (
                      <TableCell
                        className={classes.tableBodyCell}
                        key={`table-body-cell-${i}-${j}`}
                        align={cell !== "delete" ? "left" : "right"}
                      >
                        {cell !== "delete" &&
                          (j === 0 ? (
                            <TextField
                              classes={{ root: classes.textField }}
                              type='number'
                              variant='standard'
                              value={cell}
                              onChange={(e) =>
                                handleThresholdChange(Number(e.target.value), i)
                              }
                              disabled={!hasWritePermission}
                            />
                          ) : j === 1 ? (
                            <ColorPicker
                              index={i}
                              color={cell}
                              opened={pickerOpened[i] || false}
                              handlePicker={(i) => chromePickerHandler(i)}
                              handleColorChange={handleColorChange}
                              clickAwayHandler={() => clickAwayHandler()}
                              disabled={!hasWritePermission}
                            />
                          ) : (
                            <span style={{ width: "50px" }}>{cell}</span>
                          ))}
                        {cell === "delete" && (
                          <IconButton
                            color='primary'
                            onClick={() => handleRemoveThreshold(i)}
                            size='large'
                            disabled={!hasWritePermission}
                          >
                            <DeleteIcon />
                          </IconButton>
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

const mapState = ({ simulationsStepper }) => ({
  thresholds: simulationsStepper.thresholds,
});

const mapDispatch = ({
  simulationsStepper: { setThresholds, setThresholdsAreOk, setColorsAreOk },
}) => ({
  setThresholds: (value) => setThresholds(value),
  setThresholdsAreOk: (value) => setThresholdsAreOk(value),
  setColorsAreOk: (value) => setColorsAreOk(value),
});

export default compose(
  withStyles(useStyles),
  connect(mapState, mapDispatch)
)(SimulationsStepperStep3);
