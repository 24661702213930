import React, { useState, useRef, useLayoutEffect, useContext } from "react";
import withStyles from "@mui/styles/withStyles";
import { connect } from "react-redux";
import { compose } from "redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { v4 as uuidv4 } from "uuid";
import MainMapBox from "../../components/MainMapBox";
import MassiveList from "./MassiveList";
import {
  Grid,
  LinearProgress,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Snackbar,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { MoreVert, Check, Close, Replay } from "@mui/icons-material";
import TransitionsModal from "./modal";
import { UserRoleContext } from "../../contexts/user-role.context";

const useStyles = (theme) => ({
  button: {
    padding: "3px 24px",
    background: "white",
    border: "0.5px solid #018ABE",
    borderRadius: "100px",
    font: "700 13px/20px",
    color: "#018ABE",
    marginLeft: "13px",
    textTransform: "none",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    minWidth: "64px",
    "&:hover": {
      backgroundColor: "white",
      cursor: "pointer",
      outline: "none",
    },
    "&:focus": {
      outline: "none",
    },
    "&:active": {
      outline: "none",
    },
  },
  leftTab: {
    // border: '3px solid black',
    height: "90vh",
  },
  centerTab: {
    // border: '3px solid gold',
    height: "90vh",
    padding: "0px",

    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
  },
  rightTab: {
    // border: '3px solid green',
    height: "90vh",
  },
  topLeft: {
    height: "10%",
    // border: '3px solid gold',

    fontSize: "1.5em",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  middleLeft: {
    height: "90%",
    // border: '3px solid brown',

    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  bottomLeft: {
    height: "10%",
    // border: '3px solid grey',

    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  topRight: {
    height: "10%",
    // border: '3px solid gold',

    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  middleTopRight: {
    height: "35%",
    // border: '3px solid brown',

    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  middleBottomRight: {
    height: "20%",
    // border: '3px solid blue',

    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  bottomRight: {
    height: "35%",
    // border: '3px solid purple',

    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  middleBottomRightBox: {
    height: "100vh",
    display: "flex",
    // flexWrap: 'wrap',
    flexDirection: "row",
    justifyContent: "center",
    alignContent: "center",
  },
  progress: {
    backgroundColor: "lightblue",
    width: "100%",
    /* margin-left: 1%; */
    marginTop: 0,
    marginBottom: 0,
  },
});

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#24b7fc",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const Massive = ({
  classes,
  setErrorMessages,
  errorMessages,
  setErrorMessagesAsString,
  errorMessagesAsString,
  warningMessages,
  setWarningMessages,
  warningMessagesAsString,
  setWarningMessagesAsString,
  uploadMassive,
  massiveSites,
  runSimulation,
  selectedMassive,
  getSimulationStatistics,
  statisticalInfos,
  exportFinalMassiveConfiguration,
  exportMassiveResult,
  exportMassiveSimulationErrors,
  exportMassivePresenceOfDtmAndShapefile,
  resetMapParams,
}) => {
  const [siteDetailsAvailable, setSiteDetailsAvailable] = useState(true);
  const [runSimulationDisabled, setRunSimulationDisabled] = useState(true);
  const [updateStatisticsDisabled, setUpdateStatisticsDisabled] =
    useState(true);
  const [optimized, setOptimized] = useState(false);
  const [progressBarValue, setProgressBarValue] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [refreshList, setRefreshList] = useState(false);
  const [offset, setOffset] = useState(null);
  const [mapKey, setMapKey] = useState(uuidv4());

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const popoverRef = useRef(null);

  const { hasWritePermission } = useContext(UserRoleContext);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setErrorMessages([]);
    setErrorMessagesAsString(null);
    setWarningMessages([]);
    setWarningMessagesAsString(null);
    window.location.reload();
  };

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleUpload = async (e) => {
    const file = e.target.files[0];
    await uploadMassive({ file, em_offset: parseFloat(offset) });
    setRefreshList(true);
    // if (refreshRef.current) refreshRef.current.refreshMassiveList()
  };

  const updateProgressBar = () => {
    if (!statisticalInfos || statisticalInfos.length === 0) return false;
    const howManyDone = statisticalInfos
      .filter((el) => el.status.toLowerCase() === "done")
      .reduce((a, b) => a + b.count, 0);

    const totalCount = statisticalInfos.reduce((a, b) => a + b.count, 0);

    if (howManyDone === totalCount) {
      setProgressBarValue(100);
    } else {
      setProgressBarValue((howManyDone / totalCount) * 100);
    }
  };

  useLayoutEffect(() => {
    const newKey = uuidv4();
    resetMapParams();
    setMapKey(newKey);
  }, []);

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={
          errorMessages.length > 0 || warningMessages.length > 0 ? true : false
        }
      >
        <Alert
          onClose={handleCloseModal}
          severity={errorMessages.length > 0 ? "error" : "warning"}
        >
          {errorMessages.length > 0
            ? `Some errors have been found.`
            : `${warningMessages.length} warnings have been found`}
          <Link href='#' rel='noopener' onClick={handleOpenModal}>
            {" "}
            View
          </Link>{" "}
          or{" "}
          <Link href='#' rel='noopener'>
            {errorMessagesAsString && (
              <CopyToClipboard
                text={errorMessagesAsString}
                onCopy={handleCloseModal}
              >
                <span>Copy to clipboard</span>
              </CopyToClipboard>
            )}
            {warningMessagesAsString && (
              <CopyToClipboard
                text={warningMessagesAsString}
                onCopy={handleCloseModal}
              >
                <span>Copy to clipboard</span>
              </CopyToClipboard>
            )}
          </Link>
        </Alert>
      </Snackbar>
      {openModal && (
        <TransitionsModal
          handleCloseModal={handleCloseModal}
          isOpen={openModal}
          errorMessages={errorMessages}
          warningMessages={warningMessages}
        />
      )}
      <Box paddingLeft='1em' justifycontent='center' alignItems='center'>
        <h2>Massive Page</h2>
      </Box>
      <Box id='loader'>
        <h5></h5>
      </Box>
      <Grid
        container
        direction='row'
        justifycontent='center'
        alignItems='center'
      >
        <Box className={classes.progress}>
          <LinearProgress variant='determinate' value={progressBarValue} />
          {/* <div className='determinate' style='width: 0%'></div> */}
        </Box>
        <Grid item sm={3} className={classes.leftTab}>
          <Box className={classes.topLeft}>
            <TextField
              style={{ width: "20%" }}
              variant='standard'
              id='offset'
              label='EM Offset'
              value={offset || 0}
              onChange={(e) => setOffset(e.target.value)}
              type='number'
              pattern='[+-]?\d+(?:[.,]\d+)?'
              disabled={!hasWritePermission}
            />
            <input
              hidden
              id='button-upload-massive'
              onChange={handleUpload}
              type='file'
              accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
              disabled={!hasWritePermission}
            />
            <label htmlFor='button-upload-massive'>
              <Button
                className={classes.button}
                component='span'
                disabled={!hasWritePermission}
              >
                Import Massive Excel/CSV
              </Button>
            </label>
            <Tooltip title='Refresh List' placement='top'>
              <Box>
                <IconButton
                  onClick={() => {
                    window.location.reload();
                  }}
                  size='large'
                >
                  <Replay
                    color='primary'
                    style={{
                      WebkitTransform: "scaleX(-1)",
                      transform: "scaleX(-1)",
                    }}
                  />
                </IconButton>
              </Box>
            </Tooltip>
          </Box>

          <Box className={classes.middleLeft} ref={popoverRef}>
            <MassiveList
              setSiteDetailsAvailable={setSiteDetailsAvailable}
              popoverRef={popoverRef}
              setRunSimulationDisabled={setRunSimulationDisabled}
              setUpdateStatisticsDisabled={setUpdateStatisticsDisabled}
              refreshList={refreshList}
              setRefreshList={setRefreshList}
            />
          </Box>
        </Grid>
        <Grid item sm={6} className={classes.centerTab}>
          <Box height='90vh' width='100%'>
            <MainMapBox resetAll={true} key={mapKey} />
          </Box>
        </Grid>
        <Grid item sm={3} className={classes.rightTab}>
          <Box className={classes.topRight}>
            <Button
              id='run-simulation'
              className={classes.button}
              disabled={runSimulationDisabled || !hasWritePermission}
              onClick={async () => {
                await runSimulation({
                  id_massive: selectedMassive,
                  to_be_optimized: optimized,
                });
                await getSimulationStatistics(selectedMassive);
              }}
            >
              Run simulation
            </Button>
            <Tooltip title='Optimize this simulation' placement='top'>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={runSimulationDisabled || !hasWritePermission}
                    checked={optimized}
                    onChange={() => {
                      setOptimized((prev) => !prev);
                    }}
                    name='Opt'
                    color='primary'
                  />
                }
                labelPlacement='start'
                label='Opt'
              />
            </Tooltip>

            <Tooltip title='Update statistics' placement='top'>
              <Box>
                <IconButton
                  disabled={updateStatisticsDisabled}
                  onClick={async () => {
                    await getSimulationStatistics(selectedMassive);
                    updateProgressBar();
                  }}
                  size='large'
                >
                  <Replay
                    style={{
                      color: updateStatisticsDisabled ? "lightgray" : "#018ABE",
                      WebkitTransform: "scaleX(-1)",
                      transform: "scaleX(-1)",
                    }}
                  />
                </IconButton>
              </Box>
            </Tooltip>
          </Box>
          <Box className={classes.middleTopRight}>
            {statisticalInfos.length > 0 && (
              <TableContainer style={{ maxHeight: "30vh" }}>
                <Table stickyHeader aria-label='customized table'>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align='center'>Count</StyledTableCell>
                      <StyledTableCell align='center'>Status</StyledTableCell>
                      <StyledTableCell align='center'>
                        Final Result
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {statisticalInfos.map((row) => {
                      return (
                        <StyledTableRow key={uuidv4()}>
                          <StyledTableCell align='center'>
                            {row.count}
                          </StyledTableCell>
                          <StyledTableCell align='center'>
                            {row.status}
                          </StyledTableCell>
                          <StyledTableCell align='center'>
                            {row.final_result}
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Box>
          <Box className={classes.middleBottomRight}>
            <h2>Export Reports</h2>
            <IconButton
              aria-label='more'
              aria-controls='long-menu'
              aria-haspopup='true'
              onClick={handleOpenMenu}
              disabled={statisticalInfos.length === 0 ? true : false}
              size='large'
            >
              <MoreVert
                // color={statisticalInfos.length === 0 ? "lightgray" : "#018ABE"}
                primary={
                  statisticalInfos.length === 0 ? "lightgray" : "#018ABE"
                }
              />
            </IconButton>
            <Menu
              id='long-menu'
              anchorEl={anchorEl}
              keepMounted
              open={open}
              onClose={handleCloseMenu}
              PaperProps={{
                style: {
                  maxHeight: 48 * 4.5,
                  width: "20ch",
                },
              }}
            >
              {statisticalInfos.length > 0 &&
                statisticalInfos.find((el) => el.status === "DONE") && (
                  <MenuItem
                    onClick={() => {
                      exportMassiveResult(selectedMassive);
                    }}
                  >
                    Massive Result
                  </MenuItem>
                )}
              {statisticalInfos.length > 0 &&
                statisticalInfos.find((el) => el.status === "DONE") && (
                  <MenuItem
                    onClick={() => {
                      exportFinalMassiveConfiguration(selectedMassive);
                    }}
                  >
                    Final Configuration
                  </MenuItem>
                )}
              {statisticalInfos.length > 0 &&
                statisticalInfos.find((el) => el.final_result === "ERROR") && (
                  <MenuItem
                    onClick={() => {
                      exportMassiveSimulationErrors(selectedMassive);
                    }}
                  >
                    Simulation Errors
                  </MenuItem>
                )}
              {massiveSites.length > 0 && (
                <MenuItem
                  onClick={() => {
                    exportMassivePresenceOfDtmAndShapefile(selectedMassive);
                  }}
                >
                  DTM and Shapefile
                </MenuItem>
              )}
            </Menu>
            {/* <Box className={classes.middleBottomRightBox}>
            <Button
              id='create-result-report'
              className={classes.button}
              disabled={
                statisticalInfos.length > 0 &&
                statisticalInfos.find((el) => el.status === 'DONE')
                  ? false
                  : true
              }
              onClick={() => {
                exportMassiveResult(selectedMassive)
              }}
            >
              Massive Result
            </Button>
            <Button
              id='create-config-report'
              className={classes.button}
              disabled={
                statisticalInfos.length > 0 &&
                statisticalInfos.find((el) => el.status === 'DONE')
                  ? false
                  : true
              }
              onClick={() => {
                exportFinalMassiveConfiguration(selectedMassive)
              }}
            >
              Final Config
            </Button>
            <Button
              id='create-result-report'
              className={classes.button}
              disabled={
                statisticalInfos.length > 0 &&
                statisticalInfos.find((el) => el.final_result === 'ERROR')
                  ? false
                  : true
              }
              onClick={() => {
                exportMassiveSimulationErrors(selectedMassive)
              }}
            >
              Simulation Errors
            </Button>
          </Box> */}
          </Box>

          <Box className={classes.bottomRight}>
            {massiveSites.length > 0 && (
              <TableContainer style={{ maxHeight: "30vh" }}>
                <Table stickyHeader aria-label='customized table'>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align='center'>
                        Site Code
                      </StyledTableCell>
                      <StyledTableCell align='center'>DTM</StyledTableCell>
                      <StyledTableCell align='center'>
                        Shapefile
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {massiveSites.map((row) => {
                      return (
                        <StyledTableRow key={uuidv4()}>
                          <StyledTableCell align='center'>
                            {row.site_code}
                          </StyledTableCell>
                          <StyledTableCell align='center'>
                            {row.hasDtm ? (
                              <Check style={{ color: "green" }} />
                            ) : (
                              <Close style={{ color: "red" }} />
                            )}
                          </StyledTableCell>
                          <StyledTableCell align='center'>
                            {row.hasShapefile ? (
                              <Check style={{ color: "green" }} />
                            ) : (
                              <Close style={{ color: "red" }} />
                            )}
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Box>
        </Grid>
      </Grid>
      {/* </div> */}
    </>
  );
};

const massiveState = ({ massive }) => ({
  massiveList: massive.massiveList,
  massiveSites: massive.massiveSites,
  selectedMassive: massive.selectedMassive,
  statisticalInfos: massive.statisticalInfos,
  errorMessages: massive.errorMessages,
  errorMessagesAsString: massive.errorMessagesAsString,
  warningMessages: massive.warningMessages,
  warningMessagesAsString: massive.warningMessagesAsString,
});

const massiveDispatch = ({
  massive: {
    setErrorMessages,
    setErrorMessagesAsString,
    setWarningMessages,
    setWarningMessagesAsString,
    uploadMassive,
    getMassiveList,
    cloneMassive,
    deleteMassive,
    getMassiveSites,
    checkDtmAndShapefile,
    runSimulation,
    getSimulationStatistics,
    exportFinalMassiveConfiguration,
    exportMassiveResult,
    exportMassiveSimulationErrors,
    exportMassivePresenceOfDtmAndShapefile,
    resetState,
  },
  map: { setMassives, setOffices, setNeighbourhood, resetMapParams },
}) => ({
  setErrorMessages: (value) => setErrorMessages(value),
  setErrorMessagesAsString: (value) => setErrorMessagesAsString(value),
  setWarningMessages: (value) => setWarningMessages(value),
  setWarningMessagesAsString: (value) => setWarningMessagesAsString(value),
  uploadMassive: (value) => uploadMassive(value),
  getMassiveList: () => getMassiveList(),
  cloneMassive: (message) => cloneMassive(message),
  deleteMassive: (id) => deleteMassive(id),
  getMassiveSites: (id) => getMassiveSites(id),
  checkDtmAndShapefile: (id, massiveSites) =>
    checkDtmAndShapefile(id, massiveSites),
  runSimulation: (message) => runSimulation(message),
  getSimulationStatistics: (id) => getSimulationStatistics(id),
  exportFinalMassiveConfiguration: (id) => exportFinalMassiveConfiguration(id),
  exportMassiveResult: (id) => exportMassiveResult(id),
  exportMassiveSimulationErrors: (id) => exportMassiveSimulationErrors(id),
  exportMassivePresenceOfDtmAndShapefile: (id) =>
    exportMassivePresenceOfDtmAndShapefile(id),
  setMassives: (value) => setMassives(value),
  resetMassiveState: () => resetState(),
  setOffices: (value) => setOffices(value),
  setNeighbourhood: (value) => setNeighbourhood(value),
  resetMapParams: () => resetMapParams(),
});

export default compose(
  withStyles(useStyles),
  connect(massiveState, massiveDispatch)
)(Massive);
