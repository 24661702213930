import React, { useState, useEffect } from "react";

import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import useHttpService from "../../hooks/useHttpService";

import { v4 as uuidv4 } from "uuid";

export default function TextFieldCombo(props) {
  const [myURL, setURL] = useState(props.apiURL || "");
  const [selectedValue, setSelected] = useState(props.selectedValue || "");
  const [myData, setData] = useState([]);

  const httpService = useHttpService();

  function queryAPI() {
    if (!myURL) {
      if (props.mockedData) {
        setData(props.mockedData);
      } else {
        setData([]);
      }
      return;
    }
    httpService.getAPI(myURL, onDataReceived);
  }

  function onDataReceived(data) {
    setData(data);
  }

  function selectChange(e, index) {
    //setSelected(e.target.value);

    if (props.multiple && props.onSelectChange) {
      let value = index.map((el) => {
        return el[props.valueMember];
      }); //this is actually the value, not the index
      props.onSelectChange({
        target: {
          value,
          type: props.type,
          name: props.name,
          row:
            myData.find((row) => row[props.valueMember] === index) ||
            props.mockedData?.find((row) => row === index),
        },
      });
      return;
    }

    if (props.onSelectChange)
      props.onSelectChange({
        target: {
          value: e.target.value,
          type: props.type,
          name: props.name,
          row:
            myData.find((row) => row[props.valueMember] === e.target.value) ||
            props.mockedData[index],
        },
      }); //props.onSelectChange(myData.find(row => row[props.valueMember] === e.target.value));
  }

  // AGGIORNARE LA TABELLA DEGLI ALARM NEL CASO IN CUI NON CI SIANO DATI

  // POI VEDERE COME MAI I DEFAULTVALUE VENGONO NULL
  useEffect(() => {
    if (
      props.jsonDefaultValue &&
      JSON.stringify(props.jsonDefaultValue) !== "{}"
    ) {
      Object.keys(props.jsonDefaultValue).forEach((key) => {
        setSelected(null, props.jsonDefaultValue[key]);
      });
    }
  }, [props.jsonDefaultValue]);

  useEffect(() => {
    queryAPI();
    if (props.selectedValue) setSelected(props.selectedValue);
    else setSelected("");
  }, [myURL]);

  useEffect(() => {
    setURL(props.apiURL);
    if (props.selectedValue) setSelected(props.selectedValue);
    else setSelected("");
  }, [props]);

  return (
    <>
      {(myData || props.mockedData) && props.multiple ? (
        <Autocomplete
          multiple={props.multiple}
          id='checkboxes-tags-demo'
          options={myData || props.mockedData}
          disableCloseOnSelect={props.multiple}
          limitTags={props.multiple ? 2 : null}
          getOptionLabel={(option) => {
            return option[props.valueMember] || option;
          }}
          onChange={(e, value) => {
            selectChange(e, value);
          }}
          renderInput={(params) => {
            return (
              <TextField {...params} label={props.label} variant='standard' />
            );
          }}
          disabled={props.disabled}
          // defaultValue={props.defaultValue}
        />
      ) : (
        <TextField
          helperText={props.helperText || ""}
          fullWidth
          name={props.name || ""}
          sx={{ width: props.fieldSize }}
          select
          label={props.label || ""}
          value={selectedValue}
          onChange={selectChange}
          variant='standard'
          required={props.required || false}
          error={props.error || false}
          disabled={props.disabled}
        >
          {myData.map((option) => (
            <MenuItem key={uuidv4()} value={option[props.valueMember]}>
              {option[props.displayMember]}
            </MenuItem>
          ))}
        </TextField>
      )}
    </>
  );
}
