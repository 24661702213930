import React, { Suspense, useEffect } from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import ErrorBoundary from "./utils/ErrorBoundary";
import "./index.css";
import "./assets/styles/index.scss";
import store from "./store";
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
} from "@mui/material/styles";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import PrivateRoutes from "./components/CustomRoutes/PrivateRoutes";
import RestrictedRoutes from "./components/CustomRoutes/RestrictedRoutes";
import { Provider } from "react-redux";
import { tokenExpiredDoLogin } from "./utils/api";
import { ConfirmProvider } from "material-ui-confirm";

import { UserRoleProvider } from "./contexts/user-role.context";
import { SnackbarProvider } from "./contexts/snackbar.context";

import Header from "./components/Header";
import Loader from "./components/Loader";

import CEMLogin from "./pages/CEMLogin";
import CEMLanding from "./pages/CEMLanding";
import CEMSite from "./pages/CEMSite";
import Antenne from "./pages/Antenne";

import Massive from "./pages/Massive";
import { ManageSites, ManageTechnologies, ManageTenants, ManageSitesInwi,ManageMsi,ManageTenantMassive} from "./pages/Manage";
import ManageTasks from "./pages/Tasks";
import AntennaMsi from "./pages/Antenne/antenna_msi";

const theme = createTheme({
  palette: {
    primary: { main: "#03B8FF" },
    secondary: { main: "#018ABE" },
  },
});

const Routing = () => {
  useEffect(() => {
    const token = localStorage.getItem("token");
    const loginUrl = `${window.location.origin}/Efesto/login`;

    if (!token && window.location.href !== loginUrl) {
      window.location.href = loginUrl;
      tokenExpiredDoLogin();
    }
  }, [localStorage.getItem("token")]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <Suspense fallback={<Loader isLoading />}>
            <React.StrictMode>
              <BrowserRouter basename={process.env.PUBLIC_URL}>
                <Loader />
                <UserRoleProvider>
                  <SnackbarProvider>
                    <ConfirmProvider>
                      <ErrorBoundary>
                        <Routes>
                          <Route path='/' element={<Header />}>
                            <Route element={<PrivateRoutes />}>
                              <Route index element={<CEMLanding />} />
                              <Route path='/cem' element={<CEMLanding />} />
                              <Route
                                path='/cem/site/:siteId/:section'
                                element={<CEMSite />}
                              />
                              <Route path='/antenne' element={<Antenne />} />
                              <Route path='/antenne/:manufacturer/:model_code/:freq_min/:freq_max' element={<AntennaMsi />} />
                              <Route path='/massivi' element={<Massive />} />
                              <Route path='/tasks' element={<ManageTasks />} />
                            </Route>
                            <Route element={<RestrictedRoutes />}>
                              <Route
                                path='/manage/sites'
                                element={<ManageSites />}
                              />
                              <Route
                                path='/manage/tenants/*'
                                element={<ManageTenants />}
                              />
                              <Route
                                path='/manage/technologies'
                                element={<ManageTechnologies />}
                              />
                              <Route
                                path='/manage/sites_inwi'
                                element={<ManageSitesInwi/>}
                              />
                              <Route
                                path='/manage/msi_upload'
                                element={<ManageMsi/>}
                              />
                              <Route
                                path='/manage/tenant_actual_import'
                                element={<ManageTenantMassive/>}
                              />
                              
                            </Route>
                            <Route path='/login' element={<CEMLogin />} />
                          </Route>
                        </Routes>
                      </ErrorBoundary>
                    </ConfirmProvider>
                  </SnackbarProvider>
                </UserRoleProvider>
              </BrowserRouter>
            </React.StrictMode>
          </Suspense>
        </Provider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

ReactDOM.render(<Routing />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
