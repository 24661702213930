import React, { useState, useEffect } from "react";
import withStyles from "@mui/styles/withStyles";
import { connect } from "react-redux";
import { compose } from "redux";
import { Tabs, Tab, IconButton, CircularProgress } from "@mui/material";
import DownloadIcon from "@mui/icons-material/GetApp";

const useStyles = (theme) => ({
  downloadFileContainer: {
    padding: "40px",
  },
  tab: {
    fontWeight: "bold",
    textTransform: "none",
    padding: "0 30px",
  },
  tabContainer: {
    padding: "20px 0px",
    marginTop: "10px",
    height: "calc(100vh - 280px)",
    overflowY: "auto",
  },
  fileHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #EFF2F1",
    padding: "30px 0",
    fontWeight: "bold",
  },
  file: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #EFF2F1",
    padding: "30px 0",
  },
  downloadAllButton: {
    height: "32px",
    padding: "3px 24px",
    background: "white",
    border: "0.5px solid #018ABE",
    borderRadius: "100px",
    font: "700 13px/20px",
    color: "#018ABE",
    marginLeft: "13px",
    textTransform: "none",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    minWidth: "64px",
    "&:hover": {
      backgroundColor: "white",
      cursor: "pointer",
      outline: "none",
    },
    "&:focus": {
      outline: "none",
    },
    "&:active": {
      outline: "none",
    },
  },
});

const DownloadFile = ({
  classes,
  uuid,
  getSimulationFiles,
  files,
  downloadFileFromList,
}) => {
  const [activeDownloadType, setActiveDownloadType] = useState("reportistica");
  const [downloadingFiles, setDownloadingFiles] = useState({});
  const [downloadActive, setDownloadActive] = useState(false);

  const downloadTabs = [
    { value: "reportistica", label: "Reports" },
    { value: "2d", label: "2D Graphics" },
    { value: "3d", label: "3D Graphics" },
  ];

  useEffect(() => {
    async function fetchData() {
      setDownloadingFiles({});
      await getSimulationFiles(uuid);
    }
    fetchData();
  }, [activeDownloadType]);

  return (
    <div className={classes.downloadFileContainer}>
      <Tabs
        value={activeDownloadType}
        onChange={(event, value) => setActiveDownloadType(value)}
        style={{ borderBottom: "1px solid #EFF2F1" }}
      >
        {downloadTabs.map((tab, i) => (
          <Tab
            className={classes.tab}
            style={{
              color: activeDownloadType === tab.value ? "#25282B" : "#A5A5A6",
            }}
            key={`download-type-${tab.value}`}
            index={i}
            value={tab.value}
            label={tab.label}
          />
        ))}
      </Tabs>

      <div className={classes.tabContainer}>
        <div className={classes.fileHeader}>
          <div>File</div>
          {/* <Button
            className={classes.downloadAllButton}
            disabled={true}
          >
            Download all
          </Button> */}
        </div>
        {activeDownloadType === "reportistica" &&
          files
            .filter(
              (file) =>
                file.name.includes(".docx") || file.name.includes(".xlsx")
            )
            .map((file, i) => (
              <div
                className={classes.file}
                key={`files-reporting-${file.name}`}
              >
                <div>{file.name}</div>
                <IconButton
                  color='primary'
                  disabled={downloadActive}
                  onClick={async () => {
                    setDownloadActive(true);
                    setDownloadingFiles({ ...downloadingFiles, [i]: true });
                    await downloadFileFromList(file.name, uuid);
                    setDownloadingFiles({ ...downloadingFiles, [i]: false });
                    setDownloadActive(false);
                  }}
                  size='large'
                >
                  {downloadingFiles[i] ? (
                    <CircularProgress size={24} />
                  ) : (
                    <DownloadIcon />
                  )}
                </IconButton>
              </div>
            ))}
        {activeDownloadType === "2d" &&
          files
            .filter((file) => file.name.includes(".svg") || file.name.includes(".png")) 
            .map((file, i) => (
              <div className={classes.file} key={`files-2d-${file.name}`}>
                <div>{file.name}</div>
                <IconButton
                  color='primary'
                  disabled={downloadActive}
                  onClick={async () => {
                    setDownloadActive(true);
                    setDownloadingFiles({ ...downloadingFiles, [i]: true });
                    await downloadFileFromList(file.name, uuid);
                    setDownloadingFiles({ ...downloadingFiles, [i]: false });
                    setDownloadActive(false);
                  }}
                  size='large'
                >
                  {downloadingFiles[i] ? (
                    <CircularProgress size={24} />
                  ) : (
                    <DownloadIcon />
                  )}
                </IconButton>
              </div>
            ))}
        {activeDownloadType === "3d" &&
          files
            .filter(
              (file) =>
                file.name.includes(".") &&
                !file.name.includes(".docx") &&
                !file.name.includes(".xlsx") &&
                !file.name.includes(".svg") &&
                !file.name.includes(".png")

            )
            .map((file, i) => (
              <div className={classes.file} key={`files-3d-${file.name}`}>
                <div>{file.name}</div>
                <IconButton
                  color='primary'
                  disabled={downloadActive}
                  onClick={async () => {
                    setDownloadActive(true);
                    setDownloadingFiles({ ...downloadingFiles, [i]: true });
                    await downloadFileFromList(file.name, uuid);
                    setDownloadingFiles({ ...downloadingFiles, [i]: false });
                    setDownloadActive(false);
                  }}
                  size='large'
                >
                  {downloadingFiles[i] ? (
                    <CircularProgress size={24} />
                  ) : (
                    <DownloadIcon />
                  )}
                </IconButton>
              </div>
            ))}
      </div>
    </div>
  );
};

const mapState = ({ simulation }) => ({
  files: simulation.files,
});

const mapDispatch = ({
  simulation: { getSimulationFiles, downloadFileFromList },
}) => ({
  getSimulationFiles: (uuid) => getSimulationFiles({ selectedRowUUID: uuid }),
  downloadFileFromList: (fileName, uuid) =>
    downloadFileFromList({ fileName, selectedRowUUID: uuid }),
});

export default compose(
  withStyles(useStyles),
  connect(mapState, mapDispatch)
)(DownloadFile);
