import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";

import EnhancedTableBox from "../../../components/PlaygroundComponents/EnhancedTableBox";

import { GET_TENANTS, GET_TENANTS_LIST } from "../../../utils/api";

const visibleColumns = [
  {
    id: "tenant",
    alignRight: false,
    disablePadding: false,
    label: "Tenant",
  },
];
const actions = [
  {
    action: "edit",
    icon: "edit",
    tooltip: "edit tenant",
  },
  {
    action: "delete",
    icon: "delete",
    tooltip: "delete tenant",
  },
  {
    action: "open",
    icon: "open_in_browser",
    tooltip: "open technology page",
  },
];
const schema = {
  tenant: {
    title: "Tenant",
    type: "text",
    md: 5,
  },
};

const TenantsList = () => {
  const navigate = useNavigate();

  function onAction(action, row) {
    switch (action) {
      case "open":
        navigate(`/manage/tenants/${row.tenant}`);
        break;
      default:
        break;
    }
  }

  return (
    <div>
      <Grid container spacing={5} padding={5}>
        <Grid item md={12}>
          <EnhancedTableBox
            title='List of Tenants'
            addButton
            apiURL={GET_TENANTS_LIST}
            deleteURL={GET_TENANTS}
            addURL={GET_TENANTS}
            editURL={GET_TENANTS}
            valueMember='id'
            visibleColumns={visibleColumns}
            actions={actions}
            onAction={onAction}
            addJsonSchema={schema}
            editJsonSchema={schema}
            orderBy='tenant'
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default TenantsList;
