import { useEffect } from "react";
import "leaflet-ruler";
import "./leaflet-ruler.css";
import { useMap } from "react-leaflet";
import L from "leaflet";

export default function LeafletRuler() {
  const map = useMap();

  useEffect(() => {
    if (!map) return;
    const ruler = L.control.ruler();
    ruler.addTo(map);
  }, [map]);

  return null;
}
