import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import withStyles from "@mui/styles/withStyles";
import { Dialog, IconButton } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

const useStyles = (theme) => ({
  modalContainer: {
    padding: "25px 40px",
  },
  modalHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  modalTitle: {
    color: "#018ABE",
    fontWeight: "bold",
    fontSize: "24px",
    marginBottom: "25px",
  },
  modalSubtitle: {
    color: "#000000",
    fontSize: "18px",
    display: "flex",
    flexDirection: "column",
  },
  modalCloseIcon: {
    color: "#000000",
  },
  modalButtonsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "100px",
  },
  modalCloseButton: {
    background: "#C9CAC9 0% 0% no-repeat padding-box",
    borderRadius: "26px",
    opacity: 1,
    outline: "none",
    padding: "11px 30px",
    textDecoration: "none",
    font: "700 14px/19px",
    letterSpacing: 0,
    color: "#FFFFFF",
    border: "none",
    "&:hover": {
      outline: "none",
      cursor: "pointer",
      background: "#A5A5A6 0% 0% no-repeat padding-box",
    },
  },
  modalExitButton: {
    marginLeft: "20px",
    background: "#03B8FF 0% 0% no-repeat padding-box",
    borderRadius: "26px",
    opacity: 1,
    outline: "none",
    padding: "11px 30px",
    textDecoration: "none",
    font: "700 14px/19px",
    letterSpacing: 0,
    color: "#FFFFFF",
    border: "none",
    "&:hover": {
      outline: "none",
      cursor: "pointer",
      background: "#018ABE 0% 0% no-repeat padding-box",
    },
  },
});

const SimulationsStepperExitModal = ({
  classes,
  exitModalOpened,
  setExitModalOpened,
  handleExit,
}) => {
  const handleCloseModal = () => {
    setExitModalOpened(false);
  };

  return (
    <Dialog
      onClose={handleCloseModal}
      open={exitModalOpened}
      fullWidth={true}
      maxWidth={"md"}
    >
      <div className={classes.modalContainer}>
        <div className={classes.modalHeader}>
          <div className={classes.modalTitle}>Cancel Simulation</div>
          <IconButton
            className={classes.modalCloseIcon}
            onClick={handleCloseModal}
            size='large'
          >
            <ClearIcon />
          </IconButton>
        </div>

        <div className={classes.modalBody}>
          <div className={classes.modalSubtitle}>
            <span>
              You are about to cancel the simulation. Are you sure you want to
              cancel?
            </span>
            <span>
              <b>All the informations entered will be lost.</b>
            </span>
          </div>
        </div>

        <div className={classes.modalButtonsContainer}>
          <button
            className={classes.modalCloseButton}
            onClick={handleCloseModal}
          >
            Go Back
          </button>
          <button className={classes.modalExitButton} onClick={handleExit}>
            Cancel Simulation
          </button>
        </div>
      </div>
    </Dialog>
  );
};

const mapState = () => ({});

const mapDispatch = () => ({});

export default compose(
  withStyles(useStyles),
  connect(mapState, mapDispatch)
)(SimulationsStepperExitModal);
