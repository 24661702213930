const stringLoader = {
  state: {
    loadingString: false
  },
  reducers: {
    stringLoader (state, value) {
      return { ...state, loadingString: value }
    }
  }
}

export default stringLoader
