import React, { useEffect, useState } from "react";
import ArrowIcon from "../../assets/img/arrow-right.svg";
import withStyles from "@mui/styles/withStyles";
import { compose } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";
import IconButton from "@mui/material/IconButton";
import axios from "axios";
import { GET_SITE_LAST_SIM_INFOS } from "../../utils/api";

const useStyles = (theme) => ({
  detailContainer: {
    width: "25%",
    height: "calc(100vh - 220px)",
    zIndex: 1,
    position: "absolute",
    top: 0,
    right: 0,
    backgroundColor: "#ffffff",
    padding: "25px",
    boxShadow: "0px 3px 10px #00000029",
    overflowY: "scroll",
  },
  detailMainTitle: {
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "48px",
  },
  detailInfoBox: {
    marginTop: "25px",
  },
  detailTitle: {
    fontWeight: "bold",
    fontSize: "13px",
  },
  detailInfo: {
    marginTop: "5px",
  },
  arrowIcon: {
    color: "#018ABE",
    float: "right",
    border: "1px solid #018ABE",
    height: "18px",
    width: "22px",
    borderRadius: "50%",
    padding: "18px",
  },
});

const MapOfficeDetail = ({ classes, selectedOffice, putMapInCenter }) => {
  const [lastSimulationDate, setLastSimulationDate] = useState(null);
  const office = [
    { title: "ID INWIT", info: selectedOffice.site_code || "-" },
    {
      title: "Original Site Code",
      info: selectedOffice.original_site_code || "-",
    },
    { title: "Zone", info: selectedOffice.zona || "-" },
    { title: "Region", info: selectedOffice.regione || "-" },
    { title: "Province", info: selectedOffice.provincia || "-" },
    { title: "Municipality", info: selectedOffice.comune || "-" },
    { title: "Address", info: selectedOffice.address || "-" },
    { title: "Site Type", info: selectedOffice.site_type || "-" },
    {
      title: "Last simulation",
      info: lastSimulationDate
        ? dayjs(lastSimulationDate).format("DD/MM/YYYY h:mm A")
        : "-",
    },
  ];

  const handleClick = () => {
    putMapInCenter(selectedOffice);
  };

  // Workaround to get the correct last simulation date instead of using the getLastSimulationsInfos() from models/site
  useEffect(() => {
    const fetchLastSimulationDate = async () => {
      try {
        const { data } = await axios({
          method: "GET",
          url: GET_SITE_LAST_SIM_INFOS,
          headers: {
            authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          params: { id_site: selectedOffice.id },
        });
        if (data.data) {
          const { log_date_upd } = data.data[0];
          setLastSimulationDate(log_date_upd);
        } else {
          return;
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (selectedOffice) {
      fetchLastSimulationDate();
    }
  }, []);

  return (
    <div className={classes.detailContainer}>
      <div className={classes.detailMainTitle}>
        {selectedOffice.site_name || null}
        <IconButton
          aria-label='Centra sul sito'
          onClick={handleClick}
          size='large'
        >
          <GpsFixedIcon htmlColor='#018ABE' />
        </IconButton>
        <Link to={`/cem/site/${selectedOffice.site_code}/info`}>
          <img className={classes.arrowIcon} src={ArrowIcon} alt='arrow icon' />
        </Link>
      </div>
      {office.map((el, i) => (
        <div className={classes.detailInfoBox} key={`infoBox-${el.title}-${i}`}>
          <div className={classes.detailTitle}>{el.title}</div>
          <div className={classes.detailInfo}>{el.info}</div>
        </div>
      ))}
    </div>
  );
};

const mapState = () => ({});

const mapDispatch = ({ site: { putMapInCenter } }) => ({
  putMapInCenter: (site) => putMapInCenter(site),
});

export default compose(
  withStyles(useStyles),
  connect(mapState, mapDispatch)
)(MapOfficeDetail);
