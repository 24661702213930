import { useContext } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import withStyles from "@mui/styles/withStyles";
import { Dialog, IconButton } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import RadioButtonUncheckedRoundedIcon from "@mui/icons-material/RadioButtonUncheckedRounded";
import { UserRoleContext } from "../../contexts/user-role.context";

const useStyles = (theme) => ({
  modalContainer: {
    padding: "25px 40px",
  },
  modalHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  modalTitle: {
    color: "#018ABE",
    fontWeight: "bold",
    fontSize: "22px",
    marginBottom: "25px",
  },
  modalCloseIcon: {
    color: "#000000",
  },
  modalButtonsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "100px",
  },
  modalCloseButton: {
    background: "#C9CAC9 0% 0% no-repeat padding-box",
    borderRadius: "26px",
    opacity: 1,
    outline: "none",
    padding: "11px 30px",
    textDecoration: "none",
    font: "700 14px/19px",
    letterSpacing: 0,
    color: "#FFFFFF",
    border: "none",
    "&:hover": {
      outline: "none",
      cursor: "pointer",
      background: "#A5A5A6 0% 0% no-repeat padding-box",
    },
  },
  modalStartButton: {
    marginLeft: "20px",
    background: "#03B8FF 0% 0% no-repeat padding-box",
    borderRadius: "26px",
    opacity: 1,
    outline: "none",
    padding: "11px 30px",
    textDecoration: "none",
    font: "700 14px/19px",
    letterSpacing: 0,
    color: "#FFFFFF",
    border: "none",
    "&:hover": {
      outline: "none",
      cursor: "pointer",
      background: "#018ABE 0% 0% no-repeat padding-box",
    },
    "&:disabled": {
      color: "rgba(0, 0, 0, 0.26)",
      background: "white",
      border: "0.5px solid #018ABE",
      cursor: "unset",
    },
  },
  modalListBox: {
    display: "flex",
    alignItems: "center",
    marginBottom: "5px",
  },
  modalListIcon: {
    height: "20px",
    width: "20px",
  },
  modalListName: {
    marginLeft: "10px",
    color: "#000000",
    fontSize: "14px",
    fontWeight: "bold",
  },
});

const SimulationsStepperResumeModal = ({
  classes,
  resumeModalOpened,
  setResumeModalOpened,
  setActiveStep,
  setSimulationStarted,
  summaryList,
}) => {
  const { hasWritePermission } = useContext(UserRoleContext);

  const handleCloseButton = () => {
    setResumeModalOpened(false);
    setActiveStep(3);
  };

  const isStartButtonDisabled =
    hasWritePermission && summaryList.constraints === 1;

  return (
    <Dialog
      onClose={() => handleCloseButton()}
      open={resumeModalOpened}
      fullWidth={true}
      maxWidth={"md"}
    >
      <div className={classes.modalContainer}>
        <div className={classes.modalHeader}>
          <div className={classes.modalTitle}>Simulation Summary</div>
          <IconButton
            className={classes.modalCloseIcon}
            onClick={() => handleCloseButton()}
            size='large'
          >
            <ClearIcon />
          </IconButton>
        </div>

        <div className={classes.modalBody}>
          <div className={classes.modalListBox}>
            {summaryList.file_bim === 1 ? (
              <CheckCircleRoundedIcon
                className={classes.modalListIcon}
                style={{ color: "#2F861B" }}
              />
            ) : (
              <RadioButtonUncheckedRoundedIcon
                className={classes.modalListIcon}
              />
            )}
            <div className={classes.modalListName}>Project BIM</div>
          </div>
          <div className={classes.modalListBox}>
            {summaryList.file_shape === 1 ? (
              <CheckCircleRoundedIcon
                className={classes.modalListIcon}
                style={{ color: "#2F861B" }}
              />
            ) : (
              <RadioButtonUncheckedRoundedIcon
                className={classes.modalListIcon}
              />
            )}
            <div className={classes.modalListName}>Shape file</div>
          </div>
          <div className={classes.modalListBox}>
            {summaryList.measure_points === 1 ? (
              <CheckCircleRoundedIcon
                className={classes.modalListIcon}
                style={{ color: "#2F861B" }}
              />
            ) : (
              <RadioButtonUncheckedRoundedIcon
                className={classes.modalListIcon}
              />
            )}
            <div className={classes.modalListName}>
              Evaluation measure points
            </div>
          </div>
          <div className={classes.modalListBox}>
            {summaryList.parameters === 1 ? (
              <CheckCircleRoundedIcon
                className={classes.modalListIcon}
                style={{ color: "#2F861B" }}
              />
            ) : (
              <RadioButtonUncheckedRoundedIcon
                className={classes.modalListIcon}
              />
            )}
            <div className={classes.modalListName}>Parameters</div>
          </div>
          <div className={classes.modalListBox}>
            {summaryList.constraints === 1 ? (
              <CheckCircleRoundedIcon
                className={classes.modalListIcon}
                style={{ color: "#2F861B" }}
              />
            ) : (
              <RadioButtonUncheckedRoundedIcon
                className={classes.modalListIcon}
              />
            )}
            <div className={classes.modalListName}>EM Constraints</div>
          </div>
          {summaryList.constraints !== 1 && (
            <div>
              <p style={{ color: "red" }}>
                Check Parameters and EM Constraints before running the
                simulation
              </p>
            </div>
          )}
        </div>

        <div className={classes.modalButtonsContainer}>
          <button
            className={classes.modalCloseButton}
            onClick={handleCloseButton}
          >
            Close
          </button>
          <button
            className={classes.modalStartButton}
            onClick={() => {
              setResumeModalOpened(false);
              setSimulationStarted(true);
            }}
            disabled={!isStartButtonDisabled}
          >
            Run Simulation
          </button>
        </div>
      </div>
    </Dialog>
  );
};

const mapState = () => ({});

const mapDispatch = () => ({});

export default compose(
  withStyles(useStyles),
  connect(mapState, mapDispatch)
)(SimulationsStepperResumeModal);
