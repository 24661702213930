import React from "react";
import makeStyles from "@mui/styles/makeStyles";

import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Modal,
  Fade,
  Backdrop,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "86vh",
    width: "100%",
    marginTop: "7vh",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: "85%",
    height: "85vh",
    paddingTop: "3em",
    paddingBottom: "3em",
    overflowY: "auto",
  },
}));

export default function TransitionsModal({
  handleCloseModal,
  isOpen,
  errorMessages,
  warningMessages,
}) {
  const classes = useStyles();

  return (
    <Modal
      aria-labelledby='transition-modal-title'
      aria-describedby='transition-modal-description'
      className={classes.modal}
      open={isOpen}
      onClose={handleCloseModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isOpen}>
        <List component='nav' className={classes.paper} aria-label='errors'>
          {errorMessages &&
            errorMessages.length > 0 &&
            errorMessages.map((error, index) => {
              console.log(error);
              if (error)
                return (
                  <ListItem key={`${error}-${index}`}>
                    <ListItemIcon>
                      <ArrowForwardIosIcon />
                    </ListItemIcon>
                    <ListItemText primary={error} key={`${error}-${index}`} />
                  </ListItem>
                );
            })}
          {warningMessages &&
            warningMessages.length > 0 &&
            warningMessages.map((warning, index) => {
              if (warning)
                return (
                  <ListItem key={`${warning}-${index}`}>
                    <ListItemIcon>
                      <ArrowForwardIosIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={warning}
                      key={`${warning}-${index}`}
                    />
                  </ListItem>
                );
            })}
        </List>
      </Fade>
    </Modal>
  );
}
