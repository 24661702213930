import { useEffect } from "react";
import axios from "axios";
import { useSnackbar } from "../contexts/snackbar.context";

const _axios = axios.create();

function useHttpService() {
  const snackbar = useSnackbar();

  const configure = () => {
    _axios.interceptors.request.use((config) => {
      config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
      return Promise.resolve(config);
    });
  };

  const getAxiosClient = () => _axios;

  const getAPI = (url, callback) => {
    if (url) {
      _axios
        .get(url)
        .then((res) => {
          if(url.includes("export") && res.data)
            callback(res.data) ;
          else if (url.includes("diskcheck") && res.data)
            callback(res.data);
          else if (res.data.data) callback(res.data.data);
          else if (res.data.data == undefined) callback(res.data);
          else callback([]);
        })
        .catch(function (error) {
          if (error?.response?.status && error?.response?.data?.message) {
            fireErrorMessage(
              error.response.status,
              error.response.data.message
            );
          }
          console.error(error);
        });
    }
  };

  const downloadAPI = (url,callback,fileType,fileName) => {
    _axios
      .get(url, { responseType: "blob" })
      .then((res) => {
        const blob = new Blob([res.data], {
          type: fileType,fileName
        });
        const aEle = document.createElement("a"); // Create a label
        const href = window.URL.createObjectURL(blob); // Create downloaded link
        aEle.href = href;
        aEle.setAttribute("download", fileName); // File name after download
        document.body.appendChild(aEle);
        aEle.click(); // Click to download
        document.body.removeChild(aEle); // Download complete remove element
        window.URL.revokeObjectURL(href); // Release blob object

        //dispatch.loader.loader(false);
      })
      .catch(function (error) {
        if (error?.response?.status && error?.response?.data?.message) {
          fireErrorMessage(error.response.status, error.response.data.message);
        }
        console.error(error);     
        callback(fileName);
      });
  };

  const postAPI = (url, data, callback, header = {}, showSnackbar = true) => {
    _axios
      .post(url, data)
      .then((res) => {
        if (res.data?.result === 1 && res.data?.data !== null) {
          fireSuccessMessage();
          callback(res.data.data);          
          showSnackbar && fireWarningMessage(res.data.data);          
          return;
        }
        showSnackbar && fireSuccessMessage();
        if (res.data?.data) callback(res.data.data);
        else callback(res.data);
      })
      .catch(function (error) {
        if (error?.response?.status && error?.response?.data?.message) {
          fireErrorMessage(error.response.status, error.response.data.message);
        }
        console.error(error);
      });
  };

  const putAPI = (url, data, callback, showSnackbar = true) => {
    _axios
      .put(url, data)
      .then((res) => {
        const { result, data } = res.data;
        if (result === 1 && data !== null) {
          showSnackbar && fireWarningMessage(data);
          return;
        }
        showSnackbar && fireSuccessMessage();
        if (data) callback(data);
        else callback();
      })
      .catch(function (error) {
        if (error?.response?.status && error?.response?.data?.message) {
          fireErrorMessage(error.response.status, error.response.data.message);
        }
        console.error(error);
      });
  };

  const deleteAPI = (url, data, callback) => {
    _axios
      .delete(url, {
        data,
      })
      .then((res) => {
        fireSuccessMessage();
        if (res.data.data) callback(res.data.data);
        else callback();
      })
      .catch(function (error) {
        if (error?.response?.status && error?.response?.data?.message) {
          error.response.data.message = checkIfDataViolatesConstraints(
            error.response.data.message
          );
          fireErrorMessage(error.response.status, error.response.data.message);
        }
        console.error(error);
      });
  };

  const checkIfDataViolatesConstraints = (message) => {
    if (message.includes("violates foreign key constraint")) {
      const table = message.split("on table")[2];
      if (table.includes("NetworkSliceSubnet"))
        return "Cannot delete this element because is used from a Network Slice Subnet";
      if (table.includes("NetworkSlice"))
        return "Cannot delete this element because is used from a Network Slice";
      if (table.includes("NetworkFunction"))
        return "Cannot delete this element because is used from a Network Function";
    }
    return message;
  };

  const fireWarningMessage = (warningMessages) => {
    if (!warningMessages) return;
    snackbar.setSnackbarInfo({
      status: "warning",
      message: warningMessages.map((message, index) => (
        <span style={{ display: "block" }} key={`message-${index}`}>
          {message}
        </span>
      )),
    });
  };

  const fireErrorMessage = (status, message) => {
    snackbar.setSnackbarInfo({
      status: "error",
      message: `Error ${status}: ${message}`,
    });
  };

  const fireSuccessMessage = () => {
    snackbar.setSnackbarInfo({
      status: "success",
      message: "Your request has been processed succesfully",
    });
  };

  useEffect(() => {
    configure();
  }, []);

  return {
    getAPI,
    postAPI,
    deleteAPI,
    putAPI,
    downloadAPI,
    getAxiosClient,
    fireSuccessMessage,
    fireWarningMessage,
    fireErrorMessage,
    configure,
  };
}

export default useHttpService;
