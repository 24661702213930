export const mainTableHeaders = [
  { name: "ID INWIT", key: "site_code" },
  { name: "Original Site Code", key: "original_site_code" },
  { name: "Site Name", key: "site_name" },
  { name: "Zone", key: "zona" },
  { name: "Region", key: "regione" },
  { name: "Province", key: "provincia" },
  { name: "Municipality", key: "comune" },
  { name: "Address", key: "address" },
  { name: "Last Simulation", key: "id_status" },
];

export const siteParamsTableHeaders = [
  { name: "Sector/cell", key: "sector" },
  { name: "Azimuth", key: "azimuth" },
  { name: "Model", key: "model" },
  { name: "Pole ID", key: "poleid"},
  { name: "HBA", key: "hba" },
  { name: "Mechanical Downtilt", key: "downtilt" },
  { name: "Tenants", key: "tenants" },
];

export const siteTenantsTableHeaders = [
  { name: "Tenant", key: "tenant" },
  { name: "Sectors number", key: "sec_num" },
  { name: "Systems", key: "systems" },
];

export const siteSimulationsHistoryTableHeaders = [
  { name: "Simulation", key: "simulazione" },
  { name: "Description", key: "desc_progetto" },
  { name: "Start Date", key: "data_inizio" },
  { name: "End Date", key: "data_fine" },
  { name: "Result", key: "risultato" },
  { name: "Status", key: "stato" },
  { name: "Type", key: "tipo" },
  { name: "", key: "dots" },
];

export const siteDocsTableHeaders = [
  { name: "File Name", key: "file_name" },
  { name: "Upload Date", key: "file_date" },
  { name: "", key: "download" },
];

export const simulationsStepperThresholdsHeaders = [
  { name: "Threshold", key: "threshold" },
  { name: "Color", key: "color" },
  { name: "", key: "delete" },
];

export const simulationResumeInitialConfigHeaders = [
  { name: "Azimuth (°)", key: "orientamento" },
  { name: "Tenant", key: "tenant" },
  { name: "Technology", key: "tecnologia" },
  { name: "HBA (m)", key: "hba" },
  { name: "Antenna", key: "antenna" },
  { name: "Mechanical Tilt (°)", key: "tilt_meccanico" },
  { name: "Electrical Tilt (°)", key: "tilt_elettrico" },
  { name: "Initial antenna power (W)", key: "pot_tot_init_antenna" },
  { name: "Final antenna power (W)", key: "pot_tot_final_antenna" },
];

export const simulatedTechFinalConfigHeaders = [
  { name: "Azimuth (°)", key: "orientamento" },
  { name: "Tenant", key: "tenant" },
  { name: "Technology", key: "tecnologia" },
  { name: "HBA (m)", key: "hba" },
  { name: "Antenna", key: "antenna" },
  { name: "Mechanical Tilt (°)", key: "tilt_meccanico" },
  { name: "Electrical Tilt (°)", key: "tilt_elettrico" },
  { name: "Final antenna power (W)", key: "pot_tot_final_antenna" },
];

export const contributionTableStaticHeaders = [
  { name: "Measure Point", key: "point" },
  { name: "EM Background (V/m)", key: "electric_field" },
  { name: "Total (V/m)", key: "total" },
  { name: "Limit value (V/m)", key: "reference_limit_value" },
];

// export const simulationResumeFinalConfigHeaders = [
//   { name: 'Tenant', key: 'tenant' },
//   { name: 'Tecnologia', key: 'tecnologia' },
//   { name: 'Antenna', key: 'antenna' },
//   { name: 'Orientamento (°)', key: 'orientamento' },
//   { name: 'Tilt elettrico (°)', key: 'tilt_elettrico' },
//   { name: 'Tilt meccanico (°)', key: 'tilt_meccanico' },
//   { name: 'HBA (m)', key: 'hba' },
//   { name: 'Potenza tot antenna (W)', key: 'pot_tot_antenna' },
// ]
