import React, { Suspense } from "react";
import withStyles from "@mui/styles/withStyles";
import { connect } from "react-redux";
import { compose } from "redux";

const SiteMeasurePoints = React.lazy(() => import("./site-measure-points"));
const EvalMeasurePoints = React.lazy(() => import("./eval-measure-points"));
const Poles = React.lazy(() => import("./poles"));
const Shapefile = React.lazy(() => import("./shapefile"));

import { CircularProgress } from "@mui/material";

const useStyles = (theme) => ({
  button: {
    height: "32px",
    padding: "3px 24px",
    background: "white",
    border: "0.5px solid #018ABE",
    borderRadius: "100px",
    font: "700 13px/20px",
    color: "#018ABE",
    marginLeft: "13px",
    textTransform: "none",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    minWidth: "64px",
    "&:hover": {
      backgroundColor: "white",
      cursor: "pointer",
      outline: "none",
    },
    "&:focus": {
      outline: "none",
    },
    "&:active": {
      outline: "none",
    },
  },
  addOnMapContainer: {
    height: "100vh",
    width: "100vw",
    zIndex: 998,
    backgroundColor: "#ffffff",
    position: "fixed",
    top: 0,
    left: 0,
  },
  addOnMapHeader: {
    padding: "30px 40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#FAFAFA",
  },
  titleBox: {
    display: "flex",
    flexDirection: "column",
    fontWeight: "bold",
  },
  mainTitle: {
    color: "#000000",
    fontSize: "22px",
  },
  subTitle: {
    marginTop: "5px",
    color: "#A5A5A6",
    fontSize: "13px",
  },
  addOnMapBody: {
    padding: "15px 40px",
  },
  topBody: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  mainBodyWrapper: {
    display: "flex",
    height: "calc(100vh - 200px)",
    marginTop: "20px",
  },
  mainBodyLeft: {
    width: "70%",
    height: "100%",
    border: "1px solid black",
  },
  mainBodyRight: {
    width: "30%",
    height: "100%",
    marginLeft: "35px",
    overflow: "auto",
  },
  cancelButton: {
    background: "#C9CAC9 0% 0% no-repeat padding-box",
    borderRadius: "26px",
    opacity: 1,
    outline: "none",
    padding: "11px 30px",
    textDecoration: "none",
    font: "700 14px/19px",
    letterSpacing: 0,
    color: "#FFFFFF",
    border: "none",
    "&:hover": {
      outline: "none",
      cursor: "pointer",
      background: "#A5A5A6 0% 0% no-repeat padding-box",
    },
  },
  saveButton: {
    marginLeft: "20px",
    background: "#03B8FF 0% 0% no-repeat padding-box",
    borderRadius: "26px",
    opacity: 1,
    outline: "none",
    padding: "11px 30px",
    textDecoration: "none",
    font: "700 14px/19px",
    letterSpacing: 0,
    color: "#FFFFFF",
    border: "none",
    "&:hover": {
      outline: "none",
      cursor: "pointer",
      background: "#018ABE 0% 0% no-repeat padding-box",
    },
    "&:disabled": {
      color: "rgba(0, 0, 0, 0.26)",
      background: "white",
      border: "0.5px solid #018ABE",
      cursor: "unset",
    },
  },
  addNewButton: {
    height: "32px",
    padding: "3px 24px",
    background: "white",
    border: "0.5px solid #018ABE",
    borderRadius: "100px",
    font: "700 13px/20px",
    color: "#018ABE",
    marginLeft: "13px",
    textTransform: "none",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    minWidth: "64px",
    "&:hover": {
      backgroundColor: "white",
      cursor: "pointer",
      outline: "none",
    },
    "&:focus": {
      outline: "none",
    },
    "&:active": {
      outline: "none",
    },
    "&:disabled": {
      color: "rgba(0, 0, 0, 0.26)",
      background: "white",
      border: "0.5px solid #018ABE",
      cursor: "unset",
    },
  },
  input: {
    width: "100%",
    marginBottom: "15px",
  },
  topButtons: {
    display: "flex",
    flexDirection: "row",
  },
  localLoader: {
    position: "fixed",
    background: "rgba(255,255,255,.5)",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 2000,
    textAlign: "center",
    display: "flex",
    flex: "column",
    alignItems: "center",
    justifyContent: "center",
  },
});

const SiteAddOnMapPage = ({
  classes,
  addType,
  manualImportMeasurePoints,
  measurePoints,
  setMeasurePoints,
  manualImportEvalMeasurePoints,
  evalMeasurePoints,
  setEvalMeasurePoints,
  siteInfos,
  saveMeasurePoints,
  saveEvalMeasurePoints,
  setOpenAddOnMapPage,
  manualPoles,
  poles,
  setPoles,
  savePoles,
  selectedShape,
  saveShapefileInfos,
  setSelectedShape,
  setGeoJson,
  geoJson,
  importShapeFileExcelFormat,
  exportShapeFileExcelFormat,
  setBucketSupplierList,
  setShapefileFeedback,
  setGoogleStoreFiles,
  selectedEval,
  selectedUUID,
  setApiMessages,
  getSiteInfos,
}) => {
  const LocalLoader = () => {
    return (
      <div className={classes.localLoader}>
        <CircularProgress />
      </div>
    );
  };
  return (
    <>
      <div className={classes.addOnMapContainer}>
        {addType === "measure_points" ? (
          <Suspense fallback={<LocalLoader />}>
            <SiteMeasurePoints
              classes={classes}
              setOpenAddOnMapPage={setOpenAddOnMapPage}
              measurePoints={measurePoints}
              setMeasurePoints={setMeasurePoints}
              siteInfos={siteInfos}
              manualImportMeasurePoints={manualImportMeasurePoints}
              saveMeasurePoints={saveMeasurePoints}
            />
          </Suspense>
        ) : addType === "eval_measure_points" ? (
          <Suspense fallback={<LocalLoader />}>
            <EvalMeasurePoints
              classes={classes}
              siteInfos={siteInfos}
              selectedEval={selectedEval}
              evalMeasurePoints={evalMeasurePoints}
              measurePoints={measurePoints}
              setEvalMeasurePoints={setEvalMeasurePoints}
              setApiMessages={setApiMessages}
              setOpenAddOnMapPage={setOpenAddOnMapPage}
              selectedUUID={selectedUUID}
              manualImportEvalMeasurePoints={manualImportEvalMeasurePoints}
              manualImportMeasurePoints={manualImportMeasurePoints}
              saveEvalMeasurePoints={saveEvalMeasurePoints}
            />
          </Suspense>
        ) : addType === "poles" ? (
          <Suspense fallback={<LocalLoader />}>
            <Poles
              classes={classes}
              siteInfos={siteInfos}
              poles={poles}
              setPoles={setPoles}
              manualPoles={manualPoles}
              savePoles={savePoles}
              setOpenAddOnMapPage={setOpenAddOnMapPage}
              getSiteInfos={getSiteInfos}
            />
          </Suspense>
        ) : addType === "shapefile" ? (
          <Suspense fallback={<LocalLoader />}>
            <Shapefile
              classes={classes}
              poles={poles}
              siteInfos={siteInfos}
              setOpenAddOnMapPage={setOpenAddOnMapPage}
              setBucketSupplierList={setBucketSupplierList}
              setShapefileFeedback={setShapefileFeedback}
              setGoogleStoreFiles={setGoogleStoreFiles}
              selectedShape={selectedShape}
              setSelectedShape={setSelectedShape}
              geoJson={geoJson}
              setGeoJson={setGeoJson}
              saveShapefileInfos={saveShapefileInfos}
              exportShapeFileExcelFormat={exportShapeFileExcelFormat}
              importShapeFileExcelFormat={importShapeFileExcelFormat}
            />
          </Suspense>
        ) : null}
      </div>
    </>
  );
};

const mapState = ({ site, map }) => ({
  measurePoints: site.measurePoints,
  evalMeasurePoints: site.evalMeasurePoints,
  poles: site.poles,
  selectedShape: site.selectedShape,
  selectedUUID: site.selectedUUID,
  geoJson: map.geoJson,
});

const mapDispatch = ({
  site: {
    manualImportMeasurePoints,
    manualImportEvalMeasurePoints,
    setEvalMeasurePoints,
    setMeasurePoints,
    saveMeasurePoints,
    saveEvalMeasurePoints,
    manualPoles,
    setPoles,
    savePoles,
    uploadShapeFile,
    saveShapefileInfos,
    setSelectedShape,
    importShapeFileExcelFormat,
    exportShapeFileExcelFormat,
    setBucketSupplierList,
    setShapefileFeedback,
    setGoogleStoreFiles,
    setApiMessages,
    getSiteInfos,
  },
  map: { setGeoJson },
}) => ({
  manualImportMeasurePoints: () => manualImportMeasurePoints(),
  manualImportEvalMeasurePoints: () => manualImportEvalMeasurePoints(),
  setEvalMeasurePoints: (value) => setEvalMeasurePoints(value),
  setMeasurePoints: (value) => setMeasurePoints(value),
  saveMeasurePoints: () => saveMeasurePoints(),
  saveEvalMeasurePoints: () => saveEvalMeasurePoints(),
  manualPoles: (value) => manualPoles(value),
  setPoles: (value) => setPoles(value),
  savePoles: () => savePoles(),
  uploadShapeFile: (file, site) => uploadShapeFile(file, site),
  saveShapefileInfos: (values) => saveShapefileInfos(values),
  setSelectedShape: (value) => setSelectedShape(value),
  importShapeFileExcelFormat: (file, site) =>
    importShapeFileExcelFormat(file, site),
  exportShapeFileExcelFormat: (value) => exportShapeFileExcelFormat(value),
  setBucketSupplierList: (value) => setBucketSupplierList(value),
  setShapefileFeedback: (value) => setShapefileFeedback(value),
  setGoogleStoreFiles: (value) => setGoogleStoreFiles(value),
  setGeoJson: (value) => setGeoJson(value),
  setApiMessages: (value) => setApiMessages(value),
  getSiteInfos: (siteId) => getSiteInfos({ siteId }),
});

export default compose(
  withStyles(useStyles),
  connect(mapState, mapDispatch)
)(SiteAddOnMapPage);
