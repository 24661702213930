import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  forwardRef,
} from "react";
import withStyles from "@mui/styles/withStyles";
import { connect } from "react-redux";
import { compose } from "redux";
import {
  Box,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  IconButton,
  InputBase,
  Popover,
  Tooltip,
} from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { Delete, FileCopy, Check, Close } from "@mui/icons-material";
import { UserRoleContext } from "../../contexts/user-role.context";

const useStyles = (theme) => ({
  list: {
    width: "90%",
    maxHeight: "60vh",
    overflow: "auto !important",
  },
  listElement: {
    color: "#018ABE",
    cursor: "pointer",
  },
  input: {
    marginLeft: theme.spacing(1),
    width: "70%",
    fontSize: "1em",
  },
});

const MassiveList = ({
  classes,
  refreshList,
  setRefreshList,
  setSiteDetailsAvailable,
  setRunSimulationDisabled,
  setUpdateStatisticsDisabled,
  massiveList,
  getMassiveList,
  massiveSites,
  getMassiveSites,
  cloneMassive,
  deleteMassive,
  checkDtmAndShapefile,
  setSelectedMassive,
  selectedMassive,
  getSimulationStatistics,
  popoverRef,
}) => {
  const [showPopover, setShowPopover] = useState([]);
  const powerRef = useRef(null);

  const { hasWritePermission } = useContext(UserRoleContext);

  const asyncGetMassiveList = async () => {
    await getMassiveList();
  };

  useEffect(() => {
    if (refreshList) {
      asyncGetMassiveList();
      setRefreshList(false);
    }
  }, [refreshList]);

  useEffect(() => {
    let tmp = [];
    if (massiveList.length === 0) asyncGetMassiveList();
    else {
      massiveList.map((el) => tmp.push({ id: el.id, open: false }));
      setShowPopover(tmp);
    }
  }, [massiveList]);

  useEffect(() => {
    const asyncCheckDtmAndShapefile = async () => {
      await checkDtmAndShapefile(selectedMassive, massiveSites);
    };
    if (massiveSites.length > 0 && selectedMassive) {
      asyncCheckDtmAndShapefile();
      setSiteDetailsAvailable(true);
      setRunSimulationDisabled(false);
    } else {
      setSiteDetailsAvailable(false);
      setRunSimulationDisabled(true);
    }
  }, [selectedMassive]);

  return (
    <List className={classes.list}>
      {massiveList.length > 0 &&
        massiveList.map((el, index) => {
          return (
            <ListItem
              key={uuidv4()}
              className={classes.listElement}
              style={{
                backgroundColor:
                  el.id === selectedMassive ? "#EEEEEE" : "unset",
              }}
            >
              <Tooltip
                title={
                  el.creation_date?.replace("T", " ").split(".")[0] ||
                  "There are no eval for this massive yet"
                }
                placement='top'
              >
                <ListItemText
                  style={{ maxWidth: "25ch" }}
                  primary={el.description}
                  onClick={async () => {
                    await getMassiveSites(el.id);
                    await getSimulationStatistics(el.id);
                    setSelectedMassive(el.id);
                    setSiteDetailsAvailable(false);
                    setUpdateStatisticsDisabled(false);
                  }}
                />
              </Tooltip>
              <ListItemSecondaryAction>
                {showPopover.length > 0 &&
                  showPopover.length === massiveList.length &&
                  showPopover[index].open === true && (
                    <Popover
                      style={{
                        width: "100%",
                      }}
                      id={showPopover[index].id}
                      key={uuidv4()}
                      open={showPopover[index].open}
                      anchorReference='anchorEl'
                      // anchorPosition={{
                      //   top: window.innerHeight * 0.3,
                      //   left: window.innerWidth * 0.092,
                      // }}
                      anchorEl={popoverRef.current}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                    >
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <InputBase
                          inputRef={powerRef}
                          className={classes.input}
                          placeholder='Antenna power (%)'
                          inputProps={{
                            "aria-label": "antenna power",
                          }}
                          type='number'
                        />
                        <IconButton
                          edge='end'
                          type='submit'
                          className={classes.iconButton}
                          aria-label='confirm'
                          onClick={async () => {
                            let tmp = showPopover;
                            tmp = tmp.map((a) =>
                              a.id === el.id ? { ...a, open: false } : a
                            );
                            setShowPopover(tmp);
                            await cloneMassive({
                              id_massive: el.id,
                              power_increment: powerRef.current.value,
                              description: el.description,
                            });
                            setRefreshList(true);
                          }}
                          size='large'
                        >
                          <Check style={{ color: "green" }} />
                        </IconButton>
                        <IconButton
                          edge='end'
                          className={classes.iconButton}
                          aria-label='close'
                          onClick={() => {
                            let tmp = showPopover;
                            tmp = tmp.map((a) =>
                              a.id === el.id ? { ...a, open: false } : a
                            );
                            setShowPopover(tmp);
                          }}
                          size='large'
                        >
                          <Close style={{ color: "red" }} />
                        </IconButton>
                      </Box>
                    </Popover>
                  )}
                <IconButton
                  edge='end'
                  aria-label='clone'
                  onClick={async (e) => {
                    let tmp = showPopover;
                    tmp = tmp.map((a) =>
                      a.id === el.id ? { ...a, open: true } : a
                    );
                    setShowPopover(tmp);
                  }}
                  size='large'
                  disabled={!hasWritePermission}
                >
                  <Tooltip title='Clone'>
                    <FileCopy />
                  </Tooltip>
                </IconButton>
                <IconButton
                  edge='end'
                  aria-label='delete'
                  onClick={async () => {
                    await deleteMassive(el.id);
                    setRefreshList(true);
                  }}
                  size='large'
                  disabled={!hasWritePermission}
                >
                  <Tooltip title='Delete'>
                    <Delete />
                  </Tooltip>
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
    </List>
  );
};

const massiveState = ({ massive }) => ({
  massiveList: massive.massiveList,
  massiveSites: massive.massiveSites,
  selectedMassive: massive.selectedMassive,
});

const massiveDispatch = ({
  massive: {
    getMassiveList,
    cloneMassive,
    deleteMassive,
    getMassiveSites,
    checkDtmAndShapefile,
    setSelectedMassive,
    getSimulationStatistics,
  },
}) => ({
  getMassiveList: () => getMassiveList(),
  cloneMassive: (id) => cloneMassive(id),
  deleteMassive: (id) => deleteMassive(id),
  getMassiveSites: (id) => getMassiveSites(id),
  checkDtmAndShapefile: (id, massiveSites) =>
    checkDtmAndShapefile(id, massiveSites),
  setSelectedMassive: (massive) => setSelectedMassive(massive),
  getSimulationStatistics: (id) => getSimulationStatistics(id),
});

export default compose(
  withStyles(useStyles),
  connect(massiveState, massiveDispatch)
)(MassiveList);
