import React, { useEffect, useRef } from "react";
import withStyles from "@mui/styles/withStyles";
import { connect } from "react-redux";
import { compose } from "redux";
import { animateScroll } from "react-scroll";
import inwitLogo from "../../assets/img/efesto-blue.svg";

const useStyles = (theme) => ({
  simulationContainer: {
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  inwitLogo: {
    width: "35px",
    height: "47px",
    marginBottom: "10px",
  },
  messagesBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    maxHeight: "calc(100vh - 270px)",
    overflowY: "auto",
  },
  lastMessage: {
    color: "#018ABE",
    fontSize: "20px",
  },
  messages: {
    color: "#000000",
    fontSize: "18px",
  },
});

const Simulation = ({
  classes,
  startSimulationQueue,
  startTechSimulationQueue,
  isSimulatingTechnology,
  connection,
  startSimulation,
  receiveSimulationMessages,
  messages,
}) => {
  const latestMessages = useRef(null);
  latestMessages.current = messages;

  const scrollToMessagesBox = () => {
    animateScroll.scrollToBottom({
      containerId: "messagesBox",
    });
  };

  const startTechnologySimulation = async () => {
    await startTechSimulationQueue();
  };

  useEffect(() => {
    scrollToMessagesBox();
  }, [messages]);

  useEffect(() => {
    if (connection) {
      receiveSimulationMessages(latestMessages);
      if (connection.connectionState !== "Connected") {
        startSimulation();
      }
    } else if (isSimulatingTechnology) {
      startTechnologySimulation();
    } else if (!isSimulatingTechnology) {
      startSimulationQueue();
    }
  }, [connection]);

  return (
    <div className={classes.simulationContainer}>
      <img className={classes.inwitLogo} src={inwitLogo} alt='inwit logo' />
      <div id='messagesBox' className={classes.messagesBox}>
        {messages.length > 0 &&
          messages.map(({ user, message }, i) => (
            <div
              className={
                i === messages.length - 1
                  ? classes.lastMessage
                  : classes.message
              }
              key={`message-${i}`}
            >
              <p>
                <b>Module {user}:</b> {message}
              </p>
            </div>
          ))}
      </div>
    </div>
  );
};

const mapState = ({ simulation }) => ({
  connection: simulation.connection,
  messages: simulation.messages,
});

const mapDispatch = ({
  simulation: {
    startSimulationQueue,
    startTechSimulationQueue,
    startSimulation,
    receiveSimulationMessages,
  },
}) => ({
  startSimulationQueue: () => startSimulationQueue(),
  startTechSimulationQueue: () => startTechSimulationQueue(),
  startSimulation: () => startSimulation(),
  receiveSimulationMessages: (latestMessages) =>
    receiveSimulationMessages({ latestMessages }),
});

export default compose(
  withStyles(useStyles),
  connect(mapState, mapDispatch)
)(Simulation);
