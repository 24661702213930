import React, { useState, useEffect } from "react";
import withStyles from "@mui/styles/withStyles";
import { connect } from "react-redux";
import { compose } from "redux";
import {
  Stepper,
  Step,
  StepButton,
  StepLabel,
  StepConnector,
} from "@mui/material";

import SimulationsStepperExitModal from "../SimulationsStepperExitModal";
import SimulationsStepperResumeModal from "../SimulationsStepperResumeModal";

import Step1 from "./simulationsStepper.step1";
import Step2 from "./simulationsStepper.step2";
import Step3 from "./simulationsStepper.step3";
import Step4 from "./simulationsStepper.step4";
import Simulation from "../Simulation";

const useStyles = (theme) => ({
  simulationStepperContainer: {
    position: "absolute",
    width: "100vw",
    height: "100vh",
    backgroundColor: "#ffffff",
    marginTop: "-85px",
    marginLeft: "-25px",
  },
  simulationStepperHeader: {
    padding: "1rem 2rem",
    display: "flex",
    maxWidth: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#FAFAFA",
  },
  titleBox: {
    display: "flex",
    flexDirection: "column",
    fontWeight: "bold",
  },
  mainTitle: {
    color: "#000000",
    fontSize: "22px",
  },
  subTitle: {
    marginTop: "5px",
    color: "#A5A5A6",
    fontSize: "13px",
  },
  backwardButton: {
    background: "#C9CAC9 0% 0% no-repeat padding-box",
    borderRadius: "26px",
    opacity: 1,
    outline: "none",
    padding: "11px 30px",
    textDecoration: "none",
    font: "700 14px/19px",
    letterSpacing: 0,
    color: "#FFFFFF",
    border: "none",
    "&:hover": {
      outline: "none",
      cursor: "pointer",
      background: "#A5A5A6 0% 0% no-repeat padding-box",
    },
  },
  forwardButton: {
    marginLeft: "20px",
    background: "#03B8FF 0% 0% no-repeat padding-box",
    borderRadius: "26px",
    opacity: 1,
    outline: "none",
    padding: "11px 30px",
    textDecoration: "none",
    font: "700 14px/19px",
    letterSpacing: 0,
    color: "#FFFFFF",
    border: "none",
    "&:hover": {
      outline: "none",
      cursor: "pointer",
      background: "#018ABE 0% 0% no-repeat padding-box",
    },
  },
  forwardButtonDisabled: {
    marginLeft: "20px",
    background: "#A5A5A6 0% 0% no-repeat padding-box",
    borderRadius: "26px",
    opacity: 1,
    outline: "none",
    padding: "11px 30px",
    textDecoration: "none",
    font: "700 14px/19px",
    letterSpacing: 0,
    color: "#FFFFFF",
    border: "none",
    "&:hover": {
      outline: "none",
      background: "#A5A5A6 0% 0% no-repeat padding-box",
    },
  },
  simulateTechButton: {
    font: "700 14px/19px",
    marginRight: "20px",
    outline: "none",
    border: "none",
    cursor: "pointer",
    padding: "0.785em 2.15em",
    borderRadius: "26px",
    color: "#ffffff",
    backgroundColor: "#03B8FF",
    "&:hover": {
      backgroundColor: "#018ABE",
    },
  },
  connectorRoot: {
    width: "48px",
    color: "#03B8FF !important",
  },
  connectorLine: {
    borderColor: "#018ABE",
  },
  stepPageContainer: {
    padding: "30px 40px",
  },
});

const SimulationsStepper = ({
  classes,
  setSimulationsStepperActive,
  siteInfos,
  fileType,
  uploadedEval,
  description,
  setDescription,
  putDescription,
  getThresholds,
  setNewEval,
  setFileType,
  setUploadedEval,
  putThresholds,
  setStatus,
  setConnection,
  setMessages,
  thresholdsAreOk,
  colorsAreOk,
  getParameters,
  setActivePage,
  resetModelAndTenant,
  setSiteParameters,
  importedEval,
  setImportedEval,
  activeStep,
  setActiveStep,
  getConstraints,
  summaryList,
  getSummaryList,
  importAtena,
  errorMessages,
  errorMessagesAsString,
  newEval,
  getSiteSimulationsHistory,
}) => {
  const [exitModalOpened, setExitModalOpened] = useState(false);
  const [resumeModalOpened, setResumeModalOpened] = useState(false);
  const [simulationStarted, setSimulationStarted] = useState(false);
  const [simulateTechModalOpen, setSimulateTechModalOpen] = useState(false);
  const [isSimulatingTechnology, setIsSimulatingTechnology] = useState(false);

  const steps = [
    { name: "Project Infos", key: 0 },
    { name: "Parameters", key: 1 },
    { name: "Thresholds", key: 2 },
    { name: "EM Constraints", key: 3 },
  ];

  const handleExit = () => {
    setNewEval(null);
    setFileType(null);
    setUploadedEval(null);
    setDescription(null);
    setExitModalOpened(false);
    setSimulationsStepperActive(false);
    setSimulationStarted(false);
    setStatus(null);
    setConnection(null);
    setMessages([]);
    setActivePage("check");
    resetModelAndTenant({});
    setSiteParameters(null);
    setImportedEval(false);
    setActiveStep(0);
    getSiteSimulationsHistory();
  };

  const handleNext = async () => {
    if (activeStep === 0) await putDescription();
    if (activeStep === 2) await putThresholds();
    if (activeStep === 3) {
      await getSummaryList();
      setResumeModalOpened(true);
    }
    setActiveStep(activeStep + 1);
  };

  const nextIsDisabled = () => {
    let isDisabled = false;
    if (activeStep === 0) {
      const uploadedFile =
        fileType === "Add manually" ||
        (fileType === "Excel/CSV File" && uploadedEval && importedEval) ||
        (fileType === "Import Atena" &&
          !errorMessagesAsString &&
          errorMessages.length === 0);
      isDisabled = !(description && uploadedFile);
    }
    if (activeStep === 2) isDisabled = !(thresholdsAreOk && colorsAreOk);
    return isDisabled;
  };

  useEffect(() => {
    const asyncImportAtena = async () => {
      await importAtena();
    };
    if (activeStep === 0 && fileType === "Import Atena") {
      asyncImportAtena();
    }
  }, [fileType, activeStep]);

  useEffect(() => {
    async function fetchData() {
      if (activeStep === 1) {
        await resetModelAndTenant({});
        await setSiteParameters(null);
        await getParameters("isSimulationsStepper");
      }
      if (activeStep === 2) await getThresholds();
      if (activeStep === 3) await getConstraints();
      if (activeStep === 4 && !resumeModalOpened) setSimulationStarted(true);
    }
    fetchData();
  }, [activeStep]);

  return (
    <div className={classes.simulationStepperContainer}>
      <div className={classes.simulationStepperHeader}>
        <div className={classes.titleBox}>
          <span className={classes.mainTitle}>
            {simulationStarted
              ? "C.E.M Simulation running..."
              : `Simulation ${newEval.id}`}
          </span>
          <span
            className={classes.subTitle}
          >{`${siteInfos?.site_name} ${siteInfos?.site_code}`}</span>
        </div>

        {!simulationStarted && (
          <Stepper
            activeStep={activeStep}
            connector={
              <StepConnector
                classes={{
                  root: classes.connectorRoot,
                  line: classes.connectorLine,
                }}
              />
            }
          >
            {steps.map((step, index) => (
              <Step
                key={step.key}
                sx={{
                  "&.MuiSvgIcon-root.MuiStepIcon-root": {
                    border: "1px solid #018abe",
                    borderRadius: "50%",
                  },
                  "& .MuiStepLabel-root .Mui-completed": {
                    color: "primary", // circle color (COMPLETED)
                  },
                  "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel":
                    {
                      color: "grey.500", // Just text label (COMPLETED)
                    },
                  "& .MuiStepLabel-root .Mui-active": {
                    color: "primary", // circle color (ACTIVE)
                  },
                  "& .MuiStepLabel-root .MuiStepIcon-text": {
                    fill: "white", // circle's number (ACTIVE)
                  },
                  "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel":
                    {
                      color: "common.white",
                      fontWeight: "bold", // Just text label (ACTIVE)
                    },
                  "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
                    fill: "white", // circle's number (ACTIVE)
                  },
                }}
              >
                {activeStep > index && (
                  <StepButton onClick={() => setActiveStep(index)}>
                    {step.name}
                  </StepButton>
                )}
                {activeStep <= index && <StepLabel>{step.name}</StepLabel>}
              </Step>
            ))}
          </Stepper>
        )}

        <div className={classes.buttonsBox}>
          {activeStep === 1 && !simulationStarted && (
            <button
              className={classes.simulateTechButton}
              onClick={() => setSimulateTechModalOpen(true)}
            >
              Simulate Tech
            </button>
          )}
          {!simulationStarted && (
            <button
              className={classes.backwardButton}
              onClick={() => setExitModalOpened(true)}
            >
              Cancel
            </button>
          )}

          <button
            className={
              nextIsDisabled()
                ? classes.forwardButtonDisabled
                : classes.forwardButton
            }
            disabled={nextIsDisabled()}
            onClick={() => {
              if (simulationStarted) {
                handleExit();
                window.location.reload();
              } else {
                handleNext();
              }
            }}
          >
            {simulationStarted ? "Exit" : "Next"}
          </button>
        </div>
      </div>

      <div className={classes.stepPageContainer}>
        {activeStep === 0 && !simulationStarted && <Step1 />}
        {activeStep === 1 && !simulationStarted && (
          <Step2
            // Passing modal state as props to handle opening and closing from child component
            simulateTechIsOpen={simulateTechModalOpen}
            simulateTechHandler={setSimulateTechModalOpen}
            setSimulationStarted={setSimulationStarted}
            setIsSimulatingTechnology={setIsSimulatingTechnology}
          />
        )}
        {activeStep === 2 && !simulationStarted && <Step3 />}
        {activeStep === 3 && !simulationStarted && <Step4 />}
        {/* isSimulatingTechnology checks if the starting simulation is on tech only */}
        {simulationStarted && (
          <Simulation isSimulatingTechnology={isSimulatingTechnology} />
        )}
      </div>

      {exitModalOpened && (
        <SimulationsStepperExitModal
          exitModalOpened={exitModalOpened}
          setExitModalOpened={setExitModalOpened}
          handleExit={handleExit}
        />
      )}

      {resumeModalOpened && (
        <SimulationsStepperResumeModal
          resumeModalOpened={resumeModalOpened}
          setResumeModalOpened={setResumeModalOpened}
          setActiveStep={setActiveStep}
          setSimulationStarted={setSimulationStarted}
          summaryList={summaryList}
        />
      )}
    </div>
  );
};

const mapState = ({ simulationsStepper }) => ({
  fileType: simulationsStepper.fileType,
  uploadedEval: simulationsStepper.uploadedEval,
  description: simulationsStepper.description,
  thresholdsAreOk: simulationsStepper.thresholdsAreOk,
  colorsAreOk: simulationsStepper.colorsAreOk,
  importedEval: simulationsStepper.importedEval,
  activeStep: simulationsStepper.activeStep,
  summaryList: simulationsStepper.summaryList,
  errorMessages: simulationsStepper.errorMessages,
  errorMessagesAsString: simulationsStepper.errorMessagesAsString,
  newEval: simulationsStepper.newEval,
});

const mapDispatch = ({
  site: {
    getSiteParameters,
    resetModelAndTenant,
    setSiteParameters,
    getSiteSimulationsHistory,
  },
  simulationsStepper: {
    setDescription,
    setNewEval,
    setFileType,
    setUploadedEval,
    putDescription,
    getThresholds,
    putThresholds,
    setImportedEval,
    setActiveStep,
    getConstraints,
    getSummaryList,
    importAtena,
  },
  simulation: { setStatus, setConnection, setMessages },
}) => ({
  setDescription: (value) => setDescription(value),
  setNewEval: (value) => setNewEval(value),
  setFileType: (value) => setFileType(value),
  setUploadedEval: (value) => setUploadedEval(value),
  putDescription: () => putDescription(),
  getThresholds: () => getThresholds(),
  putThresholds: () => putThresholds(),
  setStatus: (value) => setStatus(value),
  setConnection: (value) => setConnection(value),
  setMessages: (value) => setMessages(value),
  getParameters: (type) => getSiteParameters({ type }),
  resetModelAndTenant: (value) => resetModelAndTenant(value),
  setSiteParameters: (value) => setSiteParameters(value),
  setImportedEval: (value) => setImportedEval(value),
  setActiveStep: (value) => setActiveStep(value),
  getConstraints: () => getConstraints(),
  getSummaryList: () => getSummaryList(),
  importAtena: () => importAtena(),
  getSiteSimulationsHistory: () => getSiteSimulationsHistory(),
});

export default compose(
  withStyles(useStyles),
  connect(mapState, mapDispatch)
)(SimulationsStepper);
