/* eslint-disable camelcase */
import axios from "axios";
import { parseTenant } from "../../utils/parser";
import { config } from "../../utils/config";
import {
  GET_SITE_INFOS,
  GET_SITE_SIM_COUNT,
  GET_SITE_LAST_SIM_INFOS,
  GET_SITE_PARAMETERS,
  GET_SITE_TENANTS_TECHNOLOGIES,
  GET_SITE_TENANTS_TECHNOLOGIES_LIST,
  GET_SITE_TENANTS_TAB,
  GET_SITE_DOCS_LIST,
  GET_SITE_DOCS_DOWNLOAD,
  PUT_SITE_INFOS_MODIFY,
  GET_SITE_MEASURE_POINTS,
  GET_SITE_EMPTY_MEASURE_POINTS,
  GET_SITE_SIMULATIONS_HISTORY,
  DELETE_SITE_SIMULATIONS_HISTORY_ROW,
  POST_SITE_SIMULATIONS_HISTORY_ROW_DUPLICATE,
  PUT_SITE_SIMULATIONS_HISTORY_ROW_PROMOTE,
  DELETE_SITE_SIMULATIONS_HISTORY_ROW_FOLDER,
  GET_FINAL_CONFIGURATION_PARAMS,
  GET_SITE_MANUAL_IMPORT_MEASURE_POINTS,
  POST_SAVE_MEASURE_POINTS,
  GET_SITE_POLES,
  POST_SAVE_POLES,
  PUT_UPDATE_SITE_POLE,
  POST_SITE_SIMULATIONS_HISTORY_ROW_DRAFT,
  DELETE_SITE_DOCS_FILE,
  POST_SITE_SHAPEFILE,
  PUT_SITE_SHAPEFILE,
  PUT_GEOJSON_INFOS,
  POST_SHAPEFILE_MESSAGE,
  POST_IMPORT_EXCEL_SHAPEFILE,
  GET_EXPORT_EXCEL_SHAPEFILE,
  GET_FILES_FROM_GOOGLE_STORAGE,
  GET_SHAPEFILE_UPLOAD_FEEDBACK,
  GET_BUCKET_SUPPLIERS,
  GET_EVAL_MEASURE_POINTS,
  DOWNLOAD_EVAL_MEASURE_POINTS,
  POST_SAVE_EVAL_MEASURE_POINTS,
  tokenExpiredDoLogin,
} from "../../utils/api";
import { result } from "lodash-es";

function removeValueFromArray(arr) {
  let what;
  const a = arguments;
  let L = a.length;
  let ax;
  while (L > 1 && arr.length) {
    what = a[--L];
    while ((ax = arr.indexOf(what)) !== -1) {
      arr.splice(ax, 1);
    }
  }
  return arr;
}

const site = {
  state: {
    loadingInfos: false,
    loadingParameters: false,
    center: null,
    lastSimulationDate: null,
    lastSimulationStatus: null,
    simulationsCount: null,
    infos: null,
    evalSelected: null,
    sectors: null,
    parameters: null,
    technologiesList: [],
    technologiesByModelAndTenant: {},
    loadingAPIs: [],
    tenants: [],
    docs: [],
    modifyModalInputs: {},
    simulationsHistory: null,
    measurePoints: [],
    evalMeasurePoints: [],
    poles: [],
    actualParams: null,
    googleStoreFiles: [],
    shapefileFeedback: null,
    bucketSupplierList: [],
    apiMessages: {},
  },
  reducers: {
    resetSiteTabs(state) {
      return {
        ...state,
        loadingInfos: false,
        loadingParameters: false,
        center: null,
        lastSimulationDate: null,
        lastSimulationStatus: null,
        simulationsCount: null,
        infos: null,
        evalSelected: null,
        sectors: null,
        parameters: null,
        technologiesList: [],
        technologiesByModelAndTenant: {},
        loadingAPIs: [],
        tenants: [],
        docs: [],
        modifyModalInputs: {},
        simulationsHistory: null,
        measurePoints: [],
        evalMeasurePoints: [],
        poles: [],
        selectedUUID: null,
        selectedShape: null,
        apiMessages: {},
      };
    },
    setLoadingInfos(state, value) {
      return { ...state, loadingInfos: value };
    },
    setEvalSelected(state, value) {
      return { ...state, evalSelected: value };
    },
    setLoadingParameters(state, value) {
      return { ...state, loadingParameters: value };
    },
    setSiteCenter(state, value) {
      return { ...state, center: value };
    },
    setSiteLastSimulationDate(state, value) {
      return { ...state, lastSimulationDate: value };
    },
    setSiteLastSimulationStatus(state, value) {
      return { ...state, lastSimulationStatus: value };
    },
    setSiteSimulationsCount(state, value) {
      return { ...state, simulationsCount: value };
    },
    setSiteInfos(state, value) {
      return { ...state, infos: value };
    },
    setSiteTenants(state, value) {
      return { ...state, tenants: value };
    },
    setOriginalSectors(state, value) {
      return { ...state, sectors: value };
    },
    setSiteParameters(state, value) {
      return { ...state, parameters: value };
    },
    setSiteTechnologies(state, value) {
      return { ...state, technologiesList: value };
    },
    setModelAndTenant(state, { model, tenant }) {
      return {
        ...state,
        technologiesByModelAndTenant: {
          ...state.technologiesByModelAndTenant,
          [model]: {
            tenants: {
              [tenant]: [],
            },
          },
        },
      };
    },
    setLoadingAPIs(state, { api, loading }) {
      const newArray = loading
        ? [...state.loadingAPIs, api]
        : removeValueFromArray(state.loadingAPIs, api);

      return {
        ...state,
        loadingAPIs: newArray,
      };
    },
    setTechnologiesByModelAndTenant(state, { model, tenant, technologies }) {
      return {
        ...state,
        technologiesByModelAndTenant: {
          ...state.technologiesByModelAndTenant,
          [model]: {
            tenants: {
              ...state.technologiesByModelAndTenant[model].tenants,
              [tenant]: technologies,
            },
          },
        },
      };
    },
    resetTechnologiesByModelAndTenant(state, value) {
      return { ...state, technologiesByModelAndTenant: value };
    },
    setSiteDocs(state, value) {
      return { ...state, docs: value };
    },
    setSiteModifyModalInputs(state, { name, value }) {
      return {
        ...state,
        modifyModalInputs: {
          ...state.modifyModalInputs,
          [name]: value,
        },
      };
    },
    resetSiteModifyModalInputs(state, value) {
      return { ...state, modifyModalInputs: value };
    },
    setSiteSimulationsHistory(state, value) {
      return { ...state, simulationsHistory: value };
    },
    resetModelAndTenant(state, value) {
      return { ...state, technologiesByModelAndTenant: value };
    },
    setMeasurePoints(state, value) {
      return { ...state, measurePoints: value };
    },
    setEvalMeasurePoints(state, value) {
      return { ...state, evalMeasurePoints: value };
    },
    setPoles(state, value) {
      return { ...state, poles: value };
    },
    setSelectedUUID(state, value) {
      return { ...state, selectedUUID: value };
    },
    setSelectedShape(state, value) {
      return { ...state, selectedShape: value };
    },
    setActualParams(state, value) {
      return { ...state, actualParams: value };
    },
    setGoogleStoreFiles(state, value) {
      return { ...state, googleStoreFiles: value };
    },
    setShapefileFeedback(state, value) {
      return { ...state, shapefileFeedback: value };
    },
    setBucketSupplierList(state, value) {
      return { ...state, bucketSupplierList: value };
    },
    setApiMessages(state, value) {
      return { ...state, apiMessages: value };
    },
    resetEvalRelatedParams(state) {
      return {
        ...state,
        evalSelected: null,
        sectors: null,
        technologiesList: [],
        technologiesByModelAndTenant: [],
        tenants: [],
        actualParams: null,
        simulationsHistory: null,
      };
    },
  },
  effects: (dispatch) => ({
    // tab infos
    async downloadMeasurePoints(_, { site }) {
      dispatch.loader.loader(true);

      await axios({
        method: "GET",
        url: GET_SITE_MEASURE_POINTS,
        headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        params: { id_site: site.infos.id },
        responseType: "blob",
      })
        .then((res) => {
          const blob = new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const aEle = document.createElement("a"); // Create a label
          const href = window.URL.createObjectURL(blob); // Create downloaded link
          aEle.href = href;
          aEle.setAttribute("download", "measure_points.xlsx"); // File name after download
          document.body.appendChild(aEle);
          aEle.click(); // Click to download
          document.body.removeChild(aEle); // Download complete remove element
          window.URL.revokeObjectURL(href); // Release blob object

          dispatch.loader.loader(false);
        })
        .catch((err) => {
          console.log(`Error ${GET_SITE_MEASURE_POINTS}`, err);
          tokenExpiredDoLogin(err);
          dispatch.loader.loader(false);
        });
    },

    async downloadEvalMeasurePoints(_, { site }) {
      dispatch.loader.loader(true);

      await axios({
        method: "GET",
        url: DOWNLOAD_EVAL_MEASURE_POINTS,
        headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        params: { uuid: site.selectedUUID },
        responseType: "blob",
      })
        .then((res) => {
          const blob = new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const aEle = document.createElement("a"); // Create a label
          const href = window.URL.createObjectURL(blob); // Create downloaded link
          aEle.href = href;
          aEle.setAttribute(
            "download",
            `eval_${site.evalSelected}_measure_points.xlsx`
          ); // File name after download
          document.body.appendChild(aEle);
          aEle.click(); // Click to download
          document.body.removeChild(aEle); // Download complete remove element
          window.URL.revokeObjectURL(href); // Release blob object

          dispatch.loader.loader(false);
        })
        .catch((err) => {
          console.log(`Error ${DOWNLOAD_EVAL_MEASURE_POINTS}`, err);
          tokenExpiredDoLogin(err);
          dispatch.loader.loader(false);
        });
    },

    async downloadEmptyMeasurePoints() {
      dispatch.loader.loader(true);

      await axios({
        method: "GET",
        url: GET_SITE_EMPTY_MEASURE_POINTS,
        headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        responseType: "blob",
      })
        .then((res) => {
          const blob = new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const aEle = document.createElement("a"); // Create a label
          const href = window.URL.createObjectURL(blob); // Create downloaded link
          aEle.href = href;
          aEle.setAttribute("download", "template-measure_points.xlsx"); // File name after download
          document.body.appendChild(aEle);
          aEle.click(); // Click to download
          document.body.removeChild(aEle); // Download complete remove element
          window.URL.revokeObjectURL(href); // Release blob object

          dispatch.loader.loader(false);
        })
        .catch((err) => {
          console.log(`Error ${GET_SITE_EMPTY_MEASURE_POINTS}`, err);
          tokenExpiredDoLogin(err);
          dispatch.loader.loader(false);
        });
    },
    async getSiteInfos({ siteId }) {
      dispatch.loader.loader(true);
      this.setLoadingInfos(true);

      this.setSiteCenter(null);
      this.setSiteInfos(null);
      dispatch.map.resetMapParams();

      await axios({
        method: "GET",
        url: GET_SITE_INFOS,
        headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        params: { site_code: siteId },
      })
        .then((res) => {
          const site = res.data.data[0];
          const siteCenter = site.geometry
            ? [site.geometry?.coordinates[1], site.geometry?.coordinates[0]]
            : undefined;

          this.setSiteCenter(siteCenter);
          this.setSiteInfos(site);

          if (site.id_eval_actual === null) {
            this.resetEvalRelatedParams();
          }

          this.setLoadingInfos(false);

          dispatch.map.setZoom(18);
          dispatch.map.setCenter(siteCenter);
          // Keeping the below line as reference. This dispatch is messing up the office state model
          // dispatch.map.setOffices({ value: [site] || [], usebounds: true });

          dispatch.loader.loader(false);
        })
        .catch((err) => {
          this.setLoadingInfos(false);
          dispatch.loader.loader(false);
          console.log(`Error ${GET_SITE_INFOS}`, err);
          tokenExpiredDoLogin(err);
        });
    },
    putMapInCenter(site) {
      const siteCenter = site.geometry
        ? [site.geometry?.coordinates[1], site.geometry?.coordinates[0]]
        : undefined;

      this.setSiteCenter(siteCenter);
      dispatch.map.setGpsPosition(siteCenter);
    },
    async getSimulationsNumber(_, { site }) {
      dispatch.loader.loader(true);

      this.setSiteSimulationsCount(null);

      await axios({
        method: "GET",
        url: GET_SITE_SIM_COUNT,
        headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        params: { id_site: site?.infos.id },
      })
        .then((res) => {
          const { data } = res.data;
          if (data) {
            const { eval_count } = data[0];
            const simulationsCount = eval_count || null;
            this.setSiteSimulationsCount(simulationsCount);

            dispatch.loader.loader(false);
          }
        })
        .catch((err) => {
          console.log(`Error ${GET_SITE_SIM_COUNT}`, err);
          tokenExpiredDoLogin(err);
          dispatch.loader.loader(false);
        });
    },
    async getSimulationsLastInfos(payload, { site }) {
      dispatch.loader.loader(true);

      this.setSiteLastSimulationDate(null);
      this.setSiteLastSimulationStatus(null);

      await axios({
        method: "GET",
        url: GET_SITE_LAST_SIM_INFOS,
        headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        params: { id_site: payload?.siteId || site.infos.id },
      })
        .then((res) => {
          const { data } = res.data;
          if (data) {
            const { log_date_upd, status } = data[0];
            const lastSimulationDate = log_date_upd || null;
            const lastSimulationStatus = status || null;
            this.setSiteLastSimulationDate(lastSimulationDate);
            this.setSiteLastSimulationStatus(lastSimulationStatus);

            dispatch.loader.loader(false);
          }
        })
        .catch((err) => {
          console.log(`Error ${GET_SITE_LAST_SIM_INFOS}`, err);
          tokenExpiredDoLogin(err);
          dispatch.loader.loader(false);
        });

      dispatch.loader.loader(false);
    },
    async modifySiteInfos(_, { site }) {
      dispatch.loader.loader(true);

      const inputs = site.modifyModalInputs;
      const newCoordinates =
        !inputs.site_lng || !inputs.site_lat
          ? undefined
          : [inputs.site_lng, inputs.site_lat];

      await axios({
        method: "PUT",
        url: PUT_SITE_INFOS_MODIFY,
        headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        data: {
          id: site.infos.id,
          site_name: inputs.site_name,
          address: inputs.site_address,
          type: "Feature",
          geometry: {
            // site.infos.geometry
            type: "Point",
            coordinates: newCoordinates,
          },
        },
      })
        .then((res) => {
          dispatch.loader.loader(false);
        })
        .catch((err) => {
          console.log(`Error ${PUT_SITE_INFOS_MODIFY}`, err);
          tokenExpiredDoLogin(err);
          dispatch.loader.loader(false);
        });
    },
    async manualImportMeasurePoints(_, { site }) {
      dispatch.loader.loader(true);
      this.setMeasurePoints([]);

      await axios({
        method: "GET",
        url: GET_SITE_MANUAL_IMPORT_MEASURE_POINTS,
        headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        params: { id_site: site.infos.id },
      })
        .then((res) => {
          this.setMeasurePoints(res.data.data);
          dispatch.loader.loader(false);
        })
        .catch((err) => {
          console.log(`Error ${GET_SITE_MANUAL_IMPORT_MEASURE_POINTS}`, err);
          tokenExpiredDoLogin(err);
          dispatch.loader.loader(false);
        });
    },
    async manualImportEvalMeasurePoints(_, { site }) {
      dispatch.loader.loader(true);
      this.setEvalMeasurePoints([]);

      await axios({
        method: "GET",
        url: GET_EVAL_MEASURE_POINTS,
        headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        params: { uuid: site.selectedUUID },
      })
        .then((res) => {
          this.setEvalMeasurePoints(res.data.data);
          dispatch.loader.loader(false);
        })
        .catch((err) => {
          console.log(`Error ${GET_EVAL_MEASURE_POINTS}`, err);
          tokenExpiredDoLogin(err);
          dispatch.loader.loader(false);
        });
    },
    async saveMeasurePoints(_, { site }) {
      dispatch.loader.loader(true);
      this.setApiMessages({});
      try {
        const response = await axios({
          method: "POST",
          url: POST_SAVE_MEASURE_POINTS,
          headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
          data: { id_site: site.infos.id, data: site.measurePoints },
        });
        if (response?.data.result === 0) {
          this.setApiMessages({
            status: "error",
            message: response.data.message,
          });
        } else {
          this.setApiMessages({
            status: "success",
            message: "Measure points saved successfully",
          });
        }
      } catch (err) {
        console.log(`Error ${POST_SAVE_MEASURE_POINTS}`, err);
        tokenExpiredDoLogin(err);
      } finally {
        dispatch.loader.loader(false);
      }
    },

    async saveEvalMeasurePoints(_, { site }) {
      dispatch.loader.loader(true);
      this.setApiMessages({});

      try {
        const response = await axios({
          method: "POST",
          url: POST_SAVE_EVAL_MEASURE_POINTS,
          headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
          data: { eval_uuid: site.selectedUUID, data: site.evalMeasurePoints },
        });
        if (response?.data.result === 0) {
          this.setApiMessages({
            status: "error",
            message: response.data.message,
          });
        } else {
          this.setApiMessages({
            status: "success",
            message: "Measure points saved successfully",
          });
        }
      } catch (err) {
        console.log(`Error ${POST_SAVE_EVAL_MEASURE_POINTS}`, err);
        tokenExpiredDoLogin(err);
        dispatch.loader.loader(false);
      } finally {
        dispatch.loader.loader(false);
      }

      // await axios({
      //   method: "POST",
      //   url: POST_SAVE_EVAL_MEASURE_POINTS,
      //   headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
      //   data: { eval_uuid: site.selectedUUID, data: site.evalMeasurePoints },
      // })
      //   .then((res) => {
      //     dispatch.loader.loader(false);
      //   })
      //   .catch((err) => {
      //     console.log(`Error ${POST_SAVE_EVAL_MEASURE_POINTS}`, err);
      //     tokenExpiredDoLogin(err);
      //     dispatch.loader.loader(false);
      //   });
    },

    async manualPoles(_, { site }) {
      dispatch.loader.loader(true);
      this.setPoles([]);

      await axios({
        method: "GET",
        url: GET_SITE_POLES,
        headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        params: { id_site: site.infos.id },
      })
        .then((res) => {
          this.setPoles(res.data.data[0].poles);
          dispatch.loader.loader(false);
        })
        .catch((err) => {
          console.log(`Error ${GET_SITE_POLES}`, err);
          tokenExpiredDoLogin(err);
          dispatch.loader.loader(false);
        });
    },
    async savePoles(_, { site }) {
      dispatch.loader.loader(true);

      site.poles.length > 0 && site.poles.map((pole) => delete pole.id);

      await axios({
        method: "POST",
        url: POST_SAVE_POLES,
        headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        data: { data: site.poles },
      })
        .then((res) => {
          this.updateSitePole(site.poles[0]);
          dispatch.loader.loader(false);
        })
        .catch((err) => {
          console.log(`Error ${POST_SAVE_POLES}`, err);
          tokenExpiredDoLogin(err);
          dispatch.loader.loader(false);
        });
    },
    async updateSitePole(pole) {
      dispatch.loader.loader(true);

      await axios({
        method: "PUT",
        url: PUT_UPDATE_SITE_POLE,
        headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        data: {
          id: pole.id_site,
          geometry: pole.geometry,
        },
      })
        .then((res) => {
          dispatch.loader.loader(false);
        })
        .catch((err) => {
          console.log(`Error ${PUT_UPDATE_SITE_POLE}`, err);
          tokenExpiredDoLogin(err);
          dispatch.loader.loader(false);
        });
    },

    async getEval(_, { site }) {
      dispatch.loader.loader(true);
      this.setActualParams(null);
      const id = site.infos?.id_eval_actual || null;

      if (!id) {
        dispatch.loader.loader(false);
        return;
      }

      try {
        const response = await axios({
          method: "GET",
          url: GET_SITE_PARAMETERS,
          headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
          params: {
            id,
          },
        });
        const { data } = response.data;

        if (!data[0]) return;

        this.setActualParams(data[0]);
      } catch (error) {
        console.log(`Error ${GET_SITE_PARAMETERS}`, error);
        tokenExpiredDoLogin(error);
      } finally {
        dispatch.loader.loader(false);
      }
    },

    // tab params and tenants
    async getSiteTenantsParameters(_, { site }) {
      dispatch.loader.loader(true);
      this.setLoadingParameters(true);
      const actualSiteEvalId = site.infos?.id_eval_actual || null;

      if (!actualSiteEvalId) {
        dispatch.loader.loader(false);
        this.setLoadingParameters(false);
        return;
      }

      await this.setSiteTenants([]);

      try {
        const { data } = await axios({
          method: "GET",
          url: GET_SITE_TENANTS_TAB,
          headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
          params: { id: actualSiteEvalId },
        });
        this.setSiteTenants(data.data);
      } catch (error) {
        console.log(`Error ${GET_SITE_TENANTS_TAB}`, error);
        tokenExpiredDoLogin(error);
      } finally {
        dispatch.loader.loader(false);
        this.setLoadingParameters(false);
      }
    },

    async getSiteParameters({ type, uuid }, { site, simulationsStepper }) {
      dispatch.loader.loader(true);
      this.setLoadingParameters(true);

      // dispatch.simulation.setFinalConfigParams([]);
      await this.setSiteTenants([]);
      await this.setSiteParameters(null);
      await this.resetTechnologiesByModelAndTenant({});
      this.setSelectedUUID(simulationsStepper?.newEval?.uuid || uuid);

      const actualSiteEvalId = site.infos?.id_eval_actual || null;
      const stepperEvalId = simulationsStepper.newEval?.id || null;
      const finalConfigUuid = site.actualParams?.uuid;

      if (site.technologiesList.length === 0) {
        await this.getSiteTecnologiesList();
      }

      if (!actualSiteEvalId && type === "") {
        this.setLoadingParameters(false);
        dispatch.loader.loader(false);
        return;
      }

      let params =
        type === "isActiveParams"
          ? {
              uuid: finalConfigUuid,
            }
          : type === "isSimulationsStepper"
          ? {
              id: stepperEvalId,
            }
          : type === "isFinalConfig"
          ? {
              uuid: uuid,
            }
          : {
              id: actualSiteEvalId,
            };

      await axios({
        method: "GET",
        url:
          type === "isFinalConfig" || type === "isActiveParams"
            ? GET_FINAL_CONFIGURATION_PARAMS
            : GET_SITE_PARAMETERS,
        headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        params,
      })
        .then((res) => {
          const parameters = res.data.data ? res.data.data[0].sectors : [];
          const evalID = res.data.data ? res.data.data[0].id : null;
          if (evalID) this.setEvalSelected(evalID);

          let sectorsInfos = [];
          this.setSiteTenants(parseTenant(parameters));
          this.setOriginalSectors(parameters);

          // diviso per settori
          // ogni settore è diviso per modello antenna
          // ogni modello ha una riga per ogni tenant
          // ogni tenant ha tecnologie possibili e tecnologie attive

          parameters &&
            parameters.map((sector, sectorIndex) => {
              const { antennas, azimuth } = sector;

              let modelRow = {};

              antennas.map((antenna, antennaIndex) => {
                const {
                  hbaSuolo,
                  poleID,
                  model,
                  technologies,
                  uuid,
                  arpaParameters: { tiltMeccanicoMax },
                } = antenna;

                const sectorsInfosTemp = [];

                technologies.map((tech, index) => {
                  let showData = true;

                  if (index > 0) showData = false;

                  const settedTenant = sectorsInfosTemp.find(
                    (el) => el === tech.tenant
                  );
                  if (!settedTenant) sectorsInfosTemp.push(tech.tenant);

                  this.getSiteTecnologies({
                    model: model,
                    tenant: tech.tenant,
                  });

                  const modelRowTenant =
                    modelRow.tenant && modelRow.tenant.values
                      ? modelRow.tenant.values
                      : [];

                  // const allTechnologies = site.technologiesByModelAndTenant[
                  //   model
                  // ]
                  //   ? site.technologiesByModelAndTenant[model][
                  //       tech.tenant
                  //     ].map((e) => ({ name: e }))
                  //   : []

                  /* workaround to get tecnologilist immediately */
                  const techlist = JSON.parse(
                    sessionStorage.getItem("siteTechnologies")
                  );

                  const tempValues = [
                    ...modelRowTenant,
                    // ...allTechnologies,
                    {
                      name: tech.band,
                      active: true,
                      details: tech,
                    },
                  ];

                  const orderValues = [];
                  techlist.map((e, ind) => {
                    let result = { name: "", key: e.name };

                    tempValues.map((l) => {
                      if (e.name === l.name) {
                        result = l.active
                          ? { name: "P", values: l.details, key: e.name }
                          : { name: "-", values: l.details, key: e.name };
                      }
                      return true;
                    });

                    return orderValues.push(result);
                  });

                  if (settedTenant) {
                    /* tenant already present */

                    const newSectorsInfos = [...sectorsInfos];

                    const antennaIndex = newSectorsInfos.findIndex(
                      (el) =>
                        el.sector.name === sector.sector &&
                        el.azimuth.name === sector.azimuth &&
                        el.model.name === antenna.model &&
                        el.poleID.name === antenna.poleID &&
                        el.hbaSuolo.name === antenna.hbaSuolo &&
                        el.downtiltMeccanico.name ===
                          antenna.arpaParameters.tiltMeccanicoMax &&
                        el.tenant.name === tech.tenant
                    );

                    newSectorsInfos[antennaIndex].tenant.values =
                      newSectorsInfos[antennaIndex].tenant.values.map((info) =>
                        info.key === tech.band
                          ? { ...info, name: "P", values: tech }
                          : { ...info }
                      );

                    sectorsInfos = [...newSectorsInfos];
                  } else {
                    /* new tenant */

                    // if (type === 'isFinalConfig') {
                    //   modelRow = {
                    //     sector: { name: sector.sector, show: showData },
                    //     azimuth: { name: azimuth, show: showData },
                    //     model: { name: model, show: showData },
                    //     amount: { name: amount, show: showData },
                    //     hbaSuolo: { name: hbaSuolo, show: showData },
                    //     downtiltMeccanico: {
                    //       name: tiltMeccanicoMax,
                    //       show: showData,
                    //     },
                    //     tenant: {
                    //       name: tech.tenant,
                    //       values: orderValues,
                    //     },
                    //   }
                    // } else {
                    modelRow = {
                      sector: { name: sector.sector, show: showData },
                      azimuth: { name: azimuth, show: showData },
                      model: { name: model, show: showData },
                      poleID: { name: poleID, show: showData },
                      hbaSuolo: { name: hbaSuolo, show: showData },
                      uuid: type === "isSimulationsStepper" ? uuid : null,
                      downtiltMeccanico: {
                        name: tiltMeccanicoMax,
                        show: showData,
                      },
                      tenant: {
                        name: tech.tenant,
                        values: orderValues,
                      },
                      details: {
                        vedi: type !== "isSimulationsStepper",
                        modifica: type === "isSimulationsStepper",
                        elimina: type === "isSimulationsStepper",
                        show: true,
                        details: orderValues,
                      },
                    };
                    // }

                    return sectorsInfos.push(modelRow);
                  }
                  return true;
                });
                return true;
              });
              return true;
            });

          if (type === "isSimulationsStepper") {
            dispatch.simulationsStepper.setParameters(sectorsInfos);
          } else if (type === "isFinalConfig" || type === "isActiveParams") {
            dispatch.simulation.setFinalConfigParams(sectorsInfos);
          } else {
            this.setSiteParameters(sectorsInfos);
          }

          this.setLoadingParameters(false);
          dispatch.loader.loader(false);
        })
        .catch((err) => {
          console.log(`Error ${GET_SITE_PARAMETERS}`, err);
          tokenExpiredDoLogin(err);
          this.setLoadingParameters(false);
          dispatch.loader.loader(false);
        });

      this.setLoadingParameters(false);
      dispatch.loader.loader(false);
    },
    async getSiteTecnologiesList() {
      this.setLoadingParameters(true);

      this.setSiteTechnologies([]);

      await axios({
        method: "GET",
        url: GET_SITE_TENANTS_TECHNOLOGIES_LIST,
        headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
      })
        .then((res) => {
          const technologies = res.data?.data.map((tec) => ({
            name: tec,
            key: tec.toLowerCase(),
          }));

          sessionStorage.setItem(
            "siteTechnologies",
            JSON.stringify(technologies)
          );
          this.setSiteTechnologies(technologies);

          this.setLoadingParameters(false);
        })
        .catch((err) => {
          console.log(`Error ${GET_SITE_TENANTS_TECHNOLOGIES_LIST}`, err);
          tokenExpiredDoLogin(err);
          this.setLoadingParameters(false);
        });
    },
    async getSiteTecnologies({ model, tenant }, { site }) {
      this.setLoadingParameters(true);

      if (
        (site.technologiesByModelAndTenant[model] &&
          site.technologiesByModelAndTenant[model]?.tenants[tenant]) ||
        site.loadingAPIs.indexOf(model + tenant) > -1
      ) {
        this.setLoadingParameters(false);
      } else {
        this.setLoadingAPIs({ api: model + tenant, loading: true });
        this.setModelAndTenant({ model: model, tenant: tenant });

        await axios({
          method: "GET",
          url: GET_SITE_TENANTS_TECHNOLOGIES,
          headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
          params: { model_codes: model, tenant: tenant },
        })
          .then((res) => {
            this.setLoadingAPIs({ api: model + tenant, loading: false });
            this.setTechnologiesByModelAndTenant({
              model: model,
              tenant: tenant,
              technologies: res.data?.data,
            });

            this.setLoadingParameters(false);
          })
          .catch((err) => {
            console.log(`Error ${GET_SITE_TENANTS_TECHNOLOGIES}`, err);
            tokenExpiredDoLogin(err);
            this.setLoadingParameters(false);
          });
      }
    },

    // tab simulations history
    async getSiteSimulationsHistory(_, { site }) {
      this.setSiteSimulationsHistory(null);
      dispatch.loader.loader(true);

      await axios({
        method: "GET",
        url: GET_SITE_SIMULATIONS_HISTORY,
        headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        params: { id_site: site.infos.id },
      })
        .then((res) => {
          this.setSiteSimulationsHistory(res.data.data);
          dispatch.loader.loader(false);
        })
        .catch((err) => {
          console.log(`Error ${GET_SITE_SIMULATIONS_HISTORY}`, err);
          tokenExpiredDoLogin(err);
          dispatch.loader.loader(false);
        });
    },
    async deleteSiteSimulationsHistoryRow({ evalId }) {
      dispatch.loader.loader(true);

      await axios({
        method: "DELETE",
        url: DELETE_SITE_SIMULATIONS_HISTORY_ROW,
        headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        data: { ids: [evalId] },
      })
        .then((res) => {
          this.getSiteSimulationsHistory();
          dispatch.loader.loader(false);
        })
        .catch((err) => {
          console.log(`Error ${DELETE_SITE_SIMULATIONS_HISTORY_ROW}`, err);
          tokenExpiredDoLogin(err);
          dispatch.loader.loader(false);
        });
    },
    async deleteSiteSimulationsHistoryRowFolder({ baseFolder, uuid }) {
      dispatch.loader.loader(true);

      await axios({
        method: "DELETE",
        url: `${DELETE_SITE_SIMULATIONS_HISTORY_ROW_FOLDER}${baseFolder}/${uuid}?folder=true`,
        headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
      })
        .then((res) => {
          dispatch.loader.loader(false);
        })
        .catch((err) => {
          console.log(
            `Error ${DELETE_SITE_SIMULATIONS_HISTORY_ROW_FOLDER}`,
            err
          );
          dispatch.loader.loader(false);
        });
    },
    async duplicateSiteSimulationsHistoryRow({ evalId }) {
      dispatch.loader.loader(true);

      await axios({
        method: "POST",
        url: POST_SITE_SIMULATIONS_HISTORY_ROW_DUPLICATE,
        headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        data: { id: evalId },
      })
        .then((res) => {
          this.getSiteSimulationsHistory();
          dispatch.loader.loader(false);
        })
        .catch((err) => {
          console.log(
            `Error ${POST_SITE_SIMULATIONS_HISTORY_ROW_DUPLICATE}`,
            err
          );
          dispatch.loader.loader(false);
        });
    },
    async promoteSiteSimulationsHistoryRow({ evalId }, { site }) {
      dispatch.loader.loader(true);

      await axios({
        method: "PUT",
        url: PUT_SITE_SIMULATIONS_HISTORY_ROW_PROMOTE,
        headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        data: { id: evalId, id_site: site.infos.id },
      })
        .then((res) => {
          this.getSiteSimulationsHistory();
          dispatch.loader.loader(false);
        })
        .catch((err) => {
          console.log(`Error ${PUT_SITE_SIMULATIONS_HISTORY_ROW_PROMOTE}`, err);
          tokenExpiredDoLogin(err);
          dispatch.loader.loader(false);
        });
    },
    async draftSiteSimulationsHistoryRow({ evalId }) {
      dispatch.loader.loader(true);

      await axios({
        method: "POST",
        url: POST_SITE_SIMULATIONS_HISTORY_ROW_DRAFT,
        headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        data: { id: evalId },
      })
        .then((res) => {
          this.getSiteSimulationsHistory();
          dispatch.loader.loader(false);
        })
        .catch((err) => {
          console.log(`Error ${POST_SITE_SIMULATIONS_HISTORY_ROW_DRAFT}`, err);
          tokenExpiredDoLogin(err);
          dispatch.loader.loader(false);
        });
    },

    // tab docs
    async getSiteDocs(_, { site }) {
      dispatch.loader.loader(true);
      this.setSiteDocs(null);

      const siteBaseFolder = site.infos.base_folder;

      await axios({
        method: "GET",
        url: GET_SITE_DOCS_LIST + siteBaseFolder,
        headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
      })
        .then((res) => {
          this.setSiteDocs(res.data.files);
          dispatch.loader.loader(false);
        })
        .catch((err) => {
          dispatch.loader.loader(false);
          console.log(`Error ${GET_SITE_DOCS_LIST}`, err);
          tokenExpiredDoLogin(err);
        });
    },
    async downloadFileFromList({ fileName }, { site }) {
      dispatch.loader.loader(true);
      const siteBaseFolder = site.infos.base_folder;

      await axios({
        method: "GET",
        url: GET_SITE_DOCS_DOWNLOAD + siteBaseFolder,
        headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        params: { file: fileName },
        responseType: "blob",
      })
        .then((res) => {
          const blob = new Blob([res.data]);
          const aEle = document.createElement("a"); // Create a label
          const href = window.URL.createObjectURL(blob); // Create downloaded link
          aEle.href = href;
          aEle.download = fileName; // File name after download
          document.body.appendChild(aEle);
          aEle.click(); // Click to download
          document.body.removeChild(aEle); // Download complete remove element
          window.URL.revokeObjectURL(href); // Release blob object

          dispatch.loader.loader(false);
        })
        .catch((err) => {
          console.log(`Error ${GET_SITE_DOCS_LIST}`, err);
          tokenExpiredDoLogin(err);
          dispatch.loader.loader(false);
        });
    },
    async deleteFileFromList({ fileName }, { site }) {
      dispatch.loader.loader(true);
      const siteBaseFolder = site.infos.base_folder;

      await axios({
        method: "DELETE",
        url: DELETE_SITE_DOCS_FILE + siteBaseFolder,
        headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        params: { file: fileName },
      })
        .then((res) => {
          this.getSiteDocs();
          dispatch.loader.loader(false);
        })
        .catch((err) => {
          console.log(`Error ${DELETE_SITE_DOCS_FILE}`, err);
          tokenExpiredDoLogin(err);
          dispatch.loader.loader(false);
        });
    },
    async saveShapefileInfos(shapeInfo) {
      dispatch.loader.loader(true);

      if (!shapeInfo) {
        dispatch.loader.loader(false);
        return;
      }

      await axios({
        method: "PUT",
        url: PUT_GEOJSON_INFOS,
        headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        data: shapeInfo,
      })
        .then((res) => {
          dispatch.loader.loader(false);
        })
        .catch((err) => {
          console.log(`Error ${PUT_GEOJSON_INFOS}`, err);
          tokenExpiredDoLogin(err);
          dispatch.loader.loader(false);
        });
    },
    async uploadShapeFile(file, siteInfos) {
      dispatch.loader.loader(true);

      const siteBaseFolder = siteInfos.site.infos.base_folder;
      const formData = new FormData();
      formData.append("file", file);

      await axios({
        method: "POST",
        url: POST_SITE_SHAPEFILE + siteBaseFolder,
        headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        data: formData,
      })
        .then((res) => {
          // document.getElementById('button-upload-shapefile').value = null
          dispatch.loader.loader(false);
        })
        .catch((err) => {
          console.log(`Error ${POST_SITE_SHAPEFILE}`, err);
          tokenExpiredDoLogin(err);
          // document.getElementById('button-upload-shapefile').value = null
          dispatch.loader.loader(false);
        });

      await axios({
        method: "PUT",
        url: PUT_SITE_SHAPEFILE,
        headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        data: {
          id: siteInfos.id,
          shape_filename: file.name,
        },
      })
        .then((res) => dispatch.loader.loader(false))
        .catch((err) => {
          console.log(`Error ${PUT_SITE_SHAPEFILE}`, err);
          tokenExpiredDoLogin(err);
          dispatch.loader.loader(false);
        });

      await axios({
        method: "POST",
        url: POST_SHAPEFILE_MESSAGE,
        headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        data: {
          queueMsg: {
          site_code: siteInfos.site.infos.site_code,
          filetype: "shapefile",
          filename: file.name,
          }
        },
      })
        .then((res) => dispatch.loader.loader(false))
        .catch((err) => {
          console.log(`Error ${PUT_SITE_SHAPEFILE}`, err);
          tokenExpiredDoLogin(err);
          dispatch.loader.loader(false);
        });
    },
    async uploadShapeFileByMessageOnly({ filetype, site_code, filename }) {
      dispatch.loader.loader(true);

      await axios({
        method: "POST",
        url: POST_SHAPEFILE_MESSAGE,
        headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        data: {
          queueMsg: {
          site_code,
          filetype,
          filename,
          }
        },
      })
        .then((res) => {
          this.getShapeUploadFeedback({ site_code, waitTime: 5000 });
          dispatch.loader.loader(false);
        })
        .catch((err) => {
          console.log(`Error ${PUT_SITE_SHAPEFILE}`, err);
          tokenExpiredDoLogin(err);
          dispatch.loader.loader(false);
        });
    },
    async importShapeFileExcelFormat(file, siteInfos) {
      dispatch.loader.loader(true);

      const formData = new FormData();
      formData.append("formFile", file);

      const site_code = siteInfos.site.infos.site_code;

      await axios({
        method: "POST",
        url: POST_IMPORT_EXCEL_SHAPEFILE,
        headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        data: formData,
        params: { site_code },
      })
        .then((res) => {
          dispatch.map.setGeoJson(null);
          this.setSelectedShape(null);
          dispatch.loader.loader(false);
        })
        .catch((err) => {
          console.log(`Error ${POST_IMPORT_EXCEL_SHAPEFILE}`, err);
          tokenExpiredDoLogin(err);
          // document.getElementById('button-upload-shapefile').value = null
          dispatch.loader.loader(false);
        });
    },

    async exportShapeFileExcelFormat(siteInfos) {
      dispatch.loader.loader(true);

      const site_code = siteInfos.site_code;
      await axios({
        method: "GET",
        url: GET_EXPORT_EXCEL_SHAPEFILE,
        headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        params: { site_code },
        responseType: "blob",
      })
        .then((res) => {
          const blob = new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const aEle = document.createElement("a"); // Create a label
          const href = window.URL.createObjectURL(blob); // Create downloaded link
          aEle.href = href;
          aEle.setAttribute("download", `shapefile_${site_code}.xlsx`); // File name after download
          document.body.appendChild(aEle);
          aEle.click(); // Click to download
          document.body.removeChild(aEle); // Download complete remove element
          window.URL.revokeObjectURL(href); // Release blob object

          dispatch.loader.loader(false);
        })
        .catch((err) => {
          console.log(`Error ${GET_EXPORT_EXCEL_SHAPEFILE}`, err);
          tokenExpiredDoLogin(err);
          dispatch.loader.loader(false);
        });
    },

    async getGoogleStorageFiles({ site_code, isArchive }, { site }) {
      dispatch.loader.loader(true);

      let files = [];
      let promises = [];

      site.bucketSupplierList.map((bucket) => {
        if (
          !isArchive &&
          bucket.bucket.toLowerCase().includes(config.BUCKET_ARCHIVE_NAME)
        ) {
          return;
        }
        promises.push(
          axios({
            method: "GET",
            url: `${GET_FILES_FROM_GOOGLE_STORAGE}${bucket.bucket}/lstobj`,
            headers: {
              authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            params: { search: site_code },
          })
        );
      });

      //Promise.all is all or nothing. If one is rejected, it rejects all
      //So we filter for the valids one
      const buckets = await Promise.all(promises.map((p) => p.catch((e) => e)));
      const bucketsWithFiles = buckets.filter(
        (result) => !(result instanceof Error)
      );

      bucketsWithFiles.map((bucket) => {
        bucket.data.map((file) => {
          files.push({
            bucket: bucket.config.url
              .split("gcstorage/")[1]
              .split("/lstobj")[0],
            file,
          });
        });
      });

      this.setGoogleStoreFiles(files);
      dispatch.loader.loader(false);
    },

    async getShapeUploadFeedback({ site_code, waitTime }) {
      dispatch.loader.loader(true);

      await new Promise((res) => setTimeout(res, waitTime));

      await axios({
        method: "GET",
        url: GET_SHAPEFILE_UPLOAD_FEEDBACK,
        headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        params: { site_code },
      })
        .then((res) => {
          res?.data?.data && this.setShapefileFeedback(res.data.data[0]);
          dispatch.loader.loader(false);
        })
        .catch((err) => {
          console.log(`Error ${GET_SHAPEFILE_UPLOAD_FEEDBACK}`, err);
          tokenExpiredDoLogin(err);
          dispatch.loader.loader(false);
        });
    },
    async getBucketSupplierList() {
      dispatch.loader.loader(true);

      await axios({
        method: "GET",
        url: GET_BUCKET_SUPPLIERS,
        headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        params: {},
      })
        .then((res) => {
          res?.data?.data && this.setBucketSupplierList(res.data.data);
          dispatch.loader.loader(false);
        })
        .catch((err) => {
          console.log(`Error ${GET_BUCKET_SUPPLIERS}`, err);
          tokenExpiredDoLogin(err);
          dispatch.loader.loader(false);
        });
    },
  }),
};

export default site;
