import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import withStyles from '@mui/styles/withStyles';

const useStyles = theme => ({})

const StringLoader = ({ classes, string, loadingString }) => {
  return (
    loadingString
      ? <div>loading string</div>
      : <>{string || '-'}</>
  )
}

const mapState = () => ({})

const mapDispatch = () => ({})

export default compose(
  withStyles(useStyles),
  connect(mapState, mapDispatch)
)(StringLoader)
