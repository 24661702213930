import React, { useEffect } from "react";
import withStyles from "@mui/styles/withStyles";
import { connect } from "react-redux";
import { compose } from "redux";
import { Button } from "@mui/material";
import SiteParamsTableBox from "../../components/SiteParamsTableBox";
import { siteParamsTableHeaders } from "../../utils/tableHeaders";

const useStyles = (theme) => ({
  finalConfigurationContainer: {
    padding: "40px 25px",
    position: "relative",
  },
  paramsTable: {
    height: "calc(100vh - 150px)",
  },
  buttonsWrapper: {
    position: "absolute",
    height: "40px",
    width: "50%",
    top: -45,
    right: 25,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  downloadButton: {
    alignItems: "center",
    background: "white",
    border: "0.5px solid #018ABE",
    borderRadius: "26px",
    color: "#018ABE",
    display: "flex",
    flexDirection: "row",
    font: "13px/20px",
    fontWeight: "bold",
    justifyContent: "center",
    marginLeft: "13px",
    minWidth: "64px",
    padding: "10px 30px",
    textTransform: "none",

    "&:hover": {
      backgroundColor: "white",
      cursor: "pointer",
      outline: "none",
    },
    "&:focus": {
      outline: "none",
    },
    "&:active": {
      outline: "none",
    },
  },
});

const FinalConfiguration = ({
  classes,
  uuid,
  getParameters,
  finalConfigParams,
  resetModelAndTenant,
  setFinalConfigParams,
  technologiesList,
  downloadFinalConfigEval,
}) => {
  const tableHeaders = [...siteParamsTableHeaders, ...technologiesList];

  const siteParamsTableInfos = {
    tableHeaders: tableHeaders,
    tableRows: finalConfigParams || [],
  };

  useEffect(() => {
    async function fetchData() {
      await resetModelAndTenant({});
      await setFinalConfigParams([]);
      await getParameters("isFinalConfig", uuid);
    }
    fetchData();
  }, []);

  return (
    <div className={classes.finalConfigurationContainer}>
      <div className={classes.buttonsWrapper}>
        <Button
          className={classes.downloadButton}
          color='primary'
          onClick={() => downloadFinalConfigEval(uuid)}
        >
          Download
        </Button>
      </div>

      <div className={classes.paramsTable}>
        <SiteParamsTableBox
          tableInfos={siteParamsTableInfos}
          isFinalConfig={true}
        />
      </div>
    </div>
  );
};

const mapState = ({ simulation, site }) => ({
  finalConfigParams: simulation.finalConfigParams,
  technologiesList: site.technologiesList,
});

const mapDispatch = ({
  site: { getSiteParameters, resetModelAndTenant },
  simulation: { setFinalConfigParams, downloadFinalConfigEval },
}) => ({
  getParameters: (type, uuid) => getSiteParameters({ type, uuid }),
  resetModelAndTenant: (value) => resetModelAndTenant(value),
  setFinalConfigParams: (value) => setFinalConfigParams(value),
  downloadFinalConfigEval: (uuid) =>
    downloadFinalConfigEval({ selectedRowUUID: uuid }),
});

export default compose(
  withStyles(useStyles),
  connect(mapState, mapDispatch)
)(FinalConfiguration);
