import React from "react";
import withStyles from "@mui/styles/withStyles";
import CloseIcon from "@mui/icons-material/Close";
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Modal,
} from "@mui/material";
import { compose } from "redux";

const useStyles = (theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: "50%",
    maxHeight: "100vh",
    paddingBottom: "3em",
  },
  tableContainer: {
    width: "100%",
    height: "800px",
    zIndex: 0,
    marginTop: "1em",
  },
  tableHeaderRow: {
    height: "80px",
  },
  tableHeaderCell: {
    backgroundColor: "#FFF",
    fontWeight: "bold",
  },
  tableBodyRow: {
    height: "80px",
    "&:hover": {
      background: "rgba(236, 236, 237, 0.5) !important",
    },
  },
  tableBodyCell: {
    borderTop: "1px solid #ccc",
    borderBottom: "none",
  },
});

const mapJsonToHeader = (name) => {
  switch (name) {
    case "el_tilt": {
      name = "Electrical Tilt (°)";
      break;
    }
    case "file_name": {
      name = "File Name";
      break;
    }
    case "frequency": {
      name = "Frequency";
      break;
    }
    case "gain": {
      name = "Gain (dBi)";
      break;
    }
  }

  return (
    <TableCell
      key={`initial-table-header-cell-${name}`}
      style={{
        fontWeight: "bold",
        visibility: "unset",
        backgroundColor: "#FFF",
      }}
      align='center'
    >
      {name}
    </TableCell>
  );
};

const TransitionsModal = ({
  handleCloseModal,
  isOpen,
  msi,
  title,
  classes,
}) => {
  return (
    <div>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        className={classes.modal}
        open={isOpen}
        onClose={handleCloseModal}
        closeAfterTransition
      >
        <div className={classes.paper}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <h2 id='transition-modal-title'>{title}</h2>
            <button
              style={{
                background: "transparent",
                outline: "none",
                border: "none",
                cursor: "pointer",
              }}
              onClick={handleCloseModal}
              aria-label='close button'
            >
              <CloseIcon />
            </button>
          </div>

          {msi && (
            <TableContainer
              component={Paper}
              className={classes.tableContainer}
            >
              <Table stickyHeader className={classes.table}>
                <TableHead>
                  <TableRow className={classes.tableHeaderRow}>
                    {Object.keys(msi[0]).map((headerCell) => {
                      return mapJsonToHeader(headerCell);
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {msi.map((row, i) => {
                    return (
                      <TableRow
                        className={classes.tableBodyRow}
                        key={`initial-table-body-row-${i}`}
                      >
                        {Object.values(row).map((value, j) => {
                          return (
                            <TableCell
                              className={classes.tableBodyCell}
                              key={`initial-table-header-cell-${j}`}
                              align='center'
                            >
                              {value !== null ? value : "--"}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default compose(withStyles(useStyles))(TransitionsModal);
