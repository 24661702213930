import { init } from '@rematch/core'
import gltfFiles from './models/gltfFiles'
import map from './models/map'
import loader from './models/loader'
import searchBox from './models/searchBox'
import site from './models/site'
import stringLoader from './models/stringLoader'
import simulationsStepper from './models/simulationsStepper'
import simulation from './models/simulation'
import login from './models/login'
import antennas from './models/antennas'
import massive from './models/massive'

const models = {
  gltfFiles,
  map,
  loader,
  searchBox,
  site,
  stringLoader,
  simulationsStepper,
  simulation,
  login,
  antennas,
  massive,
}

const store = init({
  models,
})

export const { dispatch } = store
export default store
