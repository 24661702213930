import React, { useEffect, useState } from "react";
import withStyles from "@mui/styles/withStyles";
import { connect } from "react-redux";
import { compose } from "redux";
import { Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import useHttpService from "../../hooks/useHttpService";
import { config } from "../../utils/config";
const { REDIRECT_URI, CLIENT_ID,TENANT_ID } = config;

import {
  InputAdornment,
  TextField,
  IconButton,
  Checkbox,
  Button,
  Snackbar,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import MuiAlert from "@mui/material/Alert";

import logo from "../../assets/img/efesto-blue.svg";
import inwit from "../../assets/img/inwit-black.svg";
import image from "../../assets/img/login-image.jpeg";

import {GET_TOKEN_AZURE_LOGIN} from "../../utils/api"

const useStyles = () => ({
  loginPage: {
    width: "100%",
    maxHeight: "100vh",
    display: "flex",
    overflowY: "hidden",
  },
  imageContainer: {
    width: "60%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  loginContainer: {
    width: "40%",
    height: "100%",
    padding: "120px 100px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  logo: {
    width: "30px",
    height: "40px",
    margin: "0 auto",
  },
  title: {
    fontWeight: "bold",
    fontSize: "26px",
    color: "#018ABE",
    margin: "15px 0 5px 0",
  },
  subtitle: {
    height: "9px",
    marginBottom: "100px",
  },
  input: {
    width: "100%",
    marginBottom: "15px",
  },
  optionsBox: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  remember: {
    fontSize: "12px",
    display: "flex",
    alignItems: "center",
  },
  checkbox: {
    color: "#9CA1A9",
    borderRadius: "3px",
  },
  loginButton: {
    textTransform: "none",
    width: "100%",
    marginTop: "70px",
    background: "#03B8FF 0% 0% no-repeat padding-box",
    borderRadius: "26px",
    opacity: 1,
    outline: "none",
    padding: "11px 30px",
    textDecoration: "none",
    fontSize: "16px",
    fontWeight: "bold",
    letterSpacing: 0,
    color: "#FFFFFF",
    border: "none",
    "&:hover": {
      outline: "none",
      cursor: "pointer",
      background: "#018ABE 0% 0% no-repeat padding-box",
    },
  },
});

// function Alert(props) {
//   return <MuiAlert elevation={6} variant='filled' {...props} />;
// }

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const msalConfig = {
  auth: {
      clientId: CLIENT_ID,
      tenantId: TENANT_ID,
      authority: "https://login.microsoftonline.com/e6971d01-741d-4f83-b609-1527b3ee1218",
      redirectUri: REDIRECT_URI,
      knownAuthorities: [],
      validateAuthority: true,
      navigateToLoginRequestUrl: true,
  },
  // cache: {
  //     cacheLocation: "sessionStorage", // This configures where your cache will be stored
  //     storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  // },
  system: {
      loggerOptions: {
          loggerCallback: (
              level,
              message,
              containsPii
          ) => {
              if (containsPii) {
                  return;
              }
              switch (level) {
                  case LogLevel.Error:
                      console.error(message);
                      return;
                  case LogLevel.Info:
                      console.info(message);
                      return;
                  case LogLevel.Verbose:
                      console.debug(message);
                      return;
                  case LogLevel.Warning:
                      console.warn(message);
                      return;
              }
          },
          piiLoggingEnabled: false,
      },
      windowHashTimeout: 60000,
      iframeHashTimeout: 6000,
      loadFrameTimeout: 0,
  },
};


// Add scopes for the ID token to be used at Microsoft identity platform endpoints.
const loginRequest = {
  scopes: ["openid", "profile", "User.Read"]
};

// Add scopes for the access token to be used at Microsoft Graph API endpoints.
const tokenRequest = {
  scopes: ["Mail.Read"]
};




const CEMLogin = ({
  classes,
  username,
  password,
  showPassword,
  rememberMe,
  setUsername,
  setPassword,
  setShowPassword,
  setRememberMe,
  getToken,
}) => {
  const [errorMessage, setErrorMessage] = useState(null);
  let userIsLoggedIn = localStorage.getItem("token") == null ? false : true;
  
  const navigate = useNavigate();
  const httpService = useHttpService();
    // Select DOM elements to work with
  // const welcomeDiv = document.getElementById("welcomeMessage");
  // const signInButton = document.getElementById("signIn");
  // const signOutButton = document.getElementById('signOut');
  // const cardDiv = document.getElementById("card-div");
  const profileDiv = document.getElementById("profile-div");



  // function showWelcomeMessage(account) {

  //   // Reconfiguring DOM elements
  //   cardDiv.classList.remove('d-none');
  //   welcomeDiv.innerHTML = `Welcome ${account.name}`;
  //   signInButton.classList.add('d-none');
  //   signOutButton.classList.remove('d-none');
  // }

  function updateUI(data, endpoint) {
    console.log('Graph API responded at: ' + new Date().toString());
  
    if (endpoint === graphConfig.graphMeEndpoint) {
      profileDiv.innerHTML = '';
      const title = document.createElement('p');
      title.innerHTML = "<strong>Title: </strong>" + data.jobTitle;
      const email = document.createElement('p');
      email.innerHTML = "<strong>Mail: </strong>" + data.mail;
      const phone = document.createElement('p');
      phone.innerHTML = "<strong>Phone: </strong>" + data.businessPhones[0];
      const address = document.createElement('p');
      address.innerHTML = "<strong>Location: </strong>" + data.officeLocation;
      profileDiv.appendChild(title);
      profileDiv.appendChild(email);
      profileDiv.appendChild(phone);
      profileDiv.appendChild(address);
      
    } else if (endpoint === graphConfig.graphMailEndpoint) {
        if (data.value.length < 1) {
          alert("Your mailbox is empty!")
        } else {
          const tabList = document.getElementById("list-tab");
          tabList.innerHTML = ''; // clear tabList at each readMail call
          const tabContent = document.getElementById("nav-tabContent");
  
          data.value.map((d, i) => {
            // Keeping it simple
            if (i < 10) {
              const listItem = document.createElement("a");
              listItem.setAttribute("class", "list-group-item list-group-item-action")
              listItem.setAttribute("id", "list" + i + "list")
              listItem.setAttribute("data-toggle", "list")
              listItem.setAttribute("href", "#list" + i)
              listItem.setAttribute("role", "tab")
              listItem.setAttribute("aria-controls", i)
              listItem.innerHTML = d.subject;
              tabList.appendChild(listItem)
      
              const contentItem = document.createElement("div");
              contentItem.setAttribute("class", "tab-pane fade")
              contentItem.setAttribute("id", "list" + i)
              contentItem.setAttribute("role", "tabpanel")
              contentItem.setAttribute("aria-labelledby", "list" + i + "list")
              contentItem.innerHTML = "<strong> from: " + d.from.emailAddress.address + "</strong><br><br>" + d.bodyPreview + "...";
              tabContent.appendChild(contentItem);
            }
          });
        }
    }
  }
  

  useEffect(() => {
    const error = window.sessionStorage.getItem("token_expired");
    if (error) {
      setErrorMessage(error);
    }
  }, []);

  
      // Create the main myMSALObj instance
  // configuration parameters are located at authConfig.js
  const myMSALObj = new Msal.UserAgentApplication(msalConfig);

  let accessToken;

  // Register Callbacks for Redirect flow
  myMSALObj.handleRedirectCallback(authRedirectCallBack);


  function authRedirectCallBack(error, response) {
      if (error) {
          console.log(error);
      } else {
          if (response.tokenType === "id_token") {
              console.log("id_token acquired at: " + new Date().toString());
              console.log("Account state:"+ response.accountState)
              console.log("Raw Id Token:"+response.idToken.rawIdToken)
              const data = {"token":response.idToken.rawIdToken}
              //dispatch.loader.loader(true);
              httpService.postAPI(GET_TOKEN_AZURE_LOGIN, data, AzureCEMLogin);              

              // if (myMSALObj.getAccount()) {
              //     showWelcomeMessage(myMSALObj.getAccount());
              // }

          } else if (response.tokenType === "access_token") {
              console.log("access_token acquired at: " + new Date().toString());
              accessToken = response.accessToken;
              console.log(accessToken);

              try {
                  callMSGraph(graphConfig.graphMailEndpoint, accessToken, updateUI);
              } catch (err) {
                  console.log(err)
              }
          } else {
              console.log("token type is:" + response.tokenType);
          }
      }
  }

  function AzureCEMLogin(data){    
    localStorage.setItem("token", data.token);
    console.log(data.token);
    if (rememberMe) localStorage.setItem("user", data.username);
    window.location.assign("/Efesto/cem");
    //setTimeout(function(){ navigate(`/cem`);}, 500);
    //window.location.reload();
    //setTimeout(function(){ window.location.href = '/Efesto/cem';}, 1000);
    
  }

  // if (myMSALObj.getAccount()) {
  //     showWelcomeMessage(myMSALObj.getAccount());
  // }

  function signIn() {
      myMSALObj.loginRedirect(loginRequest);
  }

  function signOut() {
      myMSALObj.logout();
  }

  // This function can be removed if you do not need to support IE
  function getTokenRedirect(request) {
      return myMSALObj.acquireTokenSilent(request)
          .then((response) => {
              console.log(response);
              if (response.accessToken) {
                  console.log("access_token acquired at: " + new Date().toString());
                  accessToken = response.accessToken;

                  if (accessToken) {
                      try {
                          console.debug(accessToken);
                      } catch (err) {
                          console.log(err)
                      }
                  }
              }
          })
          .catch(error => {
              console.log("silent token acquisition fails. acquiring token using redirect");
              // fallback to interaction when silent call fails
              return myMSALObj.acquireTokenRedirect(request);
          });
  }

  function seeProfile() {
      getTokenRedirect(loginRequest);
  }

  return userIsLoggedIn ? (
    <Navigate to='/cem' />
  ) : (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={errorMessage ? true : false}
      >
        <Alert severity='error'>{errorMessage}</Alert>
      </Snackbar>
      <div className={classes.loginPage}>
        <div className={classes.loginContainer}>
          <img className={classes.logo} src={logo} alt='logo' />
          <div className={classes.title}>EFESTO</div>
          <img className={classes.subtitle} src={inwit} alt='inwit' />

          <TextField
            className={classes.input}
            autoComplete='off'
            InputLabelProps={{ style: { padding: 0 } }}
            type='text'
            variant='outlined'
            name={"Username"}
            label={"Username"}
            value={username || ""}
            onChange={(e) => setUsername(e.target.value)}
          />

          <TextField
            className={classes.input}
            autoComplete='off'
            variant='outlined'
            name='Password'
            label='Password'
            type={showPassword ? "text" : "password"}
            InputLabelProps={{ style: { padding: 0 } }}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  {showPassword ? (
                    <IconButton
                      onClick={() => setShowPassword(false)}
                      size='large'
                    >
                      <VisibilityIcon />
                    </IconButton>
                  ) : (
                    <IconButton
                      onClick={() => setShowPassword(true)}
                      size='large'
                    >
                      <VisibilityOffIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
            value={password || ""}
            onChange={(e) => setPassword(e.target.value)}
          />

          <div className={classes.optionsBox}>
            <div className={classes.remember}>
              <Checkbox
                className={classes.checkbox}
                checked={rememberMe}
                onChange={() => setRememberMe(!rememberMe)}
              />
              Remember me
            </div>
          </div>

          <Button
            className={classes.loginButton}
            style={{
              backgroundColor: !(username && password) ? "#A5A5A6" : "#03B8FF",
              color: "#ffffff",
            }}
            disabled={!(username && password)}
            onClick={() => getToken()}
          >
            Login
          </Button>

          <Button
            className={classes.loginButton}
            style={{
              backgroundColor:"#03B8FF",
              color: "#ffffff",
            }}
            onClick={() => signIn()}
          >
            Azure Login
          </Button>

        </div>
        <div className={classes.imageContainer}>
          <img className={classes.image} src={image} alt='background' />
        </div>
      </div>
    </>
  );
};

const mapState = ({ login }) => ({
  username: login.username,
  password: login.password,
  showPassword: login.showPassword,
  rememberMe: login.rememberMe,
});

const mapDispatch = ({
  login: { setUsername, setPassword, setShowPassword, setRememberMe, getToken },
}) => ({
  setUsername: (value) => setUsername(value),
  setPassword: (value) => setPassword(value),
  setShowPassword: (value) => setShowPassword(value),
  setRememberMe: (value) => setRememberMe(value),
  getToken: () => getToken(),
});

export default compose(
  withStyles(useStyles),
  connect(mapState, mapDispatch)
)(CEMLogin);
