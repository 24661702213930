import React, { useState } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import withStyles from '@mui/styles/withStyles';
import { Button } from '@mui/material'

const useStyles = (theme) => ({
  importContainer: {
    margin: '20px 0',
    width: '450px',
    display: 'block',
  },
  select: {
    height: '56px',
    width: '330px',
    padding: '12px',
    justifyContent: 'left',
    borderRadius: '4px 0 0 4px',
    border: '1px solid rgba(165, 165, 166, 0.5)',
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '1rem',
    textTransform: 'unset',
    '&:hover': {
      backgroundColor: '#ffffff',
    },
  },
  imported: {
    height: '56px',
    width: '120px',
    padding: '15px 26px',
    backgroundColor: '#A5A5A6',
    borderRadius: '0px 4px 4px 0px',
    textTransform: 'unset',
    fontWeight: 'bold',
    color: '#ffffff',
    opacity: '0.5',
    '&:hover': {
      backgroundColor: '#A5A5A6',
    },
  },
  importEnabled: {
    height: '56px',
    width: '120px',
    padding: '15px 26px',
    backgroundColor: '#03B8FF',
    borderRadius: '0px 4px 4px 0px',
    textTransform: 'unset',
    fontWeight: 'bold',
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#03B8FF',
    },
  },
  importDisabled: {
    height: '56px',
    width: '120px',
    padding: '15px 26px',
    backgroundColor: '#A5A5A6',
    borderRadius: '0px 4px 4px 0px',
    textTransform: 'unset',
    fontWeight: 'bold',
    color: '#ffffff',
    opacity: '0.5',
    '&:hover': {
      backgroundColor: '#A5A5A6',
      opacity: '0.5',
    },
  },
})

const TwoStepsImport = ({
  classes,
  id,
  selectTitle,
  importTitle,
  selectFile,
  importFile,
  importDisabled,
}) => {
  const [importedEval, setImportedEval] = useState(false)
  const [buttonDisabled, setButtonDisabled] = useState(false)

  return (
    <div className={classes.importContainer}>
      <input hidden type='file' id={id} onChange={selectFile} />
      <label htmlFor={id}>
        <Button className={classes.select} component='span'>
          {selectTitle}
        </Button>
      </label>

      <Button
        className={
          importDisabled && !importedEval
            ? classes.importDisabled
            : !importDisabled && !importedEval
            ? classes.importEnabled
            : classes.imported
        }
        component='span'
        disabled={buttonDisabled}
        onClick={() => {
          importFile()
          setImportedEval(true)
          setButtonDisabled(true)
        }}
      >
        {importTitle}
      </Button>
    </div>
  )
}

const mapState = () => ({})

const mapDispatch = () => ({})

export default compose(
  withStyles(useStyles),
  connect(mapState, mapDispatch)
)(TwoStepsImport)
