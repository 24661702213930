import { config } from "./config";

const { BASE_API } = config;

const BASE_ISTAT_API = "api/scalar/istat/";
const BASE_MAPS_API = "api/scalar/maps/";
const BASE_EVAL_API = "api/scalar/eval/";
const BASE_COMMON_API = "api/scalar/common/";
const BASE_OFFICE_API = "api/scalar/office/";
const BASE_FILE_OFFICE_API = "fileio/office/";
const BASE_FILE_EVAL_API = "fileio/eval/";
const BASE_FILE_MAPS_API = "fileio/maps/";
const BASE_CATALOG_API = "api/scalar/hwcatalog/";
const BASE_GOOGLE_API = "gcstorage/";
const BASE_ERROR_LOG = "api/scalar/log/";
const BASE_QUEUE_API = "queue/celery/push/"

const GET_TOKEN = BASE_API + "users/authenticateext";
const GET_TOKEN_AZURE_LOGIN = BASE_API + "users/AuthenticateAzureToken";
const GET_MENU = BASE_API + "api/scalar/auth/profile_pages";

const GET_SB_LIST_ZONE = BASE_API + BASE_ISTAT_API + "zone";
const GET_SB_LIST_REGIONI = BASE_API + BASE_ISTAT_API + "zona_regione";
const GET_SB_LIST_PROVINCE = BASE_API + BASE_ISTAT_API + "province";
const GET_SB_LIST_COMUNI = BASE_API + BASE_ISTAT_API + "comuni";

const GET_OFFICES = BASE_API + BASE_OFFICE_API + "sites";
const GET_OFFICES_FROM_BOUNDS = BASE_API + BASE_MAPS_API + "maps";

const GET_SITE_INFOS = BASE_API + BASE_OFFICE_API + "site_info";
const GET_SITE_SIM_COUNT = BASE_API + BASE_EVAL_API + "eval_count";
const GET_SITE_LAST_SIM_INFOS = BASE_API + BASE_EVAL_API + "eval_last_info";
const GET_SITE_PARAMETERS = BASE_API + BASE_EVAL_API + "eval";
const GET_SITE_TENANTS_TECHNOLOGIES_LIST =
  BASE_API + BASE_COMMON_API + "technologies";
const GET_SITE_TENANTS_TECHNOLOGIES =
  BASE_API + BASE_COMMON_API + "tenants_technologies";
const GET_SITE_TENANTS_TAB = BASE_API + BASE_EVAL_API + "tenants_tab";
const GET_SITE_DOCS_LIST = BASE_API + "storage/";
const GET_SITE_DOCS_DOWNLOAD = BASE_API + "storage/";
const GET_SITE_MEASURE_POINTS =
  BASE_API + BASE_FILE_OFFICE_API + "measure_points/export";
const GET_SITE_EMPTY_MEASURE_POINTS =
  BASE_API + BASE_FILE_OFFICE_API + "measure_points/template";
const GET_SITE_SIMULATIONS_HISTORY = BASE_API + BASE_EVAL_API + "eval_list";
const GET_THRESHOLDS = BASE_API + BASE_EVAL_API + "thresholds";
const GET_TENANTS = BASE_API + BASE_COMMON_API + "tenants";
const GET_TENANTS_LIST = BASE_API + BASE_COMMON_API + "tenants_list";
const GET_MANUFACTURERS_LIST =
  BASE_API + BASE_CATALOG_API + "antenna_manufacturer";
const GET_MANUFACTURERS_LIST_II =
  BASE_API + BASE_CATALOG_API + "antenna_manufacturer_list";
const GET_MODELS_LIST = BASE_API + BASE_CATALOG_API + "antenna_model";
const GET_ANTENNA_BY_PARAMS = BASE_API + BASE_CATALOG_API + "antenna";
const GET_ANTENNA_MSI_INFO = BASE_API + BASE_CATALOG_API + "antenna_msi_info";
const GET_EXPORT_EVAL = BASE_API + BASE_FILE_EVAL_API + "eval/export";
const GET_TENANT_TECHNOLOGIES =
  BASE_API + BASE_COMMON_API + "tenants_frequencies";
const GET_SIMULATION_FILES = BASE_API + "storage/";
const GET_FILE_FROM_LIST = BASE_API + "storage/";
const GET_ANTENNA_WITH_DATASHEET = BASE_API + "storage/Datasheets";
const GET_PORTS_LIST = BASE_API + BASE_COMMON_API + "tenants_ports";
const GET_CONSTRAINTS = BASE_API + BASE_EVAL_API + "constraints";
const GET_SIMULATION_STEPPER_SUMMARY_LIST =
  BASE_API + BASE_EVAL_API + "summary";
const GET_INITIAL_CONFIGURATION = BASE_API + BASE_EVAL_API + "config_initial";
const GET_FINAL_CONFIGURATION = BASE_API + BASE_EVAL_API + "config_final";
const GET_FINAL_CONFIGURATION_PARAMS =
  BASE_API + BASE_EVAL_API + "arpa_results";
const GET_FINAL_CONFIGURATION_EVAL =
  BASE_API + BASE_FILE_EVAL_API + "efesto_arpa_results/export";
const GET_SITE_MANUAL_IMPORT_MEASURE_POINTS =
  BASE_API + BASE_OFFICE_API + "measure_points";
const GET_SITE_POLES = BASE_API + BASE_OFFICE_API + "poles";
const GET_SIMULATIONS_COUNT = BASE_API + BASE_EVAL_API + "eval_current_count";
const GET_GEOJSON = BASE_API + BASE_MAPS_API + "geojson";
const GET_MASSIVE_LIST = BASE_API + BASE_EVAL_API + "massives";
const GET_MASSIVE_SITES = BASE_API + BASE_EVAL_API + "messages_massive";
const GET_STATISTICAL_MASSIVE_INFO =
  BASE_API + BASE_EVAL_API + "statistical_results_massive";
const GET_DTM_AND_SHAPEFILE = BASE_API + BASE_MAPS_API + "dtm_shapefiles";
const GET_MASSIVE_SIMULATION_STATISTICS =
  BASE_API + BASE_EVAL_API + "statistical_results_massive";
const GET_MASSIVE_FINAL_CONFIGURATION =
  BASE_API + BASE_FILE_EVAL_API + "eval_massive/export";
const GET_MASSIVE_SIMULATION_RESULT =
  BASE_API + BASE_FILE_EVAL_API + "results_massive/export";
const GET_MASSIVE_SIMULATION_ERRORS =
  BASE_API + BASE_FILE_EVAL_API + "massive_errors/export";
const GET_GEO_JSON = BASE_API + BASE_MAPS_API + "geojson";
const GET_SRID = BASE_API + BASE_MAPS_API + "dtm_srid";
const GET_MASSIVE_HAS_DTM_SHAPEFILE_EXCEL =
  BASE_API + BASE_FILE_MAPS_API + "dtm_shapefiles/export";
const GET_EXPORT_EXCEL_SHAPEFILE =
  BASE_API + BASE_FILE_MAPS_API + "shapefiles/export";
const GET_FILES_FROM_GOOGLE_STORAGE = BASE_API + BASE_GOOGLE_API + "";
const GET_SHAPEFILE_UPLOAD_FEEDBACK = BASE_API + BASE_MAPS_API + "logs";
const GET_BUCKET_SUPPLIERS = BASE_API + BASE_MAPS_API + "buckets";
const GET_SITE_TYPE = BASE_API + BASE_OFFICE_API + "site_type";
const GET_TECH_TO_FREQ = BASE_API + BASE_COMMON_API + "tech_to_freq";
const GET_TENANTS_NOMINAL_FREQUENCIES =
  BASE_API + BASE_COMMON_API + "tenants_nominal_frequencies";
const GET_ADJACENT_SITES = BASE_API + BASE_OFFICE_API + "sites_radius";
const GET_AVAILABLE_TECHNOLOGIES =
  BASE_API + BASE_COMMON_API + "available_technologies";
const GET_SIMULATED_TECH_CONFIG =
  BASE_API + BASE_EVAL_API + "config_simulate_tech";
const GET_SITES_INWI_API =  BASE_API + BASE_OFFICE_API + "site_inwi";


const POST_SITE_SHAPEFILE = BASE_API + "storage/";
const POST_SITE_EXCEL_FILE =
  BASE_API + BASE_FILE_OFFICE_API + "measure_points/import";
const POST_SITE_SIMULATIONS_HISTORY_ROW_DUPLICATE =
  BASE_API + BASE_EVAL_API + "duplicate_eval";
const POST_SITE_SIMULATIONS_HISTORY_ROW_DRAFT =
  BASE_API + BASE_EVAL_API + "draft_eval";
const POST_SITE_PARAMETERS = BASE_API + BASE_EVAL_API + "eval";
const POST_NEW_EVAL = BASE_API + BASE_EVAL_API + "create_eval";
const POST_IMPORT_EVAL = BASE_API + BASE_FILE_EVAL_API + "eval/import";
const POST_SAVE_MEASURE_POINTS = BASE_API + BASE_OFFICE_API + "measure_points";
const POST_SAVE_POLES = BASE_API + BASE_OFFICE_API + "poles";
const POST_UPLOAD_MASSIVE =  BASE_API + BASE_FILE_EVAL_API + "eval_massive/import";
const POST_UPLOAD_MASSIVE_TENANT =  BASE_API + BASE_FILE_EVAL_API + "massive_tenant/import";
const POST_CLONE_MASSIVE = BASE_API + BASE_EVAL_API + "duplicate_eval_massive";
const POST_IMPORT_EXCEL_SHAPEFILE =
  BASE_API + BASE_FILE_MAPS_API + "shapefiles/import";
const POST_GET_ATENA_TOKEN = BASE_API + "relay";
const POST_ANTENNAS_FROM_ATENA_TO_BIM = BASE_API + BASE_EVAL_API + "bim_import";
const POST_RENDERING_QUEUE = BASE_API + BASE_QUEUE_API + "ssd_geo/GEOstart";
const POST_MSI_QUEUE = BASE_API + BASE_QUEUE_API + "ssd_msi/MSIstart";
const POST_ANTENNA = BASE_API + BASE_CATALOG_API + "antenna";

const POST_SITE_INFOS = BASE_API + BASE_OFFICE_API + "site_info";
const POST_SITES_INWI_API =  BASE_API + BASE_OFFICE_API + "site_inwi";

const PUT_SITE_INFOS_MODIFY = BASE_API + BASE_OFFICE_API + "site_info";
const PUT_SITE_SIMULATIONS_HISTORY_ROW_PROMOTE =
  BASE_API + BASE_EVAL_API + "eval_actual";
const PUT_SIMULATION_DESCRIPTION = BASE_API + BASE_EVAL_API + "eval";
const PUT_SIMULATION_THRESHOLDS = BASE_API + BASE_EVAL_API + "thresholds";
const PUT_ADD_TENANT = BASE_API + BASE_EVAL_API + "add_tenant";
const PUT_SIMULATE_TECH = BASE_API + BASE_EVAL_API + "simulate_tech";
const PUT_SITE_SHAPEFILE = BASE_API + BASE_OFFICE_API + "site_info";
const PUT_SIMULATION_EVAL = BASE_API + BASE_EVAL_API + "eval";
const PUT_UPDATE_SITE_POLE = BASE_API + BASE_OFFICE_API + "site_info";
const PUT_CONSTRAINTS_DEFAULT = BASE_API + BASE_EVAL_API + "constraints";
const PUT_CONSTRAINTS_SAVE = BASE_API + BASE_EVAL_API + "constraints_save";
const PUT_GEOJSON_INFOS = BASE_API + BASE_MAPS_API + "geojson";
const PUT_MASSIVE_OFFSET = BASE_API + BASE_EVAL_API + "eval_massive";
const PUT_ANTENNA_PORT = BASE_API + BASE_CATALOG_API + "antenna_port";
const PUT_ANTENNA_MSI = BASE_API + BASE_CATALOG_API + "antenna_msi";
const PUT_SITES_INWI_API =  BASE_API + BASE_OFFICE_API + "site_inwi";

const DELETE_SITE_SIMULATIONS_HISTORY_ROW = BASE_API + BASE_EVAL_API + "eval";
const DELETE_SITE_SIMULATIONS_HISTORY_ROW_FOLDER = BASE_API + "storage/";
const DELETE_SITE_DOCS_FILE = BASE_API + "storage/";
const DELETE_MASSIVE = BASE_API + BASE_EVAL_API + "eval_massive";
const DELETE_TECHNOLOGIES = BASE_API + BASE_COMMON_API + "technologies";

// simulation
const QUEUE_TASKS = BASE_API + "queue/celery/tasks";
const START_QUEUE = BASE_API + BASE_QUEUE_API + "ssd_em/EMstart";
const POST_DUPLICATE_MASSIVE = BASE_API + BASE_QUEUE_API + "ssd_massive/MASSIVEstart";
const POST_RUN_MASSIVE_SIMULATION = BASE_API + BASE_QUEUE_API + "ssd_massive/MASSIVEstart";
const CONNECTION_URL = BASE_API + "chatHub";
const POST_SHAPEFILE_MESSAGE = BASE_API + BASE_QUEUE_API + "ssd_geo/GEOstart";
const GET_EVAL_LOG_EXPORT = BASE_API + "fileio/log/eval_log/export";
const POST_EVAL_MEASURE_POINTS =
  BASE_API + BASE_FILE_EVAL_API + "measure_points/import";
const GET_EVAL_MEASURE_POINTS = BASE_API + BASE_EVAL_API + "measure_points";
const DOWNLOAD_EVAL_MEASURE_POINTS =
  BASE_API + BASE_FILE_EVAL_API + "measure_points/export";
const POST_SAVE_EVAL_MEASURE_POINTS =
  BASE_API + BASE_EVAL_API + "measure_points";
const POST_CLONE_AND_UPLOAD_MEASURE_POINTS =
  BASE_API + BASE_FILE_EVAL_API + "measure_points_append/import";
const GET_TENANTS_CONTRIBUTION =
  BASE_API + BASE_EVAL_API + "measure_points_contribution";

const POST_ERROR_LOG = BASE_API + BASE_ERROR_LOG + "logs";

const GET_BUILDINGS_COUNT = BASE_API + "api/scalar/maps/buildings_count";
const GET_BUILDINGS_ALIGNMENT =
  BASE_API + "api/scalar/maps/buildings_alignment";
const GET_BUILDINGS_OUT_OF_TERRAIN =
  BASE_API + "api/scalar/maps/buildings_out_of_terrain";
const GET_TERRAIN_ELEVATION = BASE_API + "api/scalar/maps/elevation";
const GET_DISTANCE_SITE_TERRAIN = BASE_API + "api/scalar/maps/site_distance";
const GET_SITE_WITHIN_TERRAIN = BASE_API + "api/scalar/maps/site_within";

export {
  GET_TOKEN,
  GET_MENU,
  GET_SB_LIST_ZONE,
  GET_SB_LIST_REGIONI,
  GET_SB_LIST_PROVINCE,
  GET_SB_LIST_COMUNI,
  GET_OFFICES,
  GET_OFFICES_FROM_BOUNDS,
  POST_RENDERING_QUEUE,
  POST_MSI_QUEUE,
  GET_SITE_INFOS,
  GET_SITE_SIM_COUNT,
  GET_SITE_LAST_SIM_INFOS,
  GET_SITE_PARAMETERS,
  POST_SITE_PARAMETERS,
  GET_SITE_TENANTS_TECHNOLOGIES_LIST,
  GET_SITE_TENANTS_TECHNOLOGIES,
  GET_SITE_TENANTS_TAB,
  GET_SITE_DOCS_LIST,
  GET_SITE_DOCS_DOWNLOAD,
  GET_SITE_MEASURE_POINTS,
  GET_SITE_EMPTY_MEASURE_POINTS,
  GET_GEOJSON,
  GET_TENANTS_CONTRIBUTION,
  POST_SITE_SHAPEFILE,
  POST_SITE_EXCEL_FILE,
  PUT_SITE_INFOS_MODIFY,
  GET_SITE_SIMULATIONS_HISTORY,
  DELETE_SITE_SIMULATIONS_HISTORY_ROW,
  POST_SITE_SIMULATIONS_HISTORY_ROW_DUPLICATE,
  PUT_SITE_SIMULATIONS_HISTORY_ROW_PROMOTE,
  POST_NEW_EVAL,
  PUT_SIMULATION_DESCRIPTION,
  GET_THRESHOLDS,
  GET_TENANTS,
  GET_MANUFACTURERS_LIST,
  GET_ANTENNA_BY_PARAMS,
  GET_ANTENNA_MSI_INFO,
  GET_MODELS_LIST,
  PUT_SIMULATION_THRESHOLDS,
  POST_IMPORT_EVAL,
  GET_EXPORT_EVAL,
  CONNECTION_URL,
  START_QUEUE,
  GET_TENANT_TECHNOLOGIES,
  PUT_ADD_TENANT,
  GET_SIMULATION_FILES,
  GET_FILE_FROM_LIST,
  GET_PORTS_LIST,
  GET_CONSTRAINTS,
  PUT_SITE_SHAPEFILE,
  GET_SIMULATION_STEPPER_SUMMARY_LIST,
  DELETE_SITE_SIMULATIONS_HISTORY_ROW_FOLDER,
  GET_INITIAL_CONFIGURATION,
  GET_FINAL_CONFIGURATION,
  GET_FINAL_CONFIGURATION_PARAMS,
  GET_FINAL_CONFIGURATION_EVAL,
  PUT_SIMULATION_EVAL,
  GET_SITE_MANUAL_IMPORT_MEASURE_POINTS,
  POST_SAVE_MEASURE_POINTS,
  GET_SITE_POLES,
  POST_SAVE_POLES,
  PUT_UPDATE_SITE_POLE,
  POST_SITE_SIMULATIONS_HISTORY_ROW_DRAFT,
  DELETE_SITE_DOCS_FILE,
  PUT_CONSTRAINTS_DEFAULT,
  PUT_CONSTRAINTS_SAVE,
  GET_SIMULATIONS_COUNT,
  GET_MANUFACTURERS_LIST_II,
  POST_UPLOAD_MASSIVE,
  POST_UPLOAD_MASSIVE_TENANT,
  GET_MASSIVE_LIST,
  POST_CLONE_MASSIVE,
  DELETE_MASSIVE,
  GET_MASSIVE_SITES,
  GET_STATISTICAL_MASSIVE_INFO,
  GET_DTM_AND_SHAPEFILE,
  GET_TOKEN_AZURE_LOGIN ,
  POST_DUPLICATE_MASSIVE,
  POST_RUN_MASSIVE_SIMULATION,
  GET_MASSIVE_SIMULATION_STATISTICS,
  GET_MASSIVE_FINAL_CONFIGURATION,
  GET_MASSIVE_SIMULATION_RESULT,
  GET_MASSIVE_SIMULATION_ERRORS,
  GET_GEO_JSON,
  GET_SRID,
  GET_SITE_TYPE,
  GET_SITES_INWI_API,
  PUT_GEOJSON_INFOS,
  POST_SHAPEFILE_MESSAGE,
  GET_MASSIVE_HAS_DTM_SHAPEFILE_EXCEL,
  POST_IMPORT_EXCEL_SHAPEFILE,
  GET_EXPORT_EXCEL_SHAPEFILE,
  PUT_MASSIVE_OFFSET,
  PUT_ANTENNA_PORT,
  PUT_ANTENNA_MSI,
  GET_FILES_FROM_GOOGLE_STORAGE,
  GET_ANTENNA_WITH_DATASHEET,
  GET_SHAPEFILE_UPLOAD_FEEDBACK,
  GET_BUCKET_SUPPLIERS,
  POST_GET_ATENA_TOKEN,
  POST_ANTENNAS_FROM_ATENA_TO_BIM,
  GET_EVAL_LOG_EXPORT,
  DELETE_TECHNOLOGIES,
  GET_TECH_TO_FREQ,
  GET_TENANTS_LIST,
  GET_TENANTS_NOMINAL_FREQUENCIES,
  GET_ADJACENT_SITES,
  GET_AVAILABLE_TECHNOLOGIES,
  PUT_SIMULATE_TECH,
  PUT_SITES_INWI_API,
  POST_SITES_INWI_API,
  GET_SIMULATED_TECH_CONFIG,
  // eval measure points
  POST_EVAL_MEASURE_POINTS,
  GET_EVAL_MEASURE_POINTS,
  DOWNLOAD_EVAL_MEASURE_POINTS,
  POST_SAVE_EVAL_MEASURE_POINTS,
  POST_CLONE_AND_UPLOAD_MEASURE_POINTS,
  POST_ANTENNA,
  // error log service
  POST_ERROR_LOG,
  // geospatial check api's
  GET_BUILDINGS_COUNT,
  GET_BUILDINGS_ALIGNMENT,
  GET_BUILDINGS_OUT_OF_TERRAIN,
  GET_TERRAIN_ELEVATION,
  GET_DISTANCE_SITE_TERRAIN,
  GET_SITE_WITHIN_TERRAIN,
  POST_SITE_INFOS,
  QUEUE_TASKS
};

export const tokenExpiredDoLogin = (err) => {
  if (err?.response?.status === 401) {
    window.localStorage.clear();
    window.sessionStorage.clear();
    sessionStorage.setItem(
      "token_expired",
      "The session token has expired! Please sign in again"
    );
    window.location.href = "/Efesto/login";
  }
};
