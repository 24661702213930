export const mainSearchBoxOptionsList = [
  {
    id: "0",
    grid: 12,
    type: "input",
    name: "ID INWIT",
    key: "idInwit",
    disabled: false,
  },
  {
    id: "1",
    grid: 12,
    type: "input",
    name: "Original Site Code",
    key: "codiceOrigine",
    disabled: false,
  },
  {
    id: "2",
    grid: 12,
    type: "input",
    name: "Site Name",
    key: "nomeSito",
    disabled: false,
  },
  {
    id: "3",
    grid: 12,
    type: "select",
    name: "Zone",
    key: "zona",
    disabled: true,
  },
  {
    id: "4",
    grid: 12,
    type: "select",
    name: "Region",
    key: "regione",
    disabled: true,
  },
  {
    id: "5",
    grid: 12,
    type: "select",
    name: "Province",
    key: "provincia",
    disabled: true,
  },
  {
    id: "6",
    grid: 12,
    type: "select",
    name: "Municipality",
    key: "comune",
    disabled: true,
  },
  {
    id: "7",
    grid: 12,
    type: "input",
    name: "Address",
    key: "indirizzo",
    disabled: false,
  },
];
