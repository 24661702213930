/* eslint-disable */
import React, { useEffect, useState } from "react";
import withStyles from "@mui/styles/withStyles";
import { connect } from "react-redux";
import { compose } from "redux";
import { Button, IconButton, CircularProgress, Popper } from "@mui/material";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import BlurOnIcon from "@mui/icons-material/BlurOn";
import BlurOffIcon from "@mui/icons-material/BlurOff";
import "./xeogl.scss";
import { config } from "../../utils/config";

const useStyles = (theme) => ({
  EM3DContainer: {
    padding: "40px 25px",
    position: "relative",
  },
  buttonsWrapper: {
    position: "absolute",
    height: "40px",
    width: "50%",
    top: -45,
    right: 25,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  fullPageButton: {
    alignItems: "center",
    background: "white",
    border: "0.5px solid #018ABE",
    borderRadius: "26px",
    color: "#018ABE",
    display: "flex",
    flexDirection: "row",
    font: "13px/20px",
    fontWeight: "bold",
    justifyContent: "center",
    marginLeft: "13px",
    minWidth: "64px",
    padding: "11px 30px",
    textTransform: "none",

    "&:hover": {
      backgroundColor: "white",
      cursor: "pointer",
      outline: "none",
    },
    "&:focus": {
      outline: "none",
    },
    "&:active": {
      outline: "none",
    },
  },
  modelsButton: {
    background: "#03B8FF 0% 0% no-repeat padding-box",
    border: "none",
    borderRadius: "26px",
    color: "#FFFFFF",
    font: "13px/20px",
    fontWeight: "bold",
    letterSpacing: 0,
    marginLeft: "20px",
    opacity: 1,
    outline: "none",
    padding: "11px 30px",
    textDecoration: "none",
    textTransform: "none",

    "&:hover": {
      outline: "none",
      cursor: "pointer",
      background: "#018ABE 0% 0% no-repeat padding-box",
    },
  },
});

const EM3D = ({
  classes,
  getSimulationFiles,
  files,
  uuid,
  downloadFileFromList,
  loadedModels,
  setLoadedModels,
  resetGltfState,
  loadingModels,
  setLoadingModels,
  modelIsLoading,
  setModelIsLoading,
  modelsVisibilityList,
  setModelsVisibilityList,
  modelsGhostedList,
  setModelsGhostedList,
}) => {
  const [mainScene, setMainScene] = useState(null);
  const [modelDropOpen, setModelDropOpen] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [orderedFiles, setOrderedFiles] = useState(null);

  const handleModelsDropdown = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setModelDropOpen(!modelDropOpen);
  };

  async function downloadFile(fileName, i) {
    setModelIsLoading(true);
    await setLoadingModels({ ...loadingModels, [i]: true });
    const file = await downloadFileFromList(fileName, uuid, "loadingGLTF");
    loadGLTF(fileName, file, i);
  }

  function loadGLTF(fileName, file, i) {
    const scene = mainScene || createMainScene();

    let model = null;

    if (fileName === "terrain.SceneJS.json") {
      model = new xeogl.SceneJSModel(scene);
      model.data = file;
    } else {
      model = new xeogl.Model(scene);
      xeogl.GLTFModel.parse(model, file, { lambertMaterials: true });
    }

    model.on("loaded", () => {
      setModelIsLoading(false);
      setLoadedModels(fileName, model);
      setLoadingModels({ ...loadingModels, [i]: false });
      setModelsVisibilityList({ ...modelsVisibilityList, [i]: true });
      setModelsGhostedList({ ...modelsGhostedList, [i]: true });
    });
    model.on("error", (error) => {
      console.log("XEOGL ERROR", error);
      model.destroy();
    });
  }

  function modelsVisibilityToggle(fileName, i) {
    function toggle(node, visible) {
      Object.keys(node.meshes).forEach(function (key) {
        node.meshes[key].visible = visible;
      });
    }

    toggle(loadedModels[fileName], !modelsVisibilityList[i]);
    setModelsVisibilityList({
      ...modelsVisibilityList,
      [i]: !modelsVisibilityList[i],
    });
  }

  function modelsGhostifyToggle(fileName, i) {
    function toggle(node, ghosted) {
      Object.keys(node.meshes).forEach(function (key) {
        node.meshes[key].ghosted = !ghosted;
      });
    }

    toggle(loadedModels[fileName], !modelsGhostedList[i]);
    setModelsGhostedList({
      ...modelsGhostedList,
      [i]: !modelsGhostedList[i],
    });
  }

  function createMainScene() {
    const newMainScene = new xeogl.Scene({
      canvas: "xeoglCanvas",
      transparent: true,
    });

    const camera = newMainScene.camera;

    camera.eye = [-100.0, 200.0, 200.0];
    camera.look = [0.0, 0.0, 0.0];

    new xeogl.AmbientLight(newMainScene, {
      color: [1, 1, 1],
      intensity: 0.6,
    });

    new xeogl.DirLight(newMainScene, {
      dir: [1, 1, 1], // Direction the light is shining in
      color: [1, 1, 1],
      intensity: 0.6,
      space: "view", // Other option is "world", for World-space
      shadow: false, // Default
    });

    new xeogl.DirLight(newMainScene, {
      dir: [1, 0, 0],
      color: [1, 1, 1],
      intensity: 0.6,
      space: "view",
      shadow: false,
    });

    new xeogl.CameraControl(newMainScene);

    setMainScene(newMainScene);
  }

  function goFullScreen() {
    const el = document.getElementById("xeoglCanvas");
    if (el.webkitRequestFullScreen) {
      el.webkitRequestFullScreen();
    } else {
      el.mozRequestFullScreen();
    }
  }

  useEffect(() => {
    const asyncGetSimulationFiles = async () => {
      await getSimulationFiles(uuid);
    };
    asyncGetSimulationFiles();
    resetGltfState();
    // This is needed to clear xeogl models even if state is resetted
    if (Object.keys(loadedModels).length > 0) {
      Object.values(loadedModels).map((Model) => Model.destroy());
    }
    if (mainScene === null) createMainScene();
  }, []);

  useEffect(() => {
    files.length > 0 && orderOriginalFiles();
  }, [files]);

  const orderOriginalFiles = () => {
    let tmp = [];
    tmp = [
      ...tmp,
      ...files.filter((el) => el.name.includes("SceneJS")),
      ...files.filter((el) => el.name.includes("terreno")),
      ...files.filter((el) => el.name.includes("palazzo")),
      ...files.filter((el) => el.name.includes("antenna")),
      ...files.filter((el) => el.name.includes("puntiMisura")),
      ...files.filter((el) => el.name.includes("lobo")),
    ];

    tmp = tmp.map((file) => {
      file.originalName = file.name;
      file = { ...file, name: file.name.replace(".ifc.gltf", "") };
      file = { ...file, name: file.name.replace(".SceneJS.json", "_texture") };
      file = { ...file, name: file.name.replace("palazzo", "shapefile") };
      file = { ...file, name: file.name.replace("terreno", "DTM") };
      file = { ...file, name: file.name.replace("terrain", "DTM") };
      return file;
    });

    if (config.REACT_APP_ENVIRONMENT === "dev")
      tmp = tmp.filter((el) => el.name !== "DTM_texture");

    setOrderedFiles(tmp);
  };

  const PopperChildren = () => {
    return (
      <>
        {orderedFiles &&
          orderedFiles.map((file, i) => {
            return (
              <div className='file' key={`file-${i}`}>
                <span style={{ marginRight: "10px" }}>•</span>
                <span>{file.name}</span>
                {modelsVisibilityList[i] === undefined && (
                  <IconButton
                    color='primary'
                    style={{ marginLeft: "10px" }}
                    onClick={() => downloadFile(file.originalName, i)}
                    disabled={modelIsLoading}
                    size='large'
                  >
                    {loadingModels[i] ? (
                      <CircularProgress size={24} />
                    ) : (
                      <DoubleArrowIcon />
                    )}
                  </IconButton>
                )}
                {modelsVisibilityList[i] !== undefined && (
                  <>
                    {modelsVisibilityList[i] ? (
                      <IconButton
                        color='primary'
                        style={{ marginLeft: "10px" }}
                        onClick={() =>
                          modelsVisibilityToggle(file.originalName, i)
                        }
                        size='large'
                      >
                        <VisibilityIcon />
                      </IconButton>
                    ) : (
                      <IconButton
                        color='primary'
                        style={{ marginLeft: "10px" }}
                        onClick={() =>
                          modelsVisibilityToggle(file.originalName, i)
                        }
                        size='large'
                      >
                        <VisibilityOffIcon />
                      </IconButton>
                    )}
                  </>
                )}
                {modelsGhostedList[i] !== undefined && (
                  <>
                    {modelsGhostedList[i] ? (
                      <IconButton
                        color='primary'
                        onClick={() =>
                          modelsGhostifyToggle(file.originalName, i)
                        }
                        size='large'
                      >
                        <BlurOnIcon />
                      </IconButton>
                    ) : (
                      <IconButton
                        color='primary'
                        onClick={() =>
                          modelsGhostifyToggle(file.originalName, i)
                        }
                        size='large'
                      >
                        <BlurOffIcon />
                      </IconButton>
                    )}
                  </>
                )}
              </div>
            );
          })}
      </>
    );
  };

  return (
    <div className={classes.EM3DContainer}>
      <div className={classes.buttonsWrapper}>
        <Button
          className={classes.fullPageButton}
          color='primary'
          onClick={() => goFullScreen()}
        >
          3D Full Screen
        </Button>

        <Button
          className={classes.modelsButton}
          color='primary'
          aria-describedby={"models-dropdown"}
          onClick={handleModelsDropdown}
        >
          Menu
        </Button>
        <Popper
          id={"models-dropdown"}
          open={modelDropOpen}
          anchorEl={anchorEl}
          children={PopperChildren}
          disablePortal={true}
          style={{
            zIndex: 1000,
            marginTop: "113px",
            marginRight: "20px",
            backgroundColor: "#ffffff",
            width: "25%",
            height: "calc(100vh - 185px)",
            boxShadow: "0px 3px 10px #00000029",
            padding: "20px",
            overflowY: "auto",
          }}
        ></Popper>
      </div>

      <div className='xeoglBox'>
        <canvas id='xeoglCanvas'></canvas>
      </div>
    </div>
  );
};

const mapState = ({ simulation, gltfFiles }) => ({
  files: simulation.files.filter(
    (file) => file.name.includes(".gltf") || file.name.includes(".SceneJS")
  ),
  loadedModels: gltfFiles.loadedModels,
  loadingModels: gltfFiles.loadingModels,
  modelIsLoading: gltfFiles.modelIsLoading,
  modelsVisibilityList: gltfFiles.modelsVisibilityList,
  modelsGhostedList: gltfFiles.modelsGhostedList,
});

const mapDispatch = ({
  simulation: { getSimulationFiles, downloadFileFromList },
  gltfFiles: {
    setLoadedModels,
    setLoadingModels,
    setModelIsLoading,
    setModelsVisibilityList,
    setModelsGhostedList,
    resetGltfState,
  },
}) => ({
  getSimulationFiles: (uuid) => getSimulationFiles({ selectedRowUUID: uuid }),
  downloadFileFromList: (fileName, uuid, type) =>
    downloadFileFromList({ fileName, selectedRowUUID: uuid, type }),
  setLoadedModels: (modelName, model) => setLoadedModels({ modelName, model }),
  setLoadingModels: (value) => setLoadingModels(value),
  setModelIsLoading: (value) => setModelIsLoading(value),
  setModelsVisibilityList: (value) => setModelsVisibilityList(value),
  setModelsGhostedList: (value) => setModelsGhostedList(value),
  resetGltfState: () => resetGltfState(),
});

export default compose(
  withStyles(useStyles),
  connect(mapState, mapDispatch)
)(EM3D);
