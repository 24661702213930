import React, { useState, useEffect } from "react";
import withStyles from "@mui/styles/withStyles";
import { connect } from "react-redux";
import { compose } from "redux";
import { siteSimulationsHistoryTableHeaders } from "../../utils/tableHeaders";
import TableBox from "../../components/TableBox";
import dayjs from "dayjs";

import SimulationResumePage from "../../components/SimulationResumePage";

const useStyles = (theme) => ({
  tabSimulationsHistoryContainer: {
    padding: "20px 0",
  },
  simulationsHistoryTable: {
    height: "calc(100vh - 250px)",
  },
});

const Simulations = ({
  classes,
  siteSimulationsHistory,
  setActivePage,
  setSimulationsStepperActive,
  setNewEval,
  setActiveStep,
  siteInfos,
  deleteSiteSimulationsHistoryRowFolder,
}) => {
  const [simulationResumePageActive, setSimulationResumePageActive] =
    useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [
    siteSimulationsHistoryTableInfos,
    setSiteSimulationsHistoryTableInfos,
  ] = useState({
    tableHeaders: siteSimulationsHistoryTableHeaders,
    tableRows: [],
  });

  const normalizeEvalType = (type) => {
    switch (type) {
      case "new_tech":
        return "Simulated Technology";
      case "massive":
        return "Massive";
      default:
        return "Standard";
    }
  };

  useEffect(() => {
    if (siteSimulationsHistory) {
      setSiteSimulationsHistoryTableInfos({
        ...siteSimulationsHistoryTableInfos,
        tableRows: siteSimulationsHistory.map((simulation) => {
          return [
            simulation.id,
            simulation.project_description,
            dayjs(simulation.date_created).format("DD/MM/YYYY h:mm A"),
            dayjs(simulation.date_updated).format("DD/MM/YYYY h:mm A"),
            simulation.result,
            simulation.actual,
            normalizeEvalType(simulation.eval_type),
            "dots",
          ];
        }),
      });
    }
  }, [siteSimulationsHistory]);

  const handleSelectedRowUUID = (id, where) => {
    const newSelectedRow = siteSimulationsHistory.find((el) => el.id === id);
    setSelectedRow(newSelectedRow);

    if (where === "params") {
      setNewEval({ uuid: newSelectedRow.uuid, id: newSelectedRow.id });
      setActiveStep(1);
      setActivePage("");
      setSimulationsStepperActive(true);
    }
    if (where === "simulation") {
      setNewEval({ uuid: newSelectedRow.uuid, id: newSelectedRow.id });
      setActiveStep(4);
      setActivePage("");
      setSimulationsStepperActive(true);
    }
    if (where === "results") setSimulationResumePageActive(true);
    if (where === "row-delete")
      deleteSiteSimulationsHistoryRowFolder(
        siteInfos.base_folder,
        newSelectedRow.uuid
      );
  };

  return (
    <div className={classes.tabSimulationsHistoryContainer}>
      <div className={classes.simulationsHistoryTable}>
        <TableBox
          isSimulationsHistory
          tableInfos={siteSimulationsHistoryTableInfos}
          handleSelectedRowUUID={handleSelectedRowUUID}
        />
      </div>

      {simulationResumePageActive && (
        <SimulationResumePage
          setSimulationResumePageActive={setSimulationResumePageActive}
          selectedRow={selectedRow}
        />
      )}
    </div>
  );
};

const mapState = ({ site }) => ({
  siteSimulationsHistory: site.simulationsHistory,
  siteInfos: site.infos,
});

const mapDispatch = ({
  simulationsStepper: { setNewEval, setActiveStep },
  site: { deleteSiteSimulationsHistoryRowFolder },
}) => ({
  setNewEval: (value) => setNewEval(value),
  setActiveStep: (value) => setActiveStep(value),
  deleteSiteSimulationsHistoryRowFolder: (baseFolder, uuid) =>
    deleteSiteSimulationsHistoryRowFolder({ baseFolder, uuid }),
});

export default compose(
  withStyles(useStyles),
  connect(mapState, mapDispatch)
)(Simulations);
