import { useContext, createContext, useReducer, useEffect } from "react";
import L from "leaflet";
import { v4 as uuidv4 } from "uuid";
import blueLocationIcon from "../assets/img/marker-location-blue.svg";

const MapFiltersContext = createContext();

// UTILITY FUNCTIONS
const generateCustomMarkers = (originalSites) => {
  if (!originalSites || originalSites.length === 0) return;

  const tempObject = {
    empty: originalSites.filter((site) => site.sectors[0] === null),
    evaluated: originalSites.filter((site) => site.sectors[0] !== null),
  };
  let customDivs = [];

  // Extracting the azimuths of sites with an actual eval selected
  const azimuts = tempObject.evaluated.reduce((acc, current) => {
    const { sectors } = current;
    return [...acc, sectors.map((sector) => sector.azimuth)];
  }, []);

  // Extracting the coords of sites without an actual eval selected
  const emptySites = tempObject.empty.reduce((acc, current) => {
    return [
      ...acc,
      [
        current.coordinates.coordinates[1],
        current.coordinates.coordinates[0],
        current.site_code,
      ],
    ];
  }, []);

  // We need to run this code every time in order to recreate a L.divIcon
  if (azimuts !== undefined) {
    azimuts.map((list, i) => {
      customDivs.push([]);
      return list.map((angle, j) => {
        let newHtml = `<div class='filter-arrow' style='transform: rotate(${angle}deg); opacity: ${
          tempObject.evaluated[i].sectors[j].visible ? "1" : "0.1"
        };'></div>`;
        return customDivs[i].push({
          position: [
            tempObject.evaluated[i].coordinates.coordinates[1],
            tempObject.evaluated[i].coordinates.coordinates[0],
          ],
          technologies: tempObject.evaluated[i].sectors[j].technologies,
          tenants: tempObject.evaluated[i].sectors[j].tenants,
          azimuth: angle,
          site_code: tempObject.evaluated[i].site_code,
          icon: new L.divIcon({
            iconAnchor: [27, 82],
            className: "arrow-wrapper",
            html: `${newHtml}`,
          }),
        });
      });
    });
  }
  if (emptySites !== undefined) {
    emptySites.map((site) => {
      customDivs.push([
        {
          position: [site[0], site[1]],
          technologies: null,
          tenants: null,
          azimuth: null,
          site_code: site[2],
          icon: new L.icon({
            iconUrl: blueLocationIcon,
            iconSize: [40, 40],
            iconAnchor: [19, 40],
          }),
        },
      ]);
    });
  }
  return customDivs;
};
const getUniqueTenantsList = (originalSites) => {
  if (!originalSites || originalSites.length === 0) return;
  let listOfTenants = [];

  originalSites.map((site) => {
    if (site.sectors[0] !== null) {
      const { sectors } = site;
      return sectors.map((sector) => {
        listOfTenants = [...listOfTenants, ...sector.tenants];
      });
    }
  });

  const uniqueTenants = [...new Set(listOfTenants)];

  return uniqueTenants.reduce((acc, tenant) => {
    return [
      ...acc,
      { label: tenant, checked: true, id: uuidv4(), type: "tenants" },
    ];
  }, []);
};
const getUniqueBandsList = (originalSites) => {
  if (!originalSites || originalSites.length === 0) return;
  let listOfBands = [];

  originalSites.map((site) => {
    if (site.sectors[0] !== null) {
      const { sectors } = site;
      return sectors.map((sector) => {
        listOfBands = [...listOfBands, ...sector.technologies];
      });
    }
  });

  const uniqueBands = [...new Set(listOfBands)];

  return uniqueBands.reduce((acc, band) => {
    return [
      ...acc,
      { label: band, checked: true, id: uuidv4(), type: "technologies" },
    ];
  }, []);
};
const toggleFilterById = (filters, filterId) => {
  const updatedFilters = filters.reduce((acc, filter) => {
    if (filter.id === filterId) {
      return [
        ...acc,
        {
          label: filter.label,
          checked: !filter.checked,
          id: filter.id,
          type: filter.type,
        },
      ];
    } else {
      return [...acc, { ...filter }];
    }
  }, []);
  return updatedFilters;
};
const filterSites = (filters, filteredSites, originalSites) => {
  if (!filters || !filteredSites) return;
  let tempFilteredSites = [];

  const activeFilters = filters.filter((element) => element.checked === false);

  const activeFiltersLabels = activeFilters.reduce((acc, current) => {
    return [...acc, current.label];
  }, []);

  if (activeFilters.length > 0) {
    filteredSites.map((site) => {
      if (site.sectors[0] !== null) {
        tempFilteredSites.push({
          coordinates: site.coordinates,
          site_code: site.site_code,
          sectors: site.sectors.reduce((acc, sector) => {
            let tenantCombinationArray = sector?.antennas?.filter(
              (combo) => !activeFiltersLabels.includes(combo.split("-")[1])
            );
            let technologyCombinationArray = sector?.antennas?.filter(
              (combo) => !activeFiltersLabels.includes(combo.split("-")[2])
            );
            if (
              tenantCombinationArray.length === 0 ||
              technologyCombinationArray.length === 0
            ) {
              return [
                ...acc,
                {
                  azimuth: sector.azimuth,
                  visible: false,
                  technologies: sector.technologies,
                  tenants: sector.tenants,
                  antennas: sector.antennas,
                },
              ];
            } else {
              return [
                ...acc,
                {
                  azimuth: sector.azimuth,
                  visible: true,
                  technologies: sector.technologies,
                  tenants: sector.tenants,
                  antennas: sector.antennas,
                },
              ];
            }
          }, []),
        });
      }
    });
  } else {
    tempFilteredSites = [...originalSites];
  }
  return tempFilteredSites;
};

const initialState = {
  originalSites: [],
  filteredSites: [],
  customMarkers: [],
  filters: [],
  error: null,
  radius: 1000,
  showSiteCodes: false,
};

const actionTypes = {
  API_SUCCESS: "API_SUCCESS",
  API_FAILURE: "API_FAILURE",
  SET_CUSTOM_MARKERS: "SET_CUSTOM_MARKERS",
  UPDATE_CUSTOM_MARKERS: "UPDATE_CUSTOM_MARKERS",
  SET_FILTERS: "SET_FILTERS",
  TOGGLE_FILTER: "TOGGLE_FILTER",
  SET_FILTERED_SITES: "SET_FILTERED_SITES",
  UPDATE_FILTERED_SITES: "UPDATE_FILTERED_SITES",
  TOGGLE_SHOW_SITE_CODES: "TOGGLE_SHOW_SITE_CODES",
  SET_RADIUS: "SET_RADIUS",
};

const reducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.API_SUCCESS:
      return {
        ...state,
        originalSites: payload,
        error: null,
      };
    case actionTypes.API_FAILURE:
      return {
        ...state,
        error: payload,
      };
    case actionTypes.SET_CUSTOM_MARKERS:
      return {
        ...state,
        customMarkers: payload,
      };
    case actionTypes.UPDATE_CUSTOM_MARKERS:
      const newCustomMarkers = generateCustomMarkers(state.filteredSites);
      return {
        ...state,
        customMarkers: newCustomMarkers,
      };
    case actionTypes.SET_FILTERS:
      return {
        ...state,
        filters: [...payload],
      };
    case actionTypes.TOGGLE_FILTER:
      const updatedFilters = toggleFilterById(state.filters, payload);
      return {
        ...state,
        filters: updatedFilters,
      };
    case actionTypes.SET_FILTERED_SITES:
      return {
        ...state,
        filteredSites: payload,
      };
    case actionTypes.UPDATE_FILTERED_SITES:
      const newFilteredSites = filterSites(
        state.filters,
        state.filteredSites,
        state.originalSites
      );
      return {
        ...state,
        filteredSites: newFilteredSites,
      };
    case actionTypes.TOGGLE_SHOW_SITE_CODES:
      const toggled = !state.showSiteCodes;
      return {
        ...state,
        showSiteCodes: toggled,
      };
    case actionTypes.SET_RADIUS:
      return {
        ...state,
        radius: payload,
      };
    default:
      throw new Error("action type not found - MapFiltersContext");
  }
};

const MapFiltersProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const value = {
    state,
    dispatch,
  };

  useEffect(() => {
    if (state.originalSites.length > 0) {
      const defaultCustomMarkers = generateCustomMarkers(state.originalSites);

      const tenantsFilters = getUniqueTenantsList(state.originalSites);
      const technologiesFilters = getUniqueBandsList(state.originalSites);
      const defaultFilters = tenantsFilters.concat(technologiesFilters);

      dispatch({
        type: actionTypes.SET_FILTERED_SITES,
        payload: state.originalSites,
      });
      dispatch({
        type: actionTypes.SET_CUSTOM_MARKERS,
        payload: defaultCustomMarkers,
      });
      dispatch({
        type: actionTypes.SET_FILTERS,
        payload: defaultFilters,
      });
    }
  }, [state.originalSites]);

  useEffect(() => {
    if (state.filteredSites.length === 0) return;
    dispatch({ type: actionTypes.UPDATE_CUSTOM_MARKERS });
  }, [state.filteredSites]);

  return (
    <MapFiltersContext.Provider value={value}>
      {children}
    </MapFiltersContext.Provider>
  );
};

const useMapFilters = () => {
  return useContext(MapFiltersContext);
};

export { useMapFilters, MapFiltersProvider, actionTypes };
