import React, { useState, useEffect, useRef } from "react";
import withStyles from "@mui/styles/withStyles";
import { connect } from "react-redux";
import { compose } from "redux";
import {
  Tabs,
  Grid,
  Tab,
  Button,
  CircularProgress,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import {
  simulationResumeInitialConfigHeaders,
  simulatedTechFinalConfigHeaders,
} from "../../utils/tableHeaders";
import TenantsContributionTable from "../TenantsContributionTable/TenantsContributionTable";
import Magnifier from "react-magnifier";
import TechnologiesContributionTable from "../TechnologiesContributionTable/TechnologiesContributionTable";

const useStyles = (theme) => ({
  EM2DContainer: {
    padding: "40px",
  },
  tab: {
    fontWeight: "bold",
    textTransform: "none",
    padding: "0 30px",
  },
  tabContainer: {
    padding: "20px 0px",
    marginTop: "10px",
    height: "calc(100vh - 280px)",
    // overflowY: 'auto',
  },
  imagesContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  imagesSideBox: {
    width: "calc(50% - 50px)",
  },
  imagesBoxHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  imageTitles: {
    fontWeight: "bold",
    fontSize: "18px",
    marginLeft: "20px",
  },
  imageDownloadButton: {
    marginRight: "20px",
    height: "32px",
    padding: "3px 24px",
    background: "white",
    border: "0.5px solid #018ABE",
    borderRadius: "100px",
    font: "700 13px/20px",
    color: "#018ABE",
    textTransform: "none",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    minWidth: "64px",
    "&:hover": {
      backgroundColor: "white",
      cursor: "pointer",
      outline: "none",
    },
    "&:focus": {
      outline: "none",
    },
    "&:active": {
      outline: "none",
    },
  },
  image: {
    width: "100%",
    margin: "50px 0",
  },
  configTitle: {
    fontWeight: "bold",
    fontSize: "18px",
    marginBottom: "10px",
  },
  configTable: {
    backgroundColor: "#ECECED",
    marginBottom: "40px",
  },
  configTableRow: {
    borderColor: "transparent",
  },
  configTableCell: {
    borderColor: "transparent",
  },
  highlightedCell: {
    backgroundColor: "rgba(255, 255, 0, 0.16)",
    fontWeight: "bold",
    borderColor: "transparent",
  },
  loadingContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

const EM2D = ({
  classes,
  uuid,
  getSimulationFiles,
  loadedImages,
  getInitialConfig,
  getFinalConfig,
  getSimulatedTechFinalConfig,
  simulatedTechFinalConfig,
  initialConfig,
  finalConfig,
  resetLoadedImages,
  downloadFileFromList,
  apiError,
  getTenantsContribution,
  getTenantsList,
  getSiteTecnologiesList,
  evalType,
}) => {
  const [activeAnalysisTab, setActiveAnalysisTab] = useState("prospetti");
  const [initialImages, setInitialImages] = useState([]);
  const [finalImages, setFinalImages] = useState([]);
  const [initialImagesDownload, setInitialImagesDownload] = useState([]);
  const [finalImagesDownload, setFinalImagesDownload] = useState([]);
  const [downloadingImage, setDownloadingImage] = useState({});
  const [downloadActive, setDownloadActive] = useState(false);
  const [initialCells, setInitialCells] = useState([]);
  const [initialFilteredSectors, setInitialFilteredSectors] = useState([]);
  const [finalFilteredSectors, setFinalFilteredSectors] = useState([]);
  const [magnifierZoom, setMagnifierZoom] = useState(1.5);
  const [sortedInitialConfig, setSortedInitialConfig] = useState([]);
  const [sortedFinalConfig, setSortedFinalConfig] = useState([]);

  const initTbody = useRef(null);
  const tableHead = useRef();
  const wheelEvent = useRef(null);

  const analysisTabs = [
    { value: "prospetti", label: "AIE Prospectives" },
    { value: "cfg", label: "AIE Config" },
    { value: "tenants-contribution", label: "Tenants Contribution" },
    { value: "technologies-contribution", label: "Technologies Contribution" },
  ];

  useEffect(() => {
    if (initialConfig && finalConfig) {
      let tempSortedInitialConfig = [...initialConfig];
      tempSortedInitialConfig.sort((a, b) => {
        return Number(a["Orientamento (°)"]) - Number(b["Orientamento (°)"]);
      });
      let tempSortedFinalConfig = [...finalConfig];
      tempSortedFinalConfig.sort((a, b) => {
        return Number(a["Orientamento (°)"]) - Number(b["Orientamento (°)"]);
      });

      setSortedInitialConfig(tempSortedInitialConfig);
      setSortedFinalConfig(tempSortedFinalConfig);
    }
  }, [initialConfig, finalConfig]);

  const initialConfigTable = {
    tableHeader: simulationResumeInitialConfigHeaders,
    tableBody:
      initialConfig &&
      initialConfig.length > 0 &&
      finalConfig &&
      finalConfig.length > 0 &&
      sortedInitialConfig &&
      sortedInitialConfig.length > 0 &&
      sortedFinalConfig &&
      sortedFinalConfig.length > 0
        ? sortedInitialConfig.map((row, index) => {
            return [
              row["Orientamento (°)"],
              row["Tenant"],
              row.Tecnologia,
              row["HBA (m)"],
              row.Antenna,
              row["Tilt Meccanico (°)"],
              row["Tilt Elettrico (°)"],
              row["Potenza in Antenna (W)"],
              sortedFinalConfig[index]["Potenza in Antenna (W)"],
            ];
          })
        : [],
  };

  const simulatedTechConfigTable = {
    tableHeader: simulatedTechFinalConfigHeaders,
    tableBody:
      simulatedTechFinalConfig && simulatedTechFinalConfig.length > 0
        ? simulatedTechFinalConfig.map((row) => {
            return [
              row["Orientamento (°)"],
              row["Tenant"],
              row["Tecnologia"],
              row["HBA (m)"],
              row["Antenna"],
              row["Tilt Meccanico (°)"],
              row["Tilt Elettrico (°)"],
              row["Potenza in Antenna (W)"],
            ];
          })
        : [],
  };

  const parseCell = (cell, headerCell) => {
    if (cell === null) {
      return parseInt("0");
    } else if (
      headerCell.key === "tilt_elettrico" ||
      headerCell.key === "tilt_meccanico"
    ) {
      return parseInt(cell);
    } else if (
      headerCell.key === "hba" ||
      headerCell.key === "pot_tot_init_antenna" ||
      headerCell.key === "pot_tot_final_antenna"
    ) {
      return parseFloat(cell).toFixed(1);
    } else {
      return cell;
    }
  };

  useEffect(() => {
    async function fetchData() {
      await resetLoadedImages([]);
      await getSimulationFiles(uuid, "loadingImages");
      await getInitialConfig(uuid);
      await getFinalConfig(uuid);
      await getTenantsContribution(uuid);
      await getTenantsList();
      await getSiteTecnologiesList();
      if (evalType === "new_tech") {
        await getSimulatedTechFinalConfig(uuid);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (activeAnalysisTab === "prospetti") {
      const filteredInitialImages = Object.values(loadedImages).filter((el) =>
        el.name.includes("INITIAL") 
      );
      const filteredFinalImages = Object.values(loadedImages).filter((el) =>
        el.name.includes("FINAL") 
      );

      const filteredInitialImagesDownload = Object.values(loadedImages).filter((el) =>
      el.name.includes("INITIAL")  && el.name.includes(".svg")
    );
    const filteredFinalImagesDownload = Object.values(loadedImages).filter((el) =>
      el.name.includes("FINAL")  && el.name.includes(".svg")
    );

      const sortedInitialImages = filteredInitialImages
        .sort((a, b) => a.palo - b.palo)
        .sort((a, b) => a.settore - b.settore);
      const sortedFinalImages = filteredFinalImages
        .sort((a, b) => a.palo - b.palo)
        .sort((a, b) => a.settore - b.settore);

        const sortedInitialImagesDownload = filteredInitialImagesDownload
        .sort((a, b) => a.palo - b.palo)
        .sort((a, b) => a.settore - b.settore);
      const sortedFinalImagesDownload = filteredFinalImagesDownload
        .sort((a, b) => a.palo - b.palo)
        .sort((a, b) => a.settore - b.settore);

      setInitialImages(sortedInitialImages);
      setFinalImages(sortedFinalImages);

      setInitialImagesDownload(sortedInitialImagesDownload);
      setFinalImagesDownload(sortedFinalImagesDownload);
    }
  }, [loadedImages, activeAnalysisTab]);

  useEffect(() => {
    if (!initTbody.current) return;

    const initialRows = Array.from(initTbody.current.rows);
    let initCells = [];

    if (!initialRows) return;

    initialRows.forEach((row, i) => {
      Array.from(row.cells).forEach((cell, j, array) => {
        if (j === array.length - 1) {
          if (cell.innerText !== array[j - 1].innerText) {
            initCells.push(true);
          } else {
            initCells.push(false);
          }
        } else {
          initCells.push(false);
        }finalImages
      });
    });

    setInitialCells(initCells);
  }, [initTbody.current, activeAnalysisTab]);

  useEffect(() => {
    initialImages &&
      initialConfig &&
      setInitialFilteredSectors(orderImages("initial"));
    finalImages && finalConfig && setFinalFilteredSectors(orderImages("final"));
  }, [initialImages, initialConfig]);

  const orderImages = (which) => {
    const imgArray = which === "initial" ? initialImages : finalImages;
    const configArray = which === "initial" ? initialConfig : finalConfig;

    const filtered = configArray.filter((el, index, array) => {
      if (index < array.length - 1) {
        return (
          Number(el["Orientamento (°)"]) !==
          Number(array[index + 1]["Orientamento (°)"])
        );
      }
      return Number(el["Orientamento (°)"]);
    });

    const imagesArrayComparedByAzimuth = filtered.sort((a, b) => {
      return Number(a["Orientamento (°)"]) - Number(b["Orientamento (°)"]);
    });

    if (imagesArrayComparedByAzimuth.length === imgArray.length - 1) {
      const titles = imagesArrayComparedByAzimuth.map((el, index) => {
        const imgFound =
          imgArray.find((image) => {
            return image.name.includes(`Sector${el["Orientamento (°)"]}.svg`),image.name.includes(`Sector${el["Orientamento (°)"]}.png`) ;
          }) || imgArray[0];

        return {
          img: imgFound.img,
          sector: el["Settore"],
          azimuth: el["Orientamento (°)"],
          pole: imgArray[index + 1].palo !== 0 ? imgArray[index + 1].palo : "",
        };
      });

      return titles;
    }
  };

  useEffect(() => {
    if (!wheelEvent.current) return;

    wheelEvent.current.addEventListener("wheel", handleZoom, {
      passive: false,
    });
    return () => {
      if (wheelEvent.current)
        wheelEvent.current.removeEventListener("wheel", handleZoom, {
          passive: false,
        });
    };
  }, [initialImages]);

  const handleZoom = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.deltaY > 0) {
      setMagnifierZoom((prev) => {
        return prev - 0.3;
      });
    } else {
      setMagnifierZoom((prev) => {
        return prev + 0.3;
      });
    }
  };

  return (
    <div className={classes.EM2DContainer}>
      <Tabs
        value={activeAnalysisTab}
        onChange={(event, value) => setActiveAnalysisTab(value)}
        style={{ borderBottom: "1px solid #EFF2F1" }}
      >
        {analysisTabs.map((tab, i) => (
          <Tab
            className={classes.tab}
            style={{
              color: activeAnalysisTab === tab.value ? "#25282B" : "#A5A5A6",
            }}
            key={`download-type-${tab.value}`}
            index={i}
            value={tab.value}
            label={tab.label}
          />
        ))}
      </Tabs>

      <div
        className={classes.tabContainer}
        style={{ overflowY: activeAnalysisTab === "cfg" ? "unset" : "auto" }}
      >
        {activeAnalysisTab === "prospetti" && loadedImages.length!=0 && (
          <div className={classes.imagesContainer}>
            {initialImages &&
            initialImages.length > 0 &&
            initialFilteredSectors &&
            initialFilteredSectors.length > 0 ? (
              <div className={classes.imagesSideBox}>
                {initialImages &&
                  initialImages.map((image, i) => (
                    <div key={`simulation-img-initial-${i}`}>
                      <div className={classes.imagesBoxHeader}>
                        <div className={classes.imageTitles}>
                          {" "}
                          Initial configuration -{" "}
                          {i === 0
                            ? image.label
                            : initialFilteredSectors &&
                              initialFilteredSectors.length > 0 &&
                              `Pole ${
                                initialFilteredSectors[i - 1]?.pole
                              } - Azimuth ${
                                initialFilteredSectors[i - 1]?.azimuth
                              }`}
                        </div>
                        <Button
                          disabled={downloadActive}
                          className={classes.imageDownloadButton}
                          style={{
                            width: downloadActive ? "116px" : "auto",
                            border:
                              downloadingImage[i] && downloadActive
                                ? "none"
                                : !downloadingImage[i] && downloadActive
                                ? "0.5px solid grey"
                                : "0.5px solid #018ABE",
                          }}
                          onClick={async () => {
                            setDownloadActive(true);
                            setDownloadingImage({
                              ...downloadingImage,
                              [i]: true,
                            });
                            await downloadFileFromList(image.name.replace(".png",".svg"), uuid, "");
                            setDownloadingImage({
                              ...downloadingImage,
                              [i]: false,
                            });
                            setDownloadActive(false);
                          }}
                        >
                          {downloadingImage[i] ? (
                            <CircularProgress size={24} />
                          ) : (
                            "Download"
                          )}
                        </Button>
                      </div>
                      <div onWheel={handleZoom} ref={wheelEvent}>
                        <Magnifier
                          src={
                            i === 0
                              ? image.img
                              : initialFilteredSectors &&
                                initialFilteredSectors[i - 1]?.img
                          }
                          className={classes.image}
                          zoomFactor={magnifierZoom}
                          mgWidth={300}
                          mgHeight={300}
                        />
                      </div>
                    </div>
                  ))}
              </div>
            ) : apiError || evalType === "new_tech" ? (
              <div>No prospectives found...</div>
            ) : (
              <div className={classes.loadingContainer}>
                <CircularProgress />
              </div>
            )}
            {finalImages &&
              finalImages.length > 0 &&
              finalFilteredSectors &&
              finalFilteredSectors.length > 0 && (
                <div className={classes.imagesSideBox}>
                  {finalImages &&
                    finalImages.map((image, i) => (
                      <div key={`simulation-img-final-${i}`}>
                        <div className={classes.imagesBoxHeader}>
                          <div className={classes.imageTitles}>
                            {" "}
                            Final configuration -{" "}
                            {i === 0
                              ? image.label
                              : finalFilteredSectors &&
                                finalFilteredSectors.length > 0 &&
                                `Pole ${
                                  finalFilteredSectors[i - 1]?.pole
                                } - Azimuth ${
                                  finalFilteredSectors[i - 1]?.azimuth
                                }`}
                          </div>
                          <Button
                            disabled={downloadActive}
                            className={classes.imageDownloadButton}
                            style={{
                              width: downloadActive ? "116px" : "auto",
                              border:
                                downloadingImage[i] && downloadActive
                                  ? "none"
                                  : !downloadingImage[i] && downloadActive
                                  ? "0.5px solid grey"
                                  : "0.5px solid #018ABE",
                            }}
                            onClick={async () => {
                              setDownloadActive(true);
                              setDownloadingImage({
                                ...downloadingImage,
                                [i]: true,
                              });
                              await downloadFileFromList(image.name.replace(".png",".svg"), uuid, "");
                              setDownloadingImage({
                                ...downloadingImage,
                                [i]: false,
                              });
                              setDownloadActive(false);
                            }}
                          >
                            {downloadingImage[i] ? (
                              <CircularProgress size={24} />
                            ) : (
                              "Download"
                            )}
                          </Button>
                        </div>
                        <div onWheel={handleZoom} ref={wheelEvent}>
                          <Magnifier
                            src={
                              i === 0
                                ? image.img
                                : finalFilteredSectors &&
                                  finalFilteredSectors[i - 1]?.img
                            }
                            className={classes.image}
                            zoomFactor={magnifierZoom}
                            mgWidth={300}
                            mgHeight={300}
                          />
                        </div>
                      </div>
                    ))}
                </div>
              )}
          </div>
        )}

        {activeAnalysisTab === "prospetti" && loadedImages.length == 0 && (
          <Grid container justifyContent='center'>
            <h2 class="center">No preview available to display! Go to DOWNLOAD page to download the results</h2>
          </Grid>
        )}


        {activeAnalysisTab === "cfg" && evalType !== "new_tech" && (
          <div>
            {initialConfig && initialConfigTable.tableBody.length > 0 && (
              <>
                <div className={classes.configTitle}>Configuration</div>
                <TableContainer style={{ maxHeight: "70vh" }}>
                  <Table className={classes.configTable} stickyHeader>
                    <TableHead ref={tableHead}>
                      <TableRow className={classes.configTableRow}>
                        {initialConfigTable.tableHeader.map((cell) => (
                          <TableCell
                            className={classes.configTableCell}
                            key={`initial-table-header-cell-${cell.key}`}
                            style={{
                              fontWeight: "bold",
                              visibility:
                                cell.key === "tilt_elettrico_min" ||
                                cell.key === "tilt_elettrico_max"
                                  ? "hidden"
                                  : "unset",
                            }}
                          >
                            {cell.name}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody ref={initTbody}>
                      {initialConfigTable.tableBody.map((row, i) => {
                        return (
                          <TableRow
                            className={classes.configTableRow}
                            key={`initial-table-body-row-${i}`}
                          >
                            {row.map((cell, j, array) => {
                              let headerCell =
                                initialConfigTable.tableHeader[j];
                              let initCell = initialCells[i * array.length + j];
                              return (
                                <TableCell
                                  className={
                                    initCell
                                      ? classes.highlightedCell
                                      : classes.configTableCell
                                  }
                                  key={`initial-table-body-cell-${j}`}
                                >
                                  {parseCell(cell, headerCell)}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}
          </div>
        )}

        {activeAnalysisTab === "cfg" && evalType === "new_tech" && (
          <div>
            {simulatedTechFinalConfig &&
              simulatedTechConfigTable.tableBody.length > 0 && (
                <>
                  <div className={classes.configTitle}>Configuration</div>
                  <TableContainer style={{ maxHeight: "70vh" }}>
                    <Table className={classes.configTable} stickyHeader>
                      <TableHead>
                        <TableRow className={classes.configTableRow}>
                          {simulatedTechConfigTable.tableHeader.map((cell) => (
                            <TableCell
                              className={classes.configTableCell}
                              key={`simulated-tech-header-${cell.key}`}
                              sx={{ fontWeight: "bold" }}
                            >
                              {cell.name}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {simulatedTechConfigTable.tableBody.map(
                          (row, index) => {
                            return (
                              <TableRow
                                className={classes.configTableRow}
                                key={`simulated-tech-body-row-${index}`}
                              >
                                {row.map((cell, index) => {
                                  return (
                                    <TableCell
                                      className={classes.configTableCell}
                                      key={`simulated-tech-body-cell-${index}`}
                                    >
                                      {cell}
                                    </TableCell>
                                  );
                                })}
                              </TableRow>
                            );
                          }
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              )}
          </div>
        )}

        {activeAnalysisTab === "tenants-contribution" && (
          <>
            <TenantsContributionTable />
          </>
        )}
        {activeAnalysisTab === "technologies-contribution" && (
          <>
            <TechnologiesContributionTable />
          </>
        )}
      </div>
    </div>
  );
};

const mapState = ({ simulation }) => ({
  loadedImages: simulation.loadedImages,
  initialConfig: simulation.initialConfig,
  finalConfig: simulation.finalConfig,
  apiError: simulation.apiError,
  simulatedTechFinalConfig: simulation.simulatedTechFinalConfig,
});

const mapDispatch = ({
  simulation: {
    getSimulationFiles,
    getInitialConfig,
    getFinalConfig,
    getSimulatedTechFinalConfig,
    resetLoadedImages,
    downloadFileFromList,
    getTenantsContribution,
  },
  simulationsStepper: { getTenantsList },
  site: { getSiteTecnologiesList },
}) => ({
  getSimulationFiles: (uuid, type) =>
    getSimulationFiles({ selectedRowUUID: uuid, type }),
  getInitialConfig: (uuid) => getInitialConfig({ selectedRowUUID: uuid }),
  getFinalConfig: (uuid) => getFinalConfig({ selectedRowUUID: uuid }),
  getSimulatedTechFinalConfig: (uuid) =>
    getSimulatedTechFinalConfig({ selectedRowUUID: uuid }),
  resetLoadedImages: (value) => resetLoadedImages(value),
  downloadFileFromList: (fileName, uuid, type) =>
    downloadFileFromList({ fileName, selectedRowUUID: uuid, type }),
  getTenantsContribution: (uuid) => getTenantsContribution({ uuid }),
  getTenantsList: () => getTenantsList(),
  getSiteTecnologiesList: () => getSiteTecnologiesList(),
});

export default compose(
  withStyles(useStyles),
  connect(mapState, mapDispatch)
)(EM2D);
