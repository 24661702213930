import React, { useState, useEffect } from "react";
import withStyles from "@mui/styles/withStyles";
import { connect } from "react-redux";
import { compose } from "redux";
import { Autocomplete } from "@mui/material";
import { Link, Snackbar, TextField } from "@mui/material";
import TwoStepsImport from "../TwoStepsImport";
import TransitionsModal from "../../pages/Massive/modal";
import MuiAlert from "@mui/material/Alert";
import { CopyToClipboard } from "react-copy-to-clipboard";

const useStyles = (theme) => ({
  step1Container: {
    padding: "20px 0",
  },
  title: {
    fontSize: "14px",
    marginBottom: "25px",
  },
  selectType: {
    width: "450px",
    display: "block",
  },
  textField: {
    width: "450px",
  },
});

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const SimulationsStepperStep1 = ({
  classes,
  fileType,
  setFileType,
  description,
  setDescription,
  uploadedEval,
  setUploadedEval,
  importEval,
  importedEval,
  setOffset,
  offset,
  errorMessagesAsString,
  errorMessages,
  setErrorMessages,
  setErrorMessagesAsString,
}) => {
  const selectEval = async ({ target }) => {
    const file = target.files[0];
    setUploadedEval(file);
  };

  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    setOffset(0);
  }, []);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setErrorMessages([]);
    setErrorMessagesAsString(null);
    window.location.reload();
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={errorMessages.length > 0 ? true : false}
      >
        <Alert onClose={handleCloseModal} severity='error'>
          Some errors have been found.
          <Link href='#' rel='noopener' onClick={handleOpenModal}>
            <span> View</span>
          </Link>{" "}
          or{" "}
          <Link href='#' rel='noopener'>
            <span>
              {errorMessagesAsString && (
                <CopyToClipboard
                  text={errorMessagesAsString}
                  onCopy={handleCloseModal}
                >
                  <span>Copy to clipboard</span>
                </CopyToClipboard>
              )}
            </span>
          </Link>
        </Alert>
      </Snackbar>
      {openModal && (
        <TransitionsModal
          handleCloseModal={handleCloseModal}
          isOpen={openModal}
          errorMessages={errorMessages}
        />
      )}
      <div className={classes.step1Container}>
        <div className={classes.importFileBox}>
          <div className={classes.title}>
            Select the type of file that contains the radio-electrical
            parameters
          </div>
          <div className={classes.inputBox}>
            <Autocomplete
              className={classes.selectType}
              options={["Excel/CSV File", "Add manually", "Import Atena"]}
              onChange={(event, newValue) => setFileType(newValue)}
              value={fileType}
              renderInput={(params) => (
                <TextField
                  style={{ width: "100%", display: "block" }}
                  {...params}
                  label='Insert data'
                  variant='outlined'
                />
              )}
            />
            {fileType === "Excel/CSV File" && (
              <TwoStepsImport
                id='button-upload-excel-file'
                selectTitle={uploadedEval?.name || "Select Excel/CSV File"}
                importTitle='Import'
                selectFile={selectEval}
                importFile={importEval}
                importDisabled={Boolean(!uploadedEval)}
                importedEval={importedEval}
              />
            )}
          </div>
        </div>

        <div className={classes.descriptionBox}>
          <div className={classes.title} style={{ marginTop: "60px" }}>
            Add a project description
          </div>
          <div className={classes.inputBox}>
            <TextField
              classes={{ root: classes.textField }}
              id='desc-area'
              label='Project description'
              variant='outlined'
              multiline
              value={description || ""}
              onChange={(e) => setDescription(e.target.value)}
              inputProps={{ maxLength: 35 }}
            />
          </div>
        </div>
        <div className={classes.descriptionBox}>
          <div className={classes.title} style={{ marginTop: "60px" }}>
            Offset (V/m)
          </div>
          <div className={classes.inputBox}>
            <TextField
              classes={{ root: classes.textField }}
              id='offset'
              label='Offset (V/m)'
              variant='outlined'
              multiline
              value={offset || 0}
              onChange={(e) => setOffset(e.target.value)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const mapState = ({ simulationsStepper }) => ({
  fileType: simulationsStepper.fileType,
  description: simulationsStepper.description,
  uploadedEval: simulationsStepper.uploadedEval,
  importedEval: simulationsStepper.importedEval,
  offset: simulationsStepper.offset,
  errorMessages: simulationsStepper.errorMessages,
  errorMessagesAsString: simulationsStepper.errorMessagesAsString,
});

const mapDispatch = ({
  simulationsStepper: {
    setFileType,
    setDescription,
    setUploadedEval,
    importEval,
    setOffset,
    setErrorMessages,
    setErrorMessagesAsString,
  },
}) => ({
  setFileType: (value) => setFileType(value),
  setDescription: (value) => setDescription(value),
  setUploadedEval: (value) => setUploadedEval(value),
  importEval: () => importEval(),
  setOffset: (value) => setOffset(value),
  setErrorMessages: (value) => setErrorMessages(value),
  setErrorMessagesAsString: (value) => setErrorMessagesAsString(value),
});

export default compose(
  withStyles(useStyles),
  connect(mapState, mapDispatch)
)(SimulationsStepperStep1);
