import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { InputLabel } from "@mui/material";

import { Checkbox } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

import produce from "immer";
import { set, has, isEmpty, isUndefined } from "lodash";

import TextFieldCombo from "./TextFieldCombo";
import ListBox from "./ListBox";

// import DateTimePicker from "@mui/lab/DateTimePicker";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
// import DateAdapter from "@mui/lab/AdapterDateFns";

//funtion to manage json states
function enhancedReducer(state, updateArg) {
  if (isEmpty(updateArg)) return {};

  // check if the type of update argument is a callback function
  if (updateArg.constructor === Function) {
    return { ...state, ...updateArg(state) };
  }

  // if the type of update argument is an object
  if (updateArg.constructor === Object) {
    // does the update object have _path and _value as it's keys
    // if yes then use them to update deep object values
    if (has(updateArg, "_path") && has(updateArg, "_value")) {
      const { _path, _value } = updateArg;

      return produce(state, (draft) => {
        set(draft, _path, _value);
      });
    } else {
      return { ...state, ...updateArg };
    }
  }
}

function roundToDecimals(value, decimals) {
  return Math.round(parseFloat(value) * 10 ** decimals) / 10 ** decimals;
}

export default function JsonSchemaBox(props) {
  const [tabs, setTabs] = useState([]);
  const [jsonSchema, setJsonSchema] = useState({});
  const [formData, setFormData] = React.useReducer(enhancedReducer, {});
  const [modifiedData, setModifiedData] = useState(null);
  const [selectedTab, setSelectedTab] = React.useState(0);

  //method to update json states with own setter
  const updateForm = React.useCallback(
    ({ target: { value, name, type } }, state, stateSetter, decimals) => {
      const updatePath = name.split(".");

      switch (type) {
        case "number":
          if (decimals > 0) value = roundToDecimals(value, decimals);
          else value = parseInt(value);
          break;
        default:
          break;
      }

      // if the input is a checkbox then use callback function to update
      // the toggle state based on previous state
      if (type === "checkbox") {
        stateSetter((prevState) => ({
          [name]: !prevState[name],
        }));

        return;
      }

      // if we have to update the root level nodes in the form
      if (updatePath.length === 1) {
        const [key] = updatePath;

        stateSetter({
          [key]: value,
        });
      }

      // if we have to update nested nodes in the form object
      // use _path and _value to update them.
      if (updatePath.length > 1) {
        // stateSetter({
        //   _path: updatePath,
        //   _value: value
        // });

        let currVal = {};
        currVal = state;
        if (type === "number") currVal[name] = value;
        else currVal[name] = `${value}`;
        stateSetter(currVal);
      }
    },
    []
  );

  useEffect(() => {
    if (props.tabs) setTabs(props.tabs);
    if (props.schema) setJsonSchema(props.schema);
    if (JSON.stringify(props.formData) !== "{}") {
      // setFormData({})
      setFormData(props.formData);
    }
  }, [props.tabs, props.schema, props.formData]);

  useEffect(() => {
    if (props.onChange && JSON.stringify(formData) !== "{}") {
      props.onChange(formData);
    }
    if (props.onOnlyModifiedDataChange && modifiedData)
      props.onOnlyModifiedDataChange(modifiedData);
  }, [formData]);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Box sx={{ width: "100%", overflowY: "auto" }}>
      <Box>
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          aria-label='basic tabs example'
        >
          {tabs && tabs.map((tab) => <Tab label={tab} key={tab} />)}
        </Tabs>
      </Box>
      <Grid container spacing={props.spacing || 3} padding={props.padding || 3}>
        {jsonSchema &&
          Object.keys(jsonSchema).map((key, index) => {
            return (
              jsonSchema[key] &&
              (jsonSchema[key]?.tab == null ||
                jsonSchema[key].tab === tabs[selectedTab]) && (
                <Grid item md={jsonSchema[key].md || 2} key={index}>
                  {jsonSchema[key].type === "html" ? (
                    <div>
                      <InputLabel shrink>{jsonSchema[key].title}</InputLabel>
                      {formData[key] && formData[key] != null
                        ? formData[key]
                        : ""}
                    </div>
                  ) : jsonSchema[key].type === "combo" ? (
                    <TextFieldCombo
                      label={jsonSchema[key].title}
                      labelSize={14}
                      apiURL={jsonSchema[key].url}
                      valueMember={jsonSchema[key].valueMember}
                      displayMember={jsonSchema[key].displayMember}
                      selectedValue={formData[key]}
                      onSelectChange={(e) => {
                        setModifiedData((prev) => {
                          return { ...prev, [key]: e.target.value };
                        });
                        updateForm(e, formData, setFormData);
                      }}
                      type='text'
                      name={key}
                      required={jsonSchema[key].mandatory}
                      error={jsonSchema[key].mandatory && !formData[key]}
                      helperText={
                        jsonSchema[key].mandatory &&
                        !formData[key] &&
                        "Field is mandatory!"
                      }
                      mockedData={jsonSchema[key].mockedData}
                      multiple={jsonSchema[key].multiple}
                      disabled={jsonSchema[key].disabled}
                      defaultValue={
                        formData[key] || props.formData[key] || null
                      }
                      jsonDefaultValue={formData || props.formData || null}
                    />
                  ) : jsonSchema[key].type === "list" ? (
                    <ListBox
                      label={jsonSchema[key].title}
                      apiURL={jsonSchema[key].url}
                      data={jsonSchema[key].data}
                      valueMember={jsonSchema[key].valueMember}
                      displayMember={jsonSchema[key].displayMember}
                      multiselect={jsonSchema[key].multiselect}
                      selectedValue={
                        !jsonSchema[key].multiselect && formData[key]
                      }
                      onSelectChange={(e) => {
                        if (!jsonSchema[key].multiselect) {
                          setModifiedData((prev) => {
                            return { ...prev, [key]: e.target.value };
                          });
                          updateForm(e, formData, setFormData);
                        }
                      }}
                      checkedValues={
                        jsonSchema[key].multiselect && formData[key]
                      }
                      onCheckChange={(e, data) => {
                        formData[key] = JSON.stringify(data);
                        setModifiedData((prev) => {
                          return {
                            ...prev,
                            [key]: data,
                          };
                        });
                        setFormData({ ...formData });
                        // e.target.type = 'text';
                        // e.target.name = key;
                        // e.target.value = data;
                        // jsonSchema[key].multiselect &&
                        //   updateForm(e, formData, setFormData)
                      }}
                      name={key}
                      dense={jsonSchema[key].dense}
                      disabled={jsonSchema[key].disabled || false}
                      required={jsonSchema[key].mandatory || false}
                    />
                  ) : jsonSchema[key].type === "checkbox" ? (
                    <FormControl
                      required={jsonSchema[key].mandatory}
                      error={jsonSchema[key].mandatory && !formData[key]}
                    >
                      <FormGroup>
                        <FormControlLabel
                          disabled={jsonSchema[key].disabled || false}
                          control={
                            <Checkbox
                              fullWidth
                              name={key}
                              checked={
                                formData[key] !== null ? formData[key] : false
                              }
                              sx={jsonSchema[key].sx || null}
                              disabled={jsonSchema[key].disabled || false}
                              onChange={(e) => {
                                formData[key] = e.target.checked;
                                setModifiedData((prev) => {
                                  return {
                                    ...prev,
                                    [key]: e.target.checked,
                                  };
                                });
                                setFormData({ ...formData });
                              }}
                            />
                          }
                          label={jsonSchema[key].title}
                        />
                      </FormGroup>
                      <FormHelperText>
                        {jsonSchema[key].mandatory &&
                          !formData[key] &&
                          "Field is mandatory!"}
                      </FormHelperText>
                    </FormControl>
                  ) : // : jsonSchema[key].type === "date" ? (
                  //   <LocalizationProvider dateAdapter={DateAdapter}>
                  //     <DateTimePicker
                  //       renderInput={(props) => <TextField {...props} />}
                  //       label={jsonSchema[key].title}
                  //       value={formData[key]}
                  //       allowSameDateSelection={true}
                  //       disablePast={
                  //         jsonSchema[key].disablePast !== null
                  //           ? jsonSchema[key].disablePast
                  //           : true
                  //       }
                  //       onChange={(newValue) => {
                  //         let tmp = { ...formData };
                  //         tmp[key] = newValue.toISOString();
                  //         setModifiedData((prev) => {
                  //           return { ...prev, [key]: newValue.toISOString() };
                  //         });
                  //         setFormData(tmp);
                  //         console.log(formData);
                  //       }}
                  //     />
                  //   </LocalizationProvider>
                  // )
                  jsonSchema[key].type === "json" ? (
                    <Box
                      sx={{
                        width: "100%",
                        maxHeight: "400px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Typography variant='h7' sx={{ color: "secondary.main" }}>
                        {jsonSchema[key].title}
                      </Typography>
                      {formData[key] ? (
                        <Box sx={{ overflowY: "auto", width: "100%" }}>
                          <pre>{JSON.stringify(formData[key], null, 2)}</pre>
                        </Box>
                      ) : (
                        <Box>NO DATA</Box>
                      )}
                    </Box>
                  ) : (
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        readOnly: jsonSchema[key].readOnly || false,
                      }}
                      inputProps={{
                        min: jsonSchema[key].min,
                        max: jsonSchema[key].max,
                      }}
                      disabled={
                        jsonSchema[key].disabled ||
                        jsonSchema[key].disabledIf?.every((el) => {
                          const [elKey, elValue] = Object.entries(el)[0];
                          return formData[elKey] === elValue;
                        }) ||
                        false
                      }
                      multiline={jsonSchema[key].multiline || false}
                      select={jsonSchema[key].enum}
                      fullWidth
                      name={key}
                      label={jsonSchema[key].title}
                      value={
                        (formData[key] || formData[key] === 0) &&
                        formData[key] !== null
                          ? jsonSchema[key].type === "number"
                            ? roundToDecimals(
                                formData[key],
                                jsonSchema[key].decimals || 2
                              )
                            : formData[key]
                          : ""
                      }
                      error={
                        ((jsonSchema[key].mandatory != null ||
                          jsonSchema[key].mandatoryIf?.every((el) => {
                            const [elKey, elValue] = Object.entries(el)[0];
                            return formData[elKey] === elValue;
                          }) != null) &&
                          !formData[key]) ||
                        jsonSchema[key].errorIfValueIsLessThanZero
                          ? formData[key] < 0
                            ? true
                            : false
                          : false
                      }
                      required={
                        jsonSchema[key].mandatory != null ||
                        jsonSchema[key].mandatoryIf?.every((el) => {
                          const [elKey, elValue] = Object.entries(el)[0];
                          return formData[elKey] === elValue;
                        }) != null ||
                        false
                      }
                      helperText={
                        jsonSchema[key].mandatory && !formData[key]
                          ? "Field is mandatory!"
                          : formData[key] < 0 &&
                            jsonSchema[key].errorIfValueIsLessThanZero
                          ? jsonSchema[key].helperText
                          : ""
                      }
                      variant='standard'
                      type={jsonSchema[key].type}
                      sx={jsonSchema[key].sx || null}
                      onChange={(e) => {
                        // console.log(jsonSchema[key].mandatory != null)
                        // console.log(
                        //   jsonSchema[key].mandatoryIf?.every((el) => {
                        //     const [elKey, elValue] = Object.entries(el)[0]
                        //     return formData[elKey] === elValue
                        //   }) != null
                        // )
                        setModifiedData((prev) => {
                          return {
                            ...prev,
                            [key]: jsonSchema[key].decimals
                              ? parseFloat(e.target.value).toFixed(
                                  jsonSchema[key].decimals
                                )
                              : jsonSchema[key] === "number"
                              ? parseInt(e.target.value)
                              : e.target.value,
                          };
                        });
                        updateForm(
                          e,
                          formData,
                          setFormData,
                          jsonSchema[key].decimals
                            ? jsonSchema[key].decimals
                            : 0
                        );
                      }}
                    >
                      {jsonSchema[key].enum &&
                        jsonSchema[key].enum.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                    </TextField>
                  )}
                </Grid>
              )
            );
          })}
      </Grid>
    </Box>
  );
}
