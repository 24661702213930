import { Grid } from "@mui/material";
import { useParams } from "react-router-dom";

import EnhancedTableBox from "../../../components/PlaygroundComponents/EnhancedTableBox";

import { GET_TENANTS_NOMINAL_FREQUENCIES } from "../../../utils/api";

const visibleColumns = [
  {
    id: "nominal_frequency",
    alignRight: false,
    disablePadding: false,
    label: "Nominal Frequency (MHz)",
  },
  {
    id: "technologies",
    alignRight: false,
    disablePadding: false,
    label: "Technologies",
    type: "array",
  },
  {
    id: "base_frequency",
    alignRight: false,
    disablePadding: false,
    label: "Base Frequency (MHz)",
  },
];
const actions = [
  {
    action: "edit",
    icon: "edit",
    tooltip: "edit tenant",
  },
  {
    action: "delete",
    icon: "delete",
    tooltip: "delete tenant",
  },
];
const schema = {
  nominal_frequency: {
    title: "Nominal Frequency",
    type: "number",
    md: 5,
    // min input length
    min: 0,
    errorIfValueIsLessThanZero: true,
    helperText: "Value cannot be less then 0",
  },

  base_frequency: {
    title: "Base Frequency",
    type: "number",
    md: 5,
    // min input length
    min: 0,
    errorIfValueIsLessThanZero: true,
    helperText: "Value cannot be less then 0",
  },
};

const TenantsTechnology = () => {
  const { tenant } = useParams();
  return (
    <div>
      <Grid container spacing={5} padding={5}>
        <Grid item md={12}>
          <EnhancedTableBox
            title={`List of Technologies - ${tenant}`}
            addButton
            apiURL={`${GET_TENANTS_NOMINAL_FREQUENCIES}?tenant=${tenant}`}
            valueMember='nominal_frequency'
            visibleColumns={visibleColumns}
            addJsonSchema={schema}
            editJsonSchema={schema}
            actions={actions}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default TenantsTechnology;
