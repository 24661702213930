import React from "react";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

export default function EnhancedIconButton(props) {
  return (
    <>
      {props.tooltip ? (
        <Tooltip title={props.tooltip}>
          {props.onClick ? (
            <IconButton
              onClick={props.onClick}
              size={props.size}
              color={props.color}
              sx={props.sx}
            >
              <Icon fontSize={props.size} style={props.sx}>
                {props.icon}
              </Icon>
            </IconButton>
          ) : (
            <Icon fontSize={props.size} style={props.sx}>
              {props.icon}
            </Icon>
          )}
        </Tooltip>
      ) : props.onClick ? (
        <IconButton
          onClick={props.onClick}
          size={props.size}
          color={props.color}
          sx={props.sx}
        >
          <Icon fontSize={props.size} style={props.sx}>
            {props.icon}
          </Icon>
        </IconButton>
      ) : (
        <Icon
          sx={props.sx}
          fontSize={props.size}
          color={props.color}
          style={props.sx}
        >
          {props.icon}
        </Icon>
      )}
    </>
  );
}
