import axios from "axios";
import {
  POST_NEW_EVAL,
  PUT_SIMULATION_DESCRIPTION,
  GET_THRESHOLDS,
  GET_TENANTS_LIST,
  GET_TENANTS,
  GET_MANUFACTURERS_LIST,
  GET_MODELS_LIST,
  POST_IMPORT_EVAL,
  PUT_SIMULATION_THRESHOLDS,
  GET_EXPORT_EVAL,
  GET_TENANT_TECHNOLOGIES,
  PUT_ADD_TENANT,
  GET_PORTS_LIST,
  GET_AVAILABLE_TECHNOLOGIES,
  POST_SITE_PARAMETERS,
  GET_CONSTRAINTS,
  GET_SIMULATION_STEPPER_SUMMARY_LIST,
  PUT_CONSTRAINTS_DEFAULT,
  PUT_CONSTRAINTS_SAVE,
  POST_GET_ATENA_TOKEN,
  POST_ANTENNAS_FROM_ATENA_TO_BIM,
  PUT_SIMULATE_TECH,
  tokenExpiredDoLogin,
} from "../../utils/api";

const simulationsStepper = {
  state: {
    activeStep: 0,
    newEval: null,
    fileType: null,
    uploadedEval: null,
    importedEval: false,
    description: null,
    parameters: null,
    tenantsList: null,
    manufacturersList: null,
    modelsList: null,
    addTenantInputs: {},
    simulateTechConfiguration: [],
    simulateTechParameter: {},
    availableTechnologiesList: null,
    tenantTechnologiesList: null,
    portsList: [],
    thresholds: null,
    thresholdsAreOk: true,
    colorsAreOk: true,
    constraints: null,
    summaryList: null,
    offset: 0,
    errorMessages: [],
    errorMessagesAsString: null,
    simulateTechInputs: {
      tenant: null,
      technology: null,
      manufacturer: null,
      freqRangeMin: null,
      freqRangeMax: null,
      model: null,
      azimuth: null,
      hba: null,
      tilt: null,
      etilt: null,
    },
  },
  reducers: {
    resetSimulationsStepper(state) {
      return {
        ...state,
        fileType: null,
        uploadedEval: null,
        importedEval: false,
        description: null,
        parameters: null,
        tenantsList: null,
        manufacturersList: null,
        modelsList: null,
        addTenantInputs: {},
        simulateTechParameter: null,
        tenantTechnologiesList: null,
        portsList: [],
        thresholds: null,
        thresholdsAreOk: true,
        colorsAreOk: true,
        constraints: null,
        summaryList: null,
      };
    },
    setNewEval(state, value) {
      return { ...state, newEval: value };
    },
    setFileType(state, value) {
      return { ...state, fileType: value };
    },
    setUploadedEval(state, value) {
      return { ...state, uploadedEval: value };
    },
    setDescription(state, value) {
      return { ...state, description: value };
    },
    setThresholds(state, value) {
      return { ...state, thresholds: value };
    },
    setParameters(state, value) {
      return { ...state, parameters: value };
    },
    setTenantsList(state, value) {
      return { ...state, tenantsList: value };
    },
    setManufacturersList(state, value) {
      return { ...state, manufacturersList: value };
    },
    setModelsList(state, value) {
      return { ...state, modelsList: value };
    },
    setAddTenantInputs(state, { name, value }) {
      return {
        ...state,
        addTenantInputs: {
          ...state.addTenantInputs,
          [name]: value,
        },
      };
    },
    resetAddTenantInputs(state, value) {
      return { ...state, addTenantInputs: value };
    },
    setSimulateTechParameter(state, value) {
      return {
        ...state,
        simulateTechParameter: value,
      };
    },
    setSimulateTechConfiguration(state, value) {
      return {
        ...state,
        simulateTechConfiguration: value,
      };
    },
    setTenantTechnologiesList(state, value) {
      return { ...state, tenantTechnologiesList: value };
    },
    setAvailableTechnologiesList(state, value) {
      return { ...state, availableTechnologiesList: value };
    },
    setThresholdsAreOk(state, value) {
      return { ...state, thresholdsAreOk: value };
    },
    setColorsAreOk(state, value) {
      return { ...state, colorsAreOk: value };
    },
    setPortsList(state, value) {
      return { ...state, portsList: value };
    },
    setImportedEval(state, value) {
      return { ...state, importedEval: value };
    },
    setActiveStep(state, value) {
      return { ...state, activeStep: value };
    },
    setConstraints(state, value) {
      return { ...state, constraints: value };
    },
    setSummaryList(state, value) {
      return { ...state, summaryList: value };
    },
    setOffset(state, value) {
      return { ...state, offset: value };
    },
    setErrorMessages(state, value) {
      return { ...state, errorMessages: value };
    },
    setErrorMessagesAsString(state, value) {
      if (value)
        value = value.reduce((a, b) => (a ? a : "") + (b ? b : "") + "\n");
      return { ...state, errorMessagesAsString: value };
    },
    setSimulateTechInputs(state, { name, value }) {
      return {
        ...state,
        simulateTechInputs: {
          ...state.simulateTechInputs,
          [name]: value,
        },
      };
    },
    resetSimulateTechInputs(state, value) {
      return { ...state, simulateTechInputs: value };
    },
  },
  effects: (dispatch) => ({
    async getThresholds(_, { simulationsStepper }) {
      dispatch.loader.loader(true);
      this.setThresholds(null);

      const { newEval } = simulationsStepper;

      await axios({
        method: "GET",
        url: GET_THRESHOLDS,
        headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        params: { uuid_eval: newEval.uuid },
      })
        .then((res) => {
          res.data.data && this.setThresholds(res.data.data.threshold);
          dispatch.loader.loader(false);
        })
        .catch((err) => {
          console.log(`Error ${GET_THRESHOLDS}`, err);
          tokenExpiredDoLogin(err);
          dispatch.loader.loader(false);
        });
    },
    async getTenantsList() {
      dispatch.loader.loader(true);
      this.setTenantsList(null);

      await axios({
        method: "GET",
        url: GET_TENANTS,
        headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
      })
        .then((res) => {
          this.setTenantsList(res.data.data);
          dispatch.loader.loader(false);
        })
        .catch((err) => {
          console.log(`Error ${GET_TENANTS_LIST}`, err);
          tokenExpiredDoLogin(err);
          dispatch.loader.loader(false);
        });
    },
    async getManufacturersList() {
      dispatch.loader.loader(true);
      this.setManufacturersList(null);

      await axios({
        method: "GET",
        url: GET_MANUFACTURERS_LIST,
        headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
      })
        .then((res) => {
          this.setManufacturersList(res.data.data);
          dispatch.loader.loader(false);
        })
        .catch((err) => {
          console.log(`Error ${GET_MANUFACTURERS_LIST}`, err);
          tokenExpiredDoLogin(err);
          dispatch.loader.loader(false);
        });
    },
    async getModelsList({
      manufacturer,
      antennaModel,
      freqRangeMin,
      freqRangeMax,
    }) {
      dispatch.loader.loader(true);
      this.setModelsList(null);

      await axios({
        method: "GET",
        url: GET_MODELS_LIST,
        headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        params: {
          model_code: antennaModel,
          manufacturer: manufacturer,
          freq_max: freqRangeMin,
          freq_min: freqRangeMax,
        },
      })
        .then((res) => {
          this.setModelsList(res.data.data);
          dispatch.loader.loader(false);
        })
        .catch((err) => {
          console.log(`Error ${GET_MODELS_LIST}`, err);
          tokenExpiredDoLogin(err);
          dispatch.loader.loader(false);
        });
    },
    async getPortsList({ model, tenant, technology }) {
      dispatch.loader.loader(true);
      this.setPortsList([]);

      await axios({
        method: "GET",
        url: GET_PORTS_LIST,
        headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        params: {
          model_code: model,
          tenant: tenant,
          technology: technology,
        },
      })
        .then((res) => {
          if (!res.data.data) {
            dispatch.loader.loader(false);
            return;
          }

          if (res.data.data.includes(null)) {
            this.setPortsList([]);
          } else {
            this.setPortsList(res.data.data);
          }
          dispatch.loader.loader(false);
        })
        .catch((err) => {
          console.log(`Error ${GET_PORTS_LIST}`, err);
          tokenExpiredDoLogin(err);
          dispatch.loader.loader(false);
        });
    },
    async getTenantTechnologiesList({ tenant }) {
      dispatch.loader.loader(true);
      this.setTenantTechnologiesList(null);

      await axios({
        method: "GET",
        url: GET_TENANT_TECHNOLOGIES,
        headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        params: { tenant: tenant },
      })
        .then((res) => {
          this.setTenantTechnologiesList(res.data.data);
          dispatch.loader.loader(false);
        })
        .catch((err) => {
          console.log(`Error ${GET_TENANT_TECHNOLOGIES}`, err);
          tokenExpiredDoLogin(err);
          dispatch.loader.loader(false);
        });
    },
    async getAvailableTechnologiesList({ tenant }, { simulationsStepper }) {
      dispatch.loader.loader(true);
      this.setAvailableTechnologiesList(null);
      const { newEval } = simulationsStepper;
      try {
        const { data } = await axios({
          method: "GET",
          url: GET_AVAILABLE_TECHNOLOGIES,
          headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
          params: { uuid: newEval.uuid, tenant: tenant },
        });
        if (data) this.setAvailableTechnologiesList(data.data);
      } catch (error) {
        console.log(`Error ${GET_AVAILABLE_TECHNOLOGIES}`, error);
        tokenExpiredDoLogin(error);
      } finally {
        dispatch.loader.loader(false);
      }
    },
    async getConstraints(_, { simulationsStepper }) {
      dispatch.loader.loader(true);
      this.setConstraints(null);

      const { newEval } = simulationsStepper;

      await axios({
        method: "GET",
        url: GET_CONSTRAINTS,
        headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        params: { uuid: newEval.uuid },
      })
        .then((res) => {
          this.setConstraints(res.data.data[0].em_constraints);
          dispatch.loader.loader(false);
        })
        .catch((err) => {
          console.log(`Error ${GET_CONSTRAINTS}`, err);
          tokenExpiredDoLogin(err);
          dispatch.loader.loader(false);
        });
    },
    async getSummaryList(_, { simulationsStepper }) {
      dispatch.loader.loader(true);
      this.setSummaryList(null);

      const { newEval } = simulationsStepper;

      await axios({
        method: "GET",
        url: GET_SIMULATION_STEPPER_SUMMARY_LIST,
        headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        params: { uuid: newEval.uuid },
      })
        .then((res) => {
          this.setSummaryList(res.data.data[0]);
          dispatch.loader.loader(false);
        })
        .catch((err) => {
          console.log(`Error ${GET_SIMULATION_STEPPER_SUMMARY_LIST}`, err);
          tokenExpiredDoLogin(err);
          dispatch.loader.loader(false);
        });
    },

    async postNewEval(_, { site }) {
      dispatch.loader.loader(true);
      this.setNewEval(null);

      await axios({
        method: "POST",
        url: POST_NEW_EVAL,
        headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        data: { id_site: site.infos.id },
      })
        .then((res) => {
          this.setNewEval(res.data.data[0]);
          dispatch.loader.loader(false);
        })
        .catch((err) => {
          console.log(`Error ${POST_NEW_EVAL}`, err);
          tokenExpiredDoLogin(err);
          dispatch.loader.loader(false);
        });
    },

    async putSimulateTechConfiguration(_, { simulationsStepper }) {
      dispatch.loader.loader(true);
      const { newEval, simulateTechConfiguration } = simulationsStepper;

      const configuration = {
        eval_uuid: newEval.uuid,
        data: simulateTechConfiguration,
      };

      if (simulateTechConfiguration.length > 0) {
        await axios({
          method: "PUT",
          url: PUT_SIMULATE_TECH,
          headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
          data: configuration,
        })
          .catch((error) => {
            console.log(`Error ${PUT_SIMULATE_TECH}`, error);
            tokenExpiredDoLogin(error);
          })
          .finally(() => {
            dispatch.loader.loader(false);
          });

        await axios({
          method: "PUT",
          url: PUT_CONSTRAINTS_DEFAULT,
          headers: {
            authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          data: { uuid: newEval.uuid },
        }).catch((error) => {
          console.log(`Error ${PUT_CONSTRAINTS_DEFAULT}`, error);
          tokenExpiredDoLogin(error);
        });
      }
    },

    async putAddTenant(_, { simulationsStepper }) {
      dispatch.loader.loader(true);

      const {
        newEval,
        addTenantInputs: {
          azimuth,
          hba,
          manufacturer,
          model,
          technology,
          tenant,
          tenantSector,
          tilt,
        },
      } = simulationsStepper;

      const tenantInfos = {
        uuid: newEval.uuid || "",
        tenant_sector: parseInt(tenantSector) || 1,
        azimuth: parseInt(azimuth) || 0,
        amount: 1,
        hba: parseFloat(hba) || 1,
        tilt: parseInt(tilt) || 0,
        port: "",
        technology: technology || "",
        tenant: tenant || "",
        brand: manufacturer || "",
        model: model || "",
      };

      await axios({
        method: "PUT",
        url: PUT_ADD_TENANT,
        headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        data: tenantInfos,
      })
        .then((res) => {
          axios({
            method: "PUT",
            url: PUT_CONSTRAINTS_DEFAULT,
            headers: {
              authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            data: { uuid: newEval.uuid },
          });
          dispatch.loader.loader(false);
        })
        .catch((err) => {
          console.log(`Error ${PUT_ADD_TENANT}`, err);
          tokenExpiredDoLogin(err);
          dispatch.loader.loader(false);
        });
    },
    async putDescription(payload, { simulationsStepper }) {
      dispatch.loader.loader(true);
      const { newEval, description, offset } = simulationsStepper;

      await axios({
        method: "PUT",
        url: PUT_SIMULATION_DESCRIPTION,
        headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        data: {
          id: payload?.id || newEval.id,
          note: payload?.newDescription || description,
          em_offset: parseInt(payload?.offset) || parseInt(offset),
        },
      })
        .then((res) => {
          dispatch.loader.loader(false);
        })
        .catch((err) => {
          console.log(`Error ${PUT_SIMULATION_DESCRIPTION}`, err);
          tokenExpiredDoLogin(err);
          dispatch.loader.loader(false);
        });
    },
    async putThresholds(_, { simulationsStepper }) {
      dispatch.loader.loader(true);
      const { newEval, thresholds } = simulationsStepper;
      thresholds.map((el) => {
        return { threshold: parseInt(el.threshold), color: el.color };
      });

      await axios({
        method: "PUT",
        url: PUT_SIMULATION_THRESHOLDS,
        headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        data: { uuid_eval: newEval.uuid, threshold: thresholds },
      })
        .then((res) => {
          dispatch.loader.loader(false);
        })
        .catch((err) => {
          console.log(`Error ${PUT_SIMULATION_THRESHOLDS}`, err);
          tokenExpiredDoLogin(err);
          dispatch.loader.loader(false);
        });
    },

    async putModifySaveEval({ sectors, evalSelected }, { simulationsStepper }) {
      dispatch.loader.loader(true);
      const { newEval } = simulationsStepper;
      try {
        await axios({
          method: "PUT",
          url: POST_SITE_PARAMETERS,
          headers: {
            authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          data: { id: evalSelected, sectors: sectors },
        });
        await axios({
          method: "PUT",
          url: PUT_CONSTRAINTS_DEFAULT,
          headers: {
            authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          data: { uuid: newEval.uuid },
        });
      } catch (error) {
        console.log(`Error ${POST_SITE_PARAMETERS}`, error);
        tokenExpiredDoLogin(error);
      } finally {
        dispatch.loader.loader(false);
      }
    },

    async putConstraintsDefault(_, { simulationsStepper }) {
      dispatch.loader.loader(true);
      const { newEval } = simulationsStepper;

      await axios({
        method: "PUT",
        url: PUT_CONSTRAINTS_DEFAULT,
        headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        data: { uuid: newEval.uuid },
      })
        .then((res) => {
          this.setConstraints(res.data.data);
          dispatch.loader.loader(false);
        })
        .catch((err) => {
          console.log(`Error ${PUT_CONSTRAINTS_DEFAULT}`, err);
          tokenExpiredDoLogin(err);
          dispatch.loader.loader(false);
        });
    },
    async putConstraintsSave({ constraints }, { simulationsStepper }) {
      dispatch.loader.loader(true);
      const { newEval } = simulationsStepper;

      const finalConstraints = constraints.map(({ tenant, body }) => {
        const tenantInfos = {
          tenant: tenant,
          antennas: body.map((antenna) => {
            let technologies = [];

            antenna.map((tech) => {
              const techIndex = tech.findIndex((i) => i.key === "technology");
              const etiltIndex = tech.findIndex((i) => i.key === "etilt");
              const potTotalIndex = tech.findIndex((i) => i.key === "potTotal");

              technologies = [
                ...technologies,
                {
                  band: tech[techIndex].value,
                  etilt: {
                    current: tech[etiltIndex].value,
                    minValue: tech[etiltIndex].isLocked
                      ? tech[etiltIndex].value
                      : tech[etiltIndex].minValue,
                    maxValue: tech[etiltIndex].isLocked
                      ? tech[etiltIndex].value
                      : tech[etiltIndex].maxValue,
                  },
                  potTotal: {
                    current: tech[potTotalIndex].value,
                    minValue: tech[potTotalIndex].isLocked
                      ? tech[potTotalIndex].value
                      : tech[potTotalIndex].minValue,
                    maxValue: tech[potTotalIndex].isLocked
                      ? tech[potTotalIndex].value
                      : tech[potTotalIndex].maxValue,
                  },
                },
              ];

              return true;
            });

            const sectorIndex = antenna[0].findIndex((i) => i.key === "sector");
            const modelIndex = antenna[0].findIndex((i) => i.key === "antenna");
            const azimuthIndex = antenna[0].findIndex(
              (i) => i.key === "azimuth"
            );
            const tiltIndex = antenna[0].findIndex((i) => i.key === "tilt");
            const hbaIndex = antenna[0].findIndex((i) => i.key === "hba");

            return {
              sector: antenna[0][sectorIndex].value,
              model: antenna[0][modelIndex].value,
              azimuth: {
                current: antenna[0][azimuthIndex].value,
                minValue: antenna[0][azimuthIndex].isLocked
                  ? antenna[0][azimuthIndex].value
                  : antenna[0][azimuthIndex].minValue,
                maxValue: antenna[0][azimuthIndex].isLocked
                  ? antenna[0][azimuthIndex].value
                  : antenna[0][azimuthIndex].maxValue,
              },
              tilt: {
                current: antenna[0][tiltIndex].value,
                minValue: antenna[0][tiltIndex].isLocked
                  ? antenna[0][tiltIndex].value
                  : antenna[0][tiltIndex].minValue,
                maxValue: antenna[0][tiltIndex].isLocked
                  ? antenna[0][tiltIndex].value
                  : antenna[0][tiltIndex].maxValue,
              },
              hba: {
                current: antenna[0][hbaIndex].value,
                minValue: antenna[0][hbaIndex].isLocked
                  ? antenna[0][hbaIndex].value
                  : antenna[0][hbaIndex].minValue,
                maxValue: antenna[0][hbaIndex].isLocked
                  ? antenna[0][hbaIndex].value
                  : antenna[0][hbaIndex].maxValue,
              },
              technologies: technologies,
            };
          }),
        };

        return tenantInfos;
      });

      await axios({
        method: "PUT",
        url: PUT_CONSTRAINTS_SAVE,
        headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        data: { uuid: newEval.uuid, em_constraints: finalConstraints },
      })
        .then((res) => {
          this.getConstraints();
          dispatch.loader.loader(false);
        })
        .catch((err) => {
          console.log(`Error ${PUT_CONSTRAINTS_SAVE}`, err);
          tokenExpiredDoLogin(err);
          dispatch.loader.loader(false);
        });
    },

    async exportEval(_, { simulationsStepper }) {
      dispatch.loader.loader(true);
      const { newEval } = simulationsStepper;

      await axios({
        method: "GET",
        url: GET_EXPORT_EVAL,
        headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        params: { uuid: newEval.uuid },
        responseType: "blob",
      })
        .then((res) => {
          const blob = new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const aEle = document.createElement("a"); // Create a label
          const href = window.URL.createObjectURL(blob); // Create downloaded link
          aEle.href = href;
          aEle.setAttribute("download", "eval.xlsx"); // File name after download
          document.body.appendChild(aEle);
          aEle.click(); // Click to download
          document.body.removeChild(aEle); // Download complete remove element
          window.URL.revokeObjectURL(href); // Release blob object

          dispatch.loader.loader(false);
        })
        .catch((err) => {
          console.log(`Error ${GET_EXPORT_EVAL}`, err);
          tokenExpiredDoLogin(err);
          dispatch.loader.loader(false);
        });
    },
    async importEval(_, { simulationsStepper }) {
      dispatch.loader.loader(true);
      this.setImportedEval(false);

      const { newEval, uploadedEval } = simulationsStepper;

      const formData = new FormData();
      formData.append("formFile", uploadedEval);

      await axios({
        method: "POST",
        url: `${POST_IMPORT_EVAL}?uuid=${newEval.uuid}`,
        headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        data: formData,
      })
        .then((res) => {
          if (res.data?.result === 0) {
            if (res.data.errors) {
              this.setErrorMessages(res.data.errors);
              this.setErrorMessagesAsString(res.data.errors);
            }
            if (res.data.message) {
              if ((typeof res.data.message).toLowerCase() === "string") {
                this.setErrorMessages([res.data.message]);
                this.setErrorMessagesAsString([res.data.message]);
              } else {
                this.setErrorMessages(res.data.message);
                this.setErrorMessagesAsString(res.data.message);
              }
            }
          } else {
            this.setImportedEval(true);
          }
          axios({
            method: "PUT",
            url: PUT_CONSTRAINTS_DEFAULT,
            headers: {
              authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            data: { uuid: newEval.uuid },
          });
          dispatch.loader.loader(false);
        })
        .catch((err) => {
          console.log(`Error ${POST_IMPORT_EVAL}`, err);
          tokenExpiredDoLogin(err);
          dispatch.loader.loader(false);
        });
    },
    async importAtena(_, { simulationsStepper }) {
      dispatch.loader.loader(true);
      let atenaToken = null;
      let antennas = null;

      try {
        //Getting the token from Atena
        let response = await axios({
          method: "POST",
          url: `${POST_GET_ATENA_TOKEN}`,
          headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
          data: {
            method: "GET",
            url: "##ATHENA_URL##/token",
            body: "username=##ATHENA_USER##&password=##ATHENA_PASSWORD##&grant_type=password",
          },
        });

        if (response?.data?.access_token) {
          atenaToken = response.data.access_token;
        } else {
          throw {
            api: POST_GET_ATENA_TOKEN,
            message: "An error occurred in getting the token from Atena.",
          };
        }

        //Getting data from specific site with Atena token
        response = await axios({
          method: "POST",
          url: `${POST_GET_ATENA_TOKEN}`,
          headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
          data: {
            method: "POST",
            url: "##ATHENA_URL##/api/Efesto_Get_Site_Info",
            header: {
              Authorization: `Bearer ${atenaToken}`,
            },
            body: `{"codiceSito": "${simulationsStepper.newEval.room_code}","codiceAttivita": ""}`,
          },
        });

        if (response.status !== 200 || !response?.data?.antennas) {
          throw {
            api: POST_GET_ATENA_TOKEN,
            message: "An error occurred in getting data from Atena.",
          };
        }
        antennas = response.data.antennas;
      } catch (err) {
        if (String(err).includes("status code 400")) {
          this.setErrorMessages([
            "Cannot import because this site is not present in Atena",
          ]);
          this.setErrorMessagesAsString([
            "Cannot import because this site is not present in Atena",
          ]);
        }
        console.log(`Error ${err.api}`, err);
        dispatch.loader.loader(false);
        return;
      }

      //posting data to Bim module
      await axios({
        method: "POST",
        url: `${POST_ANTENNAS_FROM_ATENA_TO_BIM}`,
        headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        params: { uuid: simulationsStepper.newEval.uuid },
        data: {
          antennas,
        },
      })
        .then((res) => {
          if (res?.data?.result === 0 && res?.data?.message) {
            this.setErrorMessages(res.data.message);
            this.setErrorMessagesAsString(res.data.message);
          }
          dispatch.loader.loader(false);
          this.setErrorMessages([]);
          this.setErrorMessagesAsString(null);
        })
        .catch((err) => {
          this.setErrorMessages([
            "Something went wrong while calling BIM module...",
          ]);
          this.setErrorMessagesAsString([
            "Something went wrong while calling BIM module...",
          ]);

          console.log(`Error ${POST_ANTENNAS_FROM_ATENA_TO_BIM}`, err);
          tokenExpiredDoLogin(err);
          dispatch.loader.loader(false);
        });
    },
  }),
};

export default simulationsStepper;
