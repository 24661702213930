import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import { TextField } from "@mui/material";

import { visuallyHidden } from "@mui/utils";

import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import { useLocation } from "react-router-dom";

import LinearProgress from "@mui/material/LinearProgress";
import { v4 as uuidv4 } from "uuid";

import EnhancedIconButton from "./EnhancedIconButton";
import JsonSchemaBox from "./JsonSchemaBox";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";

import useHttpService from "../../hooks/useHttpService";
import { useConfirm } from "material-ui-confirm";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  var mystyle = { backgroundColor: "primary.main", color: "white" };
  if (props.headerStyle) mystyle = props.headerStyle;

  return (
    <TableHead>
      <TableRow sx={{ height: props.rowHeight ? props.rowHeight : "50px" }}>
        {props.multiselect && (
          <TableCell padding='checkbox'>
            <Checkbox
              color='primary'
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                "aria-label": "select all",
              }}
            />
          </TableCell>
        )}
        {props.visibleColumns.map((headCell) => (
          <TableCell
            key={uuidv4()}
            align={headCell.alignRight ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              <b style={headCell.vertical && { "writing-mode": "vertical-rl" }}>
                {" "}
                &nbsp;{headCell.label} &nbsp;
              </b>
              {orderBy === headCell.id ? (
                <Box component='span' sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}

        {props.actions && <TableCell />}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const [searchValue, setSearchValue] = useState(
    props?.searchValueFromOtherPage || ""
  );

  useEffect(() => {
    props?.searchValueFromOtherPage &&
      setSearchValue(props.searchValueFromOtherPage);
  }, [props.searchValueFromOtherPage]);

  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        pt: { xs: 1, sm: 2 },
        pb: { xs: 1, sm: 2 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color='inherit'
          variant='subtitle1'
          component='div'
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant='h6'
          id='tableTitle'
          component='div'
        >
          {props.title}
        </Typography>
      )}
      {props.search && (
        <TextField
          id='search'
          sx={{ marginRight: "1em", minWidth: "30%" }}
          variant='outlined'
          label='filter'
          type='search'
          onChange={(e) => {
            setSearchValue(e.target.value);
            props.setSearchWord(e.target.value);
          }}
          value={searchValue}
        ></TextField>
      )}
      {props.onRefresh && (
        <EnhancedIconButton
          icon='refresh'
          onClick={props.onRefresh}
          tooltip='Refresh'
        />
      )}
      {props.onAdd && (
        <EnhancedIconButton
          icon='add'
          onClick={props.onAdd}
          tooltip='Add new row'
        />
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTableBox(props) {
  const [myURL, setURL] = useState();
  const [selectedValue, setSelectedValue] = useState();
  const [myData, setData] = useState();
  const [originalData, setOriginalData] = useState();
  const [searchWord, setSearchWord] = useState("");

  //states for json form
  const [tabs, setTabs] = React.useState([]);
  const [formData, setFormData] = React.useState({});
  const [schema, setSchema] = React.useState({});
  const [editedFormData, setEditedFormData] = React.useState({});

  const [openPost, setOpenPost] = React.useState(false);
  const [openPut, setOpenPut] = React.useState(false);

  const httpService = useHttpService();

  const handleClickOpenPost = (e) => {
    setSchema(props.addJsonSchema || props.editJsonSchema);
    setFormData({});

    setOpenPost(true);
  };

  const handleCloseCancel = () => {
    loadData();
    setOpenPost(false);
    setOpenPut(false);
  };

  const handleCloseSave = () => {
    if (openPost)
      if (props.addURL)
        httpService.postAPI(props.addURL, editedFormData, handleCloseCancel);
      else if (props.apiURL)
        httpService.postAPI(myURL, editedFormData, handleCloseCancel);
      else if (props.onDataAdd) props.onDataAdd(editedFormData);
    if (openPut)
      if (props.editURL)
        httpService.putAPI(props.editURL, editedFormData, handleCloseCancel);
      else if (props.apiURL)
        httpService.putAPI(myURL, editedFormData, handleCloseCancel);
      else if (props.onDataChange) props.onDataChange(editedFormData);

    setOpenPost(false);
    setOpenPut(false);
    loadData();
  };

  function onDataChange(data) {
    //formData is OLD, data is NEW
    setEditedFormData(data);
  }

  function onDataReceived(data) {
    setData(data);
    setOriginalData(data);
  }

  function SelectChange(e) {
    setSelected(e.target.value);
    if (props.onSelectChange)
      props.onSelectChange(
        myData.find((row) => row[props.valueMember] === e.target.value)
      );
  }

  function loadData() {
    httpService.getAPI(myURL, onDataReceived);
  }

  useEffect(() => {
    loadData();
    if (props.newValue) props.setnewValue(false);
  }, [myURL, props.newValue]);

  useEffect(() => {
    if (props.apiURL) setURL(props.apiURL);
    if (props.data) {
      if (
        props.data.length > 0 &&
        props.valueMember === "id" &&
        !props.data[0].hasOwnProperty("id")
      ) {
        props.data.forEach((row, index) => {
          row.id = uuidv4();
        });
      }
      if (props.data.length > 0) {
        setData(props.data);
        setOriginalData(props.data);
      } else {
        setData();
        setOriginalData();
      }
    }
    if (props.selectedValue) setSelected(props.selectedValue);
  }, [props]);

  const [order, setOrder] = React.useState(props.orderDir || "asc");
  const [orderBy, setOrderBy] = React.useState(props.orderBy);
  const [selected, setSelected] = React.useState([]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (event, name) => {
    if (props.multiselect) {
      const selectedIndex = selected.indexOf(name);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, name);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }

      setSelected(newSelected);
      props.onSelectChange(newSelected);
    }
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = myData.map((n) => n[props.valueMember]);
      setSelected(newSelecteds);
      props.onSelectChange(newSelecteds);
      return;
    }
    setSelected([]);
    props.onSelectChange([]);
  };

  var lastClicked;
  function onMouseDown(e) {
    lastClicked = e;
  }

  //prepare the confirm dialog
  const confirm = useConfirm();

  function onAction(action, e) {
    let row = myData.find((row) => row[props.valueMember] === e);
    if (props.onAction) props.onAction(action, row, lastClicked);

    if (!props.disableDefaultActions)
      switch (action) {
        case "delete":
          confirm({
            description: `This will permanently delete the selected row.`,
          }).then(() => {
            if (props.deleteURL)
              httpService.deleteAPI(
                props.deleteURL,
                { ids: [row.id] },
                handleCloseCancel
              );
            else if (props.apiURL)
              httpService.deleteAPI(
                myURL,
                { ids: [row.id] },
                handleCloseCancel
              );
            else if (props.onDataDelete) props.onDataDelete(row);
          });
          break;

        case "clone":
          if (props.cloneURL)
            httpService.postAPI(
              props.cloneURL,
              { id: row.id },
              handleCloseCancel
            );
          break;

        case "edit":
          setSchema(props.editJsonSchema);
          setFormData(row);

          setOpenPut(true);
          break;

        default:
          break;
      }
  }

  useEffect(() => {
    if (props.comingWordToSearch && myData) {
      setSearchWord(props.comingWordToSearch);
    }
  }, [props.comingWordToSearch, myData]);

  useEffect(() => {
    if (
      searchWord !== null &&
      searchWord !== "" &&
      myData &&
      myData.length > 0
    ) {
      let tmp = new Set();
      let tmpSearchWord = searchWord.toLocaleLowerCase();

      myData.forEach((data, index) =>
        Object.entries(data).map((key) => {
          if (
            props.visibleColumns.filter((el) => {
              //we check if
              return el.id === key[0];
            }).length === 0
          )
            return null;

          const timeArray = ["started", "canceled", "modified", "inserted"];

          let value =
            key[1] && timeArray.includes(key[0])
              ? new Date(key[1]).toLocaleString()
              : key[1];
          if (value === null) return null;

          if (
            typeof value === "string" &&
            value.toLocaleLowerCase().includes(tmpSearchWord)
          )
            tmp.add(data);
          if (Array.isArray(value)) {
            value.forEach((el) => {
              if (el?.props?.style?.color === tmpSearchWord) tmp.add(data);
              if (
                (el?.props?.icon === "lock" && tmpSearchWord === "locked") ||
                (el?.props?.icon === "lock_open" &&
                  tmpSearchWord === "unlocked")
              )
                tmp.add(data);
            });
          }
          if (value?.props?.style?.color === tmpSearchWord) tmp.add(data);
          if (value?.props?.icon === tmpSearchWord) tmp.add(data);
        })
      );
      tmp = Array.from(tmp);
      tmp.length > 0 && setData(Array.from(tmp));
    } else if (originalData) {
      setData(originalData);
    }
  }, [searchWord]);

  const location = useLocation();

  useEffect(() => {
    if (location?.state?.searchValue) {
      const alarmId = location.state.searchValue;
      setSearchWord(alarmId);
    }
  }, []);

  const renderArrayItems = (array) =>
    array &&
    array.map((arrayItem) => (
      <span key={uuidv4()} style={{ marginRight: "0.5rem" }}>
        {arrayItem}
      </span>
    ));

  if (myData && myData.length > 0)
    return (
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <EnhancedTableToolbar
          title={props.title}
          numSelected={selected.length}
          search={props.search}
          setSearchWord={setSearchWord}
          searchValueFromOtherPage={location?.state?.searchValue || ""}
          onRefresh={loadData}
          onAdd={props.addButton && handleClickOpenPost}
        />

        <TableContainer
          sx={{
            maxHeight:
              window.innerHeight *
              (props.heightPercentage != null ? props.heightPercentage : 1),
          }}
        >
          <Table stickyHeader>
            <EnhancedTableHead
              headerStyle={props.headerStyle}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={myData.length}
              visibleColumns={props.visibleColumns}
              multiselect={props.multiselect}
              actions={props.actions}
              search={props.search}
            />
            <TableBody>
              {stableSort(myData, getComparator(order, orderBy)).map(
                (row, index) => {
                  const isItemSelected = isSelected(row[props.valueMember]);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      key={row[props.valueMember]}
                      hover
                      role='checkbox'
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      selected={isItemSelected}
                      sx={{
                        height: props.rowHeight ? props.rowHeight : "25px",
                      }}
                    >
                      {props.multiselect && (
                        <TableCell padding='checkbox'>
                          <Checkbox
                            onClick={(event) =>
                              handleClick(event, row[props.valueMember])
                            }
                            color='primary'
                            checked={isItemSelected}
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                          />
                        </TableCell>
                      )}

                      {props.visibleColumns.map((headCell) => (
                        <TableCell
                          key={uuidv4()}
                          align={headCell.alignRight ? "right" : "left"}
                          padding={headCell.disablePadding ? "none" : "normal"}
                          sortDirection={
                            orderBy === headCell.id ? order : false
                          }
                        >
                          {row[headCell.id] && headCell.type === "datetime" ? (
                            new Date(row[headCell.id]).toLocaleString()
                          ) : headCell.type === "date" ? (
                            new Date(row[headCell.id]).toLocaleDateString()
                          ) : headCell.type === "color" ? (
                            <span
                              style={{
                                padding: 10,
                                backgroundColor: row[headCell.id],
                                color: "#000000",
                              }}
                            >
                              {"Color HEX code: " + row[headCell.id]}
                            </span>
                          ) : headCell.type === "array" ? (
                            row[headCell.id] &&
                            renderArrayItems(row[headCell.id])
                          ) : (
                            <span
                              style={
                                headCell.textTransform
                                  ? {
                                      textTransform: `${headCell.textTransform}`,
                                    }
                                  : undefined
                              }
                            >
                              {row[headCell.id]}
                            </span>
                          )}
                        </TableCell>
                      ))}

                      <TableCell align='right' padding='none'>
                        {props.actions &&
                          props.actions.map((action) => (
                            <Tooltip
                              title={action?.tooltip || ""}
                              key={uuidv4()}
                            >
                              <IconButton
                                onMouseDown={onMouseDown}
                                onClick={() =>
                                  onAction(
                                    action.action,
                                    row[props.valueMember]
                                  )
                                }
                                sx={
                                  props.disableDefaultActions
                                    ? {
                                        opacity: "0.2",
                                      }
                                    : undefined
                                }
                              >
                                <Icon color='primary'>{action.icon}</Icon>
                              </IconButton>
                            </Tooltip>
                          ))}
                      </TableCell>
                    </TableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Dialog
          fullWidth
          maxWidth='lg'
          open={openPost || openPut}
          onClose={handleCloseCancel}
        >
          <DialogTitle>
            {(openPost && "Add new") || (openPut && "Edit row")}
          </DialogTitle>
          <DialogContent>
            <JsonSchemaBox
              schema={schema}
              formData={formData}
              onChange={onDataChange}
              tabs={tabs}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseCancel}>Cancel</Button>
            <Button onClick={handleCloseSave}>Save</Button>
          </DialogActions>
        </Dialog>
      </Paper>
    );
  else if (myData && myData.length === 0)
    return (
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <EnhancedTableToolbar
          title={props.title}
          numSelected={selected.length}
          search={props.search}
          setSearchWord={setSearchWord}
          searchValueFromOtherPage={location?.state?.searchValue || ""}
          onRefresh={loadData}
          onAdd={props.addButton && handleClickOpenPost}
        />
        <LinearProgress />
        <Dialog
          fullWidth
          maxWidth='lg'
          open={openPost || openPut}
          onClose={handleCloseCancel}
        >
          <DialogTitle>
            {(openPost && "Add new") || (openPut && "Edit row")}
          </DialogTitle>
          <DialogContent>
            <JsonSchemaBox
              schema={schema}
              formData={formData}
              onChange={onDataChange}
              tabs={tabs}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseCancel}>Cancel</Button>
            <Button onClick={handleCloseSave}>Save</Button>
          </DialogActions>
        </Dialog>
      </Paper>
    );
  else
    return (
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <EnhancedTableToolbar
          title={props.title}
          numSelected={selected.length}
          search={props.search}
          setSearchWord={setSearchWord}
          searchValueFromOtherPage={location?.state?.searchValue || ""}
          onRefresh={loadData}
          onAdd={props.addButton && handleClickOpenPost}
        />
        NO DATA
        <Dialog
          fullWidth
          maxWidth='lg'
          open={openPost || openPut}
          onClose={handleCloseCancel}
        >
          <DialogTitle>
            {(openPost && "Add new") || (openPut && "Edit row")}
          </DialogTitle>
          <DialogContent>
            <JsonSchemaBox
              schema={schema}
              formData={formData}
              onChange={onDataChange}
              tabs={tabs}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseCancel}>Cancel</Button>
            <Button onClick={handleCloseSave}>Save</Button>
          </DialogActions>
        </Dialog>
      </Paper>
    );
}
