import React, { useState } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import withStyles from "@mui/styles/withStyles";
import { Dialog, IconButton, TextField } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { useParams } from "react-router-dom";
import axios from "axios";
import { POST_RENDERING_QUEUE, tokenExpiredDoLogin } from "../../utils/api";

const useStyles = (theme) => ({
  modalContainer: {
    padding: "25px 40px",
  },
  modalHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  modalTitle: {
    color: "#018ABE",
    fontWeight: "bold",
    fontSize: "22px",
  },
  modalCloseIcon: {
    color: "#000000",
  },
  modalInputsContainer: {
    marginTop: "20px",
  },
  modalInputs: {
    width: "60%",
    marginBottom: "20px",
  },
  modalButtonsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "100px",
  },
  modalCancelButton: {
    background: "#C9CAC9 0% 0% no-repeat padding-box",
    borderRadius: "26px",
    opacity: 1,
    outline: "none",
    padding: "11px 30px",
    textDecoration: "none",
    font: "700 14px/19px",
    letterSpacing: 0,
    color: "#FFFFFF",
    border: "none",
    "&:hover": {
      outline: "none",
      cursor: "pointer",
      background: "#A5A5A6 0% 0% no-repeat padding-box",
    },
  },
  modalSaveButton: {
    marginLeft: "20px",
    background: "#03B8FF 0% 0% no-repeat padding-box",
    borderRadius: "26px",
    opacity: 1,
    outline: "none",
    padding: "11px 30px",
    textDecoration: "none",
    font: "700 14px/19px",
    letterSpacing: 0,
    color: "#FFFFFF",
    border: "none",
    "&:hover": {
      outline: "none",
      cursor: "pointer",
      background: "#018ABE 0% 0% no-repeat padding-box",
    },
  },
});

const SiteInfoModifyModal = ({
  classes,
  openModifyModal,
  setOpenModifyModal,
  setSiteModifyModalInputs,
  modifySiteInfos,
  siteInfos,
  modifyModalInputs,
  getSiteInfos,
}) => {
  let { siteId } = useParams();

  useState(() => {
    setSiteModifyModalInputs("site_name", siteInfos.site_name);
    setSiteModifyModalInputs("site_address", siteInfos.address);
    setSiteModifyModalInputs("site_lat", siteInfos.geometry.coordinates[1]);
    setSiteModifyModalInputs("site_lng", siteInfos.geometry.coordinates[0]);
  }, [siteInfos]);

  const updateRenderingQueue = async () => {
    if (
      modifyModalInputs.site_lat == siteInfos.geometry.coordinates[1] &&
      modifyModalInputs.site_lng == siteInfos.geometry.coordinates[0]
    ) {
      return;
    } else {
      try {
        await axios({
          method: "post",
          url: POST_RENDERING_QUEUE,
          headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
          data: {
            queueMsg: {
            site_code: siteId,
            filetype: "rendering",
            }
          },
        });
      } catch (err) {
        console.log(`Error ${POST_RENDERING_QUEUE}`, err);
        tokenExpiredDoLogin(err);
      }
    }
  };

  return (
    <Dialog
      onClose={() => setOpenModifyModal(false)}
      open={openModifyModal}
      fullWidth={true}
      maxWidth={"md"}
    >
      <div className={classes.modalContainer}>
        <div className={classes.modalHeader}>
          <div className={classes.modalTitle}>Change Site Informations</div>
          <IconButton
            className={classes.modalCloseIcon}
            onClick={() => setOpenModifyModal(false)}
            size='large'
          >
            <ClearIcon />
          </IconButton>
        </div>
        <div className={classes.modalInputsContainer}>
          <TextField
            className={classes.modalInputs}
            autoComplete='off'
            InputLabelProps={{
              style: { padding: 0 },
            }}
            name='site_name'
            label='Name'
            variant='outlined'
            value={modifyModalInputs.site_name}
            onChange={(e) =>
              setSiteModifyModalInputs(e.target.name, e.target.value)
            }
          />
          <TextField
            className={classes.modalInputs}
            autoComplete='off'
            InputLabelProps={{
              style: { padding: 0 },
            }}
            name='site_address'
            label='Address'
            variant='outlined'
            value={modifyModalInputs.site_address}
            onChange={(e) =>
              setSiteModifyModalInputs(e.target.name, e.target.value)
            }
          />
          <TextField
            className={classes.modalInputs}
            autoComplete='off'
            InputLabelProps={{
              style: { padding: 0 },
            }}
            name='site_lat'
            label='Latitude'
            variant='outlined'
            value={modifyModalInputs.site_lat}
            onChange={(e) =>
              setSiteModifyModalInputs(e.target.name, e.target.value)
            }
          />
          <TextField
            className={classes.modalInputs}
            autoComplete='off'
            InputLabelProps={{
              style: { padding: 0 },
            }}
            name='site_lng'
            label='Longitude'
            variant='outlined'
            value={modifyModalInputs.site_lng}
            onChange={(e) =>
              setSiteModifyModalInputs(e.target.name, e.target.value)
            }
          />
        </div>
        <div className={classes.modalButtonsContainer}>
          <button
            className={classes.modalCancelButton}
            onClick={() => {
              setOpenModifyModal(false);
            }}
          >
            Close
          </button>
          <button
            className={classes.modalSaveButton}
            onClick={async () => {
              await modifySiteInfos();
              await updateRenderingQueue();
              await getSiteInfos(siteId);
              setOpenModifyModal(false);
            }}
          >
            Save
          </button>
        </div>
      </div>
    </Dialog>
  );
};

const mapState = ({ site }) => ({
  modifyModalInputs: site.modifyModalInputs,
  siteInfos: site.infos,
});

const mapDispatch = ({
  site: { setSiteModifyModalInputs, modifySiteInfos, getSiteInfos },
}) => ({
  setSiteModifyModalInputs: (name, value) =>
    setSiteModifyModalInputs({ name, value }),
  modifySiteInfos: () => modifySiteInfos(),
  getSiteInfos: (siteId) => getSiteInfos({ siteId }),
});

export default compose(
  withStyles(useStyles),
  connect(mapState, mapDispatch)
)(SiteInfoModifyModal);
