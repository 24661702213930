import { useState, useEffect, useContext } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import withStyles from "@mui/styles/withStyles";
import { connect } from "react-redux";
import { compose } from "redux";
import { IconButton, Button, Tabs, Tab } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SimulationsStepper from "../../components/SimulationsStepper";

import Info from "./site.info";
import Parameters from "./site.parameters";
import Tenants from "./site.tenants";
import Simulations from "./site.simulations";
import Docs from "./site.docs";
import Preview from "./site.preview";
import { UserRoleContext } from "../../contexts/user-role.context";

const useStyles = (theme) => ({
  sitePage: {
    padding: "25px 25px 0",
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  sitePageHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
  siteTitleContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: "30px",
  },
  siteMainTitle: {
    fontSize: "18px",
    fontWeight: "bold",
  },
  newSimulationButton: {
    background: "#03B8FF 0 0 no-repeat padding-box",
    borderRadius: "26px",
    opacity: 1,
    font: "700 14px/22px",
    letterSpacing: 0,
    height: "40px",
    color: "#fff",
    border: "none",
    textTransform: "none",
    cursor: "pointer",
    "&:hover": {
      background: "#018ABE 0 0 no-repeat padding-box",
    },
    "&:disabled": {
      color: "rgba(0, 0, 0, 0.26)",
      background: "white",
      border: "0.5px solid #018ABE",
      cursor: "unset",
    },
    padding: "0 22px",
  },
  tab: {
    fontWeight: "bold",
    textTransform: "none",
    padding: "0 30px",
  },
  routesContainer: {
    borderTop: "1px solid #A5A5A6",
  },
});

const CEMSite = ({
  classes,
  siteInfos,
  getSiteInfos,
  getSiteParameters,
  getSiteTenantsParameters,
  getSiteSimulationsHistory,
  getSiteDocs,
  getSimulationsNumber,
  getSimulationsLastInfos,
  postNewEval,
  resetModelAndTenant,
  resetSimulationsStepper,
  setFinalConfigParams,
  setActiveStep,
  getEval,
}) => {
  let navigate = useNavigate();
  const location = useLocation();
  let { siteId, section } = useParams();
  const { pathname } = location;
  const { hasWritePermission } = useContext(UserRoleContext);

  const [activePage, setActivePage] = useState(pathname);
  const [simulationsStepperActive, setSimulationsStepperActive] =
    useState(false);

  const siteTabs = [
    {
      value: "info",
      label: "Site Informations",
      path: `/cem/site/${siteId}/info`,
    },
    { value: "tenants", label: "Tenants", path: `/cem/site/${siteId}/tenants` },
    {
      value: "parameters",
      label: "Actual Parameters",
      path: `/cem/site/${siteId}/parameters`,
    },
    {
      value: "simulations-history",
      label: "Simulation History",
      path: `/cem/site/${siteId}/simulations-history`,
    },
    {
      value: "docs",
      label: "Site Documents",
      path: `/cem/site/${siteId}/docs`,
    },
    {
      value: "preview",
      label: "3D Preview",
      path: `/cem/site/${siteId}/preview`,
    },
  ];

  const handleSimulationStepperActive = async () => {
    await resetSimulationsStepper();
    await postNewEval();
    await setActiveStep(0);
    setActivePage("");
    setSimulationsStepperActive(true);
  };

  const handleSiteTabs = (event, value) => {
    navigate(`${value}`, {
      state: { from: location },
      replace: true,
    });
    setActivePage(value);
  };

  useEffect(() => {
    async function fetchdata() {
      if (!siteInfos) {
        await getSiteInfos(siteId);
        await getSimulationsNumber({ site: siteInfos });
        await getSimulationsLastInfos({ site: siteInfos });
      }
      if (activePage === `/cem/site/${siteId}/info`) {
        await getSiteInfos(siteId);
        await getSimulationsNumber({ site: siteInfos });
        await getSimulationsLastInfos({ site: siteInfos });
        setFinalConfigParams([]);
      }
      if (activePage === `/cem/site/${siteId}/parameters`) {
        await resetModelAndTenant({});
        if (siteInfos?.id_eval_actual) {
          await getSiteParameters("isActiveParams");
        }
      }
      if (activePage === `/cem/site/${siteId}/tenants`) {
        await getSiteTenantsParameters();
      }
      if (activePage === `/cem/site/${siteId}/simulations-history`) {
        await getSiteSimulationsHistory();
      }
      if (activePage === `/cem/site/${siteId}/docs`) await getSiteDocs();

      if (activePage === "check") setActivePage(section);
    }
    fetchdata();
  }, [activePage]);

  useEffect(() => {
    if (!siteInfos) return;

    if (siteInfos?.id_eval_actual) {
      const fetchActualEval = async () => {
        await getEval();
      };

      fetchActualEval();
    }
  }, [siteInfos]);

  return (
    <div className={classes.sitePage}>
      {!simulationsStepperActive && (
        <>
          <div className={classes.sitePageHeader}>
            <div className={classes.siteTitleContainer}>
              <Link to={"/cem"}>
                <IconButton className={classes.arrowIcon} size='large'>
                  <ArrowBackIcon />
                </IconButton>
              </Link>
              <div className={classes.siteMainTitle}>
                {siteInfos?.site_name}
              </div>
            </div>
            <Button
              className={classes.newSimulationButton}
              onClick={() => handleSimulationStepperActive()}
              disabled={!hasWritePermission}
            >
              New C.E.M Simulation
            </Button>
          </div>

          {activePage !== "" && activePage !== "check" && (
            <>
              <Tabs value={pathname} onChange={handleSiteTabs} aria-label='tab'>
                {siteTabs.map((tab, i) => (
                  <Tab
                    className={classes.tab}
                    component={Link}
                    style={{
                      color: pathname === tab.path ? "#25282B" : "#A5A5A6",
                    }}
                    key={`site-tab-${i}`}
                    index={i}
                    value={tab.path}
                    label={tab.label}
                    to={`${tab.path}`}
                  />
                ))}
              </Tabs>

              <div className={classes.routesContainer}>
                {pathname === `/cem/site/${siteId}/info` && <Info />}
                {pathname === `/cem/site/${siteId}/parameters` && (
                  <Parameters />
                )}
                {pathname === `/cem/site/${siteId}/tenants` && <Tenants />}
                {pathname === `/cem/site/${siteId}/simulations-history` && (
                  <Simulations
                    setActivePage={setActivePage}
                    setSimulationsStepperActive={setSimulationsStepperActive}
                  />
                )}
                {pathname === `/cem/site/${siteId}/docs` && <Docs />}
                {pathname === `/cem/site/${siteId}/preview` && (
                  <Preview siteInfos={siteInfos} />
                )}
              </div>
            </>
          )}
        </>
      )}

      {simulationsStepperActive && (
        <SimulationsStepper
          setActivePage={setActivePage}
          setSimulationsStepperActive={setSimulationsStepperActive}
          siteInfos={siteInfos}
        />
      )}
    </div>
  );
};

const mapState = ({ site }) => ({
  siteInfos: site.infos,
  siteDocs: site.docs,
});

const mapDispatch = ({
  site: {
    getSiteInfos,
    getSiteParameters,
    getSiteTenantsParameters,
    getSiteSimulationsHistory,
    getSiteDocs,
    getSimulationsNumber,
    getSimulationsLastInfos,
    resetModelAndTenant,
    getEval,
  },
  simulationsStepper: { postNewEval, resetSimulationsStepper, setActiveStep },
  simulation: { setFinalConfigParams },
}) => ({
  getSiteInfos: (siteId) => getSiteInfos({ siteId }),
  getSiteParameters: (type) => getSiteParameters({ type }),
  getSiteTenantsParameters: () => getSiteTenantsParameters(),
  getSiteSimulationsHistory: () => getSiteSimulationsHistory(),
  getSiteDocs: () => getSiteDocs(),
  getSimulationsNumber: (site) => getSimulationsNumber({ site }),
  getSimulationsLastInfos: (site) => getSimulationsLastInfos({ site }),
  postNewEval: () => postNewEval(),
  resetModelAndTenant: (value) => resetModelAndTenant(value),
  resetSimulationsStepper: () => resetSimulationsStepper(),
  setActiveStep: (value) => setActiveStep(value),
  getEval: () => getEval(),
  setFinalConfigParams: (value) => setFinalConfigParams(value),
});

export default compose(
  withStyles(useStyles),
  connect(mapState, mapDispatch)
)(CEMSite);
