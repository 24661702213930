import axios from "axios";
import { HubConnectionBuilder } from "@microsoft/signalr";
import {
  START_QUEUE,
  CONNECTION_URL,
  GET_SIMULATION_FILES,
  GET_FILE_FROM_LIST,
  GET_INITIAL_CONFIGURATION,
  GET_FINAL_CONFIGURATION,
  GET_FINAL_CONFIGURATION_EVAL,
  PUT_SIMULATION_EVAL,
  GET_EVAL_LOG_EXPORT,
  GET_TENANTS_CONTRIBUTION,
  GET_SIMULATED_TECH_CONFIG,
  tokenExpiredDoLogin,
} from "../../utils/api";

const simulation = {
  state: {
    status: null,
    connection: null,
    messages: [],
    files: [],
    loadedImages: [],
    initialConfig: [],
    finalConfig: [],
    finalConfigParams: [],
    simulatedTechFinalConfig: [],
    tenantsContribution: [],
    apiError: null,
  },
  reducers: {
    setStatus(state, value) {
      return { ...state, status: value };
    },
    setConnection(state, value) {
      return { ...state, connection: value };
    },
    setMessages(state, value) {
      return { ...state, messages: value };
    },
    setFiles(state, value) {
      return { ...state, files: value };
    },
    setLoadedImages(state, imageInfos) {
      const { imageName, image } = imageInfos;
/*       const correctBaseImage = image.replace(
        "data:application/octet-stream;base64,",
        "data:image/svg+xml;base64, "
      ); */

      const correctBaseImage = 
      imageName.includes(".png")
          ?image.replace(
            "data:application/octet-stream;base64,",
            "data:image/png;base64, "
          )

        :imageName.includes(".svg")
          ?image.replace(
            "data:application/octet-stream;base64,",
            "data:image/svg+xml;base64, "
          )
          :0;

      const imagePalo = imageName.includes("TopProjection")
        ? 0
        : Number(imageName.split("_")[2].slice(-1));
      const imageSettore = imageName.includes("TopProjection")
        ? 0
        : Number(imageName.split("_")[3].slice(-5, -4));
      const imageLabel = imageName.includes("TopProjection")
        ? "Top"
        : `Pole ${imagePalo} - Sector ${imageSettore}`;

      return {
        ...state,
        loadedImages: [
          ...state.loadedImages,
          {
            name: imageName,
            img: correctBaseImage,
            label: imageLabel,
            palo: imagePalo,
            settore: imageSettore,
          },
        ],
      };
    },
    resetLoadedImages(state, value) {
      return { ...state, loadedImages: [] };
    },
    setInitialConfig(state, value) {
      return { ...state, initialConfig: value };
    },
    setFinalConfig(state, value) {
      return { ...state, finalConfig: value };
    },
    setFinalConfigParams(state, value) {
      return { ...state, finalConfigParams: value };
    },
    setSimulatedTechFinalConfig(state, value) {
      return { ...state, simulatedTechFinalConfig: value };
    },
    setApiError(state, value) {
      return { ...state, apiError: value };
    },
    setTenantsContribution(state, value) {
      return { ...state, tenantsContribution: value };
    },
  },
  effects: (dispatch) => ({
    async startTechSimulationQueue(_, { site, simulationsStepper }) {
      dispatch.loader.loader(true);
      this.setMessages([]);

      const {
        newEval: { uuid },
        simulateTechParameter,
      } = simulationsStepper;

      let item = { messageUuid: uuid, status: "sent" };

      let localUuidArray = localStorage.getItem("uuid");
      if (localUuidArray) {
        localUuidArray = JSON.parse(localUuidArray);
        if (localUuidArray.find((el) => el.messageUuid === uuid)) {
          this.getConnection();
          dispatch.loader.loader(false);
          return;
        } else {
          localUuidArray.push(item);
          localStorage.setItem("uuid", JSON.stringify(localUuidArray));
        }
      } else {
        localUuidArray = [item];
        localStorage.setItem("uuid", JSON.stringify(localUuidArray));
      }

      const { infos } = site;

      const params = {
        queueMsg: {
        new_tech: "true",
        site_code: infos.site_code,
        EVAL_UUID: uuid,
        tenant: simulateTechParameter.tenant,
        technology: simulateTechParameter.technology,
        }
      };

      await axios({
        method: "POST",
        url: START_QUEUE,
        headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        data: params,
      })
        .then((res) => {
          this.putSimulationEval();
          this.getConnection();
          dispatch.loader.loader(false);
        })
        .catch((err) => {
          console.log(`Error ${START_QUEUE}`, err);
          tokenExpiredDoLogin(err);
          dispatch.loader.loader(false);
        });
    },
    async startSimulationQueue(_, { site, simulationsStepper }) {
      dispatch.loader.loader(true);
      this.setMessages([]);

      const {
        newEval: { uuid },
      } = simulationsStepper;

      let item = { messageUuid: uuid, status: "sent" };

      let localUuidArray = localStorage.getItem("uuid");
      if (localUuidArray) {
        localUuidArray = JSON.parse(localUuidArray);
        if (localUuidArray.find((el) => el.messageUuid === uuid)) {
          this.getConnection();
          dispatch.loader.loader(false);
          return;
        } else {
          localUuidArray.push(item);
          localStorage.setItem("uuid", JSON.stringify(localUuidArray));
        }
      } else {
        localUuidArray = [item];
        localStorage.setItem("uuid", JSON.stringify(localUuidArray));
      }

      const { infos } = site;

      const params = {
        queueMsg: {
          status: null,
          EVAL_UUID: uuid,
          ABC_SN: 0,
          ABC_CYCLES: 0,
          CoverageWeight: null,
          CoverageTreeshold: null,
          InterferenceTreeshold: null,
          roomName: infos.site_name,
          roomCode: infos.site_code,
          latWGS84: Number(infos.geometry.coordinates[1]),
          longWGS84: Number(infos.geometry.coordinates[0]),
          CALC_RADIUS: null,
          CALC_RESOLUTION: null,
          FOCUS_ZONE_RADIUS: null,
          COMPUTATION_ZONE_RADIUS: null,
          PROPAG_MODEL: null,
          CELL_SIZE: null,
          banda: null,
          neType: null,
          FBAND: null,
          REPORT_COLUMN: null,
          ZONE: null,
          EDGECOVERAGEPROBABILITY: null,
          SHADOWING: null,
          INDOORCOVERAGE: null,
          COMPUTATION_ZONE_SHAPE_UUID: null,
          ZONE_SHAPE: false,
          thresholdVariation: 100,
          transmitters: [],
          EVAL_PARENT_UUID: null,
          ssdEmSectorValues: [],
        },
      };

      await axios({
        method: "POST",
        url: START_QUEUE,
        headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        data: params,
      })
        .then((res) => {
          this.putSimulationEval();
          this.getConnection();
          dispatch.loader.loader(false);
        })
        .catch((err) => {
          console.log(`Error ${START_QUEUE}`, err);
          tokenExpiredDoLogin(err);
          dispatch.loader.loader(false);
        });
    },
    async putSimulationEval(_, { simulationsStepper }) {
      dispatch.loader.loader(true);
      const {
        newEval: { id },
      } = simulationsStepper;

      await axios({
        method: "PUT",
        url: PUT_SIMULATION_EVAL,
        headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        data: { id: id, final_result: "CALCULATING", status: "SUBMITTED" },
      })
        .then((res) => {
          dispatch.loader.loader(false);
        })
        .catch((err) => {
          console.log(`Error ${PUT_SIMULATION_EVAL}`, err);
          tokenExpiredDoLogin(err);
          dispatch.loader.loader(false);
        });
    },
    getConnection() {
      this.setConnection(null);

      const newConnection = new HubConnectionBuilder()
        .withUrl(CONNECTION_URL)
        .withAutomaticReconnect()
        .build();

      this.setConnection(newConnection);
    },
    startSimulation(_, { simulation, simulationsStepper }) {
      const {
        newEval: { uuid },
      } = simulationsStepper;

      let localUuidArray = localStorage.getItem("uuid");
      if (localUuidArray) {
        localUuidArray = JSON.parse(localUuidArray);
        let item = localUuidArray.find((el) => el.messageUuid === uuid);
        item.status = "started";
        let newLocalUuidArray = localUuidArray.filter(
          (el) => el.messageUuid !== uuid
        );
        newLocalUuidArray
          ? newLocalUuidArray.push(item)
          : (newLocalUuidArray = [item]);
        localStorage.setItem("uuid", JSON.stringify(newLocalUuidArray));
      }

      simulation.connection
        .start()
        .then((result) => {
          const user = "test";
          const group = uuid;

          simulation.connection
            .invoke("JoinGroup", user, group)
            .then((res) => console.log(`Connected to the group ${group}`))
            .catch((e) => alert("Connection failed: ", e));
        })
        .catch((e) => alert("Connection failed: ", e));
    },
    receiveSimulationMessages({ latestMessages }, { simulation }) {
      if (simulation.status !== "on") {
        simulation.connection.on("ReceiveMessage", (user, msg) => {
          const regexMessage = msg.replace(/ *\([^)]*\) */g, "");
          const message = JSON.parse(regexMessage).message;
          const status = JSON.parse(regexMessage).status;

          const updatedChat = [
            ...latestMessages.current,
            { user, status, message },
          ];
          this.setMessages(updatedChat);
        });
        this.setStatus("on");
      }
    },
    async getSimulationFiles({ selectedRowUUID, type }, { site }) {
      dispatch.loader.loader(true);
      this.setFiles([]);
      this.setApiError(null);

      const baseFolder = site.infos.base_folder;
      const uuid = selectedRowUUID;

      axios({
        method: "GET",
        url: `${GET_SIMULATION_FILES}${baseFolder}/${uuid}`,
        headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
      })
        .then((res) => {
          if (type === "loadingImages") {
            const images = res.data.files.filter((file) =>
             file.name.includes(".png")
            );
            images.map((img) =>
              this.downloadFileFromList({
                fileName: img.name,
                selectedRowUUID: uuid,
                type: "loadingImages",
              })
            );
          }

          this.setFiles(res.data.files);
          dispatch.loader.loader(false);
        })
        .catch((err) => {
          console.log(`Error ${GET_SIMULATION_FILES}`, err);
          tokenExpiredDoLogin(err);
          this.setApiError("Error");
          dispatch.loader.loader(false);
        });
    },
    async downloadFileFromList({ fileName, selectedRowUUID, type }, { site }) {
      if (type === "loadingImages") dispatch.loader.loader(true);

      const baseFolder = site.infos.base_folder;
      const uuid = selectedRowUUID;
      let file = null;

      await axios({
        method: "GET",
        url: `${GET_FILE_FROM_LIST}${baseFolder}/${uuid}`,
        headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        params: { file: fileName },
        responseType: type !== "loadingGLTF" ? "blob" : null,
      })
        .then((res) => {
          if (type === "loadingGLTF") {
            file = res.data;
          } else if (type === "loadingImages") {
            const reader = new FileReader();
            reader.readAsDataURL(res.data);
            reader.onload = function () {
              dispatch.simulation.setLoadedImages({
                imageName: fileName,
                image: reader.result,
              });
              dispatch.loader.loader(false);
            };
            reader.onerror = function (error) {
              console.log("Error: ", error);
            };
          } else {
            const blob = new Blob([res.data]);
            const aEle = document.createElement("a"); // Create a label
            const href = window.URL.createObjectURL(blob); // Create downloaded link
            aEle.href = href;
            aEle.download = fileName; // File name after download
            document.body.appendChild(aEle);
            aEle.click(); // Click to download
            document.body.removeChild(aEle); // Download complete remove element
            window.URL.revokeObjectURL(href); // Release blob object
          }
        })
        .catch((err) => {
          if (type === "loadingGLTF") {
            dispatch.gltfFiles.setLoadingModels({});
            dispatch.gltfFiles.setModelsIsLoading(false);
          }
          console.log(`Error ${GET_FILE_FROM_LIST}`, err);
          tokenExpiredDoLogin(err);
        });

      if (type === "loadingGLTF") {
        return file;
      }
    },
    async getInitialConfig({ selectedRowUUID }) {
      dispatch.loader.loader(true);
      this.setInitialConfig([]);
      this.setApiError(null);

      axios({
        method: "GET",
        url: GET_INITIAL_CONFIGURATION,
        headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        params: { uuid: selectedRowUUID },
      })
        .then((res) => {
          this.setInitialConfig(res.data.data);
          dispatch.loader.loader(false);
        })
        .catch((err) => {
          console.log(`Error ${GET_INITIAL_CONFIGURATION}`, err);
          tokenExpiredDoLogin(err);
          this.setApiError("Error");
          dispatch.loader.loader(false);
        });
    },
    async getFinalConfig({ selectedRowUUID }) {
      dispatch.loader.loader(true);
      this.setFinalConfig([]);
      this.setApiError(null);

      axios({
        method: "GET",
        url: GET_FINAL_CONFIGURATION,
        headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        params: { uuid: selectedRowUUID },
      })
        .then((res) => {
          this.setFinalConfig(res.data.data);
          dispatch.loader.loader(false);
        })
        .catch((err) => {
          console.log(`Error ${GET_FINAL_CONFIGURATION}`, err);
          tokenExpiredDoLogin(err);
          this.setApiError("Error");
          dispatch.loader.loader(false);
        });
    },
    async getSimulatedTechFinalConfig({ selectedRowUUID }) {
      dispatch.loader.loader(true);
      try {
        const { data } = await axios({
          method: "GET",
          url: GET_SIMULATED_TECH_CONFIG,
          headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
          params: { uuid: selectedRowUUID },
        });
        this.setSimulatedTechFinalConfig(data.data);
      } catch (error) {
        console.log(`Error ${GET_SIMULATED_TECH_CONFIG}`, error);
        tokenExpiredDoLogin(error);
      } finally {
        dispatch.loader.loader(false);
      }
    },
    async downloadFinalConfigEval({ selectedRowUUID }) {
      dispatch.loader.loader(true);

      await axios({
        method: "GET",
        url: GET_FINAL_CONFIGURATION_EVAL,
        headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        params: { uuid: selectedRowUUID },
        responseType: "blob",
      })
        .then((res) => {
          const blob = new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const aEle = document.createElement("a"); // Create a label
          const href = window.URL.createObjectURL(blob); // Create downloaded link
          aEle.href = href;
          aEle.setAttribute("download", "eval.xlsx"); // File name after download
          document.body.appendChild(aEle);
          aEle.click(); // Click to download
          document.body.removeChild(aEle); // Download complete remove element
          window.URL.revokeObjectURL(href); // Release blob object

          dispatch.loader.loader(false);
        })
        .catch((err) => {
          console.log(`Error ${GET_FINAL_CONFIGURATION_EVAL}`, err);
          tokenExpiredDoLogin(err);
          dispatch.loader.loader(false);
        });
    },
    async downloadEvalSimulationLog({ selectedRowUUID }) {
      dispatch.loader.loader(true);

      await axios({
        method: "GET",
        url: GET_EVAL_LOG_EXPORT,
        headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        params: { uuid: selectedRowUUID },
        responseType: "blob",
      })
        .then((res) => {
          const blob = new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const aEle = document.createElement("a");
          const href = window.URL.createObjectURL(blob);
          aEle.href = href;
          aEle.setAttribute("download", "eval_simulation_log.xlsx");
          document.body.appendChild(aEle);
          aEle.click();
          document.body.removeChild(aEle);
          window.URL.revokeObjectURL(href);

          dispatch.loader.loader(false);
        })
        .catch((err) => {
          console.log(`ERROR ${GET_EVAL_LOG_EXPORT}`, err);
          tokenExpiredDoLogin(err);
          dispatch.loader.loader(false);
        });
    },
    async getTenantsContribution({ uuid }) {
      dispatch.loader.loader(true);
      try {
        const { data } = await axios({
          method: "GET",
          url: GET_TENANTS_CONTRIBUTION,
          headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
          params: { eval_uuid: uuid },
        });
        const contribution = data.data;
        this.setTenantsContribution(contribution);
      } catch (error) {
        console.log(`ERROR ${GET_TENANTS_CONTRIBUTION}`, error);
        tokenExpiredDoLogin(error);
      } finally {
        dispatch.loader.loader(false);
      }
    },
  }),
};

export default simulation;
