import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";

import withStyles from "@mui/styles/withStyles";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
} from "@mui/material";

import { contributionTableStaticHeaders } from "../../utils/tableHeaders";

const useStyles = (theme) => ({
  title: {
    fontFamily: "TIM Sans, sans-serif",
    fontWeight: "700",
    fontSize: "18px",
    marginBottom: "10px",
  },
  headerCell: {
    fontWeight: "700",
    fontFamily: "TIM Sans, sans-serif",
  },
  bodyCell: {
    fontFamily: "TIM Sans, sans-serif",
  },
});

const TenantsContributionTable = ({
  classes,
  contributionData,
  tenantsList,
}) => {
  const [tableHeader, setTableHeader] = useState(
    contributionTableStaticHeaders
  );
  const [tableBody, setTableBody] = useState([]);
  const [tooltips, setTooltips] = useState([]);

  const isDataPresent =
    tenantsList &&
    tenantsList.length > 0 &&
    contributionData &&
    contributionData.length > 0;

  useEffect(() => {
    if (isDataPresent) {
      let tenantHeaderInfos = parseTenantsAsHeader(tenantsList);
      const updatedHeaderInfos = tableHeader.concat(tenantHeaderInfos);
      setTableHeader(updatedHeaderInfos);

      setTableBody(createTableBody(updatedHeaderInfos, contributionData));

      setTooltips(extractTooltips(contributionData));
    }
  }, [tenantsList, contributionData]);

  const parseTenantsAsHeader = (tenants) => {
    return tenants.reduce((acc, tenant) => {
      return [...acc, { name: tenant, key: tenant }];
    }, []);
  };

  const createTableBody = (headerInfos, contributions) => {
    let body = [];
    contributions.map((contribution, index) => {
      body.push([]);
      headerInfos.map((info) => {
        body[index].push({
          key: info.key,
          value: contribution[info.key] || "-",
        });
      });
    });
    return body;
  };

  const extractTooltips = (contributions) => {
    const tooltipsList = contributions.reduce((acc, current) => {
      if (current.description) {
        return [...acc, current.description];
      } else {
        return [];
      }
    }, []);
    return tooltipsList;
  };

  return (
    <>
      {isDataPresent ? (
        <>
          <div className={classes.title}>Tenants Contribution Table</div>
          <p>Hover on the point cell to see the point description.</p>
          <TableContainer>
            <Table>
              <TableHeader tableHeader={tableHeader} classes={classes} />
              <TableContent
                tableBody={tableBody}
                tooltips={tooltips}
                classes={classes}
              />
            </Table>
          </TableContainer>
        </>
      ) : (
        <div>No contributions found...</div>
      )}
    </>
  );
};

const TableHeader = (props) => {
  const { classes, tableHeader } = props;

  if (tableHeader)
    return (
      <TableHead>
        <TableRow>
          {tableHeader &&
            tableHeader.map((cell, index) => (
              <TableCell
                key={`${cell.key}-${index}`}
                align='center'
                className={classes.headerCell}
              >
                {cell.name}
              </TableCell>
            ))}
        </TableRow>
      </TableHead>
    );
};

const TableContent = (props) => {
  const { classes, tableBody, tooltips } = props;

  if (tableBody && tableBody.length > 0)
    return (
      <TableBody>
        {tableBody.map((row, i) => {
          return (
            <TableRow key={`table-row-${i}`}>
              {row.map((cell, j) => {
                if (cell.key === "point") {
                  return (
                    <Tooltip
                      title={tooltips[i] || "No description available"}
                      placement='right'
                      key={`table-cell-${j}`}
                    >
                      <TableCell align='center' className={classes.bodyCell}>
                        {cell.value}
                      </TableCell>
                    </Tooltip>
                  );
                } else {
                  return (
                    <TableCell
                      key={`table-cell-${j}`}
                      align='center'
                      className={classes.bodyCell}
                    >
                      {cell.value}
                    </TableCell>
                  );
                }
              })}
            </TableRow>
          );
        })}
      </TableBody>
    );
};

const mapState = ({ simulation, simulationsStepper }) => ({
  contributionData: simulation.tenantsContribution,
  tenantsList: simulationsStepper.tenantsList,
});

const mapDispatch = ({}) => ({});

export default compose(
  withStyles(useStyles),
  connect(mapState, mapDispatch)
)(TenantsContributionTable);
