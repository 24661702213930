import React, { useState, useEffect } from "react";
import { useParams} from "react-router-dom";

import {Dialog, Grid,Button, DialogTitle,DialogContent, TextField } from "@mui/material";
import { FormControl, InputLabel, Select, MenuItem,Box } from "@mui/material";
import EnhancedTableBox from "../../components/PlaygroundComponents/EnhancedTableBox";
import {GET_ANTENNA_MSI_INFO,PUT_ANTENNA_MSI,GET_FILES_FROM_GOOGLE_STORAGE, POST_MSI_QUEUE} from "../../utils/api"
import useHttpService from "../../hooks/useHttpService";
const visibleColumns = [
    {
      id: "file_name",
      alignRight: false,
      disablePadding: false,
      label: "File Name",
    },
    {
        id: "frequency",
        alignRight: false,
        disablePadding: false,
        label: "Frequency",
    },
    
    {
        id: "el_tilt",
        alignRight: false,
        disablePadding: false,
        label: "Electric Tilt",
    },
    {
        id: "gain",
        alignRight: false,
        disablePadding: false,
        label: "Gain",
    },   

  ];

  const actions = [
    {
      action: "edit",
      icon: "edit",
      tooltip: "edit msi",
    },
    {
      action: "delete",
      icon: "delete",
      tooltip: "delete msi",
    },
];
const schema = {
    file_name: {
      title: "Filename",
      type: "text",
      md: 5,
    },
    frequency: {
        title: "Frequency",
        type: "number",
        md: 5,
    },
    el_tilt: {
    title: "Electric tilt",
    type: "number",
    md: 5,
    },
    gain: {
        title: "Gain",
        type: "real",
        md: 5,
        },
  };

const AntennaMsi = () => {         
    const httpService = useHttpService();  
    const { manufacturer, model_code, freq_min, freq_max } = useParams();    
    const [openDialog, setOpenDialog] = useState(false);
    
    
    const [selectedMsiFile, setSelectedMsiFile] = useState("");
    const [tilt,setTilt] = useState();
    const [msiFileList, setMsiFileList] = useState([]);
    const [fileOnCloud, setFileOnCloud] = useState();

    

    useEffect(() => {
        httpService.getAPI(GET_FILES_FROM_GOOGLE_STORAGE + "efesto-msi/lstobj?search=MSI", findMsiFileList)
    }, []);

    const addNewMsiFile = () => {
        setOpenDialog(!openDialog);
      };

    async function findMsiFileList (e)  {
        const models_with_file_on_cloud = []
        const file_list = []
        for (var j = 0; j < e.length; j++){
            // if (e[j].includes(manufacturer.toUpperCase() + "/" + model_code.toUpperCase()) && (e[j].includes('.msi') || e[j].includes('.MSI') || e[j].includes('.txt') || e[j].includes('.TXT')))
            if (e[j].includes(manufacturer.toUpperCase() + "/" + model_code) && (e[j].includes('.msi') || e[j].includes('.MSI') || e[j].includes('.txt') || e[j].includes('.TXT')))     
            {file_list.push(e[j].split("/")[3]) }            
                models_with_file_on_cloud.push(e[j].split("/")[2])
        }
        const file_list_set = new Set(file_list)
        setMsiFileList(Array.from(file_list_set))
        if (models_with_file_on_cloud.includes(model_code))
        // if (models_with_file_on_cloud.includes(model_code.toUpperCase()))
            setFileOnCloud(true)
    }
    
    async function onChangeFile(e){
        setSelectedMsiFile((e.target.value));
    }

    const handleCloseCancel = () => {
        setOpenDialog(false);
    };

    async function importMsiFile() {
        const queue_msg = {
            "queueMsg":
            {                
                // "antenna_model": model_code.toUpperCase(),
                "manufacturer": manufacturer.toUpperCase(),
                "antenna_model": model_code,

                "msi_filename": selectedMsiFile,
                "single_upload": true,
                "el_tilt":tilt      
            }
          }    
        httpService.postAPI(POST_MSI_QUEUE, queue_msg) ;
        setOpenDialog(false);
    }

    return (
        <div>        
        <Grid container spacing={5} padding={5}>
            <Grid justifyContent='center' paddingLeft='3em'>
                <h2>{model_code + " : " + freq_min +" - " + freq_max}</h2>
                <Button
                    variant='contained'
                    onClick={addNewMsiFile}   
                    sx={{ color: "#fff", marginTop: "0.5rem"}}
                    >
                    Add a new MSI file
                </Button>
            </Grid>
        </Grid>
        <Grid padding={5}>
            <Grid item md={12}>
            <EnhancedTableBox
                title='List of MSI files'
                apiURL={GET_ANTENNA_MSI_INFO+"?model_code="+ model_code + "&freq_max=" + freq_max +"&freq_min=" + freq_min}
                editURL= {PUT_ANTENNA_MSI}
                deleteURL = {PUT_ANTENNA_MSI}
                valueMember='id'
                visibleColumns={visibleColumns}
                actions={actions}
                editJsonSchema={schema}
                orderBy='frequency'
            />
            </Grid>
        </Grid>
        <Dialog
        fullWidth
        maxWidth='lg'
        open={openDialog}
        onClose={handleCloseCancel}
        >
            <DialogTitle>ADD A NEW MSI FILE</DialogTitle>
            <DialogContent>                
                <Grid item sx={{ boxSizing: "border-box" }} justifyContent='center'>
                    <Box paddingLeft='3em' justifycontent='center' alignItems='center'>
                    {!fileOnCloud && <h4>No single MSI files found for {model_code}</h4>}
                    </Box>
                </Grid>
                <Grid container spacing={5} padding={5}>
                    <Grid item md={4}>
                        <TextField
                            autoFocus    
                            type = "number"   
                            variant='outlined'                     
                            fullWidth
                            margin='dense'
                            id='el_tilt'
                            label='Electric Tilt'
                            onChange={(e) => setTilt(e.target.value)}
                        />
                    </Grid>                       
                    <Grid item md={6}>  
                        <FormControl fullWidth margin='dense'>
                            <InputLabel id='demo-simple-select-label'>MSI File</InputLabel>
                            <Select
                            labelId='demo-simple-select-label'
                            id='demo-simple-select'
                            value={selectedMsiFile}
                            onChange={onChangeFile}
                            label="MSI File"
                            >
                            {msiFileList &&
                            msiFileList.map((msiFile) => (
                                <MenuItem
                                value = {msiFile}
                                name = {msiFile}
                                >
                                {msiFile}
                                </MenuItem>
                            ))}
                            </Select>
                        </FormControl>
                    </Grid>     
                </Grid>
                <Grid container spacing={5} padding={5}>
                    <Grid item sx={{ boxSizing: "border-box" }} justifyContent='center'>
                        <Button
                        variant='contained'                    
                        disabled={!fileOnCloud || selectedMsiFile=="" || tilt==null}
                        onClick={importMsiFile} 
                        sx={{ color: "#fff", marginTop: "0.5rem"}}
                        >
                        Import
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>

        
        </div>
    );
};

export default AntennaMsi;
