import axios from "axios";
import {
  POST_UPLOAD_MASSIVE,
  GET_MASSIVE_LIST,
  DELETE_MASSIVE,
  POST_CLONE_MASSIVE,
  POST_DUPLICATE_MASSIVE,
  GET_MASSIVE_SITES,
  GET_STATISTICAL_MASSIVE_INFO,
  GET_DTM_AND_SHAPEFILE,
  POST_RUN_MASSIVE_SIMULATION,
  GET_MASSIVE_SIMULATION_STATISTICS,
  GET_MASSIVE_FINAL_CONFIGURATION,
  GET_MASSIVE_SIMULATION_RESULT,
  GET_MASSIVE_SIMULATION_ERRORS,
  GET_MASSIVE_HAS_DTM_SHAPEFILE_EXCEL,
  PUT_MASSIVE_OFFSET,
  tokenExpiredDoLogin,
} from "../../utils/api";

const duplicateMessage = {
  id_massive: null,
  description: "MASSIVE_DUPLICATE_TEST",
  duplicate: true,
  power_increment: null,
};

const runSimulationMessage = {
  id_massive: null,
  duplicate: false,
  to_be_optimized: false,
};

const massive = {
  state: {
    massiveList: [],
    massiveSites: [],
    statisticalInfos: [],
    selectedMassive: null,
    errorMessages: [],
    errorMessagesAsString: null,
    warningMessages: [],
    warningMessagesAsString: null,
    refreshMassiveList: false,
  },
  reducers: {
    setMassiveList(state, value) {
      return { ...state, massiveList: value };
    },
    setMassiveSites(state, value) {
      return { ...state, massiveSites: value };
    },
    setStatisticalInfos(state, value) {
      return { ...state, statisticalInfos: value };
    },
    setSelectedMassive(state, value) {
      return { ...state, selectedMassive: value };
    },
    setErrorMessages(state, value) {
      return { ...state, errorMessages: value };
    },
    setWarningMessages(state, value) {
      return { ...state, warningMessages: value };
    },
    setErrorMessagesAsString(state, value) {
      if (value)
        value = value.reduce((a, b) => (a ? a : "") + (b ? b : "") + "\n");
      return { ...state, errorMessagesAsString: value };
    },
    setWarningMessagesAsString(state, value) {
      if (value)
        value = value.reduce((a, b) => (a ? a : "") + (b ? b : "") + "\n");
      return { ...state, warningMessagesAsString: value };
    },
    setRefreshMassiveList(state, value) {
      return { ...state, refreshMassiveList: value };
    },
    resetState(state) {
      return {
        ...state,
        massiveList: [],
        massiveSites: [],
        statisticalInfos: [],
        selectedMassive: null,
      };
    },
  },
  effects: (dispatch) => ({
    async uploadMassive({ file, em_offset }) {
      dispatch.loader.loader(true);

      const formData = new FormData();
      formData.append("formFile", file, file.name);

      let id_massive = null;

      await axios({
        method: "POST",
        url: `${POST_UPLOAD_MASSIVE}?note=${file.name}`,
        headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        data: formData,
      })
        .then((res) => {
          if (res.data?.result === 1 && res.data?.data.warnings) {
            this.setWarningMessages(res.data.data.warnings);
            this.setWarningMessagesAsString(res.data.data.warnings);
          }
          if (res.data?.result === 0) {
            if (res.data.errors) {
              this.setErrorMessages(res.data.errors);
              this.setErrorMessagesAsString(res.data.errors);
            }
            if (res.data.message) {
              if ((typeof res.data.message).toLowerCase() === "string") {
                this.setErrorMessages([res.data.message]);
                this.setErrorMessagesAsString([res.data.message]);
              } else {
                this.setErrorMessages(res.data.message);
                this.setErrorMessagesAsString(res.data.message);
              }
            }
          } else {
            this.setRefreshMassiveList(true);
            id_massive = res.data.data.id_massive;
          }
          dispatch.loader.loader(false);
        })
        .catch((err) => {
          console.log(`Error ${POST_UPLOAD_MASSIVE}`, err);
          tokenExpiredDoLogin(err);
          dispatch.loader.loader(false);
        });
      if (!id_massive) return;

      const data = {
        id_massive,
        em_offset,
      };

      await axios({
        method: "PUT",
        url: `${PUT_MASSIVE_OFFSET}`,
        headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        data,
      })
        .then((res) => {
          dispatch.loader.loader(false);
        })
        .catch((err) => {
          console.log(`Error ${PUT_MASSIVE_OFFSET}`, err);
          tokenExpiredDoLogin(err);
          dispatch.loader.loader(false);
        });
    },
    async getMassiveList() {
      dispatch.loader.loader(true);

      await axios({
        method: "GET",
        url: GET_MASSIVE_LIST,
        headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
      })
        .then((res) => {
          const orderedData = res?.data?.data;
          orderedData?.length > 0 &&
            orderedData.sort((a, b) => {
              return a.id > b.id ? -1 : 1;
            });
          orderedData && this.setMassiveList(orderedData);
          dispatch.loader.loader(false);
        })
        .catch((err) => {
          console.log(`Error ${GET_MASSIVE_LIST}`, err);
          tokenExpiredDoLogin(err);
          dispatch.loader.loader(false);
        });
    },

    async deleteMassive(id) {
      dispatch.loader.loader(true);

      await axios({
        method: "DELETE",
        url: DELETE_MASSIVE,
        headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        data: { ids: [id] },
      })
        .then((res) => {
          dispatch.loader.loader(false);
        })
        .catch((err) => {
          console.log(`Error ${DELETE_MASSIVE}`, err);
          tokenExpiredDoLogin(err);
          dispatch.loader.loader(false);
        });
    },

    async cloneMassive(message) {
      dispatch.loader.loader(true);

      console.log({
        ...duplicateMessage,
        id_massive: parseInt(message.id_massive),
        description: message.description,
        power_increment: Number(parseFloat(message.power_increment).toFixed(2)),
      });

      await axios({
        method: "POST",
        url: POST_DUPLICATE_MASSIVE,
        headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        data: {
          queueMsg: {
          ...duplicateMessage,
          id_massive: parseInt(message.id_massive),
          description: message.description,
          power_increment: Number(
            parseFloat(message.power_increment).toFixed(2)
          ),
          }
        },
      })
        .then((res) => {
          dispatch.loader.loader(false);
        })
        .catch((err) => {
          console.log(`Error ${POST_DUPLICATE_MASSIVE}`, err);
          tokenExpiredDoLogin(err);
          dispatch.loader.loader(false);
        });
    },

    async checkDtmAndShapefile(id, { massive }) {
      dispatch.loader.loader(true);
      this.setMassiveSites([]);

      await axios({
        method: "GET",
        url: GET_DTM_AND_SHAPEFILE,
        headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        params: { id_massive: id },
      })
        .then((res) => {
          const dtm = res?.data?.data["dtm files not present"];
          const shapefile = res?.data?.data["shapefiles not present"];
          let tmp = massive.massiveSites;
          tmp.map((el) => {
            el.hasDtm = !dtm ? true : dtm.includes(el.site_code) ? false : true;
            el.hasShapefile = !shapefile
              ? true
              : shapefile.includes(el.site_code)
              ? false
              : true;
          });
          this.setMassiveSites(tmp);
          dispatch.map.setMassives(tmp);
          dispatch.loader.loader(false);
        })
        .catch((err) => {
          console.log(`Error ${GET_DTM_AND_SHAPEFILE}`, err);
          tokenExpiredDoLogin(err);
          dispatch.loader.loader(false);
        });
    },

    async getMassiveSites(id) {
      dispatch.loader.loader(true);

      await axios({
        method: "GET",
        url: GET_MASSIVE_SITES,
        headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        params: { id_massive: id },
      })
        .then((res) => {
          if (res?.data?.data?.length > 0) {
            this.setMassiveSites(res.data.data);
          }
          dispatch.loader.loader(false);
        })
        .catch((err) => {
          console.log(`Error ${GET_MASSIVE_SITES}`, err);
          tokenExpiredDoLogin(err);
          dispatch.loader.loader(false);
        });
    },

    async runSimulation(message) {
      dispatch.loader.loader(true);

      await axios({
        method: "POST",
        url: POST_RUN_MASSIVE_SIMULATION,
        headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        data: {
          queueMsg: {
          ...runSimulationMessage,
          id_massive: parseInt(message.id_massive),
          to_be_optimized: message.to_be_optimized,
          }
        },
      })
        .then((res) => {
          dispatch.loader.loader(false);
        })
        .catch((err) => {
          console.log(`Error ${POST_RUN_MASSIVE_SIMULATION}`, err);
          tokenExpiredDoLogin(err);
          dispatch.loader.loader(false);
        });
    },

    async getSimulationStatistics(id_massive) {
      dispatch.loader.loader(true);

      await axios({
        method: "GET",
        url: GET_MASSIVE_SIMULATION_STATISTICS,
        headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        params: {
          id_massive,
        },
      })
        .then((res) => {
          if (res?.data?.data?.length > 0) {
            this.setStatisticalInfos(res.data.data);
          }
          dispatch.loader.loader(false);
        })
        .catch((err) => {
          console.log(`Error ${GET_MASSIVE_SIMULATION_STATISTICS}`, err);
          tokenExpiredDoLogin(err);
          dispatch.loader.loader(false);
        });
    },

    async exportFinalMassiveConfiguration(id_massive) {
      dispatch.loader.loader(true);

      await axios({
        method: "GET",
        url: GET_MASSIVE_FINAL_CONFIGURATION,
        headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        params: {
          id_massive,
        },
        responseType: "blob",
      })
        .then((res) => {
          const blob = new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const aEle = document.createElement("a"); // Create a label
          const href = window.URL.createObjectURL(blob); // Create downloaded link
          aEle.href = href;
          aEle.setAttribute("download", "massive_final_configuration.xlsx"); // File name after download
          document.body.appendChild(aEle);
          aEle.click(); // Click to download
          document.body.removeChild(aEle); // Download complete remove element
          window.URL.revokeObjectURL(href); // Release blob object

          dispatch.loader.loader(false);
        })
        .catch((err) => {
          console.log(`Error ${GET_MASSIVE_FINAL_CONFIGURATION}`, err);
          tokenExpiredDoLogin(err);
          dispatch.loader.loader(false);
        });
    },

    async exportMassiveResult(id_massive) {
      dispatch.loader.loader(true);

      await axios({
        method: "GET",
        url: GET_MASSIVE_SIMULATION_RESULT,
        headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        params: {
          id_massive,
        },
        responseType: "blob",
      })
        .then((res) => {
          const blob = new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const aEle = document.createElement("a"); // Create a label
          const href = window.URL.createObjectURL(blob); // Create downloaded link
          aEle.href = href;
          aEle.setAttribute("download", "massive_simulation_result.xlsx"); // File name after download
          document.body.appendChild(aEle);
          aEle.click(); // Click to download
          document.body.removeChild(aEle); // Download complete remove element
          window.URL.revokeObjectURL(href); // Release blob object

          dispatch.loader.loader(false);
        })
        .catch((err) => {
          console.log(`Error ${GET_MASSIVE_SIMULATION_RESULT}`, err);
          tokenExpiredDoLogin(err);
          dispatch.loader.loader(false);
        });
    },
    async exportMassiveSimulationErrors(id_massive) {
      dispatch.loader.loader(true);

      await axios({
        method: "GET",
        url: GET_MASSIVE_SIMULATION_ERRORS,
        headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        params: {
          id_massive,
        },
        responseType: "blob",
      })
        .then((res) => {
          const blob = new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const aEle = document.createElement("a"); // Create a label
          const href = window.URL.createObjectURL(blob); // Create downloaded link
          aEle.href = href;
          aEle.setAttribute("download", "massive_simulation_errors.xlsx"); // File name after download
          document.body.appendChild(aEle);
          aEle.click(); // Click to download
          document.body.removeChild(aEle); // Download complete remove element
          window.URL.revokeObjectURL(href); // Release blob object

          dispatch.loader.loader(false);
        })
        .catch((err) => {
          console.log(`Error ${GET_MASSIVE_SIMULATION_ERRORS}`, err);
          tokenExpiredDoLogin(err);
          dispatch.loader.loader(false);
        });
    },
    async exportMassivePresenceOfDtmAndShapefile(id_massive) {
      dispatch.loader.loader(true);

      await axios({
        method: "GET",
        url: GET_MASSIVE_HAS_DTM_SHAPEFILE_EXCEL,
        headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        params: {
          id_massive,
        },
        responseType: "blob",
      })
        .then((res) => {
          const blob = new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const aEle = document.createElement("a"); // Create a label
          const href = window.URL.createObjectURL(blob); // Create downloaded link
          aEle.href = href;
          aEle.setAttribute("download", "massive_dtm_shapefile.xlsx"); // File name after download
          document.body.appendChild(aEle);
          aEle.click(); // Click to download
          document.body.removeChild(aEle); // Download complete remove element
          window.URL.revokeObjectURL(href); // Release blob object

          dispatch.loader.loader(false);
        })
        .catch((err) => {
          console.log(`Error ${GET_MASSIVE_HAS_DTM_SHAPEFILE_EXCEL}`, err);
          tokenExpiredDoLogin(err);
          dispatch.loader.loader(false);
        });
    },
  }),
};

export default massive;
