/* eslint-disable no-debugger */
import axios from "axios";
import {
  GET_OFFICES,
  GET_OFFICES_FROM_BOUNDS,
  GET_GEO_JSON,
  GET_SRID,
  tokenExpiredDoLogin,
} from "../../utils/api";

const map = {
  state: {
    center: [41.891943, 12.497571],
    zoom: 6,
    bounds: [
      [48.09375860961484, 24.03176665306],
      [35.049214203926994, 2.0044229030609],
    ],
    usebounds: false,
    offices: [],
    selectedOffice: null,
    neighbourhood: [],
    canLoadNeighbourhood: true,
    gpsPosition: null,
    massives: [],
    geoJson: null,
  },
  reducers: {
    resetMapParams(state) {
      return {
        ...state,
        center: [41.891943, 12.497571],
        zoom: 6,
      };
    },
    setCenter(state, value) {
      return { ...state, center: value };
    },
    setZoom(state, value) {
      return { ...state, zoom: value };
    },
    setOffices(state, value) {
      let tmp = state.offices;
      tmp = tmp.concat(value);
      return { ...state, offices: tmp };
    },
    setBounds(state, coords) {
      const newBounds = [];
      coords.map((e) => newBounds.push([e[1], e[0]]));
      return { ...state, usebounds: true, bounds: newBounds };
    },
    setNeighbourhood(state, value) {
      return { ...state, neighbourhood: value };
    },
    resetOffices(state) {
      return { ...state, offices: [] };
    },
    setSelectedOffice(state, value) {
      return { ...state, selectedOffice: value };
    },
    resetSelectedOffice(state, _) {
      return { ...state, selectedOffice: null };
    },
    setCanLoadNeighbourhood(state, value) {
      return { ...state, canLoadNeighbourhood: value };
    },
    setGpsPosition(state, value) {
      return { ...state, gpsPosition: value };
    },
    setMassives(state, value) {
      return { ...state, massives: value };
    },
    setGeoJson(state, value) {
      return { ...state, geoJson: value };
    },
    resetMapState(state, _) {
      return {
        ...state,
        center: [41.891943, 12.497571],
        zoom: 6,
        bounds: [
          [48.09375860961484, 24.03176665306],
          [35.049214203926994, 2.0044229030609],
        ],
        usebounds: false,
        offices: [],
        selectedOffice: null,
        neighbourhood: [],
        canLoadNeighbourhood: true,
        gpsPosition: null,
        massives: [],
        geoJson: null,
      };
    },
  },
  effects: (dispatch) => ({
    async loadOffices(location) {
      dispatch.loader.loader(true);

      if (!location) {
        dispatch.loader.loader(false);
        return null;
      }

      let params = {};
      if (!location.hasOwnProperty("freetext")) {
        params.id_municipality = location.id_municipality?.value || null;
        params.address = location.address || null;
        params.original_site_code = location.original_site_code || null;
        params.site_code = location.site_code || null;
        params.site_name = location.site_name || null;
      } else {
        params = location;
      }

      this.resetOffices();
      this.resetMapParams();
      this.setNeighbourhood([]);

      await axios({
        method: "GET",
        url: GET_OFFICES,
        headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        params: params,
      })
        .then((res) => {
          dispatch.loader.loader(false);
          this.setSelectedOffice(null);
          res?.data?.data && this.setOffices(res.data.data);
          res?.data?.data[0]?.geometry?.coordinates &&
            this.setCenter(res.data.data[0].geometry.coordinates);

          if (res.data.data.length === 1) {
            this.setZoom(16);
          } else {
            this.setZoom(6);
          }
        })
        .catch((err) => {
          console.log(`Error ${GET_OFFICES_FROM_BOUNDS}`, err);
          tokenExpiredDoLogin(err);
          dispatch.loader.loader(false);
        });
    },
    async loadOfficesFromBounds({ bounds, zoom }) {
      dispatch.loader.loader(true);

      if (!bounds) {
        dispatch.loader.loader(false);
        return null;
      }

      const payload = {
        zoom,
        southwest_lon: bounds.getSouthWest().lng,
        southwest_lat: bounds.getSouthWest().lat,
        northeast_lon: bounds.getNorthEast().lng,
        northeast_lat: bounds.getNorthEast().lat,
      };

      await axios({
        method: "GET",
        url: GET_OFFICES_FROM_BOUNDS,
        headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        params: payload,
      })
        .then((res) => {
          res.data?.data && this.setNeighbourhood(res.data.data);
          dispatch.loader.loader(false);
        })
        .catch((err) => {
          console.log(`Error ${GET_OFFICES_FROM_BOUNDS}`, err);
          tokenExpiredDoLogin(err);
          dispatch.loader.loader(false);
        });
      // }
    },
    async getGeoJson(site_code) {
      if (this.geoJson) return;
      dispatch.loader.loader(true);

      await axios({
        method: "GET",
        url: GET_GEO_JSON,
        headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        params: {
          site_code,
          // epsg: srid,
        },
      })
        .then((res) => {
          res?.data?.data?.features && this.setGeoJson(res.data.data);
          dispatch.loader.loader(false);
        })
        .catch((err) => {
          console.log(`Error ${GET_GEO_JSON}`, err);
          tokenExpiredDoLogin(err);
          dispatch.loader.loader(false);
        });
    },
  }),
};

export default map;
