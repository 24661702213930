import axios from "axios";
import { GET_TOKEN, tokenExpiredDoLogin } from "../../utils/api";

const login = {
  state: {
    username: localStorage.getItem("user") || "",
    password: "",
    showPassword: false,
    rememberMe: !!localStorage.getItem("user"),
    userRoutes: [],
  },
  reducers: {
    setUsername(state, value) {
      return { ...state, username: value };
    },
    setPassword(state, value) {
      return { ...state, password: value };
    },
    setShowPassword(state, value) {
      return { ...state, showPassword: value };
    },
    setRememberMe(state, value) {
      return { ...state, rememberMe: value };
    },
    setUserRoutes(state, value) {
      return { ...state, userRoutes: value };
    },
  },
  effects: (dispatch) => ({
    async getToken(_, { login }) {
      dispatch.loader.loader(true);

      const { username, password, rememberMe } = login;

      await axios({
        method: "POST",
        url: GET_TOKEN,
        data: { username: username, password: password },
      })
        .then((res) => {
          localStorage.setItem("token", res.data.data.token);
          console.log(res.data.data.token);
          if (rememberMe) localStorage.setItem("user", username);
          dispatch.loader.loader(false);
          // window.location.reload()
          window.location.assign("/Efesto/cem");
        })
        .catch((err) => {
          console.log(`Error ${GET_TOKEN}`, err);
          tokenExpiredDoLogin(err);
          dispatch.loader.loader(false);
        });
    },
  }),
};

export default login;
