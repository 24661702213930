const gltfFiles = {
  state: {
    names: {},
    loadedModels: {},
    loadingModels: {},
    modelIsLoading: false,
    modelsVisibilityList: {},
    modelsGhostedList: {},
  },
  reducers: {
    setGltfFiles(state, newFiles) {
      const a = {
        ...state,
        names: newFiles.filter(
          (file) =>
            file.name.includes(".gltf") || file.name.includes(".SceneJS")
        ),
      };
      return a;
    },
    setLoadedModels(state, modelInfos) {
      const { modelName, model } = modelInfos;
      return {
        ...state,
        loadedModels: { ...state.loadedModels, [modelName]: model },
      };
    },
    setLoadingModels(state, value) {
      return { ...state, loadingModels: value };
    },
    setModelIsLoading(state, value) {
      return { ...state, modelIsLoading: value };
    },
    setModelsVisibilityList(state, value) {
      return { ...state, modelsVisibilityList: value };
    },
    setModelsGhostedList(state, value) {
      return { ...state, modelsGhostedList: value };
    },
    resetLoadedModels(state, _) {
      return { ...state, loadedModels: {} };
    },
    resetLoadingModels(state, _) {
      return { ...state, loadingModels: {} };
    },
    resetModelsVisibilityList(state, _) {
      return { ...state, modelsVisibilityList: {} };
    },
    resetModelsGhostedList(state, _) {
      return { ...state, modelsGhostedList: {} };
    },
    resetGltfState(state, _) {
      return {
        ...state,
        names: {},
        loadedModels: {},
        loadingModels: {},
        modelIsLoading: false,
        modelsVisibilityList: {},
        modelsGhostedList: {},
      };
    },
  },
  effects: {},
};

export default gltfFiles;
