import React from "react";
import { useState, useEffect, useContext} from "react";
import { Grid } from "@mui/material";
import useHttpService from "../../../hooks/useHttpService";
import { TextField } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { FormControl, InputLabel, Select, MenuItem,Box } from "@mui/material";
import {GET_MANUFACTURERS_LIST_II,
        POST_MSI_QUEUE,
        POST_SITE_SHAPEFILE,
        GET_FILES_FROM_GOOGLE_STORAGE,
        PUT_ANTENNA_PORT,
        POST_ANTENNA
      } from "../../../utils/api"
import ComboBox from "../../../components/PlaygroundComponents/ComboBox";
import Button from "@mui/material/Button";
import { UserRoleContext } from "../../../contexts/user-role.context";
import { useNavigate } from "react-router-dom";

const ManageMsi = () => {      
  const httpService = useHttpService();  
  const navigate = useNavigate(); 
  const [selectedManufacturer, setSelectedManufacturer] = useState("");
  const [selectedAntennaModel, setSelectedAntennaModel] = useState("");
  const [selectedAntennaModelForDatasheet, setSelectedAntennaModelForDatasheet] = useState("");


  const [selectedMsi, setSelectedMsi] = useState("");
  const [selectedDatasheet, setSelectedDatasheet] = useState("");

  const [manufacturerList, setManufacturerList] = useState([]);
  const [modelList, setModelList] = useState([]);
  const [msiList, setMsiList] = useState([]);
  const [datasheetList, setDatasheetList] = useState([]);


  const { hasWritePermission } = useContext(UserRoleContext);

  useEffect(() => {
    httpService.getAPI(GET_FILES_FROM_GOOGLE_STORAGE + "efesto-msi/lstobj?search=MSI", findManufacturerList)
  }, []);

  async function findManufacturerList(e){
    const manufacturer_list = []
    for (var j = 0; j < e.length; j++){
      if (e[j].split("/").length > 2) 
      manufacturer_list.push(e[j].split("/")[1]) 
    }
    const manufacturer_list_set = new Set(manufacturer_list)
    setManufacturerList(Array.from(manufacturer_list_set))  }
  


  async function onChangeManufacturer (e) {
    setSelectedManufacturer((e.target.value).toUpperCase());
    setSelectedAntennaModel("")
    setSelectedAntennaModelForDatasheet("")
    setSelectedMsi("")
    setSelectedDatasheet("")
    setModelList([])
    setMsiList([])
    setDatasheetList([])
    httpService.getAPI(GET_FILES_FROM_GOOGLE_STORAGE + "efesto-msi/lstobj?search="+e.target.value, findModelList)
  }

  async function findModelList(e){
    const model_list = []
    for (var j = 0; j < e.length; j++){
      if (e[j].split("/").length > 3) 
      model_list.push(e[j].split("/")[2]) 
    }
    const model_list_set = new Set(model_list)
    setModelList(Array.from(model_list_set))
  }

  async function onChangeAntennaModel(e){
    setSelectedAntennaModel((e.target.value));
    httpService.getAPI(GET_FILES_FROM_GOOGLE_STORAGE + "efesto-msi/lstobj?search="+selectedManufacturer+'/'+e.target.value, findMsiList)
  }

  async function onChangeAntennaModelForDatasheet(e){
    setSelectedAntennaModelForDatasheet(e.target.value);
    httpService.getAPI(GET_FILES_FROM_GOOGLE_STORAGE + "efesto-msi/lstobj?search="+selectedManufacturer+'/'+e.target.value, findDatasheetList)

  }

  async function findMsiList (data)  {
    const msi_list = []
    for (var j = 0; j < data.length; j++){
      if(data[j].includes('MSI') && data[j].includes('.zip')){
        msi_list.push(data[j].split("/")[3]) 
      }
    }
    const msi_list_set = new Set(msi_list)
    setMsiList(Array.from(msi_list_set))

  }

  async function findDatasheetList (data)  {    
    const datasheet_list = []
    for (var j = 0; j < data.length; j++){
      if(data[j].includes('DATASHEET') && data[j].includes('.zip')){        
        datasheet_list.push(data[j].split("/")[3]) 
      }
    }        
    const datasheet_list_set = new Set(datasheet_list)
    setDatasheetList(Array.from(datasheet_list_set))

  }
  
  const onChangeMsi = (e) => {
    setSelectedMsi((e.target.value));
  }
  const onChangeDatasheet = (e) => {
    setSelectedDatasheet((e.target.value));
  }

  const uploadMsi =  async (e) => {    
    const antenna ={
      "manufacturer" : selectedManufacturer,
      "model_code" : selectedAntennaModel
    }    
    httpService.postAPI(POST_ANTENNA,antenna)
    const update_msi_filename = {
      "model_code": selectedAntennaModel,
      "msi_filename": "MSI/"+ selectedManufacturer+"/"+selectedAntennaModel+"?file="+ selectedMsi,
    }
    if(selectedAntennaModel!==""){
      httpService.putAPI(PUT_ANTENNA_PORT,update_msi_filename)
    }

    const queue_msg = {
      "queueMsg":
      {
          "manufacturer": selectedManufacturer,
          "antenna_model": selectedAntennaModel,
          "msi_filename": "MSI/"+ selectedManufacturer+"/"+selectedAntennaModel+"?file="+ selectedMsi,
          "single_upload": false

      }
    }    
      httpService.postAPI(POST_MSI_QUEUE, queue_msg) ;
      navigate(`/Tasks`);
  
    //httpService.getAPI(GET_FILES_FROM_GOOGLE_STORAGE + "efesto-msi/lstobj?search=.zip" , sendMessageToMsiModule)

    //----------------------Uploading MSI to storage-------------------------------------//
    // const file = e.target.files[0];
    // const formData = new FormData();    
    // const zipFileToUpload = new File([file],selectedAntennaModel+ '.zip');
    // formData.append("file", zipFileToUpload);
    // httpService.postAPI(POST_SITE_SHAPEFILE+"_MSI/"+selectedManufacturer, formData, sendMessageToMsiModule ) ; 
    // alert("Uploading to storage")
  }
  

  const updateDatasheet = async (e) => {
    const update_datasheet_filename = {
      "model_code": selectedAntennaModel,
      "datasheet_filename": "DATASHEET/"+ selectedManufacturer+"/"+selectedAntennaModelForDatasheet+"?file="+ selectedDatasheet
    }
    if(selectedAntennaModel!==""){
      httpService.putAPI(PUT_ANTENNA_PORT,update_datasheet_filename)
    }

  }

  // function sendMessageToMsiModule(data){  
  //   for (var i = 0; i < data.length; i++) {
  //     if (data[i].includes('MSI') && data[i].includes(selectedManufacturer+'/'+selectedMsi+'/'+selectedMsi+'.zip')){
  //         const update_filenames = {
  //           "model_code":selectedMsi,
  //           "msi_filename": selectedMsi,
  //           "datasheet_filename": selectedDatasheet
  //         }
  //         httpService.putAPI(PUT_ANTENNA_PORT,update_filenames)
  //         console.log(data[i]);

  //         const queue_msg = {
  //           "queueMsg":
  //           {
  //               "manufacturer": selectedManufacturer,
  //               "antenna_model": selectedMsi
  //           }
  //         }    
  //           httpService.postAPI(POST_MSI_QUEUE, queue_msg) ;
  //           navigate(`/Tasks`);
  //         }
  //       }

  //       if (data[i].includes('DATASHEET') && data[i].includes(selectedManufacturer+'/'+selectedMsi+'/'+selectedMsi+'.zip')){
  //         const update_datasheet_filename = {
  //           "model_code":selectedMsi,
  //           "datasheet_filename": data[i]
  //         }
  //         httpService.putAPI(PUT_ANTENNA_PORT,update_datasheet_filename)
  //         console.log(data[i]);
  //       }
  //     }

  const uploadDatasheets = (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    const zipFileToUpload = new File([file],selectedMsi+ '.zip');
    formData.append("file", zipFileToUpload);
    httpService.postAPI(POST_SITE_SHAPEFILE+"Datasheets/"+selectedManufacturer, formData) ;

  }


  return (
    <div>
        <Grid>
          <Box paddingLeft='3em' justifycontent='center' alignItems='center'>
            <h2>MSI Upload Page</h2>
          </Box>
        </Grid>
      <Grid container spacing={5} padding={5}>
        <Grid item md={3}>  
          <FormControl fullWidth>
            <InputLabel id='demo-simple-select-label'>Manufacturer</InputLabel>
            <Select
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              value={selectedManufacturer}
              onChange={onChangeManufacturer}
              label="Manufacturer"
            >
            {manufacturerList &&
              manufacturerList.map((manufacturer) => (
                <MenuItem
                  value = {manufacturer}
                  name = {manufacturer}
                >
                  {manufacturer}
                </MenuItem>
              ))}
            </Select>
            </FormControl>
          </Grid>
        {/* <Grid item md={3}>
          <ComboBox
            label='Manufacturer'
            apiURL={`${GET_MANUFACTURERS_LIST_II}`}
            valueMember='manufacturer'
            displayMember='manufacturer'
            onSelectChange={onChangeManufacturer}
            selectedValue={selectedManufacturer}
          />
        </Grid> */}
      </Grid>
      <Grid>
        <Box paddingLeft='3em' justifycontent='center' alignItems='center'>
          <h3>MSI</h3>
        </Box>
      </Grid>
      <Grid container spacing={5} padding={5}>
        <Grid item md={3}>  
          <FormControl fullWidth>
            <InputLabel id='demo-simple-select-label'>Antenna Model</InputLabel>
            <Select
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              value={selectedAntennaModel}
              onChange={onChangeAntennaModel}
              label="Antenna Model"
            >
            {modelList &&
              modelList.map((model) => (
                <MenuItem
                  value = {model}
                  name = {model}
                >
                  {model}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={3}>  
          <FormControl fullWidth>
            <InputLabel id='demo-simple-select-label'>Choose MSI</InputLabel>
            <Select
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              value={selectedMsi}
              onChange={onChangeMsi}
              label="Choose MSI"
            >
            {msiList &&
              msiList.map((msi) => (
                <MenuItem
                  value = {msi}
                  name = {msi}
                >
                  {msi}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
            {/* <TextField
                style={{ width: "70%" }}
                variant='outlined'
                label='Antenna Model'
                onChange={onChangeAntennaModel}
            />           */}
        </Grid>
        <Grid item md={3}> 
          <Button 
            variant='contained'
            onClick={uploadMsi}
            sx={{ color: "#fff", marginRight: "1rem", marginTop:"0.5rem"}}
            disabled={selectedAntennaModel =="" || selectedMsi==""}            
            >
              PROCESS MSI
          </Button>        
        </Grid>
      </Grid>
      <Grid>
        <Box paddingLeft='3em' justifycontent='center' alignItems='center'>
          <h3>Datasheet</h3>
        </Box>
      </Grid>
      <Grid>
        <Box paddingLeft='3em' justifycontent='center' alignItems='center'>
        {selectedAntennaModel!="" &&
                    <h5>Choose datasheet for {selectedAntennaModel}</h5>}
        </Box>
      </Grid>
      <Grid container spacing={5} padding={5}>
        <Grid item md={3}>  
          <FormControl fullWidth>
            <InputLabel id='demo-simple-select-label'>Datasheet Folder</InputLabel>
            <Select
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              value={selectedAntennaModelForDatasheet}
              onChange={onChangeAntennaModelForDatasheet}
              label="Datasheet Folder"
            >
            {modelList &&
              modelList.map((model) => (
                <MenuItem
                  value = {model}
                  name = {model}
                >
                  {model}
                </MenuItem>
              ))}
            </Select>
            </FormControl>
          </Grid>
          <Grid item md={3}>  
            <FormControl fullWidth>
              <InputLabel id='demo-simple-select-label'>Choose Datasheet</InputLabel>
              <Select
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                value={selectedDatasheet}
                label="Choose Datasheet"
                onChange={onChangeDatasheet}
              >
              {datasheetList &&
                datasheetList.map((datasheet) => (
                  <MenuItem
                    value = {datasheet}
                    name = {datasheet}
                  >
                    {datasheet}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
              {/* <TextField
                  style={{ width: "70%" }}
                  variant='outlined'
                  label='Antenna Model'
                  onChange={onChangeAntennaModel}
            />           */}
        </Grid>
        <Grid item md={3}> 
          <Button 
            variant='contained'
            onClick={updateDatasheet}
            sx={{ color: "#fff", marginRight: "1rem", marginTop:"0.5rem"}}
            disabled={selectedAntennaModelForDatasheet =="" || selectedAntennaModel=="" || selectedDatasheet == ""}            
            >
              Update Datasheet
          </Button>        
        </Grid>
      </Grid>


        {/* --------------Buttons to upload datasheet snad msi files to storage-------------*/}

        {/*<Grid item md={3}>      
          <input
            hidden
            id='button-upload-msi'
            onChange={uploadMsi}
            type='file'
            accept='.zip'
          />
          <label htmlFor='button-upload-msi'>
            <Button
              variant='contained'
              component='span'              
              disabled={!hasWritePermission}
              sx={{ color: "#fff", marginRight: "1rem" }}
            >
              UPLOAD MSI
            </Button>
          </label> 
        </Grid>
        <Grid item md={2}>      
          <input
            hidden
            id='button-upload-datasheet'
            onChange={uploadDatasheets}
            type='file'
            accept='.zip'
          />
          <label htmlFor='button-upload-datasheet'>
            <Button
              variant='contained'
              component='span'
              disabled={!hasWritePermission}
              sx={{ color: "#fff", marginRight: "1rem" }}
            >
              UPLOAD DATASHEETS
            </Button>
          </label>  
        </Grid>*/}
    </div>
  );  
};

export default ManageMsi;