const searchBox = {
  state: {
    globalFilterValue: null,
    filtersValues: {},
    filtersLists: []
  },
  reducers: {
    setGlobalFilterValue (state, value) {
      return { ...state, globalFilterValue: value }
    },
    setFiltersValues (state, { key, value }) {
      return { ...state, filtersValues: { ...state.filtersValues, [key]: value } }
    },
    setFiltersLists (state, { name, data }) {
      return { ...state, filtersLists: { ...state.filtersLists, [name]: data } }
    }
  },
  effects: {}
}

export default searchBox
