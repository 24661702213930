import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import withStyles from '@mui/styles/withStyles';
import { ChromePicker } from "react-color";
import { ClickAwayListener } from "@mui/material";

const useStyles = (theme) => ({
  colorPickerContainer: {
    width: "207px",
  },
  colorBar: {
    height: "16px",
    borderRadius: "9px",
    position: "relative",
    zIndex: 0,
  },
  colorPicker: {
    paddingTop: "10px",
    marginLeft: "-10px",
    position: "absolute",
    zIndex: 1,
  },
});

const ColorPicker = ({
  classes,
  index,
  color,
  opened,
  handlePicker,
  handleColorChange,
  clickAwayHandler,
}) => {
  return (
    <div className={classes.colorPickerContainer}>
      <div
        className={classes.colorBar}
        style={{ backgroundColor: color }}
        onClick={() => handlePicker(index)}
      />
      {opened && (
        <ClickAwayListener onClickAway={clickAwayHandler}>
          <div className={classes.colorPicker}>
            <ChromePicker
              disableAlpha
              color={color}
              onChangeComplete={(color) => handleColorChange(color, index)}
            />
          </div>
        </ClickAwayListener>
      )}
    </div>
  );
};

const mapState = () => ({});

const mapDispatch = () => ({});

export default compose(
  withStyles(useStyles),
  connect(mapState, mapDispatch)
)(ColorPicker);
