import { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { compose } from "redux";

import withStyles from "@mui/styles/withStyles";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
} from "@mui/material";

const useStyles = () => ({
  title: {
    fontFamily: "TIM Sans, sans-serif",
    fontWeight: "700",
    fontSize: "18px",
    marginBottom: "10px",
    "&:not(:first-child)": {
      marginTop: "3rem",
    },
  },
  headerCell: {
    fontWeight: "700",
    fontFamily: "TIM Sans, sans-serif",
    padding: "10px",
    letterSpacing: "normal",
    lineHeight: "normal",
  },
  bodyCell: {
    fontFamily: "TIM Sans, sans-serif",
  },
});

const TechnologiesContributionTable = ({
  classes,
  technologiesList,
  contributionData,
}) => {
  const [tableHeader, setTableHeader] = useState([
    { name: "Measure Point", key: "point" },
  ]);
  const [tableBody, setTableBody] = useState({});
  const [availableTenants, setAvailableTenants] = useState([]);

  const isDataPresent =
    technologiesList &&
    technologiesList.length > 0 &&
    contributionData &&
    contributionData.length > 0;

  useEffect(() => {
    if (isDataPresent) {
      if (contributionData[0].technology_contribution !== null) {
        const technologiesHeader = parseTechnologiesAsHeader(technologiesList);
        const updatedHeader = tableHeader.concat(technologiesHeader);
        setTableHeader(updatedHeader);

        const tenantsList = Object.keys(
          contributionData[0].technology_contribution
        );
        setAvailableTenants(tenantsList);
      }
    }
  }, []);

  useEffect(() => {
    if (contributionData && contributionData.length > 0) {
      let currentContribution = {};
      let tempMap;
      availableTenants.map((tenant) => {
        currentContribution[tenant] = [];
        contributionData.map((item) => {
          tempMap = new Map(Object.entries(item.technology_contribution));
          let mapLookup = tempMap.get(tenant);
          let reducedTempMap = Object.keys(mapLookup).reduce((acc, current) => {
            return [
              ...acc,
              {
                tech: current,
                value: mapLookup[current] !== 0 ? mapLookup[current] : "-",
              },
            ];
          }, []);
          currentContribution[tenant].push({
            point: item.point,
            description: item.description,
            technologies: reducedTempMap,
          });
        });
      });
      setTableBody(currentContribution);
    }
  }, [availableTenants, contributionData]);

  const parseTechnologiesAsHeader = (techs) => {
    return techs.reduce((acc, tech) => {
      return [...acc, { name: tech.name, key: tech.key }];
    }, []);
  };

  return (
    <>
      {availableTenants && availableTenants.length > 0 ? (
        <>
          {availableTenants.map((tenant, index) => {
            return (
              <Fragment key={index}>
                <div className={classes.title}>{tenant} Contribution Table</div>
                <p>Hover on the point cell to see the point description.</p>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {tableHeader &&
                          tableHeader.length > 0 &&
                          tableHeader.map((header) => {
                            return (
                              <TableCell
                                key={`${header.key}-${header.name}`}
                                align='center'
                                className={classes.headerCell}
                              >
                                {header.name}
                              </TableCell>
                            );
                          })}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {tableBody &&
                        tableBody[tenant] &&
                        tableBody[tenant].length > 0 &&
                        tableBody[tenant].map((row) => {
                          return (
                            <TableRow key={`point-${row.point}`}>
                              <Tooltip
                                title={
                                  row.description || "No description available"
                                }
                                placement='right'
                              >
                                <TableCell
                                  align='center'
                                  className={classes.bodyCell}
                                >
                                  {row.point}
                                </TableCell>
                              </Tooltip>
                              {row.technologies.map((tech, j) => (
                                <TableCell
                                  key={`tech-${j}`}
                                  align='center'
                                  className={classes.bodyCell}
                                >
                                  {tech.value}
                                </TableCell>
                              ))}
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Fragment>
            );
          })}
        </>
      ) : (
        <div>No contributions found...</div>
      )}
    </>
  );
};

const mapState = ({ site, simulation }) => ({
  technologiesList: site.technologiesList,
  contributionData: simulation.tenantsContribution,
});

const mapDispatch = ({}) => ({});

export default compose(
  withStyles(useStyles),
  connect(mapState, mapDispatch)
)(TechnologiesContributionTable);
