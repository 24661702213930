import { Routes, Route } from "react-router-dom";

import TenantsList from "./tenants-list";
import TenantsTechnology from "./tenants-technology";

const ManageTenants = () => {
  return (
    <Routes>
      <Route index element={<TenantsList />} />
      <Route path=':tenant' element={<TenantsTechnology />} />
    </Routes>
  );
};

export default ManageTenants;
