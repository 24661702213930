import React, { useState, useContext, useEffect } from "react";
import withStyles from "@mui/styles/withStyles";
import { Outlet, NavLink, useLocation, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import {
  AppBar,
  Toolbar,
  IconButton,
  Tabs,
  Tab,
  Tooltip,
  Grid,
  Typography,
  MenuItem,
  Popover,
  Snackbar,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import efestoLogo from "../../assets/img/efesto-white.svg";
import inwitLogo from "../../assets/img/inwit-white.svg";

import { UserRoleContext } from "../../contexts/user-role.context";
import { useSnackbar } from "../../contexts/snackbar.context";

// For future reference in implementing notification system
// import NotificationsIcon from '@mui/icons-material/Notifications'
// import AccountCircleIcon from '@mui/icons-material/AccountCircle'

const useStyles = () => ({
  container: {
    color: "#FFFFFF",
    background: "#03B8FF",
    boxShadow: "unset",
    height: "60px",
  },
  grow: {
    flexGrow: 1,
  },
  logoContainer: {
    maxWidth: "190px",
  },
  efestoLogo: {
    marginTop: "8px",
  },
  efestoText: {
    fontSize: "23px",
    fontWeight: "Bold",
    color: "#ffffff",
    marginTop: "5px",
    marginLeft: "10px",
  },
  inwitLogo: {
    marginBottom: "8px",
    marginLeft: "10px",
  },
  actionContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  separator: {
    opacity: "50%",
    width: "1px",
    height: 20,
    background: "#ffffff",
  },
  indicator: {
    height: 0,
    border: "1px solid #018ABE",
    maxWidth: "90px",
    marginBottom: "14px",
    color: "#212c64",
    backgroundColor: "unset",
    borderRadius: "25%",
  },
  rootIndicator: {
    borderBottom: "none !important",
    "& .MuiSvgIcon-root": { fill: "#212c64" },
  },
  toolbar: {
    display: "flex",
    minHeight: "55px",
  },
  tab: {
    opacity: "60%",
    height: "55px",
    textAlign: "left",
    color: "#ffffff",
    font: "Bold 14px/19px",
    letterSpacing: 0,
    textTransform: "none",
    marginRight: "3rem",
  },
  colorIcon: {
    marginLeft: 12,
    color: "#ffffff",
    "&:hover": {
      color: "#ffffff",
    },
  },
});

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const Header = ({ classes, apiMessages }) => {
  let location = useLocation();
  let params = useParams();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbar, setSnackbar] = useState(null);
  const { hasRestrictedRouteAccess } = useContext(UserRoleContext);
  const snackbarContext = useSnackbar();
  const userIsLoggedIn = localStorage.getItem("token") == null ? false : true;

  const openPopoverHandler = (event) => {
    setAnchorEl(event.target);
    setOpen(!open);
  };
  const closePopoverHandler = (_event, _reason) => {
    setAnchorEl(null);
    setOpen(!open);
  };
  const determineTabsValue = () => {
    switch (location.pathname) {
      case "/antenne":
        return "antenne";
      case `/manage/antenne/${params["*"]}`:
        return "antenne";
      case "/massivi":
        return "massivi";
      case "/manage/sites":
      case "/manage/tenants":
      case `/manage/tenants/${params["*"]}`:
      case "/manage/technologies":
        return "manage";
      default:
        return "cem";
    }
  };

  const handleCloseSnackbar = (_event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
    snackbarContext.setSnackbarInfo(null);
  };

  useEffect(() => {
    if (snackbarContext?.snackbarInfo) {
      setSnackbar(snackbarContext.snackbarInfo);
      setOpenSnackbar(true);
    }
  }, [snackbarContext]);

  useEffect(() => {
    if (Object.keys(apiMessages).length > 0) {
      setSnackbar(apiMessages);
      setOpenSnackbar(true);
    }
  }, [apiMessages]);

  return (
    <>
      {snackbar && (
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleCloseSnackbar}
            severity={snackbar?.status || ""}
            sx={{ width: "100%" }}
          >
            {snackbar?.message || ""}
          </Alert>
        </Snackbar>
      )}
      {userIsLoggedIn ? (
        <AppBar position='static' className={classes.container}>
          <Toolbar className={classes.toolbar}>
            <Grid container className={classes.logoContainer}>
              <Grid item xs={2}>
                <img
                  className={classes.efestoLogo}
                  src={efestoLogo}
                  alt='efesto logo'
                />
              </Grid>
              <Grid item xs={10}>
                <Typography className={classes.efestoText}>EFESTO</Typography>
                <img
                  className={classes.inwitLogo}
                  src={inwitLogo}
                  alt='inwit logo'
                />
              </Grid>
            </Grid>
            <Tabs
              variant='scrollable'
              scrollButtons='auto'
              value={determineTabsValue()}
              aria-label='tab'
              name='value'
              indicatorColor='secondary'
              textColor='inherit'
              classes={{
                indicator: classes.indicator,
                root: classes.rootIndicator,
              }}
            >
              <Tab
                className={classes.tab}
                component={NavLink}
                value='cem'
                label='C.E.M'
                style={{ width: "78px" }}
                to='/cem'
              />
              <Tab
                className={classes.tab}
                component={NavLink}
                value='antenne'
                label='Antennas'
                style={{ width: "78px" }}
                to='/antenne'
              />
              <Tab
                className={classes.tab}
                component={NavLink}
                value='massivi'
                label='Massives'
                style={{ width: "78px" }}
                to='/massivi'
              />
              {hasRestrictedRouteAccess && (
                <Tab
                  className={classes.tab}
                  label='Manage'
                  value='manage'
                  onClick={openPopoverHandler}
                />
              )}
              <Tab
                className={classes.tab}
                component={NavLink}
                value='tasks'
                label='Tasks'
                style={{ width: "78px" }}
                to='/tasks'
              />
            </Tabs>
            <Popover
              open={open}
              onClose={closePopoverHandler}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <MenuItem
                component={NavLink}
                to='/manage/sites'
                onClick={closePopoverHandler}
              >
                Sites
              </MenuItem>
              <MenuItem
                component={NavLink}
                to='/manage/tenants'
                onClick={closePopoverHandler}
              >
                Tenants
              </MenuItem>
              <MenuItem
                component={NavLink}
                to='/manage/technologies'
                onClick={closePopoverHandler}
              >
                Technologies
              </MenuItem>
              <MenuItem
                component={NavLink}
                to='/manage/sites_inwi'
                onClick={closePopoverHandler}
              >
                Sites INWI
              </MenuItem>
              <MenuItem
                component={NavLink}
                to='/manage/msi_upload'
                onClick={closePopoverHandler}
              >
                Msi Upload
              </MenuItem>
              <MenuItem
                component={NavLink}
                to='/manage/tenant_actual_import'
                onClick={closePopoverHandler}
              >
                Tenant Actual Import
              </MenuItem>
            </Popover>
            <div className={classes.grow} />
            <div className={classes.actionContainer}>
              <Tooltip title='Esci' aria-label='add'>
                <IconButton
                  className={classes.colorIcon}
                  aria-label='Disconnetti'
                  edge='end'
                  color='inherit'
                  onClick={() => {
                    window.localStorage.removeItem("token");
                    window.sessionStorage.clear();
                    // window.sessionStorage.getItem("token_expired") &&
                    //   window.sessionStorage.removeItem("token_expired");
                    window.location.reload();
                  }}
                  size='large'
                >
                  <ExitToAppIcon />
                </IconButton>
              </Tooltip>
            </div>
          </Toolbar>
        </AppBar>
      ) : null}
      <Outlet />
    </>
  );
};

const mapState = ({ site }) => ({
  apiMessages: site.apiMessages,
});

const mapDispatch = ({
  map: { resetMapParams, setMassives },
  massive: { resetState },
}) => ({
  setMassives: (value) => setMassives(value),
  resetMapParams: () => resetMapParams(),
  resetMassiveState: () => resetState(),
});

export default compose(
  withStyles(useStyles),
  connect(mapState, mapDispatch)
)(Header);
