import { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { UserRoleContext } from "../../contexts/user-role.context";

const RestrictedRoutes = () => {
  const { hasWritePermission } = useContext(UserRoleContext);
  return hasWritePermission ? <Outlet /> : <Navigate to='/cem' />;
};

export default RestrictedRoutes;
