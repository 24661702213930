import React, { useState } from "react";

import { Grid } from "@mui/material";
import EnhancedTableBox from "../../../components/PlaygroundComponents/EnhancedTableBox";

import { GET_SITES_INWI_API,PUT_SITES_INWI_API,POST_SITES_INWI_API } from "../../../utils/api";
import useHttpService from "../../../hooks/useHttpService";

const actions = [
  {
    action: "load",
    icon: "play_arrow",
    tooltip: "Load",
  },
];


const visibleColumns = [
  {
    id: "site_code",
    alignRight: false,
    disablePadding: false,
    label: "Site Code",
  },
  {
    id: "site_code_inwi",
    alignRight: false,
    disablePadding: false,
    label: "Site Code INWI",
  },
  {
    id: "site_name",
    alignRight: false,
    disablePadding: false,
    label: "Site Name",
  },
  {
    id: "site_name_inwi",
    alignRight: false,
    disablePadding: false,
    label: "Site Name INWI",
  }, 
  {
    id: "original_site_code",
    alignRight: false,
    disablePadding: false,
    label: "Original Site Code",
  },
  {
    id: "original_site_code_inwi",
    alignRight: false,
    disablePadding: false,
    label: "Original Site Code INWI",
  },
  {
    id: "site_type",
    alignRight: false,
    disablePadding: false,
    label: "Site Type",
  },
  {
    id: "site_type_inwi",
    alignRight: false,
    disablePadding: false,
    label: "Site Type INWI",
  },
  {
    id: "address",
    alignRight: false,
    disablePadding: false,
    label: "Address",
  },
  {
    id: "address_inwi",
    alignRight: false,
    disablePadding: false,
    label: "Address INWI",
  },
  {
    id: "municipality",
    alignRight: false,
    disablePadding: false,
    label: "Municipality",
  },
  {
    id: "municipality_inwi",
    alignRight: false,
    disablePadding: false,
    label: "Municipality INWI",
  },
  {
    id: "longitude",
    alignRight: false,
    disablePadding: false,
    label: "Longitude",
  },
  {
    id: "longitude_inwi",
    alignRight: false,
    disablePadding: false,
    label: "Longitude INWI",
  },
  {
    id: "latitude",
    alignRight: false,
    disablePadding: false,
    label: "Latitude",
  },
  {
    id: "latitude_inwi",
    alignRight: false,
    disablePadding: false,
    label: "Latitude INWI",
  },
];

const schema = {
  site_code: {
    title: "Site Code",
    type: "text",
    md: 6,
  }
}

const ManageSitesInwi = () => {    
  const httpService = useHttpService();
  function onAction(action, row) { 
    const data = {"site_code":row.site_code_inwi} 
    switch (action) {     
      case 'load':
        if (row.site_code !== null)
          {httpService.putAPI(
            PUT_SITES_INWI_API,
            data
          );}
        else
          {httpService.postAPI(
            POST_SITES_INWI_API,
            data
          );} 
        break;
      default:
        break;
    }  
  }
 
  const [selectedSite, setSelectedSite] = useState(null);
  function onChangeSite(e) {
    setSelectedSite(e.site_code);
  }
  return (
    <div>
      <Grid container spacing={5} padding={5}>
        <Grid item md={12}>
          <EnhancedTableBox
            addButton
            title='INWI Sites'
            apiURL={GET_SITES_INWI_API}
            valueMember='uuid'
            onSelectChange={onChangeSite}
            visibleColumns={visibleColumns}
            actions={actions}
            onAction={onAction}
            orderBy='site_code_inwi' 
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default ManageSitesInwi;
