import React, { useState, useEffect, memo } from "react";
import withStyles from "@mui/styles/withStyles";
import { compose } from "redux";
import { connect } from "react-redux";
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SiteParamsModal from "../SiteParamsModal";

const useStyles = (theme) => ({
  tableContainer: {
    width: "100%",
    height: "100%",
    zIndex: 0,
  },
  tableHeaderRow: {
    height: "80px",
  },
  tableHeaderCell: {
    fontFamily: "TIM Sans, sans-serif",
    backgroundColor: "#FFF",
    fontWeight: "bold",
  },
  tableBody: {},
  tableBodyRow: {
    height: "80px",
    "&:hover": {
      background: "rgba(236, 236, 237, 0.5) !important",
    },
  },
  tableBodyCell: {
    borderTop: "1px solid #ccc",
    borderBottom: "none",
    textAlign: "left",
  },
  button: {
    fontWeight: "bold",
    height: "32px",
    padding: "3px 24px",
    background: "white",
    border: "0.5px solid #018ABE",
    borderRadius: "100px",
    font: "700 13px/20px",
    color: "#018ABE",
    marginLeft: "13px",
    textTransform: "none",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    minWidth: "64px",
    "&:hover": {
      backgroundColor: "white",
      cursor: "pointer",
      outline: "none",
    },
    "&:focus": {
      outline: "none",
    },
    "&:active": {
      outline: "none",
    },
  },
});

const SiteParamsTableBox = memo(
  ({
    classes,
    tableInfos: { tableHeaders, tableRows },
    loadingParameters,
    possibleTechModelTenant,
    setSiteParameters,
    isSimulationStepper,
    setSimulationsStepperParameters,
    isFinalConfig,
    setFinalConfigParams,
    sectors,
    evalSelected,
    putModifySaveEval,
    selectedUUID,
    getParameters,
  }) => {
    const [openParamsModal, setOpenParamsModal] = useState(false);
    const [paramsModalTypeIsModify, setParamsModalTypeIsModify] =
      useState(false);
    const [activeTenantRow, setActiveTenantRow] = useState(null);

    const borderColor = "1px solid #ccc";

    useEffect(() => {
      if (tableRows && tableRows.length > 0) {
        const newParams = tableRows.map((row, index) => {
          const possibleAntenna = possibleTechModelTenant[row.model.name];
          const possibleTenant =
            possibleTechModelTenant[row.model.name]?.tenants[row.tenant.name] ||
            null;
          if (possibleAntenna && possibleTenant) {
            return {
              ...row,
              tenant: {
                ...row.tenant,
                values: row.tenant.values.map((value) => {
                  if (
                    possibleTenant.includes(value.key) &&
                    value.name !== "P"
                  ) {
                    return { ...value, name: "-" };
                  } else {
                    return { ...value };
                  }
                }),
              },
            };
          } else {
            return { ...row };
          }
        });

        if (isSimulationStepper) {
          setSimulationsStepperParameters(newParams);
        } else if (isFinalConfig) {
          setFinalConfigParams(newParams);
        } else {
          setSiteParameters(newParams);
        }
      }
    }, [possibleTechModelTenant]);

    const deleteTenant = async (row) => {
      const antennaRowId = row.uuid;
      let rowFoundIndex;
      let tmp = [...sectors];

      tmp.forEach((sec, index) => {
        let sectorFound = sec.antennas.some(
          (element) => element.uuid === antennaRowId
        );
        if (sectorFound) {
          rowFoundIndex = index;
        }
      });

      tmp[rowFoundIndex].antennas = tmp[rowFoundIndex].antennas.filter(
        (antenna) => antenna.uuid !== antennaRowId
      );

      if (tmp[rowFoundIndex].antennas.length === 0) {
        tmp = tmp.filter((sector) => sector.antennas.length !== 0);
      }

      await putModifySaveEval(tmp, evalSelected, true);
      await getParameters("isSimulationsStepper", selectedUUID);
    };

    if (tableRows) {
      return (
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table stickyHeader>
            <TableHead>
              <TableRow className={classes.tableHeaderRow}>
                {tableHeaders.map((el, i) => {
                  const firstDigit = el.name.match(/\d/); // will give you the first digit in the string
                  const name = el.name.includes("5GNR")
                    ? "5G" + " " + el.name.match(/\d+/g)[1]
                    : el.name.indexOf(firstDigit) > -1
                    ? el.name.match(/[a-zA-Z]+/g) + " " + el.name.match(/\d+/g)
                    : el.name;

                  return (
                    <TableCell
                      key={`table-header-${el.key}`}
                      className={classes.tableHeaderCell}
                      style={{
                        maxWidth:
                          el.name === "vedi" ||
                          el.name === "modifica" ||
                          el.name === "elimina"
                            ? "50px"
                            : "unset",
                      }}
                    >
                      {`${name.split(" ")[0]}\n`}
                      {name.split(" ")[1]}
                    </TableCell>
                  );
                })}
                <TableCell className={classes.tableHeaderCell}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={classes.tableBody}>
              {tableRows.length > 0 ? (
                tableRows.map((row, i) => {
                  return (
                    <React.Fragment key={`table-body-row-${i}`}>
                      <TableRow className={classes.tableBodyRow}>
                        {Object.keys(row).map((cell, j) => {
                          //Per l'inserimento del tenant con i valori delle technologie
                          if (row[cell]?.values) {
                            return row[cell].values.map((e, index) => {
                              /* add tenant name */
                              const tenantCell =
                                index === 0 ? (
                                  <TableCell
                                    style={{
                                      fontWeight: "bold",
                                      borderBottom:
                                        i === tableRows.length - 1
                                          ? borderColor
                                          : "none",
                                      borderTop: i === 0 ? "none" : borderColor,
                                    }}
                                    className={classes.tableBodyCell}
                                    key={`table-body-cell-${j}`}
                                  >
                                    <span>{row[cell].name}</span>
                                  </TableCell>
                                ) : null;

                              /* add VALUES of tenant */
                              return (
                                <React.Fragment
                                  key={`table-body-cell-tenant-values-${index}`}
                                >
                                  {tenantCell}
                                  <TableCell
                                    style={{
                                      fontWeight: "bold",
                                      borderBottom:
                                        i === tableRows.length - 1
                                          ? borderColor
                                          : "none",
                                      borderTop: i === 0 ? "none" : borderColor,
                                      color: e.name.includes("P")
                                        ? "#2F861B"
                                        : "#000000",
                                      textAlign:
                                        e.name.includes("P") ||
                                        e.name.includes("-")
                                          ? "center"
                                          : "left",
                                    }}
                                    className={classes.tableBodyCell}
                                  >
                                    <span>{e.name}</span>
                                  </TableCell>
                                </React.Fragment>
                              );
                            });
                          } else if (cell !== "uuid") {
                            return (
                              <TableCell
                                rowSpan={1}
                                className={classes.tableBodyCell}
                                style={{
                                  borderTop:
                                    !row[cell].show || i === 0 ? "none" : "",
                                  borderBottom:
                                    i === tableRows.length - 1
                                      ? borderColor
                                      : "none",
                                }}
                                key={`table-body-cell-${j}`}
                              >
                                <span>
                                  {row[cell].name ?? ""}
                                  {row[cell].vedi && (
                                    <VisibilityIcon
                                      onClick={() => {
                                        setOpenParamsModal(true);
                                        setActiveTenantRow(row);
                                      }}
                                      style={{ color: "#018ABE" }}
                                    />
                                  )}
                                  {row[cell].modifica && (
                                    <EditIcon
                                      style={{ color: "#018ABE" }}
                                      onClick={() => {
                                        setParamsModalTypeIsModify(true);
                                        setOpenParamsModal(true);
                                        setActiveTenantRow(row);
                                      }}
                                    />
                                  )}
                                  {row[cell].elimina && (
                                    <DeleteIcon
                                      style={{ color: "#018ABE" }}
                                      onClick={() => {
                                        deleteTenant(row);
                                      }}
                                    />
                                  )}
                                </span>
                              </TableCell>
                            );
                          }
                        })}
                      </TableRow>
                    </React.Fragment>
                  );
                })
              ) : (
                <TableRow className={classes.tableBodyRow}>
                  <TableCell
                    className={classes.tableBodyCell}
                    style={{ border: "none" }}
                  >
                    non ci sono risultati
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>

          {activeTenantRow && (
            <SiteParamsModal
              openParamsModal={openParamsModal}
              setOpenParamsModal={setOpenParamsModal}
              activeTenantRow={activeTenantRow}
              setActiveTenantRow={setActiveTenantRow}
              paramsModalTypeIsModify={paramsModalTypeIsModify}
              isFinalConfig={isFinalConfig}
            />
          )}
        </TableContainer>
      );
    } else {
      return <div>{loadingParameters && "Caricamento..."}</div>;
    }
  }
);

const mapState = ({ site }) => ({
  loadingParameters: site.loadingParameters,
  possibleTechModelTenant: site.technologiesByModelAndTenant,
  sectors: site.sectors,
  evalSelected: site.evalSelected,
  selectedUUID: site.selectedUUID,
});

const mapDispatch = ({
  site: { setSiteParameters, getSiteParameters },
  simulationsStepper: { setParameters, putModifySaveEval },
  simulation: { setFinalConfigParams },
}) => ({
  getParameters: (type, uuid) => getSiteParameters({ type, uuid }),

  setSiteParameters: (value) => setSiteParameters(value),
  setSimulationsStepperParameters: (value) => setParameters(value),
  putModifySaveEval: (sectors, evalSelected) =>
    putModifySaveEval({ sectors, evalSelected }),
  setFinalConfigParams: (value) => setFinalConfigParams(value),
});

export default compose(
  withStyles(useStyles),
  connect(mapState, mapDispatch)
)(SiteParamsTableBox);
