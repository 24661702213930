/* eslint-disable no-debugger */
import { useRef } from "react";
import { useMap, useMapEvents } from "react-leaflet";

const MapInstance = ({
  cluster,
  selectedOffice,
  loadOfficesFromBounds,
  canLoadNeighbourhood,
  officeClicked,
  clusterAlreadyShowed,
  gpsPosition,
  setGpsPosition,
}) => {
  const canLoadNeighbourhoodRef = useRef(true);
  const showDefaultMap = useRef(true); //Only to see the map in its initial state

  const mapInstance = useMap();

  useMapEvents({
    movestart() {
      showDefaultMap.current = false;
      if (mapInstance.getZoom() >= 10 && !canLoadNeighbourhoodRef.current) {
        canLoadNeighbourhoodRef.current = true;
      }
    },
    moveend() {
      showDefaultMap.current = false;
      if (mapInstance.getZoom() >= 10 && canLoadNeighbourhood) {
        canLoadNeighbourhoodRef.current = false;

        loadOfficesFromBounds({
          bounds: mapInstance.getBounds(),
          zoom: mapInstance.getZoom(),
        });
      }
    },
  });

  if (selectedOffice && officeClicked.current) {
    mapInstance.setView(
      [
        selectedOffice.geometry.coordinates[1],
        selectedOffice.geometry.coordinates[0],
      ],
      18
    );
    officeClicked.current = false;
  } else if (
    cluster &&
    !clusterAlreadyShowed.current &&
    Object.keys(cluster.getBounds()).length > 0
  ) {
    clusterAlreadyShowed.current = true;
    mapInstance.fitBounds(cluster.getBounds());
  } else if (gpsPosition) {
    mapInstance.setView(gpsPosition, 20);
    setGpsPosition(null);
  } else if (showDefaultMap.current) {
    mapInstance.setView([41.891943, 12.497571], 6);
  }

  return null;
};

export default MapInstance;
