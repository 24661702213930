import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import withStyles from '@mui/styles/withStyles';
import { CircularProgress } from '@mui/material'

const useStyles = theme => ({
  loader: {
    position: 'fixed',
    background: 'rgba(255,255,255,.5)',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 2000,
    textAlign: 'center',
    display: 'flex',
    flex: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  }
})

const Loader = ({ classes, isLoading }) => {
  return (
    isLoading && (
      <div className={classes.loader}>
        <CircularProgress />
      </div>
    )
  )
}

const mapState = ({ map, loader }) => ({
  map: map,
  isLoading: loader.loading
})

const mapDispatch = () => ({})

export default compose(
  withStyles(useStyles),
  connect(mapState, mapDispatch)
)(Loader)
